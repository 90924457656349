import React, { useState } from "react";
import CreateStory from "../hoc/createStory";
import "../../scss/components/StoryBarCard.scss";
import { Dropdown } from "./";
import { TreeDot } from "../../icons/icons";
import { openStory } from "../../redux/utils/embedApi";

const StoryBarCard = ({
  image,
  deleteStory,
  id,
  isBig = false,
  platform,
  media,
  type,
}) => {
  const [dropOpen, setDropOpen] = useState(false);
  // const getImage = (place) => {
  //   switch (place) {
  //     case "youtube":
  //       return youtube;

  //     case "instagram":
  //       return insta;

  //     case "tiktok":
  //       return tiktok;

  //     default:
  //       return insta;
  //   }
  // };

  const download = async (url2) => {
    let blob = await fetch(url2).then((r) => r.blob());
    var uri = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.download = "instagtamData";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className={`bar__card phh${isBig ? " big__card" : ""}`}
      style={{ backgroundImage: `url('${image}')` }}
      onClick={() =>
        openStory({
          storyData: {
            _id: "a5a5a5",
            backgroundColor: `rgb(190, 195, 201)`,
            cards: [
              {
                _id: "fsdf",
                backgroundMedia: {
                  full: media + (type === "video" ? ".mp4" : ""),
                  small: media + (type === "video" ? ".mp4" : ""),
                  small_thumb: media + (type === "video" ? ".mp4" : ""),
                  thumb: media + (type === "video" ? ".mp4" : ""),
                  original: media + (type === "video" ? ".mp4" : ""),
                },
              },
            ],
            texts: [],
            products: [],
          },
        })
      }
    >
      <div
        className="st__drop"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Dropdown
          button={<TreeDot />}
          isOpen={dropOpen}
          setIsOpen={setDropOpen}
          toRight
          dropdown={
            <div className="setting__block">
              <div className="setting__part sp1">
                <p
                  className="setting__link fi1"
                  onClick={(e) => download(media)}
                >
                  Download
                </p>
                <CreateStory image={media} isVideo={type === "video"}>
                  <p className="setting__link">Edit</p>
                </CreateStory>
              </div>
            </div>
          }
        />
      </div>
      {/* {isBig && platform ? (
        <div className="bar__image">
          <img src={getImage(platform)} alt="" />
        </div>
      ) : null} */}
      {/* <div className="barcard__hover">
        <div className="barcard__delete" onClick={deleteStory}>
          <Trash2 />
        </div>
        <div className="barcard__drag">
          <div className="drag__big" />
          <div className="drag__small" />
          <div className="drag__big" />
        </div>
      </div> */}
    </div>
  );
};

export default StoryBarCard;
