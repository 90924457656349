import { setAuthToken } from '../utils/fetchData';
import { fetchOrgInfo, fetchAcceptOrgInvitation } from '../utils/acceptOrgInvite';

export const actions = {
	ACCEPT_ORG_INVITE_PENDING: 'ACCEPT_ORG_INVITE_PENDING',
	ACCEPT_ORG_INVITE_FULFILLED: 'ACCEPT_ORG_INVITE_FULFILLED',
	ACCEPT_ORG_INVITE_REJECTED: 'ACCEPT_ORG_INVITE_REJECTED',
	RESET_ACCEPT_ORG_INVITE_DATA: 'RESET_ACCEPT_ORG_INVITE_DATA',
	ORG_INFO_PENDING: 'ORG_INFO_PENDING',
	ORG_INFO_FULFILLED: 'ORG_INFO_FULFILLED',
	ORG_INFO_REJECTED: 'ORG_INFO_REJECTED',
	RESET_ORG_INFO_DATA: 'RESET_ORG_INFO_DATA'
};

export function acceptOrgInvite({ idToken, orgToken }) {
	return async (dispatch) => {
		dispatch({
			type: actions.ACCEPT_ORG_INVITE_PENDING
		});

		setAuthToken(idToken);

		try {
			let data = await fetchAcceptOrgInvitation(orgToken);

			dispatch({
				type: actions.ACCEPT_ORG_INVITE_FULFILLED,
				data
			});
		} catch (error) {
			console.error(error);

			dispatch({
				type: actions.ACCEPT_ORG_INVITE_REJECTED,
				error
			});
		}
	};
}

export function resetAcceptOrgInviteData() {
	return {
		type: actions.RESET_ACCEPT_ORG_INVITE_DATA
	};
}

export function getOrgInfo(orgToken) {
	return async (dispatch) => {
		dispatch({
			type: actions.ORG_INFO_PENDING
		});

		try {
			let data = await fetchOrgInfo(orgToken);

			console.log(data);

			dispatch({
				type: actions.ORG_INFO_FULFILLED,
				data
			});
		} catch (error) {
			console.error(error);

			dispatch({
				type: actions.ORG_INFO_REJECTED,
				error
			});
		}
	};
}

export function resetOrgInfoData() {
	return {
		type: actions.RESET_ORG_INFO_DATA
	};
}
