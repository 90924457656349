import React, { useState } from "react";
import { Input, Transition } from "../components/common";
import { inviteToOrg } from "../redux/actions/orgActions";
import { PlanClose } from "../icons/icons";
import { connect } from "react-redux";

const initialModal = { isOpen: false, text: "" };

const Invite = ({ orgInfo, idToken }) => {
  const [email, setEmail] = useState();
  const [body, setBody] = useState(
    `Hey you, \n\nI'd love to invite you to radd. - an amazing new platform where you can help share your stories with the world's coolest brands.\n\n${
      process.env.REACT_APP_CUSTOMER_PLATFORM
    }/brand/${
      orgInfo.invite || orgInfo.alias
    } \n\nHoping to see your stories asap. \nMe`
  );
  const [showModal, setModal] = useState(initialModal);

  const sendInvite = async () => {
    const body2 = `<div style="white-space: pre-wrap;">${body}</div>`;
    fetch(`https://api.dev.raddcontent.com/org-invite`, {
      method: "POST",
      headers: {
        Authorization: idToken,
      },
      body: JSON.stringify({
        emails: email.split(`,`),
        text: body,
        html: body2,
        name: orgInfo.name,
      }),
    })
      .then((x) => x.json())
      .then((res) => {
        if (res.result === "ok") {
          setModal({ isOpen: true, text: "Mail sent" });
        } else {
          setModal({ isOpen: true, text: "Sorry, something went wrong" });
        }
      })
      .catch(() => {
        setModal({ isOpen: true, text: "Sorry, something went wrong" });
      });
  };

  return (
    <Transition withbutton>
      <div className="invite__page page withbutton">
        <div className="page__title">Invite customers to make your stories</div>
        <div className="page__subtitle">
          Send your customers e-mail invitations so that they can add their
          stories to your dashboard
        </div>
        <div className="page__subtitle mobile__sub">
          Send user invitations on e-mail so that they can add their stories to
          your store
        </div>
        <div className="invite__group">
          <div className="invite__part">
            <Input
              label="E-mail"
              placeholder="Please enter e-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="invite__hint">
              <span className="requiredspan">*</span> For multiple email
              addresses enter a comma
            </div>
          </div>
          <div className="invite__part custom__input">
            <div className="input__label">Invitation text</div>
            <textarea
              className="input__custom invite__textarea"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
        </div>
        <div className="page__button">
          <div className="accent__button" onClick={sendInvite}>
            Send invitation
          </div>
        </div>
      </div>
      {showModal.isOpen && (
        <div className="plan-modal">
          <div
            className="plan-modal-close"
            onClick={() => setModal(initialModal)}
          >
            <PlanClose />
          </div>
          <p className="plan-modal-title">{showModal.text}</p>
          <div to="plans" onClick={() => setModal(initialModal)}>
            <div className="plan-modal-button">OK</div>
          </div>
        </div>
      )}
    </Transition>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken, orgInfo, profile } = auth;

  return {
    idToken,
    orgInfo,
    profile,
  };
};

const mapDispatchToProps = {
  inviteToOrg,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
