import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../scss/components/PlanModal.scss";
import { PlanClose } from "../../icons/icons";
import {
  showPlanPopupPopup,
  hideInfoPopup,
} from "../../redux/actions/infoPopupActions";
import store from "../../redux/reducers";
import { Link } from "react-router-dom";

export const openPlanModal = (plan) =>
  store.dispatch(showPlanPopupPopup({ text: plan, titleText: "" }));

const PlanModal = () => {
  const dispatch = useDispatch();
  const { isVisible, text, titleText, storiesText } = useSelector((state) =>
    state.infoPopup.toJS()
  );
  return (
    isVisible && (
      <div className="plan-modal">
        <div
          className="plan-modal-close"
          onClick={() => dispatch(hideInfoPopup())}
        >
          <PlanClose />
        </div>
        <p className="plan-modal-title">{titleText || "Time to go up"}</p>
        <p className="plan-modal-text">
          {storiesText || (
            <>
              Upgrade to the radd.
              <br /> <span style={{ textTransform: "capitalize" }}>
                {text}
              </span>{" "}
              Plan to enjoy this feature
            </>
          )}
        </p>
        <Link to="plans" onClick={() => dispatch(hideInfoPopup())}>
          <div className="plan-modal-button">Upgrade now</div>
        </Link>
      </div>
    )
  );
};

export default PlanModal;
