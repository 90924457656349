import React, { useEffect, useState } from "react";
import { Transition, AnalyticChart } from "../../components/common";

import { connect } from "react-redux";
import {
  getAllDeviceAnalytics,
  getDesktopAnalytics,
  getMobileAnalytics,
} from "../../redux/actions/storiesActions";
import { DefaultPage } from "../../components/containers";
import { Link } from "react-router-dom";
import { StoryPage } from "../../icons/icons";
import { Button } from "../../components/atoms";
import withPermission from "../../components/hoc/withPermission";

const AnalyticsUpgrade = () => (
  <DefaultPage
    title="Get full dashboard analytics"
    subtitle="Upgrade your plan to pro to use dashboard analytic"
    icon={<StoryPage />}
    cta={
      <Link to="plans">
        <Button text="Upgrade plan" />
      </Link>
    }
  />
);

const Analytics = ({
  dataProp,
  orgInfo,
  getDesktopAnalytics,
  getMobileAnalytics,
  desktopAnalyticsFetched,
  desktopAnalytic,
  mobileAnalyticsFetched,
  mobileAnalytic,
  getAllDeviceAnalytics,
}) => {
  const [load, setLoad] = useState(false);
  const [analytic, setAnalytics] = useState("All activity");
  const [date, setDate] = useState({ fromDate: null, toDate: null });

  useEffect(() => {
    if (
      dataProp.length !== 0 &&
      orgInfo.alias &&
      !mobileAnalyticsFetched &&
      !desktopAnalyticsFetched &&
      !load
    ) {
      getAllDeviceAnalytics(
        dataProp,
        orgInfo.alias,
        new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        new Date()
      );
      setLoad(true);
      // getMobileAnalytics(dataProp, orgInfo.alias, new Date(Date.now() - 6 * 24 * 60 * 60 * 1000), new Date());
    }
    // eslint-disable-next-line
  }, [dataProp, orgInfo]);

  const updateAnalytics = async (dataProp, orgInfo, fromDate, toDate) => {
    setDate({ fromDate, toDate });
    switch (analytic) {
      case "Mobile":
        await getMobileAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      case "Desktop":
        await getDesktopAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      case "All activity":
        await getAllDeviceAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      default:
        break;
    }
  };

  const changeAnalytics = async (val, dataProp, orgInfo) => {
    setAnalytics(val);
    const fromDate =
      date.fromDate || new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    const toDate = date.toDate || new Date();
    switch (val) {
      case "Mobile":
        await getMobileAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      case "Desktop":
        await getDesktopAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      case "All activity":
        await getAllDeviceAnalytics(dataProp, orgInfo, fromDate, toDate);
        break;
      default:
        break;
    }
  };

  return (
    <Transition>
      <div className="page analytic__page">
        <div className={desktopAnalyticsFetched ? "" : "pholder_item"}>
          <AnalyticChart
            data={
              desktopAnalyticsFetched && desktopAnalytic
                ? desktopAnalytic
                : [
                    [1, 1, 1, 1, 1, 1, 1],
                    [1, 1, 1, 1, 1, 1, 1],
                    [1, 1, 1, 1, 1, 1, 1],
                    [1, 1, 1, 1, 1, 1, 1],
                  ]
            }
            title="Desktop devices"
            setAnalytics={(val) =>
              changeAnalytics(val, dataProp, orgInfo.alias)
            }
            analytic={analytic}
            getAnalytic={(fromDate, toDate) =>
              updateAnalytics(dataProp, orgInfo.alias, fromDate, toDate)
            }
          />
        </div>
      </div>
    </Transition>
  );
};

const mapStateToProps = (state) => {
  const storybars = state.storybars.toJS();
  let { data } = storybars;
  const auth = state.auth.toJS();
  let { orgInfo } = auth;
  const dataProp = [...data];

  const stories = state.stories.toJS();
  let {
    desktopAnalyticsFetched,
    desktopAnalytic,
    mobileAnalyticsFetched,
    mobileAnalytic,
  } = stories;
  return {
    orgInfo,
    dataProp,
    desktopAnalyticsFetched,
    desktopAnalytic,
    mobileAnalyticsFetched,
    mobileAnalytic,
  };
};

const mapDispatchToProps = {
  getDesktopAnalytics,
  getMobileAnalytics,
  getAllDeviceAnalytics,
};

export default withPermission(
  "professional",
  connect(mapStateToProps, mapDispatchToProps)(Analytics),
  AnalyticsUpgrade
);
