import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  AccountCard,
  NewInput,
  Transition,
  StoriesCount,
  Switcher,
} from "../../components/common";
import { Pencel2 } from "../../icons/icons";
import { resetPassword } from "../../redux/actions/authActions";
import { uploadFile } from "../../redux/utils/fileUpload";
import { setOrgData, updateOrdData } from "../../redux/actions/orgActions";
import { defaultImage } from "../../globalConst";
import cn from "classnames";

const initPasswordData = {
  password: "",
  newPassword: "",
  repeatPassword: "",
};

let timeout;
const Account = () => {
  const dispatch = useDispatch();
  const { email, forgotPasswordSuccess, plan } = useSelector(
    (state) => state.auth.toJS().profile
  );
  const { name, logo, alias, isPurchaseEmail, price, invite } = useSelector((state) => state.auth.toJS().orgInfo
  );
  const { idToken } = useSelector((state) => state.auth.toJS());

  const [companyData, setCompanyData] = useState({
    name: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [passwordData, setPasswordData] = useState(initPasswordData);
  const [image, setImage] = useState("");
  const [blob, setBlob] = useState("");
  const [purchaseEmail, setPurchaseEmail] = useState(isPurchaseEmail);
  const [isPrice, setIsPrice] = useState(price);
  const [error, setError] = useState(null);
  const [link, setLink] = useState("");
  const [validPreloader, setValidPreloader] = useState(false)
  const [savePreloader, setSavePreloader] = useState(false)

  useEffect(() => {
    setPasswordData(initPasswordData);
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    setCompanyData({ name });
  }, [name]);

  useEffect(() => {
    setUserData({ email });
  }, [email]);

  //User data handlers
  const handleUserDataChange = (name, value) => {
    setUserData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    handleUserDataChange("email", value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    handleUserDataChange("password", value);
  };

  //Company data handlers
  const handleCompanyDataChange = (name, value) => {
    setCompanyData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const changeLink = (val) => {
    setLink(val);
    setValidPreloader(true)
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fetch(`https://api.dev.raddcontent.com/organization/story/validate`, {
        method: "POST",
        headers: {
          Authorization: idToken,
        },
        body: JSON.stringify({ invite: val }),
      })
          .then((x) => x.json())
          .then((res) => {
            setError(String(!res.result.valid));
            setValidPreloader(false)
          });
    }, 600);
  };

  const copyToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2px";
    textArea.style.height = "2px";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Copying text command was " + msg);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  const saveLink = () => {
    if (link && error === "false") {
      setSavePreloader(true)
      fetch(
          `https://api.dev.raddcontent.com/organization/${alias}/story`,
          {
            method: "POST",
            headers: {
              Authorization: idToken,
            },
            body: JSON.stringify({ invite: link }),
          }
      ).then((x) => x.json())
          .then((res) => {
            dispatch(updateOrdData(res.result))
            setSavePreloader(false)
          })
    }
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    handleCompanyDataChange("name", value);
  };

  const handleCompanyDataSubmit = (e) => {
    e.preventDefault();
    handleLogoUpdate();
  };

  //Password data handlers
  const handlePasswordDataChange = (name, value) => {
    setPasswordData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    handlePasswordDataChange("password", value);
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    handlePasswordDataChange("newPassword", value);
  };

  const handleRepeatPasswordChange = (e) => {
    const value = e.target.value;
    handlePasswordDataChange("repeatPassword", value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(passwordData));
  };

  const handleLogoRemove = () => {
    setBlob("");
    setImage(defaultImage);
  };

  const handleLogoUpdate = async () => {
    const orgData = {
      name: companyData.name,
      logo: image || companyData.image,
    };
    if (blob) {
      const getBase64 = (blob2) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob2);
        });
      const orgLogo = await getBase64(blob);
      const orgImage = await uploadFile({
        authToken: idToken,
        mediaType: "org_logo",
        isBase64: true,
        blob: orgLogo,
      });
      orgData.logo = orgImage;
    }
    setBlob("");
    dispatch(
      setOrgData({
        authToken: idToken,
        orgAlias: alias,
        orgData: orgData,
      })
    );
  };

  const setNewOrgData = (orgData) => {
    dispatch(setOrgData({
      authToken: idToken,
      orgAlias: alias,
      orgData,
    }));
  };

  const handlePurchaseEmail = () => {
    setPurchaseEmail(!purchaseEmail);
    setNewOrgData({
      isPurchaseEmail: !isPurchaseEmail,
    });
  }

  const handlePrice = (e) => {
    setIsPrice(!isPrice);
    setNewOrgData({
      price: !price,
    });
  };

  const renderBasicInformation = () => {
    return (
        <form className="basic-info" onSubmit={handleCompanyDataSubmit}>
          <div className="basic-info-company">
            <div className="basic-info-company__logo">
              <img src={image || logo} alt="company logo"/>
              <label className="basic-info-company__logo-pencel" htmlFor="logo">
                <Pencel2/>
                <input
                    type="file"
                    accept="image/*"
                    id="logo"
                    style={{display: "none"}}
                    onChange={(e) => {
                      setImage(URL.createObjectURL(e.target.files[0]));
                      setBlob(e.target.files[0]);
                    }}
                />
              </label>
              <p>Jpg/PNG</p>
            </div>
            <div className="basic-info-company__info">
              <p className="basic-info-company__info-title">
                Update Company Logo
              </p>
              <p className="basic-info-company__info-subtitle">
                Your logo will be displayed on the story's top left corner
              </p>
              <p
                  className="basic-info-company__info-remove cursor-pointer"
                  onClick={handleLogoRemove}
              >
                Remove logo
              </p>
            </div>
          </div>
          <div>
            <NewInput
                label="Company Name"
                placeholder=""
                name="name"
                type="text"
                onChange={handleCompanyNameChange}
                value={companyData.name}
            />
          </div>
          <button className="button button--accent" type="submit">
            Update
          </button>

          <div className="link__container">
            <div
                className={`${
                    error ? (error === "true" ? "invalid" : "valid") : ""
                }`}
            >
              <label className="input__label">Invite link</label>
              <div className="link__show">
                <div className='label'>{`${process.env.REACT_APP_CUSTOMER_PLATFORM}/brand/`}</div>
                <input
                    className="new__input"
                    placeholder={invite || alias}
                    value={link}
                    onChange={(e) =>
                        changeLink(e.target.value.replace(/[^A-Za-z0-9]/gi, ""))
                    }
                />
                <div
                    className={cn("small__button button--accent", {
                      "disabled_button": validPreloader || savePreloader
                    })}
                    onClick={(e) => saveLink()}
                >
                  {(validPreloader || savePreloader) &&
                      <img src="/images/loader_white.gif" className='loader_white' alt="loader_white"/>}
                  {validPreloader && 'Checking link'}
                  {savePreloader && 'Saving'}
                  {(!savePreloader && !validPreloader) && 'Save'}
                </div>
                <div
                    className="small__button button__default"
                    onClick={() =>
                        copyToClipboard(
                            `${process.env.REACT_APP_CUSTOMER_PLATFORM}/brand/${
                                invite || alias
                            }`
                        )
                    }
                >
                  Copy
                </div>
              </div>
            </div>
          </div>

          <div className="permission__item">
            <Switcher isOpen={isPrice} setIsOpen={handlePrice}/>
            <span className='label'>Hide product price</span>
          </div>

          <div className="permission__item">
            <Switcher isOpen={purchaseEmail} setIsOpen={handlePurchaseEmail}/>
            <span className='label'>Automatic post-purchase invitation</span>
          </div>
        </form>
    );
  };

  const renderUserInformation = () => {
    return (
        <form className="user-info" onSubmit={handleCompanyDataSubmit}>
          <div>
            <NewInput
                label="Email Address"
                placeholder=""
                name="name"
                type="text"
                onChange={handleEmailChange}
                value={userData.email}
            />
          </div>
          <div>
            <NewInput
                label="Confirm passowrd"
                placeholder=""
                name="name"
                type="password"
                onChange={handlePasswordChange}
                value={userData.password}
            />
          </div>
          <button className="button button--accent" type="submit">
            Update
          </button>
        </form>
    );
  };

  const renderChangePassword = () => {
    return (
        <form onSubmit={handlePasswordSubmit}>
          <div className="grid-basic">
            <div>
              <NewInput
                  placeholder="Enter Current Password"
                  name="password"
                  type="password"
                  onChange={handleOldPasswordChange}
                  value={passwordData.password}
              />
            </div>
            <div>
              <NewInput
                  placeholder="Enter new Password"
                  name="newPassword"
                  type="password"
                  onChange={handleNewPasswordChange}
                  value={passwordData.newPassword}
              />
            </div>
            <div></div>
            <div>
              <NewInput
                  placeholder="Confirm new password"
                  name="repeatPassowrd"
                  type="password"
                  onChange={handleRepeatPasswordChange}
                  value={passwordData.repeatPassword}
              />
            </div>
          </div>
          <button className="button button--accent" type="submit">
            Update
          </button>
        </form>
    );
  };

  const renderPlanInformation = () => {
    return (
        <div className="plan-info">
          <p className="plan-info__text">Your current plan is</p>
          <p className="plan-info__plan">{plan}</p>
          <StoriesCount/>
          <br/>
          <Link className="button button--accent" to="/plans">
            Upgrade your plan &gt;
          </Link>
        </div>
    );
  };

  return (
      <Transition>
      <div className="page-wrapper account-page">
        <div className="grid-basic">
          <div className="grid-info">
            <AccountCard
              title="Basic information"
              content={renderBasicInformation()}
            />
          </div>
          <div className="grid-user">
            <AccountCard
              title="User information"
              content={renderUserInformation()}
            />
          </div>
          <div className="grid-plan">
            <AccountCard
              title="Plan information"
              content={renderPlanInformation()}
            />
          </div>
          <div className="grid-password">
            <AccountCard
              title="Password & Security"
              content={renderChangePassword()}
            />
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Account;
