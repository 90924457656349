import React, { useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { SwitchButton } from "./SwitchButton";

const ProductCard = ({
  image,
  name,
  promo,
  id,
  switchProductFunc,
  addIdToArray,
  arrId,
  isShow,
  socket,
}) => {
  const [defimage, setImage] = useState(null);

  const isBetween = (promo) => {
    const today = new Date();
    const startDate = new Date(promo.startDate);
    const endDate = new Date(promo.endDate);
    return today >= startDate && today <= endDate;
  };

  return (
    <div
      className="live-product"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="live-product-img">
        {promo && isBetween(promo) ? (
          <div className="live-product-promo">{`${promo.promo}%`}</div>
        ) : (
          ``
        )}
        <img
          src={defimage || image}
          alt="product"
          className="live-product-image"
          onError={() =>
            setImage(
              `https://dashboard.raddcontent.com/images/placeholderimage.jpg`
            )
          }
        />
      </div>
      <div className="live-product-name">
        <LinesEllipsis
          text={name || ""}
          maxLine="1"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>

      <SwitchButton
        arrId={arrId}
        isShow={isShow}
        id={id}
        socket={socket}
        addIdToArray={addIdToArray}
        switchProductFunc={switchProductFunc}
      />
    </div>
  );
};

export default ProductCard;
