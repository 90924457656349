import axios from "axios";

export let instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000,
  validateStatus: function (status) {
    return true;
  },
});

export function setAuthToken(token) {
  instance.defaults.headers.common["Authorization"] = token;
}

export function removeAuthToken() {
  delete instance.defaults.headers.common["Authorization"];
}

export async function fetchRequest(req) {
  let response = await req;

  if (response.status !== 200) {
    throw response.data;
  }

  return response.data;
}
