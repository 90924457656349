import Immutable from "immutable";
import _ from "lodash";

import { actions as authActions } from "../actions/authActions";
import { actions as userActions } from "../actions/userActions";
import { actions as orgActions } from "../actions/orgActions";

const A = Object.assign({}, authActions, userActions, orgActions);

const initialState = Immutable.fromJS({
  userInfoFetched: false,
  userInfoFetching: false,
  setUserInfoFetched: false,
  setUserInfoFetching: false,
  loggedIn: false,
  loggingIn: false,
  preloader: false,
  loginError: null,
  regError: null,
  profile: {},
  idToken: "",
  accessToken: "",
  orgInfo: {},
  hasOrg: false,
  redirectUrl: "",
  setOrgDataFetched: false,
  setOrgDataFetching: false,
  orgMembersFetched: false,
  orgMembersFetching: false,
  setOrgAdminFetched: false,
  setOrgAdminFetching: false,
  inviteToOrgFetched: false,
  inviteToOrgFetching: false,
  inviteToOrgData: {},
  removeUserFromOrgFetched: false,
  removeUserFromOrgFetching: false,
  removeUserFromOrgData: {},
  createOrganizationFetched: false,
  createOrganizationFetching: false,
  declineOrgInviteFetched: false,
  declineOrgInviteFetching: false,
  declineOrgInviteData: {},
  leaveOrgFetched: false,
  leaveOrgFetching: false,
  forgotPasswordFetching: false,
  forgotPasswordFetched: false,
  forgotPasswordSuccess: false,
  forgotPasswordFailure: false,
});

export default function reducer(state = initialState, action) {
  // eslint-disable-next-line
  let profile, idToken, accessToken, isLoggedIn, orgInfo;

  switch (action.type) {
    case A.LOGIN_START:
      return state.set("loggedIn", false).set("loggingIn", true);
    case A.LOGIN_PRELOADER:
      return state.set("preloader", action.preloader);
    case A.LOGIN_FAILURE:
      return state
        .set("loggedIn", false)
        .set("loggingIn", false)
        .set("loginError", action.error);
    case A.REGISTRATION_FAILURE:
      return state
        .set("loggedIn", false)
        .set("loggingIn", false)
        .set("regError", action.error);
    case A.LOGOUT:
      return initialState.set("userInfoFetched", true);
    case A.USER_INFO_PENDING:
      return state.set("userInfoFetching", true).set("userInfoFetched", false);
    case A.USER_INFO_FULFILLED_NOT_LOGGED:
      return state
        .set("userInfoFetching", false)
        .set("userInfoFetched", true)
        .set("loggingIn", false)
        .set("loggedIn", false);
    case A.USER_INFO_FULFILLED:
      ({ profile, idToken, accessToken, isLoggedIn, orgInfo } = action.data);

      return state
        .set("userInfoFetching", false)
        .set("userInfoFetched", true)
        .set("loggingIn", false)
        .set("loggedIn", true)
        .set("profile", Immutable.fromJS(profile))
        .set("idToken", idToken)
        .set("accessToken", accessToken)
        .set("orgInfo", Immutable.fromJS(orgInfo))
        .set("hasOrg", !_.isEmpty(orgInfo));
    case A.UPDATE_ORG_DATA_FIELDS:
          return state.set('orgInfo', action.data)
    case A.USER_INFO_REJECTED:
      return state
        .set("userInfoFetching", false)
        .set("userInfoFetched", false)
        .set("error", action.error);
    case A.SET_USER_DATA_PENDING:
      return state
        .set("setUserInfoFetched", false)
        .set("setUserInfoFetching", true);
    case A.SET_USER_DATA_FULFILLED:
      return state
        .set("setUserInfoFetched", true)
        .set("setUserInfoFetching", false)
        .set("profile", Immutable.fromJS(action.data));
    case A.SET_USER_DATA_REJECTED:
      return state
        .set("setUserInfoFetched", false)
        .set("setUserInfoFetching", false)
        .set("error", action.error);
    case A.SET_REDIRECT_URL:
      return state.set("redirectUrl", action.data);
    case A.SET_ORG_DATA_PENDING:
      return state
        .set("setOrgDataFetched", false)
        .set("setOrgDataFetching", true);
    case A.SET_ORG_DATA_FULFILLED:
      return state
        .set("setOrgDataFetched", true)
        .set("setOrgDataFetching", false)
        .set("orgInfo", Immutable.fromJS(action.data));
    case A.SET_ORG_DATA_REJECTED:
      return state
        .set("setOrgDataFetched", false)
        .set("setOrgDataFetching", false)
        .set("error", action.error);
    case A.GET_ORG_MEMBERS_PENDING:
      return state
        .set("orgMembersFetched", false)
        .set("orgMembersFetching", true);
    case A.GET_ORG_MEMBERS_FULFILLED:
      return state
        .set("orgMembersFetched", true)
        .set("orgMembersFetching", false)
        .setIn(["orgInfo", "members"], Immutable.fromJS(action.data));
    case A.GET_ORG_MEMBERS_REJECTED:
      return state
        .set("orgMembersFetched", false)
        .set("orgMembersFetching", false)
        .set("error", action.error);
    case A.SET_ORG_ADMIN_PENDING:
      return state
        .set("setOrgAdminFetched", false)
        .set("setOrgAdminFetching", true);
    case A.SET_ORG_ADMIN_FULFILLED:
      return state
        .set("setOrgAdminFetched", true)
        .set("setOrgAdminFetching", false);
    case A.SET_ORG_ADMIN_REJECTED:
      return state
        .set("setOrgAdminFetched", false)
        .set("setOrgAdminFetching", false)
        .set("error", action.error);
    case A.INVITE_TO_ORG_PENDING:
      return state
        .set("inviteToOrgFetched", false)
        .set("inviteToOrgFetching", true);
    case A.INVITE_TO_ORG_FULFILLED:
      return state
        .set("inviteToOrgFetched", true)
        .set("inviteToOrgFetching", false)
        .set("inviteToOrgData", Immutable.fromJS(action.data));
    case A.INVITE_TO_ORG_REJECTED:
      return state
        .set("inviteToOrgFetched", false)
        .set("inviteToOrgFetching", false)
        .set("error", action.error);
    case A.REMOVE_USER_FROM_ORG_PENDING:
      return state
        .set("removeUserFromOrgFetched", false)
        .set("removeUserFromOrgFetching", true);
    case A.REMOVE_USER_FROM_ORG_FULFILLED:
      return state
        .set("removeUserFromOrgFetched", true)
        .set("removeUserFromOrgFetching", false)
        .set("removeUserFromOrgData", Immutable.fromJS(action.data));
    case A.REMOVE_USER_FROM_ORG_REJECTED:
      return state
        .set("removeUserFromOrgFetched", false)
        .set("removeUserFromOrgFetching", false)
        .set("error", action.error);
    case A.CREATE_ORGANIZATION_PENDING:
      return state
        .set("createOrganizationFetched", false)
        .set("createOrganizationFetching", true);
    case A.CREATE_ORGANIZATION_FULFILLED:
      return state
        .set("createOrganizationFetched", true)
        .set("createOrganizationFetching", false)
        .set("orgInfo", action.data)
        .set("hasOrg", true);
    case A.CREATE_ORGANIZATION_REJECTED:
      return state
        .set("createOrganizationFetched", false)
        .set("createOrganizationFetching", false)
        .set("error", action.error);
    case A.DECLINE_ORG_INVITE_PENDING:
      return state
        .set("declineOrgInviteFetched", false)
        .set("declineOrgInviteFetching", true);
    case A.DECLINE_ORG_INVITE_FULFILLED:
      return state
        .set("declineOrgInviteFetched", true)
        .set("declineOrgInviteFetching", false)
        .set("declineOrgInviteData", Immutable.fromJS(action.data));
    case A.DECLINE_ORG_INVITE_REJECTED:
      return state
        .set("declineOrgInviteFetched", false)
        .set("declineOrgInviteFetching", false)
        .set("error", action.error);
    case A.LEAVE_ORG_PENDING:
      return state.set("leaveOrgFetched", false).set("leaveOrgFetching", true);
    case A.LEAVE_ORG_FULFILLED:
      return state
        .set("leaveOrgFetched", true)
        .set("leaveOrgFetching", false)
        .setIn(["profile", "organization"], null)
        .set("orgInfo", Immutable.fromJS({}))
        .set("hasOrg", false);
    case A.LEAVE_ORG_REJECTED:
      return state
        .set("leaveOrgFetched", false)
        .set("leaveOrgFetching", false)
        .set("error", action.error);
    case A.RESET_PASSWORD_START:
      return state
        .set("forgotPasswordFetching", true)
        .set("forgotPasswordFetched", false)
        .set("forgotPasswordSuccess", false)
        .set("forgotPasswordFailure", false);
    case A.RESET_PASSWORD_SUCCESS:
      return state
        .set("forgotPasswordFetching", false)
        .set("forgotPasswordFetched", true)
        .set("forgotPasswordSuccess", true)
        .set("forgotPasswordFailure", false);
    case A.RESET_PASSWORD_FAILURE:
      return state
        .set("forgotPasswordFetching", false)
        .set("forgotPasswordFetched", true)
        .set("forgotPasswordSuccess", false)
        .set("forgotPasswordFailure", true);
    default:
      return state;
  }
}
