import { setAuthToken } from "./fetchData";
import {
  fetchLogin,
  fetchRegistration,
  fetchShopifyLogin,
  fetchResetPassword,
} from "./fetchUserData";
import { uploadFile } from "../utils/fileUpload";
import { createOrganization } from "../actions/orgActions";
import store from "../reducers";
import { getUserInfo } from "../actions/authActions";
import { defaultImage } from "../../globalConst";

class Client {
  async signUp({ email, password, orgLogo, orgName, orgHomepage, plan }) {
    try {
      const res = await fetchRegistration({
        email,
        password,
        plan
      });
      if (res.result) {
        this.setToken(res.result);
        await this.createOrg({
          orgLogo,
          orgName,
          orgHomepage,
          token: res.result,
        });
        await this.getMe();
      }
    } catch (e) {}
  }

  async signIn({ email, password }) {
    try {
      const res = await fetchLogin({
        email,
        password,
      });
      if (res.result) {
        this.setToken(res.result);
        await this.getMe();
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async shopify({ email, shopifyToken, shopUrl, company, domain }) {
    try {
      const res = await fetchShopifyLogin({
        email,
        shopifyToken,
        shopUrl,
      });
      if (res.result) {
        this.setToken(res.result);
        await this.createOrg(
          {
            orgLogo: null,
            orgName: company,
            orgHomepage: domain,
            token: res.result,
          },
          false
        );
        await this.getMe(res.result);
      }
    } catch (e) {}
  }

  logout() {
    try {
      localStorage.removeItem("idToken");
      window.location.reload();
    } catch (e) {}
  }

  isLoggedIn() {
    try {
      if (!this.getToken()) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  setToken(token) {
    try {
      setAuthToken(token);
      localStorage.setItem("idToken", token);
    } catch (e) {}
  }

  getToken() {
    try {
      return localStorage.getItem("idToken");
    } catch (e) {
      return false;
    }
  }

  async getMe(token) {
    return store.dispatch(getUserInfo(token));
  }

  async createOrg({ orgLogo, orgName, orgHomepage, token }, isUpload = true) {
    const orgImage = isUpload
      ? await uploadFile({
          authToken: token,
          mediaType: "org_logo",
          isBase64: true,
          blob: orgLogo,
        })
      : defaultImage;
    return store.dispatch(
      createOrganization({
        authToken: token,
        name: orgName,
        logo: orgImage,
        homepage: orgHomepage,
      })
    );
  }

  async resetPassword({ password, newPassword, repeatPassword }) {
    await fetchResetPassword({
      authToken: this.getToken(),
      password,
      newPassword,
      repeatPassword,
    });
  }
}

export default new Client();
