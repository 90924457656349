import Immutable from 'immutable';

import {actions as A} from '../actions/acceptOrgInviteActions';

const initialState = Immutable.fromJS({
    fetched: false,
    fetching: false,
    data: {},
    error: null
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case A.ORG_INFO_PENDING:
            return state.withMutations(state => (
                state
                    .set('fetching', true)
                    .set('fetched', false)
            ));
        case A.ORG_INFO_FULFILLED:
            return state.withMutations(state => (
                state
                    .set('fetching', false)
                    .set('fetched', true)
                    .set('data', action.data)
            ));
        case A.ORG_INFO_REJECTED:
            return state.withMutations(state => (
                state
                    .set('fetching', false)
                    .set('fetched', false)
                    .set('error', action.error)
            ));
        case A.RESET_ORG_INFO_DATA:
            return initialState;
        default:
            return state;
    }
}