import store from "../reducers";
import _ from "lodash";

const Saga = window.Saga;
export function openStory(params) {
  console.log(params);

  if (params.storyData && !params.storyData.user) {
    params.storyData = _.clone(params.storyData);
    params.storyData.user = store.getState().auth.get("profile").toJS();
  }
  return Saga.openStory(params);
}

export function closeStory() {
  return Saga.closeStory();
}
