import React from "react";
import "../../scss/components/CheckBox.scss";

const CheckBox = ({ isOpen, setOpen, text }) => (
  <div className="checkbox" onClick={setOpen}>
    <div className="checkbox__outer">
      {isOpen && <div className="checkbox__inner"></div>}
    </div>
    <div className="checkbox__text">{text}</div>
  </div>
);

export default CheckBox;
