import {
  fetchStorybarData,
  fetchRemoveStorybarData,
} from "../utils/fetchStorybar";
import { updateStorybars } from "./storybarsActions";
import { updateStories } from "./storiesActions";

export const actions = {
  RESET_STORYBAR_DATA: "RESET_STORYBAR_DATA",
  STORYBAR_DATA_PENDING: "STORYBAR_DATA_PENDING",
  STORYBAR_DATA_FULFILLED: "STORYBAR_DATA_FULFILLED",
  STORYBAR_DATA_REJECTED: "STORYBAR_DATA_REJECTED",
};

export function resetStorybarData() {
  return {
    type: actions.RESET_STORYBAR_DATA,
  };
}

export function getStorybarData({ authToken, barId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.STORYBAR_DATA_PENDING,
    });

    try {
      let data = await fetchStorybarData({ authToken, barId });

      dispatch({
        type: actions.STORYBAR_DATA_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.STORYBAR_DATA_REJECTED,
        error,
      });
    }
  };
}

export function removeStoryFromBar({ authToken, barId, storyId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.STORYBAR_DATA_PENDING,
    });

    try {
      await fetchRemoveStorybarData({ authToken, barId, storyId });

      dispatch(updateStorybars({ authToken }));
      dispatch(updateStories({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.STORYBAR_DATA_REJECTED,
        error,
      });
    }
  };
}
