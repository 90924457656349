import React, { memo } from 'react';
import '../../scss/components/TutorialCard.scss';

const TutorialCard = memo(({ setTutorial, title, hint, mobileHint, last }) => {
	return (
		<div className="tutorial__card">
			<h3 className="tutorial__title">{title}</h3>
			<p className="tutorial__hint">{hint}</p>
			<p className="tutorial__hint mobilehint">{mobileHint}</p>
			<div className="tutorial__buttons">
				<div className="small__button button__default" onClick={() => setTutorial(0)}>
					Skip
				</div>
				<div
					className="small__button button__accent"
					onClick={() => {
						last ? setTutorial(0) : setTutorial((t) => t + 1);
					}}
				>
					{last ? 'Finish' : 'Next'}
				</div>
			</div>
		</div>
	);
});

export default TutorialCard;
