import React from "react";

const LiveEnd = () => {
    return (
        <div className="live-offline live-end-bgc">
            <div className="live-offline__container">
                <div className="live-offline-icon">
                    <svg width="85" height="185" viewBox="0 0 85 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7377 160.393C13.7856 152.977 13.6898 142.574 13.5749 137.709C13.4505 132.381 13.6037 130.916 13.7281 128.201C14.1495 119.062 16.2081 117.984 16.2991 116.232C16.2991 116.232 16.1794 101.05 15.9975 80.8035C15.9161 71.4053 17.9125 68.437 19.6983 63.4052L49.1376 62.5146C51.8522 75.5083 53.4465 98.0246 53.6667 110.707C53.7816 117.17 53.6667 149.487 54.0354 153.135C54.3418 155.946 55.328 158.665 56.4627 159.838C58.8565 162.265 62.61 164.377 66.2247 166.263C65.8321 167.383 57.7745 167.699 56.027 166.909C53.2358 165.626 48.7258 162.294 47.7348 161.672C46.8278 160.68 46.1518 159.5 45.7552 158.216C45.3587 156.932 45.2516 155.576 45.4415 154.246V153.944C45.3553 152.374 39.9357 134.358 38.4851 124.223C37.8292 119.646 38.4851 111.775 38.4851 111.775L34.2193 92.3178C34.2193 92.3178 32.4718 107.38 30.8057 115.298C29.4508 121.757 23.3178 155.491 22.322 160.36C22.2741 163.462 20.3064 173.066 19.8563 175.527C19.4063 177.988 14.0776 178.179 13.6324 173.981C13.3308 171.209 13.7281 161.734 13.7377 160.393Z" fill="#EDBABB"/>
                        <path d="M69.1891 168.959C69.3471 168.959 69.5291 170.716 68.6625 171.429C67.6619 172.252 64.7606 173.564 61.0023 172.947C57.2439 172.329 55.2283 171.075 53.7538 169.739C52.2792 168.403 50.2157 166.517 48.5161 166.081C46.9409 165.674 45.2365 164.889 44.7434 164.372C44.2503 163.855 44.3891 161.638 44.3891 161.638L69.1891 168.959Z" fill="#9E2C6A"/>
                        <path d="M45.4387 153.959C45.3011 153.96 45.1679 154.007 45.0616 154.095C44.9552 154.182 44.8821 154.303 44.8546 154.438C44.7205 155.046 45.0461 156.884 44.7684 157.88C44.3874 159.35 44.2738 160.876 44.4333 162.385C44.6104 163.529 47.1718 164.401 48.8092 165.095C50.4465 165.789 52.3903 167.216 54.0756 168.71C55.7608 170.203 59.5 171.87 61.9608 171.984C65.7191 172.162 68.1848 171.204 68.9795 169.849C69.961 168.178 69.262 167.173 65.001 164.995C64.1967 164.583 61.372 162.763 60.4144 162.122C59.3943 161.431 58.4334 160.655 57.5418 159.805C57.0912 159.372 56.6645 158.915 56.2635 158.435C55.8413 158.004 55.4863 157.512 55.2103 156.975C55.1672 156.86 55.1337 156.721 55.0954 156.602C54.9843 156.263 54.901 155.915 54.8464 155.563C54.7889 155.194 54.7602 154.658 54.4251 154.428C53.9427 154.209 53.4091 154.128 52.8835 154.194C52.3227 154.215 51.7642 154.277 51.2126 154.38C50.571 154.505 49.8337 154.538 49.4364 155.137C49.1711 155.621 49.0327 156.165 49.0342 156.717C49.0342 156.975 49.0342 157.234 49.0007 157.487C49.0007 157.598 49.039 157.799 48.8906 157.746C48.7422 157.693 48.5985 157.31 48.5459 157.176C48.4357 156.884 48.3687 156.578 48.2442 156.291C48.1198 156.003 48.0288 155.534 47.7367 155.333C46.5638 154.557 45.8552 154.706 45.5201 154.313L45.4387 153.959Z" fill="#F351A9"/>
                        <path d="M65.666 165.373C60.2129 165.578 58.3936 168.944 58.1016 171.055C59.3199 171.574 60.6125 171.897 61.9317 172.013C65.69 172.19 68.3088 171.237 69.1132 169.887C70.0228 168.298 69.4818 166.986 65.666 165.373Z" fill="#FAFAFA"/>
                        <path d="M57.5096 165.166C57.4479 165.167 57.388 165.145 57.342 165.104C57.2888 165.056 57.2561 164.99 57.2508 164.918C57.2454 164.847 57.2678 164.776 57.3133 164.721C57.9637 164.076 58.7458 163.58 59.6061 163.266C60.4664 162.952 61.3844 162.828 62.2972 162.902C62.3322 162.907 62.3657 162.919 62.396 162.937C62.4262 162.956 62.4525 162.98 62.4735 163.008C62.4944 163.037 62.5094 163.069 62.5178 163.103C62.5262 163.138 62.5277 163.173 62.5223 163.208C62.5181 163.243 62.5072 163.276 62.4899 163.307C62.4727 163.337 62.4496 163.364 62.422 163.385C62.3944 163.407 62.3628 163.422 62.329 163.431C62.2953 163.44 62.2601 163.443 62.2254 163.438C60.3822 163.179 58.3379 164.348 57.7155 165.075C57.6907 165.106 57.6592 165.13 57.6234 165.145C57.5876 165.161 57.5487 165.168 57.5096 165.166Z" fill="#F0F0F0"/>
                        <path d="M55.2064 163.707C55.1557 163.704 55.1067 163.687 55.0651 163.657C55.0235 163.628 54.9909 163.588 54.971 163.541C54.9511 163.494 54.9447 163.443 54.9524 163.393C54.9602 163.342 54.9818 163.295 55.0149 163.257C55.6643 162.612 56.4457 162.116 57.3052 161.802C58.1647 161.487 59.082 161.363 59.9941 161.437C60.029 161.443 60.0625 161.455 60.0928 161.473C60.123 161.491 60.1494 161.515 60.1703 161.544C60.1912 161.572 60.2063 161.605 60.2146 161.639C60.223 161.673 60.2245 161.709 60.2191 161.744C60.2156 161.779 60.205 161.813 60.188 161.843C60.171 161.874 60.1479 161.901 60.1201 161.922C60.0923 161.944 60.0605 161.96 60.0264 161.968C59.9924 161.977 59.957 161.979 59.9223 161.974C58.079 161.715 56.0395 162.883 55.4123 163.611C55.3878 163.642 55.3564 163.666 55.3207 163.683C55.2849 163.7 55.2458 163.708 55.2064 163.707Z" fill="#F0F0F0"/>
                        <path d="M53.084 162.05C53.0223 162.046 52.9635 162.023 52.9164 161.983C52.8673 161.931 52.8398 161.863 52.8398 161.791C52.8398 161.72 52.8673 161.651 52.9164 161.6C53.5661 160.955 54.3474 160.459 55.2069 160.145C56.0663 159.831 56.9836 159.707 57.8956 159.78C57.9665 159.791 58.0303 159.829 58.0733 159.887C58.1163 159.944 58.135 160.016 58.1254 160.087C58.1213 160.122 58.1102 160.156 58.0927 160.187C58.0751 160.217 58.0516 160.244 58.0234 160.266C57.9953 160.287 57.9631 160.303 57.9288 160.312C57.8945 160.32 57.8588 160.322 57.8238 160.317C55.9853 160.058 53.941 161.226 53.3186 161.954C53.2894 161.987 53.2531 162.013 53.2124 162.029C53.1718 162.046 53.1278 162.053 53.084 162.05Z" fill="#F0F0F0"/>
                        <path d="M51.0052 160.393C50.9447 160.39 50.8871 160.367 50.8424 160.326C50.8153 160.303 50.793 160.276 50.7766 160.244C50.7603 160.213 50.7503 160.179 50.7471 160.144C50.744 160.109 50.7479 160.073 50.7585 160.04C50.7691 160.006 50.7862 159.975 50.8089 159.948C51.8909 158.641 54.2751 157.832 56.2429 158.119C56.3133 158.128 56.3771 158.166 56.4202 158.222C56.4633 158.279 56.4822 158.35 56.4727 158.421C56.4631 158.491 56.426 158.555 56.3694 158.598C56.3129 158.641 56.2415 158.66 56.171 158.65C54.3996 158.401 52.1781 159.129 51.2158 160.297C51.1899 160.328 51.1576 160.352 51.1212 160.369C51.0848 160.385 51.0452 160.393 51.0052 160.393Z" fill="#F0F0F0"/>
                        <path d="M21.9739 177.404C21.8829 179.845 21.5286 181.473 20.6669 182.924C19.8051 184.374 17.8853 185.021 16.1617 184.647C15.2079 184.45 14.3147 184.029 13.5556 183.419C12.7964 182.809 12.1931 182.027 11.7954 181.138C10.9432 179.161 11.0293 177.432 11.3645 175.158L21.9739 177.404Z" fill="#9E2C6A"/>
                        <path d="M13.7403 159.938C13.0317 160.01 13.4913 163.29 13.1658 166.565C12.8115 170.088 11.7582 171.568 11.4374 174.459C11.0736 177.758 11.672 179.218 12.9216 181.162C14.1712 183.106 18.4513 184.992 20.4717 182.072C22.1187 179.707 22.1282 177.126 21.908 173.847C21.7432 171.071 21.7432 168.287 21.908 165.511C21.9846 163.046 22.9278 160.628 22.3054 160.369C22.2671 160.733 21.7931 161.111 21.7548 161.466C21.7402 161.912 21.6743 162.356 21.5585 162.787C21.4915 162.969 21.3383 163.165 21.2665 162.869C21.2329 162.666 21.2329 162.459 21.2665 162.256C21.3479 161.346 20.8691 161.298 20.6106 161.222C19.5211 161.044 18.4233 160.921 17.3214 160.853C16.7804 160.829 14.6691 160.805 14.4489 161.107C14.2909 161.313 14.3052 161.442 14.2669 161.858C14.2758 162.194 14.2387 162.529 14.1568 162.854C13.9605 162.974 13.9126 162.711 13.7977 162.079C13.7642 161.868 13.7451 161.121 13.7451 160.906C13.7451 160.69 13.7307 160.149 13.7403 159.938Z" fill="#F351A9"/>
                        <path d="M11.2853 176.37C11.2135 178.414 11.6205 180.023 12.7216 181.339C14.2202 183.125 18.5961 185.169 20.6165 182.244C21.6171 180.808 21.8565 179.118 21.957 177.404C20.6643 175.24 13.2148 174.45 11.2853 176.37Z" fill="#FAFAFA"/>
                        <path d="M20.3427 173.875C20.3113 173.872 20.2807 173.864 20.2517 173.851C20.223 173.851 16.9003 172.616 13.7549 173.406C13.6883 173.423 13.6179 173.413 13.5582 173.379C13.4985 173.345 13.4541 173.29 13.4341 173.224C13.4255 173.191 13.4236 173.157 13.4283 173.124C13.4331 173.09 13.4445 173.058 13.4619 173.029C13.4793 173 13.5023 172.975 13.5296 172.955C13.5569 172.935 13.5879 172.921 13.6208 172.913C16.9721 172.085 20.3235 173.339 20.4671 173.392C20.4979 173.404 20.5258 173.423 20.5493 173.446C20.5728 173.47 20.5913 173.498 20.6038 173.529C20.6163 173.559 20.6225 173.592 20.6221 173.626C20.6216 173.659 20.6145 173.692 20.6012 173.722C20.5778 173.77 20.5409 173.81 20.4949 173.837C20.4489 173.865 20.396 173.878 20.3427 173.875Z" fill="#F0F0F0"/>
                        <path d="M13.8457 170.936C13.7963 170.934 13.7485 170.918 13.7084 170.889C13.6683 170.86 13.6378 170.819 13.6207 170.773C13.6093 170.741 13.6042 170.708 13.6057 170.675C13.6073 170.641 13.6154 170.609 13.6296 170.578C13.6439 170.548 13.6639 170.521 13.6887 170.498C13.7135 170.476 13.7424 170.459 13.7739 170.447C16.9194 169.337 20.4766 170.802 20.6107 170.869C20.6417 170.881 20.6697 170.901 20.6929 170.925C20.716 170.949 20.7339 170.978 20.7451 171.01C20.7564 171.041 20.7609 171.075 20.7584 171.108C20.7558 171.142 20.7463 171.174 20.7304 171.204C20.6969 171.263 20.6425 171.308 20.5779 171.329C20.5132 171.35 20.4429 171.347 20.3809 171.319C20.3473 171.319 16.8811 169.882 13.9558 170.921C13.921 170.936 13.883 170.941 13.8457 170.936Z" fill="#F0F0F0"/>
                        <path d="M13.9102 168.609C13.8613 168.605 13.8143 168.588 13.7745 168.56C13.7347 168.531 13.7038 168.492 13.6852 168.446C13.673 168.415 13.6672 168.382 13.6681 168.348C13.669 168.315 13.6766 168.282 13.6904 168.252C13.7042 168.222 13.7241 168.194 13.7487 168.172C13.7733 168.149 13.8021 168.132 13.8336 168.121C17.2903 166.833 20.6751 168.446 20.7996 168.518C20.8289 168.534 20.8548 168.556 20.8755 168.582C20.8963 168.608 20.9116 168.638 20.9205 168.67C20.9295 168.702 20.9319 168.736 20.9275 168.769C20.9232 168.802 20.9123 168.834 20.8954 168.863C20.8587 168.921 20.801 168.963 20.7341 168.98C20.6672 168.997 20.5964 168.988 20.5363 168.954C20.5076 168.954 17.2424 167.393 14.0251 168.59C13.9883 168.603 13.9494 168.61 13.9102 168.609Z" fill="#F0F0F0"/>
                        <path d="M14.0091 166.34C13.9612 166.337 13.9151 166.321 13.8761 166.293C13.8372 166.265 13.8069 166.226 13.7889 166.182C13.7759 166.151 13.7693 166.118 13.7695 166.085C13.7698 166.051 13.7768 166.018 13.7903 165.988C13.8038 165.957 13.8233 165.93 13.8478 165.907C13.8722 165.885 13.9011 165.867 13.9325 165.856C17.1738 164.597 20.8076 166.249 20.9464 166.335C20.9764 166.35 21.003 166.37 21.0247 166.396C21.0464 166.421 21.0627 166.45 21.0726 166.482C21.0825 166.514 21.0857 166.548 21.0821 166.581C21.0785 166.614 21.0682 166.646 21.0518 166.675C21.0184 166.736 20.9625 166.781 20.8962 166.8C20.8299 166.82 20.7585 166.813 20.6975 166.78C20.6639 166.78 17.1355 165.167 14.1288 166.335C14.0902 166.348 14.0486 166.35 14.0091 166.34Z" fill="#EBEBEB"/>
                        <path d="M17.8387 63.2808C15.2247 67.8386 12.2228 71.8171 12.783 85.1029C13.5586 103.401 13.9176 115.744 13.9176 115.744C12.8931 118.679 12.5484 123.94 12.9601 133.697C13.2761 141.492 13.6495 151.972 13.6495 151.972C13.6495 151.972 19.5431 154.663 24.1345 152.805C25.4224 146.413 28.7163 125.942 30.7223 117.472C32.5464 109.812 34.2125 96.7943 34.2125 96.7943L38.1479 111.698C38.1479 111.698 36.8265 119.813 38.5022 127.047C39.6896 132.151 43.4191 146.887 43.4191 146.887C43.4191 146.887 50.1218 148.252 54.0956 145.331C54.0956 145.331 54.6174 119.756 54.5025 113.46C54.3254 104.009 54.3302 99.9877 52.5205 80.6982C51.7496 72.4635 50.275 66.5747 49.8202 62.7397L17.8387 63.2808Z" fill="#F351A9"/>
                        <path d="M13.3906 150.191C15.2578 151.369 22.1137 152.336 24.6607 151.388L23.9522 154.351C23.9522 154.351 22.8031 155.18 18.5326 154.83C15.0328 154.538 13.4624 153.174 13.4624 153.174L13.3906 150.191Z" fill="#7B2E58"/>
                        <path d="M42.6523 144.038C44.735 144.771 51.835 143.751 54.1092 142.262L54.0661 145.336C52.3289 146.503 50.3344 147.231 48.2539 147.457C44.7493 147.849 43.3896 146.892 43.3896 146.892L42.6523 144.038Z" fill="#7B2E58"/>
                        <path d="M34.1797 96.7941L36.6597 81.3252C36.6597 81.3252 44.8657 80.3677 47.8532 77.4951C47.8532 77.4951 44.0853 82.795 38.5173 83.652L36.1618 95.0418L38.0768 111.531L34.1797 96.7941Z" fill="#9E2C6A"/>
                        <path d="M42.2148 33.7075C46.4184 34.0091 47.9313 34.6076 50.1815 37.8297C52.4891 41.1284 55.8932 46.6342 59.369 51.7139L69.5427 47.6156L73.8133 54.079C73.8133 54.079 61.6623 61.5956 58.9716 62.2563C55.4431 63.1276 54.4473 61.4232 52.5753 59.2065C50.5741 56.8127 44.0342 48.0178 44.0342 48.0178L42.2148 33.7075Z" fill="#EDBABB"/>
                        <path d="M59.3672 51.6949C55.3743 52.8823 54.6992 55.5251 54.6992 55.5251C54.795 53.7488 56.0637 52.0349 58.8262 50.9146L59.3672 51.6949Z" fill="#B6787B"/>
                        <path d="M84.3441 34.7848L83.5972 34.1241C83.485 34.0474 83.3563 33.9983 83.2215 33.9808C83.0867 33.9633 82.9497 33.978 82.8216 34.0236L74.5964 36.1253C74.1824 36.257 73.8014 36.476 73.4793 36.7676C73.1572 37.0591 72.9014 37.4165 72.7293 37.8154L66.5771 54.7254C66.498 54.8657 66.4643 55.0271 66.4806 55.1874C66.4968 55.3477 66.5624 55.4991 66.6681 55.6206L67.4389 56.3053C67.5509 56.3843 67.6803 56.4352 67.8162 56.4535C67.9521 56.4718 68.0903 56.4571 68.2193 56.4106L76.4445 54.3088C76.8592 54.1786 77.2409 53.9601 77.5632 53.6684C77.8855 53.3766 78.1409 53.0186 78.3117 52.6188C79.5181 49.2962 83.2573 39.0314 84.4686 35.7088C84.554 35.563 84.5882 35.3928 84.5656 35.2253C84.543 35.0579 84.4651 34.9028 84.3441 34.7848Z" fill="#7B2E58"/>
                        <path d="M74.4645 37.255C74.0422 37.5753 73.7119 38.0014 73.507 38.4902L67.3501 55.3954C67.2811 55.5297 67.2509 55.6805 67.2627 55.831C67.2746 55.9815 67.328 56.1257 67.4171 56.2476L66.6703 55.6204C66.5679 55.503 66.5036 55.3574 66.4856 55.2027C66.4677 55.048 66.497 54.8915 66.5697 54.7538L72.7314 37.8391C72.922 37.3791 73.2289 36.9765 73.6219 36.6709L74.4645 37.255Z" fill="#9E2C6A"/>
                        <path d="M67.3376 55.4005L73.4945 38.4905C73.6639 38.0899 73.9188 37.7311 74.2413 37.4392C74.5638 37.1472 74.9462 36.9293 75.3617 36.8005L83.5821 34.6987C84.3433 34.5024 84.7455 34.9477 84.4774 35.685C83.2661 39.0076 79.5269 49.2723 78.3204 52.5949C78.1488 52.9942 77.8932 53.3518 77.571 53.6435C77.2488 53.9351 76.8676 54.1539 76.4533 54.285L68.2281 56.3868C67.4668 56.5783 67.0695 56.1378 67.3376 55.4005Z" fill="#F351A9"/>
                        <path d="M73.4484 42.0574L74.6022 38.8928C74.6331 38.8173 74.6806 38.7498 74.7411 38.6951C74.8016 38.6404 74.8735 38.6 74.9517 38.5768L76.4885 38.1794C76.6322 38.1459 76.7087 38.2273 76.6561 38.3661L75.507 41.5307C75.4739 41.6048 75.4257 41.6711 75.3656 41.7255C75.3054 41.7799 75.2346 41.8212 75.1576 41.8467L73.6159 42.2393C73.4723 42.2776 73.4005 42.2154 73.4484 42.0574Z" fill="#7B2E58"/>
                        <path d="M74.6679 39.6542C74.7523 39.4683 74.8754 39.3026 75.029 39.168C75.1825 39.0334 75.363 38.9332 75.5584 38.8739C75.927 38.7781 76.1281 38.9744 76.0036 39.3143C75.9192 39.5002 75.7961 39.666 75.6425 39.8005C75.489 39.9351 75.3085 40.0354 75.1131 40.0947C74.7445 40.1857 74.5482 39.9894 74.6679 39.6542Z" fill="#9E2C6A"/>
                        <path d="M74.1033 41.2577C74.1877 41.0718 74.3108 40.906 74.4644 40.7715C74.6179 40.6369 74.7984 40.5366 74.9938 40.4773C75.3625 40.3863 75.5587 40.5826 75.439 40.9178C75.3546 41.1037 75.2315 41.2694 75.078 41.404C74.9244 41.5386 74.7439 41.6388 74.5485 41.6982C74.1799 41.7843 73.9788 41.588 74.1033 41.2577Z" fill="#9E2C6A"/>
                        <path d="M82.9525 44.9494C83.8526 43.8913 83.7951 43.7381 83.4073 43.1253C83.1172 42.6403 82.7158 42.2315 82.2362 41.9326C81.7566 41.6337 81.2128 41.4534 80.6496 41.4065C79.6586 41.349 78.658 41.2485 78.1265 41.4687C77.5951 41.689 77.9398 42.7949 79.2038 43.1971C81.1954 43.8243 81.1188 44.3748 81.1188 44.3748C80.2713 44.1014 79.4099 43.8729 78.5383 43.6902C77.7914 43.5849 76.3886 43.8243 75.5221 43.8913C74.6555 43.9583 74.3922 45.0403 75.8093 45.5478L74.2198 45.6627C73.1043 45.7441 72.683 46.2995 73.4873 47.0511C73.742 47.316 74.0801 47.4851 74.4448 47.5299C74.4448 47.5299 73.2767 47.4294 73.2192 47.8172C73.0564 48.9279 73.7554 49.2535 76.0583 50.1679C78.2606 51.0105 78.6197 52.2649 78.1026 53.0644C78.1026 53.0644 79.0601 52.6144 79.9123 52.1069C80.5395 51.7478 81.0805 51.3313 80.8699 50.3833C80.8699 50.3833 82.1577 50.5174 82.3062 49.7274C82.3765 49.1447 82.321 48.5537 82.1434 47.9943C82.3851 47.8836 82.5905 47.7065 82.7354 47.4835C82.8804 47.2606 82.959 47.0011 82.9621 46.7352C83.0088 46.2308 82.8858 45.7253 82.6126 45.2989L82.9525 44.9494Z" fill="#EDBABB"/>
                        <path d="M75.793 45.5663C76.2692 45.5046 76.7507 45.4934 77.2293 45.5328C77.7039 45.6441 78.158 45.83 78.5746 46.0833C79.4141 46.5297 80.2148 47.0453 80.9684 47.625C80.0789 47.3095 79.2187 46.917 78.3975 46.452C78.011 46.2148 77.604 46.0129 77.1814 45.8487C76.7218 45.7243 76.243 45.6716 75.793 45.5663Z" fill="#B6787B"/>
                        <path d="M74.4375 47.5102C74.9517 47.4078 75.4833 47.4358 75.9839 47.5915C76.4792 47.7448 76.9599 47.9419 77.4202 48.1804C78.3303 48.6441 79.1888 49.2025 79.9816 49.8465C79.0241 49.4731 78.124 49.0087 77.2287 48.5539C76.372 48.034 75.4251 47.68 74.4375 47.5102Z" fill="#B6787B"/>
                        <path d="M39.3864 33.4731C39.3864 33.4731 28.6381 34.1386 23.7738 34.7514L20.4704 35.4552C19.0963 35.934 15.1034 37.4325 16.511 48.8606C16.9897 52.8775 18.2297 58.7615 18.2297 58.7615L16.6977 64.636C23.5584 70.2614 47.2908 68.5618 50.2112 64.928C50.2112 64.928 49.766 58.9961 49.292 54.9218C50.166 54.232 50.8807 53.3615 51.3872 52.3699C51.8938 51.3784 52.1802 50.2891 52.2268 49.1766C52.4231 45.72 51.2693 43.1921 48.1286 39.2997C44.5522 34.852 42.2494 33.6934 42.2494 33.6934L39.3864 33.4731Z" fill="#F351A9"/>
                        <path opacity="0.6" d="M39.3864 33.4731C39.3864 33.4731 28.6381 34.1386 23.7738 34.7514L20.4704 35.4552C19.0963 35.934 15.1034 37.4325 16.511 48.8606C16.9897 52.8775 18.2297 58.7615 18.2297 58.7615L16.6977 64.636C23.5584 70.2614 47.2908 68.5618 50.2112 64.928C50.2112 64.928 49.766 58.9961 49.292 54.9218C50.166 54.232 50.8807 53.3615 51.3872 52.3699C51.8938 51.3784 52.1802 50.2891 52.2268 49.1766C52.4231 45.72 51.2693 43.1921 48.1286 39.2997C44.5522 34.852 42.2494 33.6934 42.2494 33.6934L39.3864 33.4731Z" fill="white"/>
                        <path opacity="0.5" d="M48.0961 39.3141C44.5198 34.8664 42.2169 33.7077 42.2169 33.7077L39.3443 33.4731C39.3443 33.4731 28.596 34.1386 23.7318 34.7514L20.4283 35.4552C19.1835 35.8718 15.7987 37.1548 16.2056 45.8827C27.7726 47.4148 43.5574 45.404 50.6001 42.8809C49.8561 41.6308 49.0191 40.4385 48.0961 39.3141Z" fill="#F351A9"/>
                        <path d="M48.6515 20.9916C48.9771 19.7899 50.3224 19.6463 50.5666 18.0377C50.8107 16.429 49.9346 15.3709 48.5988 14.6863C47.2631 14.0017 46.971 12.7186 47.1625 10.0231C47.354 7.32769 45.4055 5.78128 43.1936 5.51317C42.102 5.37912 40.8381 5.51317 40.0577 4.75194C39.7485 4.37865 39.4894 3.96663 39.2869 3.5263C38.1857 1.61124 36.2084 0.811702 33.5943 0.888304C32.4197 0.912022 31.2613 1.16746 30.1855 1.63996C28.845 2.19054 28.2178 2.6741 26.9299 3.9141C25.4936 3.71302 22.7982 4.09603 21.3715 5.427C20.3421 6.33665 19.3463 7.34205 19.0782 9.35766C18.8723 10.9519 19.0447 11.8472 18.384 12.709C17.0339 14.4996 15.296 14.5379 14.5539 16.0604C13.4814 18.1957 16.3014 19.9288 14.9512 22.5189C13.8214 24.6973 14.2618 26.7129 16.7322 27.5842C17.9722 28.0199 18.8867 27.4167 20.4187 28.446C21.9508 29.4754 21.855 30.227 23.5068 30.5909C26.5086 31.2659 27.3608 29.7435 28.8354 29.5328C30.8558 29.2408 31.6362 30.7297 33.6231 29.3988C34.2933 28.92 34.6811 28.1492 35.361 27.6944C37.5202 26.3299 38.2336 27.0241 40.929 27.9098C41.628 28.1396 43.9357 28.7189 45.4581 26.9523C46.3609 25.7917 46.8072 24.3405 46.7125 22.8732C47.1913 24.3095 50.5426 24.2089 50.8299 21.8774C50.7124 22.1662 50.485 22.3966 50.1977 22.5178C49.9104 22.639 49.5867 22.6411 49.2978 22.5237C49.009 22.4062 48.7786 22.1788 48.6574 21.8915C48.5362 21.6042 48.534 21.2805 48.6515 20.9916Z" fill="#E71F8B"/>
                        <path d="M26.4934 31.1605C26.4264 29.8199 26.1152 25.7696 26.1152 25.7696C25.6364 27.0335 23.3863 27.1436 22.2324 25.626C20.7961 23.7109 20.7195 21.3841 21.6436 19.9382C22.6585 18.3439 25.172 18.2434 26.3546 20.642C28.0207 20.9053 28.5856 18.3487 27.1589 16.0459C26.9866 10.4683 34.6181 12.1487 36.0161 7.10254C37.3319 7.30979 38.5825 7.81643 39.6716 8.58342C40.7607 9.35041 41.659 10.3573 42.2975 11.5263C42.9247 12.752 43.0348 13.5132 42.963 16.0219C42.9342 17.0034 43.6332 18.1189 43.863 19.4068C44.9115 25.3148 43.2119 27.7277 41.2969 28.9294C40.2771 29.571 38.9413 30.0019 36.4709 30.2125C36.4709 30.2125 36.5427 31.3233 36.6145 32.0606C36.6863 32.7979 36.7869 33.0181 37.6582 33.2192C38.2132 33.3353 38.7742 33.4201 39.3387 33.4729C39.3387 33.4729 42.7858 37.461 37.4236 38.5909C29.4091 40.2714 23.7358 34.7608 23.7358 34.7608L25.3779 34.3682C26.6036 34.0762 26.6036 33.3915 26.4934 31.1605Z" fill="#EDBABB"/>
                        <path d="M23.5157 24.9794C23.5317 25.1415 23.5954 25.2953 23.6988 25.4212C23.8022 25.5471 23.9406 25.6396 24.0965 25.6868C24.2525 25.734 24.4189 25.7339 24.5748 25.6865C24.7307 25.6391 24.869 25.5465 24.9722 25.4205C25.0755 25.2945 25.139 25.1406 25.1548 24.9784C25.1706 24.8163 25.1379 24.6531 25.0609 24.5095C24.9839 24.3659 24.866 24.2484 24.7222 24.1718C24.5784 24.0952 24.4151 24.063 24.253 24.0793C24.0359 24.1011 23.8364 24.2081 23.6982 24.3768C23.5599 24.5456 23.4943 24.7623 23.5157 24.9794Z" fill="#EBEBEB"/>
                        <path d="M33.9064 23.7876L36.8987 24.5057C36.8608 24.706 36.7828 24.8965 36.6692 25.0657C36.5557 25.2349 36.409 25.3794 36.2381 25.4904C36.0672 25.6013 35.8755 25.6765 35.6747 25.7114C35.4739 25.7462 35.2682 25.74 35.0698 25.6931C34.6664 25.5888 34.3193 25.3317 34.1022 24.976C33.885 24.6203 33.8148 24.1941 33.9064 23.7876Z" fill="#B6787B"/>
                        <path d="M34.143 24.4485C34.5325 24.4104 34.9232 24.5084 35.2487 24.7257C35.5743 24.943 35.8145 25.2662 35.9288 25.6406C35.6566 25.7441 35.3594 25.7625 35.0766 25.6932C34.7911 25.6215 34.5314 25.4714 34.3267 25.2599C34.122 25.0484 33.9804 24.784 33.918 24.4963C33.9919 24.4758 34.0671 24.4598 34.143 24.4485Z" fill="#F28F8F"/>
                        <path d="M41.5605 13.4174L39.1954 13.3312C39.1933 13.1769 39.2227 13.0237 39.2819 12.8812C39.341 12.7386 39.4286 12.6096 39.5393 12.502C39.65 12.3945 39.7814 12.3106 39.9256 12.2556C40.0698 12.2006 40.2238 12.1756 40.378 12.1822C40.696 12.1969 40.9961 12.3334 41.2162 12.5633C41.4364 12.7933 41.5596 13.0991 41.5605 13.4174Z" fill="#F351A9"/>
                        <path d="M30.595 14.3179L32.9075 13.6811C32.8736 13.5282 32.8089 13.3838 32.7172 13.2568C32.6255 13.1297 32.5088 13.0228 32.3743 12.9426C32.2398 12.8623 32.0903 12.8104 31.935 12.79C31.7797 12.7697 31.6218 12.7813 31.4712 12.8241C31.1618 12.9138 30.8985 13.1186 30.7355 13.3965C30.5726 13.6743 30.5223 14.0041 30.595 14.3179Z" fill="#F351A9"/>
                        <path d="M39.2891 17.1949C39.2926 17.4572 39.3971 17.708 39.5808 17.8952C39.7644 18.0825 40.0132 18.1917 40.2753 18.2003C40.4039 18.2048 40.532 18.1834 40.652 18.1373C40.7721 18.0912 40.8817 18.0214 40.9742 17.932C41.0667 17.8426 41.1402 17.7356 41.1904 17.6171C41.2406 17.4987 41.2664 17.3714 41.2664 17.2428C41.264 16.9802 41.1599 16.7287 40.976 16.5412C40.7921 16.3537 40.5426 16.2448 40.2801 16.2374C40.1516 16.2329 40.0234 16.2543 39.9034 16.3004C39.7833 16.3465 39.6738 16.4164 39.5813 16.5057C39.4888 16.5951 39.4152 16.7022 39.365 16.8206C39.3148 16.939 39.289 17.0663 39.2891 17.1949Z" fill="#9E2C6A"/>
                        <path d="M32.1719 17.8941C32.1754 18.1564 32.2799 18.4072 32.4636 18.5944C32.6472 18.7817 32.896 18.8909 33.1581 18.8995C33.2867 18.904 33.4148 18.8826 33.5349 18.8365C33.6549 18.7904 33.7645 18.7206 33.857 18.6312C33.9495 18.5418 34.023 18.4348 34.0732 18.3163C34.1234 18.1979 34.1493 18.0706 34.1492 17.942C34.1483 17.6838 34.0486 17.4357 33.8705 17.2487C33.6924 17.0617 33.4495 16.9501 33.1916 16.9366C33.0608 16.9281 32.9295 16.9465 32.8061 16.9908C32.6826 17.0351 32.5696 17.1043 32.474 17.1941C32.3783 17.2838 32.3022 17.3923 32.2502 17.5127C32.1983 17.6332 32.1716 17.763 32.1719 17.8941Z" fill="#9E2C6A"/>
                        <path d="M36.5107 30.2122C32.7428 30.1117 28.4674 28.8908 27.0742 26.8608C27.0742 26.8608 27.6823 28.9243 29.2335 29.7622C31.507 30.931 34.042 31.498 36.5969 31.4091L36.5107 30.2122Z" fill="#B6787B"/>
                        <path d="M29.0678 15.7925L23.5859 18.7895C24.2255 18.7623 24.8533 18.9681 25.3526 19.3689L29.3311 17.1139L29.0678 15.7925Z" fill="#EBEBEB"/>
                        <path d="M44.3638 14.3704C42.8844 14.2938 40.8927 14.3704 39.7006 14.8492C38.3696 15.3806 37.91 15.8929 37.1631 15.9599C36.4163 16.0269 35.8322 15.5961 34.3672 15.2944C33.199 15.0551 31.1546 15.3184 29.6992 15.5961C29.1965 15.6439 28.7417 15.8259 28.7752 16.4961C28.8087 17.1664 29.2827 17.1951 29.4167 17.6978C29.7614 19.4453 30.3743 21.6093 32.6627 21.528C37.4265 21.4801 35.6119 16.9653 37.369 17.0754C38.0106 17.1712 37.9914 18.392 38.4319 19.2299C38.5992 19.5644 38.819 19.8698 39.083 20.1347C40.7347 21.662 43.6121 20.5465 43.9616 18.89C44.3015 18.0569 44.1531 16.769 44.517 16.0413C44.8665 15.711 45.125 14.4997 44.3638 14.3704ZM35.588 18.6027C35.5159 19.0979 35.3144 19.5652 35.0039 19.9576C34.7667 20.2351 34.4734 20.4592 34.1433 20.6152C33.8132 20.7711 33.4539 20.8554 33.0888 20.8625C32.0643 20.9295 31.011 20.5178 30.695 19.5459C30.6519 19.4549 30.6184 19.3687 30.5849 19.2778C30.3312 18.5548 29.9625 17.0084 30.3264 16.5296C30.9919 15.7253 34.4007 15.5482 35.4731 16.6302C35.7604 17.0084 35.7173 17.8654 35.588 18.6027ZM43.6648 17.6452C43.5834 18.1622 43.4014 19.2873 42.4535 19.8475C41.1991 20.5896 39.3511 20.0486 39.0016 18.8038C38.0441 16.2184 39.0447 15.5338 41.654 15.0934C43.818 14.9497 43.976 15.5003 43.6887 17.65L43.6648 17.6452Z" fill="#FAFAFA"/>
                        <path d="M43.6915 17.6502C43.6101 18.1672 43.4282 19.2923 42.4802 19.8525C41.2259 20.5946 39.3778 20.0536 39.0283 18.8088C38.0708 16.2234 39.0714 15.5388 41.6807 15.0983C43.8208 14.9499 43.9788 15.5005 43.6915 17.6502Z" fill="#F351A9"/>
                        <path d="M35.59 18.603C35.5179 19.0982 35.3164 19.5655 35.0059 19.9579C34.7687 20.2354 34.4754 20.4595 34.1453 20.6155C33.8152 20.7714 33.4558 20.8557 33.0908 20.8628C32.0663 20.9298 31.013 20.5181 30.697 19.5462C30.6539 19.4552 30.6204 19.369 30.5869 19.2781C30.3332 18.5551 29.9645 17.0087 30.3284 16.53C30.9938 15.7256 34.4026 15.5485 35.4751 16.6305C35.7623 17.0087 35.7193 17.8657 35.59 18.603Z" fill="#F351A9"/>
                        <path d="M33.01 15.8834C31.8466 15.8499 30.6784 16.0988 30.3193 16.5345C29.9603 16.9702 30.3193 18.5597 30.5779 19.2826C30.5779 19.3257 30.6114 19.3688 30.6305 19.4119C30.9801 19.3694 31.3228 19.2825 31.6503 19.1533C31.861 19.0672 32.1291 18.9762 32.2966 19.1533C32.3524 19.2352 32.3843 19.331 32.3885 19.43C32.3927 19.529 32.3692 19.6272 32.3206 19.7135C32.1247 20.0318 32.0343 20.4037 32.062 20.7764C32.3977 20.8729 32.7477 20.9102 33.0962 20.8865C33.4612 20.8794 33.8205 20.7951 34.1506 20.6392C34.4807 20.4832 34.774 20.2591 35.0112 19.9816C35.1131 19.8563 35.2029 19.7217 35.2793 19.5794C34.8838 19.8065 34.4379 19.9316 33.9819 19.9433C33.9037 19.957 33.8234 19.9547 33.7461 19.9366C33.6688 19.9185 33.5959 19.885 33.5319 19.838C33.4736 19.7649 33.4344 19.6786 33.4176 19.5867C33.4009 19.4948 33.4073 19.4002 33.4361 19.3113C33.542 18.831 33.7493 18.3788 34.0441 17.9852C34.1367 17.8701 34.2065 17.7385 34.25 17.5974C34.2718 17.5261 34.2705 17.4499 34.2464 17.3794C34.2224 17.3089 34.1767 17.2478 34.1159 17.2048C34.0555 17.1784 33.9904 17.1648 33.9244 17.1648C33.8585 17.1648 33.7933 17.1784 33.7329 17.2048C33.4192 17.3046 33.121 17.448 32.8472 17.6309C32.6701 17.7458 32.4403 17.8798 32.2631 17.7649C32.2175 17.7302 32.1807 17.6853 32.1558 17.6337C32.1308 17.5821 32.1184 17.5254 32.1195 17.4681C32.1338 17.1703 32.2466 16.8857 32.4403 16.659C32.6222 16.3621 32.8185 16.1323 33.01 15.8834Z" fill="#7B2E58"/>
                        <path d="M43.2105 18.7225C43.0927 18.7799 42.9643 18.8125 42.8334 18.8182C42.7025 18.824 42.5717 18.8028 42.4493 18.756C42.3911 18.7328 42.3411 18.6928 42.3057 18.6411C42.2938 18.5891 42.2938 18.5351 42.3057 18.4831C42.3552 18.108 42.4754 17.7457 42.66 17.4155C42.7501 17.2626 42.7792 17.0813 42.7414 16.908C42.6872 16.7961 42.5981 16.7048 42.4876 16.6478C42.3771 16.5908 42.2511 16.5712 42.1285 16.592C41.8796 16.6159 41.6498 16.7212 41.4008 16.7452C41.2702 16.768 41.1358 16.7424 41.0226 16.6734C40.9647 16.626 40.9198 16.5647 40.8921 16.4951C40.8645 16.4256 40.855 16.3502 40.8646 16.276C40.8833 16.1272 40.9322 15.9839 41.0082 15.8547C41.141 15.5955 41.3015 15.3515 41.487 15.127C39.2559 15.5243 38.2793 16.1371 38.7676 18.0809C38.8401 18.1283 38.917 18.1684 38.9974 18.2006C39.3592 18.3228 39.7511 18.3228 40.1129 18.2006C40.4164 18.0625 40.7394 17.972 41.0705 17.9325C41.1943 17.9251 41.3166 17.9625 41.4152 18.0378C41.4729 18.1019 41.515 18.1785 41.5382 18.2615C41.5615 18.3446 41.5652 18.4319 41.5492 18.5166C41.4995 18.7632 41.4157 19.0017 41.3003 19.2252C41.1861 19.4504 41.1302 19.7005 41.1375 19.9529C41.1451 20.0331 41.1679 20.111 41.2045 20.1827C41.6355 20.1816 42.0585 20.0659 42.4302 19.8476C42.8979 19.5571 43.2461 19.1087 43.4116 18.5836C43.3493 18.6364 43.282 18.6829 43.2105 18.7225Z" fill="#7B2E58"/>
                        <path d="M40.5791 21.3559L37.3953 22.7922L36.6484 17.5258C36.6902 17.4141 36.7595 17.3147 36.8498 17.2369C36.9402 17.159 37.0488 17.1053 37.1655 17.0806L40.5791 21.3559Z" fill="#B6787B"/>
                        <path d="M11.9672 61.8875C10.5309 59.7666 7.22266 59.4315 7.22266 59.4315C8.16416 59.2888 9.12564 59.3584 10.0368 59.6351C10.948 59.9117 11.7858 60.3885 12.4891 61.0305L11.9672 61.8875Z" fill="#B6787B"/>
                        <path d="M20.4415 35.4551C16.6832 36.0918 13.2122 37.0159 10.962 40.8268C8.19474 45.5283 4.51782 51.8959 1.7745 57.8517C0.127548 61.4233 -0.504419 63.5203 1.34361 65.9907C2.97141 68.1739 10.7609 74.8143 12.9058 77.0741C14.3421 78.5774 16.2571 81.474 17.7557 82.6565C17.7557 82.6565 20.8916 82.1299 22.8114 76.6384C22.8114 76.6384 22.979 75.8102 24.9371 76.6384C26.2968 77.1986 28.0491 77.4092 28.9348 76.6384C29.8205 75.8676 27.8911 75.3362 26.9384 74.6659C25.5662 73.5749 23.9684 72.8029 22.2609 72.4061C19.8192 71.8412 19.101 71.5922 16.8604 69.0165C14.6198 66.4407 11.9674 61.8733 11.9674 61.8733L19.1968 50.2824C23.5392 43.0435 22.1459 37.4276 20.4415 35.4551Z" fill="#EDBABB"/>
                    </svg>

                </div>
                <div className="live-offline-title title-live-end">All good things come to an end</div>
                <div className="live-offline-subtitle live-end-subtitle title-live-description">
                    Thank you for joining us on this live stream
                </div>
            </div>
        </div>
    );
};

export default LiveEnd;
