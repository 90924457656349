export default [
  {
    title: "https://barrym.com",
    date: "2020-04-17 June",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    date: "2020-04-17 June",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    date: "2020-04-17 June",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063410-808f-11ea-ae73-45be688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
];
