import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductTags } from "./";
import { TextContainer } from "../containers";
import {
  createText,
  setCardBackgroundImage,
  removeCard,
} from "../../redux/actions/editStoryActions";

import { uploadFile } from "../../redux/utils/fileUpload";
import { storySize } from "../../globalConst";
import {
  EditCardTag,
  EditCardText,
  EditCardDelete,
  EditCardSoundOn,
  EditCardSoundOff,
  Plus,
} from "../../icons/icons";
import "../../scss/components/CardEdit.scss";
import {setPreloader} from "../../redux/actions/storiesActions";

const CardEdit = ({ cardData, storyId, storyData }) => {
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.auth.toJS());

  const [isMuted, setIsMuted] = useState(true);
  const [tagOpened, setTagOpened] = useState(false);
  const [activeText, setActiveText] = useState(null);
  const [size, setSize] = useState(storySize);
  const video = useRef(null);
  const story = useRef(null);

  const { backgroundMedia } = cardData;
  const cardId = cardData._id;

  const isVideo = useMemo(
    () =>
      backgroundMedia && backgroundMedia.full
        ? backgroundMedia.full.split(".").pop() === "mp4" ||
          backgroundMedia.isVideo
        : false,
    [backgroundMedia]
  );

  const isOnly = () => !(storyData.cards.length <= 1);

  useEffect(() => {
    if (window.innerWidth > 500) {
      if (story.current) {
        const vh = window.innerHeight;
        const ofTop = story.current.getBoundingClientRect().top;
        const storyH = vh - ofTop - 50;
        const storyW = Math.round((storyH * 414) / 736);
        if (storyH < 736) {
          setSize({ width: storyW, height: storyH });
        }
      }
    } else {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
  }, [story, setSize]);

  useEffect(() => {
    if (video.current) video.current.muted = isMuted;
  }, [isMuted]);

  //Upload background media
  const handleMediaUpload = async ({ isVideo, full, blob }) => {
    try {
      dispatch(
          setCardBackgroundImage(cardId, full, {
            isVideo,
          })
      );
      dispatch(setPreloader(true))
      console.log('start')
      await uploadFile({
        blob,
        storyId: storyId,
        cardId: cardId,
        authToken: idToken,
      });
      console.log('file uploaded')
      dispatch(setPreloader(false))
    } catch (e) {
      console.log(e)
      throw e
    }
  };

  const handleUploadMediaClick = (e) => {
    if (e.target.files[0]) {
      handleMediaUpload({
        isVideo: e.target.files[0].type.split("/")[0] === "video",
        full: URL.createObjectURL(e.target.files[0]),
        blob: e.target.files[0],
      });
    }
  };

  const handleProductTag = () => {
    setTagOpened((prev) => !prev);
  };

  const handleAddText = async () => {
    const acttxt = activeText == null ? 0 : activeText + 1;
    await dispatch(createText(cardId, storyId, idToken));
    setActiveText(acttxt);
  };

  const handleStoryDelete = () => {
    dispatch(removeCard(cardId, storyId, idToken));
  };

  const handleStorySound = () => setIsMuted((prev) => !prev);

  const getMedia = () => {
    if (!backgroundMedia) {
      return (
        <label className="card-edit__upload" htmlFor={`device-${cardId}`}>
          <p>
            Upload video
            <br />
            or
            <br />
            Take Live
          </p>
          <span className="plus">
            <Plus />
          </span>
          <input
            type="file"
            accept="image/*,video/*"
            id={`device-${cardId}`}
            style={{ display: "none" }}
            onChange={handleUploadMediaClick}
          />
        </label>
      );
    }

    if (!isVideo) {
      return (
        <img
          src={
            backgroundMedia
              ? backgroundMedia.full
                ? backgroundMedia.full
                : backgroundMedia.original
                ? backgroundMedia.original
                : null
              : null
          }
          alt=""
        />
      );
    } else {
      return (
        <video
          ref={video}
          loop
          playsInline
          autoPlay
          muted={isMuted}
          src={backgroundMedia.full}
        />
      );
    }
  };

  const renderMuteButton = () => {
    if (!isVideo) return null;
    if (isMuted)
      return (
        <div
          className="card-edit-tools__sound card-edit-tools__item"
          onClick={handleStorySound}
        >
          <EditCardSoundOff />
        </div>
      );
    return (
      <div
        className="card-edit-tools__sound card-edit-tools__item"
        onClick={handleStorySound}
      >
        <EditCardSoundOn />
      </div>
    );
  };

  return (
    <div className="card-edit">
      <div className="card-edit__avatar"></div>
      <div className="card-edit__media">{getMedia()}</div>
      <TextContainer
        texts={cardData.texts}
        storyId={storyId}
        storyData={storyData}
        cardId={cardId}
        idToken={idToken}
        size={size}
        activeText={activeText}
        setActiveText={setActiveText}
      />
      <div className="card-edit-tools">
        <div
          className="card-edit-tools__tag card-edit-tools__item"
          onClick={handleProductTag}
        >
          <EditCardTag />
        </div>
        <div
          className="card-edit-tools__text card-edit-tools__item"
          onClick={handleAddText}
        >
          <EditCardText />
        </div>
        {isOnly() && (
          <div
            className="card-edit-tools__delete card-edit-tools__item"
            onClick={handleStoryDelete}
          >
            <EditCardDelete />
          </div>
        )}
        {renderMuteButton()}
      </div>
      <ProductTags
        storyId={storyId}
        cardId={cardId}
        products={cardData.products || []}
        isOpen={tagOpened}
        onClose={handleProductTag}
      />
    </div>
  );
};

export default CardEdit;
