import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import '../../scss/components/switchButton.scss'

export const SwitchButton = ({ id , switchProductFunc, arrId,  addIdToArray, isShow}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = async checked => {
    await setChecked(checked);
    await switchProductFunc({id: id, status: checked})

    addIdToArray(checked, id)
  };

  useEffect(() => {
    setChecked(isShow)
  }, [isShow])

  return (
    <div className={`switchButton ${checked}`}>
      <label>
        <Switch
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          disabled={arrId.length > 2 && !arrId.some((el) => el === id)}
        />
      </label>
    </div>
  );
};