import React, { useState, useEffect } from "react";
import { Close } from "../../icons/icons";
import { Avatar } from "../common";
import { Story } from "./";
import avatar from "../../images/account_avatar.png";
import defaultPic from "../../images/default_upload.png";
import "../../scss/components/StoriesSaga.scss";

const StoriesSaga = ({
  story: { cards, title, screenSize, user },
  setClose,
  isPause = false,
}) => {
  const [current, setCurrent] = useState(0);
  const [width, setWidth] = useState(0);
  const [pause, setPause] = useState(false);
  const [canChange, setCanChange] = useState(true);
  const [inter, setInter] = useState(false);

  const handleClick = (e) => {
    if (canChange) {
      const wd = e.target.offsetWidth;
      const left = e.target.getBoundingClientRect().left;
      if (e.pageX - left < wd / 2) {
        if (current !== 0) {
          setCurrent((c) => c - 1);
          setWidth(0);
        }
      } else {
        if (current < cards.length - 1) {
          setCurrent((c) => c + 1);
          setWidth(0);
        }
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pause && !isPause && !inter) {
        if (width > 101) {
          if (current === cards.length - 1) {
            setPause(true);
            clearInterval(interval);
            return;
          }
          setCurrent((c) => c + 1);
          setWidth(0);
        } else {
          setWidth((w) => w + 1);
        }
      }
    }, 50);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className="stories__saga">
      <div className="story">
        <div className="storysaga__header">
          <div className="stories__duration">
            {cards
              ? cards.map((e, i) => {
                  return (
                    <div
                      className={`story__length ${
                        current === i ? "current" : current > i ? "done" : ""
                      }`}
                      key={i}
                    >
                      <div
                        className="story__time"
                        style={{ width: width + "%" }}
                      />
                    </div>
                  );
                })
              : null}
          </div>
          <div className="story__header">
            <div className="story__info">
              <div className="avatar">
                <Avatar image={user ? user.picture : defaultPic} />
              </div>
              <div className="info">
                <div className="more">{user ? user.name : "not published"}</div>
                <div className="name">{title}</div>
              </div>
            </div>
          </div>
          <div className="story__close" onClick={() => setClose(false)}>
            <Close />
          </div>
        </div>
        <div className="stories__content" onClick={handleClick}>
          <Story
            story={cards[current]}
            title={title}
            pause={pause}
            avatar={avatar}
            setPause={setPause}
            setCanChange={setCanChange}
            screenSize={screenSize ? screenSize : { width: 414, height: 736 }}
            setInter={setInter}
            setWidth={setWidth}
          />
        </div>
      </div>
    </div>
  );
};

export default StoriesSaga;
