import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "./index.scss";
import { Provider } from "react-redux";
import PlanModal from "./components/modal/PlanModal";
import ErrorBoundary from "./components/containers/ErrorBoundary";

import store from "./redux/reducers";

ReactDOM.render(
  <div className="app">
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <Routes />
          <PlanModal />
        </Router>
      </Provider>
    </ErrorBoundary>
  </div>,
  document.getElementById("root")
);
