import React, { useState, useEffect } from "react";
// import _ from "lodash";
import { CheckGroup } from "../../common";
import { withRouter } from "react-router-dom";
import {
  HomePage,
  ProductPage,
  PasteUrl,
  YoutubeChannel,
  Publish,
  Preview,
  Close,
} from "../../../icons/icons";
import { connect } from "react-redux";
import { publishStory } from "../../../redux/actions/publishStoryActions";
import { getStorybarData } from "../../../redux/actions/storybarActions";
import { setStoryPublishing } from "../../../redux/actions/editStoryActions";
import "../../../scss/components/Publish.scss";
//import { openStory } from "../../../redux/utils/embedApi";

const PublishBox = ({
  setPubl,
  setPreview,
  publishStory,
  idToken,
  match,
  data,
  setStoryPublishing,
  history,
}) => {
  const [publish, setPublish] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const publishNewStory = async () => {
    const result = data.filter((e) => e.title === "new_homepage");

    if (result.length !== 0) {
      // setStoryPublishing(true, match.params.id, idToken);
      await publishStory({
        authToken: idToken,
        storyId: match.params.id,
        publishData: result.map((e) => ({ id: e.id, duration: 0 })),
      });
      // setTimeout(() => {
      //   setStoryPublishing(false, match.params.id, idToken);
      // }, 7000);
      history.replace("/stories");
    }
  };

  return (
    <div
      className="publish"
      style={isVisible ? { opacity: 1 } : { opacity: 0 }}
    >
      <div className="publish__container">
        <div
          className="publish__close cursor__button"
          onClick={() => setPubl(false)}
        >
          <Close />
        </div>
        <div className="title">Where do you want to publish stories?</div>
        <div className="subtitle">
          Choose a place where you want to post stories, you can choose more
          than one
        </div>
        <div>
          <CheckGroup
            className="publish__checkgroup"
            onChange={setPublish}
            current={publish}
            items={[
              {
                name: "home",
                title: "Home page",
                icon: <HomePage />,
                defaultCheck: true,
              },
              { name: "product", title: "Product page", icon: <ProductPage /> },
              { name: "url", title: "Paste URL", icon: <PasteUrl /> },
              {
                name: "youtube",
                title: "YouTube channel",
                icon: <YoutubeChannel />,
              },
            ]}
          />
        </div>
        <div className="publish__buttons">
          <div
            className="cancel_button cursor__button"
            onClick={() => setPreview(true)}
          >
            <Preview /> <span>Preview</span>
          </div>
          <div
            className="submit_button cursor__button"
            onClick={() => publishNewStory()}
          >
            <Publish /> <span>Publish</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;
  const storybars = state.storybars.toJS();
  let { data } = storybars;

  return {
    idToken,
    data,
  };
};

const mapDispatchToProps = {
  publishStory,
  getStorybarData,
  setStoryPublishing,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublishBox)
);
