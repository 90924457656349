import { setAuthToken, instance, fetchRequest } from "./fetchData";

export function fetchLivesList({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/live`));
}

export function fetchLive({ liveId, authToken }) {
  setAuthToken("anon");

  return fetchRequest(instance.get(`/live/${liveId}`));
}

export function fetchCreateLive({ authToken, liveData }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post("/live", liveData));
}

export function fetchContactLive({ authToken, liveData }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post("/live/contact", liveData));
}

export function fetchEditLive({ liveId, liveData, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/live/${liveId}`, liveData));
}

export function fetchDeleteLive({ liveId, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.delete(`/live/${liveId}`));
}
