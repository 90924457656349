import React, { useState, useEffect } from "react";
import { ProductCard, Avatar, ProductView } from "../common";
import { BackArrow, DropdownArrow } from "../../icons/icons";
import classnames from "classnames";

const ProductBar = ({ products, title, avatar, isOpen, setOpen, prod }) => {
  const [active, setActive] = useState(false);
  const [shadow, setShadow] = useState({ top: false, bottom: true });
  const [product, setProduct] = useState(null);
  const [isProd, setIsProd] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setProduct(prod);
  }, [prod]);

  useEffect(() => {
    if (product !== null) {
      setActive(true);
      setIsProd(true);
      setShadow((prev) => ({ ...prev, top: false }));
    }
  }, [product]);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
    }
  }, [isOpen]);

  return (
    <div className={classnames("pb__overlay", { opened: show })}>
      <div
        className={classnames("product__bar", {
          opened: isOpen,
          active,
          closed: !isOpen,
        })}
      >
        <div className={classnames("product__header", { over: shadow.top })}>
          <div
            className="back__button"
            onClick={(e) => {
              e.stopPropagation();
              if (isProd) {
                setIsProd(false);
              } else {
                setOpen(false);
                setActive(false);
                setTimeout(() => {
                  setShow(false);
                }, 400);
              }
            }}
          >
            <BackArrow /> <span>Back</span>
          </div>
          <div
            className="button__primary small__button"
            onClick={() => {
              if (isProd) {
                window.open(products[product].url);
              } else {
                products.forEach((element) => {
                  window.open(element.url);
                });
              }
            }}
          >
            {isProd ? "View product" : "Open all product"}
          </div>
        </div>
        <div
          className="product__container"
          onScroll={(e) => {
            if (!active) {
              setActive(true);
              e.stopPropagation();
            }
            if (e.target.scrollTop !== 0) {
              setShadow((prev) => ({ ...prev, top: true }));
            } else {
              setShadow((prev) => ({ ...prev, top: false }));
            }
            if (
              e.target.scrollHeight - e.target.scrollTop <=
              e.target.clientHeight
            ) {
              setShadow((prev) => ({ ...prev, bottom: false }));
            } else {
              setShadow((prev) => ({ ...prev, bottom: true }));
            }
          }}
        >
          <div className="product__grid">
            {products
              ? products.map((e, i) => {
                  return (
                    <ProductCard
                      key={i}
                      {...e}
                      setProduct={setProduct}
                      id={i}
                    />
                  );
                })
              : null}
          </div>
          <div className="product__button">
            <div className="accent__button">Shop all</div>
          </div>
        </div>
        <ProductView {...products[product]} isOpen={isProd} />
        <div className={classnames("product__footer", { over: shadow.bottom })}>
          <div className="footer__info">
            <div className="avatar">
              <Avatar image={avatar} />
            </div>
            <div className="info">
              <div className="more">More from {title}</div>
              <div className="name">surely</div>
            </div>
          </div>
          <div className="more__arrow">
            <DropdownArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBar;
