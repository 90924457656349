import React, { useRef, useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NewStoryCard } from "../../components/common";
import { getCustomerStoriesList } from "../../redux/actions/storiesActions";
import { filterCards, getCoverImage } from "../../helpers/storiesHelper";
import { mockAnalytics, mockStories } from "../../mocks";
import "../../scss/pages/Review.scss";

const Review = () => {
  const dispatch = useDispatch();
  const [rowSize, setRowSize] = useState(10000);
  const { idToken } = useSelector((state) => state.auth.toJS());
  const { customerData, customerFetched, customerFetching } = useSelector(
    (state) => state.stories.toJS()
  );

  const container = useRef(null);

  const reviewsData = useMemo(
    () =>
      customerFetched
        ? customerData && customerData.length
          ? customerData
          : null
        : [...mockStories[0].live],
    [customerData, customerFetched]
  );

  //Fetching storie reviews
  useEffect(() => {
    dispatch(getCustomerStoriesList({ authToken: idToken }));
  }, [idToken, dispatch]);

  useEffect(() => {
    if (container.current) {
      new ResizeObserver(() => {
        setRowSize(getRowSize());
      }).observe(container.current);
    }
    // eslint-disable-next-line
  }, [container.current]);

  const getRowSize = () => {
    if (!container.current) {
      return 10000;
    }

    const contrainerWidth = container.current.offsetWidth;
    const storycardWidth = 106;
    const gap = 30;
    return (
      Math.floor((contrainerWidth - storycardWidth) / (storycardWidth + gap)) +
      1
    );
  };

  return (
    <div className="page-wrapper review-page">
      <h2 className="review-page__title">Story Reviews</h2>
      <div
        className={`review-page-reviews${
          customerFetching ? " pholder_item" : ""
        }`}
        ref={container}
      >
        {reviewsData ? (
          reviewsData.map((e, i) => {
            const cards = filterCards(e);
            return (
              <NewStoryCard
                key={e.id}
                id={e.id}
                story={e}
                idToken={idToken}
                isReview
                snapshot={cards[0].snapshot}
                image={getCoverImage(e, cards[0])}
                background={cards[0].backgroundColor}
                publishing={e.publishing}
                statistic={mockAnalytics[0].statistic}
                isLast={(i + 1) % rowSize === 0}
              />
            );
          })
        ) : (
          <div className="review-page__empty">
            <p>You currently have no reviews</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
