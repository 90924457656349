import Immutable from 'immutable';

import {actions as A} from '../actions/publishStoryActions';

const initialState = Immutable.fromJS({
    fetched: false,
    fetching: false,
    data: {},
    error: null,
    publishFetched: false,
    publishFetching: false,
    unpublishFetched: false,
    unpublishFetching: false,
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case A.PUBLISH_STORY_DATA_PENDING:
            return state
                .set('fetched', false)
                .set('fetching', true);
        case A.PUBLISH_STORY_DATA_FULFILLED:
            return state
                .set('fetched', true)
                .set('fetching', false)
                .set('data', action.data);
        case A.PUBLISH_STORY_DATA_REJECTED:
            return state
                .set('fetched', false)
                .set('fetching', false)
                .set('error', action.error);
        case A.PUBLISH_STORY_PENDING:
            return state
                .set('publishFetched', false)
                .set('publishFetching', true);
        case A.PUBLISH_STORY_FULFILLED:
            return state
                .set('publishFetched', true)
                .set('publishFetching', false);
        case A.PUBLISH_STORY_REJECTED:
            return state
                .set('publishFetched', false)
                .set('publishFetching', false)
                .set('error', action.error);
        case A.UNPUBLISH_STORY_PENDING:
            return state
                .set('unpublishFetched', false)
                .set('unpublishFetching', true);
        case A.UNPUBLISH_STORY_FULFILLED:
            return state
                .set('unpublishFetched', true)
                .set('unpublishFetching', false);
        case A.UNPUBLISH_STORY_REJECTED:
            return state
                .set('unpublishFetched', false)
                .set('unpublishFetching', false)
                .set('error', action.error);
        default:
            return state;
    }
}