import React from 'react';

const JustText = ({ position: { left = 0, top = 0 }, rotation = 0, scale = 1, text, styles }) => {
	return (
		<div
			className="text__container"
			style={{
				transform: `scale(1,1) translate(${left}px,${top}px)`
			}}
		>
			<div className="text__editor" style={{ transform: `rotate(${rotation}rad) scale(${scale})` }}>
				<p className="text__span" style={styles}>
					{text}
				</p>
			</div>
		</div>
	);
};

export default JustText;
