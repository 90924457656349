import React from "react";
import { PlanCard, Transition } from "../../components/common";
import basic from "../../images/basic.svg";
import advanced from "../../images/advanced.svg";
import professional from "../../images/professional.svg";
// import enterprise from "../../images/enterprise.svg";

const PlanChoose = ({ setPlan, plan, setChoosenPlan, setStep }) => {
  return (
    <Transition>
      <div className="page__title">Your wish is our command!</div>
      <div className="page__subtitle">
        In order to start creating awesome stories, please choose the plan that
        best meets your needs
      </div>
      <div className="page__subtitle mobile__sub">
        Please choose the plan that best meets your needs
      </div>
      <div className="choose__plan">
          <PlanCard
              isActive={plan === "freemium"}
              title="Freemium"
              subtitle="Immediately integrate radd. story reviews"
              image={basic}
              cardClick={setPlan}
              name="freemium"
              buttonClick={setChoosenPlan}
              price="for free"
              points={[
                  "3 self created stories",
                  "Shoppable stories",
                  "Video gallery",
              ]}
          />
        {/*<PlanCard*/}
        {/*  isActive={plan === "booster"}*/}
        {/*  title="Booster"*/}
        {/*  subtitle="Immediately integrate radd. story reviews"*/}
        {/*  image={basic}*/}
        {/*  cardClick={setPlan}*/}
        {/*  name="booster"*/}
        {/*  buttonClick={setChoosenPlan}*/}
        {/*  price="$99.99"*/}
        {/*  points={[*/}
        {/*    "25 story reviews",*/}
        {/*    "Story review & ratings option",*/}
        {/*    "Shoppable stories",*/}
        {/*    "Multiple product tagging",*/}
        {/*  ]}*/}
        <PlanCard
          isActive={plan === "advanced"}
          title="Advanced"
          subtitle="Add radd. story reviews with in-story promotions"
          image={advanced}
          cardClick={setPlan}
          name="advanced"
          buttonClick={setChoosenPlan}
          price="$299.99"
          points={[
            "100 story reviews",
            "All Basic features",
            "Offer in-story promotions",
          ]}
        />
        <PlanCard
          title="Pro"
          subtitle="Add radd. story reviews + enjoy all advanced features!"
          image={professional}
          name="pro"
          cardClick={setPlan}
          isActive={plan === "pro"}
          buttonClick={setChoosenPlan}
          price="$599.99"
          points={[
            "200 story reviews",
            "All Advanced features",
            "LIVE",
            "Place stories on product page",
            "Choose your story layout",
            "Full dashboard analytics",
            "No radd. Logo",
          ]}
        />
        {/* <PlanCard
          title="Enterprise"
          subtitle="Leave us your details and an expert will be in touch"
          image={enterprise}
          isEnter
          name="enterprise"
          cardClick={setPlan}
          isActive={plan === "enterprise"}
          buttonClick={setChoosenPlan}
          price="$199.99"
          points={[
            "Unlimited story upload",
            "All PRO plan features:",
            "",
            "Broadcast LIVE influencer shopping stories ",
            "Your very own customer success manager",
            "Your very own content creator",
          ]}
        /> */}
      </div>
      <div className="skip">
        <div className="skip__now" onClick={() => setStep(2)}>
          Skip for now
        </div>
        <div className="skip__hint">
          *stories will not upload unless payment is done
        </div>
      </div>
    </Transition>
  );
};
export default PlanChoose;
