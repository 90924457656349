import React, { useState, useEffect, useRef, useMemo } from "react";
import { TutorialCard, Text, ProductLabel } from "../common";
import {
  removeText,
  setTextParam,
  createText,
  setCardBackgroundColor,
  setStorySound,
  removeCard,
  setActiveCard,
  removeRating,
} from "../../redux/actions/editStoryActions";
import { BackIcon, Trash2, BigStar } from "../../icons/icons";
import _ from "lodash";
import { connect } from "react-redux";
import RoundButton2 from "../common/RB2";

const StoriesEditor = ({
  hideSound,
  setTutorial,
  tutorial,
  _id,
  index,
  updateText,
  setActiveText,
  activeText,
  removeCard,
  hovered,
  hoveredInst,
  storyData,
  setEdited,
  story: {
    sound = true,
    backgroundMedia,
    backgroundColor = "#E7E9EA",
    texts,
    products,
    instagrams,
    rating,
  },
  isHint,
  setColorTarget,
  handleTextDelete,
  setColorOpen,
  setStorySound,
  setAct,
  isActive = false,
  setSize,
  size,
  removeRating,
}) => {
  const [isHover, setHover] = useState(false);
  const [active, setActive] = useState(null);
  const video = useRef(null);
  const story = useRef(null);
  const isVideo = useMemo(
    () =>
      backgroundMedia && backgroundMedia.full
        ? backgroundMedia.full.split(".").pop() === "mp4" ||
          backgroundMedia.isVideo
        : false,
    [backgroundMedia]
  );
    const setSound = (sound) => {
      
      setStorySound(_id, sound);
  };

  useEffect(() => {
    if (window.innerWidth > 500) {
      if (story.current) {
        const vh = window.innerHeight;
        const ofTop = story.current.getBoundingClientRect().top;
        const storyH = vh - ofTop - 50;
        const storyW = Math.round((storyH * 414) / 736);
        if (storyH < 736) {
          setSize({ width: storyW, height: storyH });
        }
      }
    } else {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
  }, [story, setSize]);

  useEffect(() => {
    if (
      window.innerWidth < 500 &&
      document.querySelector(".button_story.active__round")
    ) {
      document
        .querySelector(".button_story.active__round")
        .setAttribute("style", `background: ${backgroundColor} !important`);
    }
  }, [backgroundColor, index]);

  useEffect(() => {
    if (isVideo && video.current) {
      video.current.play();
    }
  }, [isActive, setActiveText, setColorOpen, video, isVideo, backgroundMedia]);

  useEffect(() => {
    if (video.current) {
      if (isHint) {
        video.current.load();
        video.current.pause();
      } else {
        video.current.play();
      }
    }
  }, [isHint, video]);

  const getMedia = () => {
    if (!isVideo) {
      return (
        <img
          className="image__story"
          src={
            backgroundMedia
              ? backgroundMedia.full
                ? backgroundMedia.full
                : backgroundMedia.original
                ? backgroundMedia.original
                : null
              : null
          }
          alt=""
        />
      );
    } else {
      return (
        <video ref={video} loop autoPlay muted={!sound} src={backgroundMedia.full} />
      );
    }
  };

  return (
    <React.Fragment>
      <div
        id="st1"
        className="stories__editor"
        ref={story}
        style={{
          background: backgroundColor,
          width: size.width,
          height: size.height,
        }}
        onMouseLeave={() => {
          if (active === null) setHover(false);
        }}
        onMouseEnter={() => setHover(true)}
      >
        <div className="editor__container" id="editor__container">
          {storyData.cards.length > 1 ? (
            <div
              className="delete__card"
              onClick={(e) => {
                e.stopPropagation();
                removeCard(_id);
                if (storyData.cards.length - 1 === index) {
                  setAct(index - 1);
                }
              }}
            >
              <Trash2 />
            </div>
          ) : null}
          {rating ? (
            <div className="editor__rating">
              {[1, 2, 3, 4, 5].map((e, i) => {
                return (
                  <div
                    className={`editor__star${rating >= e ? " picked" : ``}`}
                  >
                    <BigStar />
                  </div>
                );
              })}
              <div
                className="editor__rating-hover"
                onClick={() => removeRating(_id)}
              >
                <Trash2 />
              </div>
            </div>
          ) : null}
          <div id="texts__container">
            {texts && texts.lenght !== 0
              ? texts.map((e, i) => {
                  return (
                    <Text
                      {...Object.assign(
                        {
                          position: { left: 0, top: 323 },
                          text: "",
                          rotation: 0,
                          scale: 1,
                          styles: {
                            fontWeight: "normal",
                            fontStyle: "normal",
                            color: "black",
                            textAlign: "left",
                          },
                        },
                        e
                      )}
                      updateText={updateText}
                      screenSize={{ width: 414, height: 736 }}
                      id={i}
                      key={e._id}
                      _id={e._id}
                      size={size}
                      isActive={activeText === i}
                      setActiveText={setActiveText}
                      setEdited={setEdited}
                      isHint={isHint}
                      handleTextDelete={handleTextDelete}
                    />
                  );
                })
              : null}
          </div>
          <div className="product__container">
            {products && products.length !== 0
              ? products.map((e, i) => {
                  return (
                    <ProductLabel
                      pos={e.pos}
                      error={e.error || false}
                      hovered={i === hovered && isActive}
                      screenSize={{ width: 414, height: 736 }}
                      key={i}
                      size={size}
                    />
                  );
                })
              : null}
          </div>
          <div className="product__container">
            {instagrams && instagrams.length !== 0
              ? instagrams.map((e, i) => {
                  return (
                    <ProductLabel
                      pos={e.pos}
                      error={e.error || false}
                      hovered={i === hoveredInst && isActive}
                      screenSize={{ width: 414, height: 736 }}
                      key={i}
                      size={size}
                      isInst
                    />
                  );
                })
              : null}
          </div>
          <div className="image__cont">
            {backgroundMedia ? getMedia() : null}
          </div>
          {isHover ||
          (tutorial !== 0 && isActive) ||
          window.innerWidth < 500 ? (
            <RoundButton2
              id="button_story"
              icon={<BackIcon />}
              activeClass="button_story active__round"
              stroke
              isBg
              isAuto
              isBottom={window.innerWidth < 500}
              isColor
              setActive={setActive}
              isActive={active === 7}
              isProd
              onOpen={() => {
                setColorOpen(true);
                setColorTarget("back");
              }}
              onClose={() => {
                setColorOpen(false);
                if (window.innerWidth < 500) {
                  document
                    .querySelector("#button_story .round__button")
                    .setAttribute(
                      "style",
                      `background: transparent !important`
                    );
                }
              }}
              mustOpen={active === 7}
              isTutorial={tutorial === 7}
              tutotial={
                <TutorialCard
                  setTutorial={setTutorial}
                  last
                  title="Add background color"
                  hint="Click on the background and select a color from the palette."
                  mobileHint="Click on the background and select a color."
                />
              }
            />
          ) : null}
          <div
            className={`story__sound cursor__button ${hideSound}`}
            onClick={() => setSound(!sound)}
          >
            Sound <span>{sound === true ? "OFF" : "ON"}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const editStory = state.editStory.toJS();
  let { storyData } = editStory;

  const auth = state.auth.toJS();
  let { idToken, authToken } = auth;

  return {
    storyData,
    authToken,
    idToken,
  };
};

const mapDispatchToProps = {
  removeText,
  setTextParam,
  createText,
  setCardBackgroundColor,
  setStorySound,
  removeCard,
  setActiveCard,
  removeRating,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ..._.mapValues(
    {
      removeText,
      setTextParam,
      createText,
      setCardBackgroundColor,
      setStorySound,
      removeCard,
      setActiveCard,
      removeRating,
    },
    (action, actionName) =>
      _.partialRight(
        dispatchProps[actionName],
        stateProps.storyData.id,
        stateProps.idToken
      )
  ),
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StoriesEditor);
