import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Transition,
  AnalyticCard,
  CreateStoryButton,
} from "../../components/common";
import { getHomepageAnalytics } from "../../redux/actions/storiesActions";
import { analyticNames } from "../../helpers/analytics";
import { Views2 } from "../../icons/icons";
import homepageImage from "../../images/homepage-image.png";
import "../../scss/pages/Home.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { orgInfo } = useSelector((state) => state.auth.toJS());
  const { homepageAnalytics, homepageAnalyticsFetching } = useSelector(
    (state) => state.stories.toJS()
  );

  useEffect(() => {
    if (!homepageAnalytics && !homepageAnalyticsFetching) {
      dispatch(
        getHomepageAnalytics(
          orgInfo.alias,
          new Date(Date.now() - 29 * 24 * 60 * 60 * 1000),
          new Date()
        )
      );
    }
    // eslint-disable-next-line
  }, [homepageAnalytics, homepageAnalyticsFetching]);

  return (
    <Transition>
      <div className={`page-wrapper home-page`}>
        <div className="home-page-info">
          <h1>One Seamless Experience</h1>
          <h6>
            Engage your online shoppers, collect <b>story reviews</b> and host{" "}
            <b>LIVEcasts</b>. All in one place.
          </h6>
          <CreateStoryButton />
        </div>
        <div className="home-page-analytic">
          <p className="home-page-analytic__title"> Your Monthly Analytics</p>
          <div
            className={`home-page-analytic__display${
              homepageAnalyticsFetching ? " pholder_item" : ""
            }`}
          >
            <AnalyticCard
              icon={<Views2 />}
              title={analyticNames[0]}
              count={
                homepageAnalytics ? homepageAnalytics[analyticNames[0]] : null
              }
              loading={homepageAnalyticsFetching}
            />
            <AnalyticCard
              icon={<Views2 />}
              title={analyticNames[1]}
              count={
                homepageAnalytics ? homepageAnalytics[analyticNames[1]] : null
              }
              loading={homepageAnalyticsFetching}
            />
            <AnalyticCard
              icon={<Views2 />}
              title={analyticNames[2]}
              count={
                homepageAnalytics ? homepageAnalytics[analyticNames[2]] : null
              }
              loading={homepageAnalyticsFetching}
            />
            <AnalyticCard
              icon={<Views2 />}
              title={analyticNames[3]}
              count={
                homepageAnalytics ? homepageAnalytics[analyticNames[3]] : null
              }
              loading={homepageAnalyticsFetching}
            />
          </div>
          <div className="home-page-analytic__image">
            <img src={homepageImage} alt="analytic" />
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Home;
