import {
  fetchSetOrgData,
  fetchOrgMembers,
  fetchSetOrgAdmin,
  fetchInviteToOrg,
  fetchRemoveUserFromOrg,
  fetchCreateOrganization,
  fetchDeclineOrgInvite,
  fetchLeaveOrg,
} from "../utils/fetchOrg";

export const actions = {
  SET_ORG_DATA_PENDING: "SET_ORG_DATA_PENDING",
  SET_ORG_DATA_FULFILLED: "SET_ORG_DATA_FULFILLED",
  SET_ORG_DATA_REJECTED: "SET_ORG_DATA_REJECTED",
  GET_ORG_MEMBERS_PENDING: "GET_ORG_MEMBERS_PENDING",
  GET_ORG_MEMBERS_FULFILLED: "GET_ORG_MEMBERS_FULFILLED",
  GET_ORG_MEMBERS_REJECTED: "GET_ORG_MEMBERS_REJECTED",
  SET_ORG_ADMIN_PENDING: "SET_ORG_ADMIN_PENDING",
  SET_ORG_ADMIN_FULFILLED: "SET_ORG_ADMIN_FULFILLED",
  SET_ORG_ADMIN_REJECTED: "SET_ORG_ADMIN_REJECTED",
  INVITE_TO_ORG_PENDING: "INVITE_TO_ORG_PENDING",
  INVITE_TO_ORG_FULFILLED: "INVITE_TO_ORG_FULFILLED",
  INVITE_TO_ORG_REJECTED: "INVITE_TO_ORG_REJECTED",
  REMOVE_USER_FROM_ORG_PENDING: "REMOVE_USER_FROM_ORG_PENDING",
  REMOVE_USER_FROM_ORG_FULFILLED: "REMOVE_USER_FROM_ORG_FULFILLED",
  REMOVE_USER_FROM_ORG_REJECTED: "REMOVE_USER_FROM_ORG_REJECTED",
  CREATE_ORGANIZATION_PENDING: "CREATE_ORGANIZATION_PENDING",
  CREATE_ORGANIZATION_FULFILLED: "CREATE_ORGANIZATION_FULFILLED",
  CREATE_ORGANIZATION_REJECTED: "CREATE_ORGANIZATION_REJECTED",
  DECLINE_ORG_INVITE_PENDING: "DECLINE_ORG_INVITE_PENDING",
  DECLINE_ORG_INVITE_FULFILLED: "DECLINE_ORG_INVITE_FULFILLED",
  DECLINE_ORG_INVITE_REJECTED: "DECLINE_ORG_INVITE_REJECTED",
  LEAVE_ORG_PENDING: "LEAVE_ORG_PENDING",
  LEAVE_ORG_FULFILLED: "LEAVE_ORG_FULFILLED",
  LEAVE_ORG_REJECTED: "LEAVE_ORG_REJECTED",
  UPDATE_ORG_DATA_FIELDS: "UPDATE_ORG_DATA_FIELDS",
};

export function updateOrdData(data) {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_ORG_DATA_FIELDS,
      data
    })
  }
}
export function setOrgData({ authToken, orgAlias, orgData }) {
  return async (dispatch) => {
    if (!("approvalRequired" in orgData)) {
      dispatch({
        type: actions.SET_ORG_DATA_PENDING,
      });
    }

    try {
      let { result } = await fetchSetOrgData({ authToken, orgAlias, orgData });

      dispatch({
        type: actions.SET_ORG_DATA_FULFILLED,
        data: result,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: actions.SET_ORG_DATA_REJECTED,
        error,
      });
    }
  };
}

export function getOrgMembers({ authToken, orgAlias }) {
  return async (dispatch) => {
    dispatch({
      type: actions.GET_ORG_MEMBERS_PENDING,
    });

    try {
      let data = await fetchOrgMembers({ authToken, orgAlias });

      dispatch({
        type: actions.GET_ORG_MEMBERS_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: actions.GET_ORG_MEMBERS_REJECTED,
        error,
      });
    }
  };
}

export function setOrgAdmin({ authToken, orgAlias, userId, value }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_ORG_ADMIN_PENDING,
    });

    try {
      await fetchSetOrgAdmin({ authToken, orgAlias, userId, value });

      dispatch({
        type: actions.SET_ORG_ADMIN_FULFILLED,
      });

      dispatch(getOrgMembers({ authToken, orgAlias }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SET_ORG_ADMIN_REJECTED,
        error,
      });
    }
  };
}

export function inviteToOrg({ authToken, orgAlias, email }) {
  return async (dispatch) => {
    dispatch({
      type: actions.INVITE_TO_ORG_PENDING,
    });

    try {
      await fetchInviteToOrg({ authToken, orgAlias, email });

      dispatch({
        type: actions.INVITE_TO_ORG_FULFILLED,
        data: {
          email,
        },
      });

      dispatch(getOrgMembers({ authToken, orgAlias }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.INVITE_TO_ORG_REJECTED,
        error,
      });
    }
  };
}

export function removeUserFromOrg({ authToken, orgAlias, userName, userId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.REMOVE_USER_FROM_ORG_PENDING,
    });

    try {
      await fetchRemoveUserFromOrg({ authToken, orgAlias, userId });

      dispatch({
        type: actions.REMOVE_USER_FROM_ORG_FULFILLED,
        data: {
          name: userName,
        },
      });

      dispatch(getOrgMembers({ authToken, orgAlias }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.REMOVE_USER_FROM_ORG_REJECTED,
        error,
      });
    }
  };
}

export function createOrganization({ authToken, name, logo, homepage }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CREATE_ORGANIZATION_PENDING,
    });

    try {
      let data = await fetchCreateOrganization({
        authToken,
        name,
        logo,
        homepage,
      });

      dispatch({
        type: actions.CREATE_ORGANIZATION_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.CREATE_ORGANIZATION_REJECTED,
        error,
      });
    }
  };
}

export function declineOrgInvite({
  authToken,
  orgAlias,
  inviteToken,
  userEmail,
}) {
  return async (dispatch) => {
    dispatch({
      type: actions.DECLINE_ORG_INVITE_PENDING,
    });

    try {
      await fetchDeclineOrgInvite({ authToken, orgAlias, inviteToken });

      dispatch({
        type: actions.DECLINE_ORG_INVITE_FULFILLED,
        data: {
          email: userEmail,
        },
      });

      dispatch(getOrgMembers({ authToken, orgAlias }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.DECLINE_ORG_INVITE_REJECTED,
        error,
      });
    }
  };
}

export function leaveOrg({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.LEAVE_ORG_PENDING,
    });

    try {
      let data = await fetchLeaveOrg({ authToken });

      dispatch({
        type: actions.LEAVE_ORG_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.LEAVE_ORG_REJECTED,
        error,
      });
    }
  };
}

export function logout() {
  try {
    localStorage.removeItem(`idToken`);
    localStorage.removeItem(`accessToken`);
  } catch (e) {}

  return {
    type: actions.LOGOUT,
  };
}
