import React, { useRef } from "react";

const InstagramTag = ({ name, url, pos, active, scale }) => {
  const ref = useRef(null);

  return (
    <div
      className="instagram__tag"
      ref={ref}
      style={
        ref.current
          ? Object.assign(
              active
                ? {
                    opacity: 1,
                  }
                : {
                    opacity: 0,
                  },
              {
                transform: `scale(${scale.width},${scale.height}) translate(${
                  pos.left - ref.current.offsetWidth / 2 || 0
                }px,${pos.top || 0}px)`,
              }
            )
          : {}
      }
    >
      <span
        onClick={() => window.open(name, "_blank")}
        className="instagram__link"
      >
        {url}
      </span>
      <div className="tag__triangle"></div>
    </div>
  );
};

export default InstagramTag;
