import React, {useEffect, useState} from 'react'
import { SwitchButton } from './SwitchButton'
import '../../scss/components/LiveStreamProducts.scss'
import { editLiveStream } from "../../redux/actions/livesActions";
import { connect } from "react-redux";

const LiveStreamProducts = ({products, socket, switchProductFunc, editLiveStream, idToken, storyId}) => {
  const [arrId, setArrId] = useState([])

  useEffect(() => {
      const ids = []
      if (Array.isArray(products) && products.length !== 0) {
          products.map(({productId, isShow}) => {
              if (isShow) {
                  ids.push(productId)
              }
              return ids
          })
      }
      setArrId(ids)
  }, [products])

  const addIdToArray = async (checked, id) => {
      if (checked) {
          setArrId( prev => {
              return [...prev, id]
          })
          await editStream(id, true)
      } else {
          setArrId(prev => prev.filter(el => el !== id))
          await editStream(id, false)
      }
  }

  const editStream = async (id, value) => {
      const newProducts = products.map((el) => {
          if (el.productId === id) {
              el.isShow = value
          }
          return el
      })

      await editLiveStream({
          authToken: idToken,
          liveData: {
              products: newProducts
          },
          liveId: storyId
      })
  }

  return(
    <div className='LiveStreamProducts'>

      <div className='LiveStreamProducts__title'>
        <h4 className='LiveStreamProducts__title--h4'>ADD PRODUCTS FOR SHOPPERS</h4>
        <p className='LiveStreamProducts__title--p'>Swipe to add products to shoppers’ screens *only 3 products can be turned on at once</p>
      </div>
      <div className='LiveStreamProducts__products-wrapper'>
      { products
        ? products.map((item, i) => {
            return (
              <div className='LiveStreamProducts__products' key={i}>
                <p className='LiveStreamProducts__products--top-line'/>
                <div className='LiveStreamProducts__products--img '>
                 {item?.promo?.promo ?  <p className='LiveStreamProducts__products--discount'>{item?.promo?.promo}</p> : null}
                  <img src={item.image} alt='img-goods'/>
               </div>
                <div className='LiveStreamProducts__products--info'>
                  <div className='LiveStreamProducts__products--info__text'>
                    <h6 className='product-title'>{item.name}</h6>
                    <div className={`LiveStreamProducts__products--info__price ${item?.promo?.promo ? 'discount' : null}`}>
                      {item?.promo?.promo ? 
                          <p className='LiveStreamProducts__products--price discount'>{
                            (+item?.price?.slice(0, -1) - (+item?.price?.slice(0, -1) / 100 * +item?.promo?.promo))}
                          </p> 
                          : null}
                      <p className='LiveStreamProducts__products--price'>{item.price}</p>
                    </div>
                  </div>
                  <SwitchButton arrId={arrId}
                                isShow={item.isShow}
                                id={item.productId}
                                socket={socket}
                                addIdToArray={addIdToArray}
                                switchProductFunc={switchProductFunc}/>
                </div>
              </div>
            )
        })
        : null
      }
      </div>
      
    </div>
  )
}
const mapStateToProps = (state) => {
    const auth = state.auth.toJS();
    let { idToken } = auth;

    return { idToken };
};

const mapDispatchToProps = { editLiveStream };

export default connect(mapStateToProps, mapDispatchToProps, null)(LiveStreamProducts)
