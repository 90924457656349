import React from "react";
import Gallery from "../common/Gallery/Gallery";
//import image from "../../images/product.png";

//Some images for example
// const arr = [image, image, image, image, image, image, image, image, image];

const Share = ({
  data,
  dataFetching,
  dataFetched,
  discoverClick,
  customerClick,
  customerData,
}) => (
  <div className="share__block">
    <Gallery
      images={data}
      loaded={dataFetched}
      loading={dataFetching}
      discoverClick={discoverClick}
      customerClick={customerClick}
      customerData={customerData}
    />
  </div>
);

export default Share;
