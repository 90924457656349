import React from 'react'
import { Close } from '../../icons/icons'
import '../../scss/components/ExitLiveStreamPopup.scss'

export const ExitLiveStreamPopup = ({setExitPopupStream, exitPopupStream}) => {
  return (
    <div className="exitPopupLiveStream" style={{visibility: exitPopupStream ? "visible" : "hidden"}}>
      <div className="exitPopupLiveStream__popup">
        <div className="closePopup" onClick={() => setExitPopupStream(false)}>
          <Close/>
        </div>
        <h4 className='exitPopupLiveStream__popup--title'>Are you sure you want to exit the LIVE stream?</h4>
        <div className="wrapper-buttons">
          <button className='continueStream' onClick={() => setExitPopupStream(false)}>Continue LIVE stream</button>
          <button id='exitStream'>Exit</button>
        </div>
      </div>
    </div>
  )
}
