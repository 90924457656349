import { Analytics, CreateStory, Invite, ViewTutorial, Live } from "../pages";

import Home1 from "../pages/NewPages/Home";
import Account from "../pages/NewPages/Account";
import Pricing1 from "../pages/NewPages/Pricing";
import Stories1 from "../pages/NewPages/Stories";
import Edit from "../pages/NewPages/Edit";
import Review from "../pages/NewPages/Review";
import Customization from "../pages/Customization/Customization";

import globalRoutes from "./globalRoutes";

export default [
  {
    path: "/",
    exact: true,
    withHeader: true,
    headerTitle: "Home",
    component: Home1,
  },
  {
    path: "/plans",
    exact: true,
    withHeader: true,
    component: Pricing1,
  },
  {
    path: "/manage-account",
    exact: true,
    withHeader: true,
    headerTitle: "Manage account",
    component: Account,
  },
  {
    path: "/stories",
    exact: true,
    withHeader: true,
    headerTitle: "Stories",
    component: Stories1,
  },
  {
    path: "/discover",
    exact: true,
    withHeader: true,
    headerTitle: "Review",
    component: Review,
  },
  {
    path: "/invite",
    exact: true,
    withHeader: true,
    headerTitle: "Invite",
    component: Invite,
  },
  {
    path: "/live",
    exact: true,
    withHeader: true,
    headerTitle: "LIVE",
    component: Live,
  },
  {
    path: "/analytics",
    exact: true,
    withHeader: true,
    headerTitle: "Analytics",
    component: Analytics,
  },
  {
    path: "/view-tutorial",
    exact: true,
    withHeader: true,
    headerTitle: "View tutorial",
    component: ViewTutorial,
  },
  {
    path: "/customization",
    exact: true,
    withHeader: true,
    headerTitle: "View tutorial",
    component: Customization,
  },
  {
    path: "/edit/:id",
    exact: true,
    withHeader: true,
    hideTopbar: true,
    component: Edit,
  },
  {
    path: "/create-stories/:id",
    exact: true,
    withHeader: false,
    component: CreateStory,
  },
  ...globalRoutes,
];
