import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loader } from "../atoms";
import ProductTagsCard from "./ProductTagsCard";
import NewInput from "./NewInput";
import { UploadIcon, BackArrow, Close } from "../../icons/icons";
import {
  getProductData,
  resetProductData,
  createProduct,
  setProdParams,
  removeProduct,
} from "../../redux/actions/editStoryActions";
import { uploadFile } from "../../redux/utils/fileUpload";
import "../../scss/components/ProductTags.scss";

const ProductTags = ({ cardId, storyId, products, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { addProductData, addProductDataFetching, addProductDataFetched } =
    useSelector((state) => state.editStory.toJS());
  const { idToken } = useSelector((state) => state.auth.toJS());
  const [activeProduct, setActiveProduct] = useState({});
  const [imageUploading, setImageUploading] = useState(false);
  const [addProductActive, setAddProductActive] = useState(false);

  useEffect(() => {
    if (addProductData && addProductDataFetched) {
      setActiveProduct(addProductData);
    }
    // eslint-disable-next-line
  }, [addProductDataFetched]);

  const handleProductAdd = () => {
    setAddProductActive(true);
  };

  const handleProductDelete = (id) => {
    dispatch(removeProduct(cardId, id, storyId, idToken));
  };

  const handleFetchProductData = () => {
    dispatch(getProductData(activeProduct.url));
  };

  const handleAddProduct = () => {
    if (activeProduct._id) {
      dispatch(
        setProdParams(
          cardId,
          activeProduct._id,
          activeProduct,
          storyId,
          idToken
        )
      );
    } else {
      dispatch(createProduct(cardId, activeProduct, storyId, idToken));
    }
    resetAddProduct();
  };

  const resetAddProduct = () => {
    dispatch(resetProductData());
    setActiveProduct({});
    setAddProductActive(false);
  };

  const handleProductDataChange = (name, value) => {
    setActiveProduct((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleProductUrlChange = (e) => {
    const value = e.target.value;
    handleProductDataChange("url", value);
  };

  const handleProductNameChange = (e) => {
    const value = e.target.value;
    handleProductDataChange("name", value);
  };

  const handleProductShopifyIdChange = (e) => {
    const value = e.target.value;
    handleProductDataChange("shopifyId", value);
  };

  const uploadProductImage = async (blob) => {
    setImageUploading(true);
    const url = await uploadFile({
      blob,
      mediaType: "cover",
    });
    setTimeout(() => {
      setImageUploading(false);
    }, 400);
    handleProductDataChange("image", url);
  };

  console.log(activeProduct);

  const renderProducts = () => (
    <>
      <div className="product-tags-products">
        {products.map((product) => (
          <ProductTagsCard
            image={product.image}
            name={product.name}
            onEdit={() => {
              setAddProductActive(true);
              setActiveProduct(product);
            }}
            onDelete={() => {
              handleProductDelete(product._id);
            }}
          />
        ))}
      </div>
      <button className="button button--accent" onClick={handleProductAdd}>
        Add Product
      </button>
    </>
  );

  const renderProductAdding = () => {
    return (
      <div
        className={`product-tags-add${
          addProductActive ? " product-tags-add--active" : ""
        }`}
      >
        <NewInput
          label="URL"
          placeholder="Please paste the URL here"
          name="url"
          type="text"
          value={activeProduct.url || ""}
          onChange={handleProductUrlChange}
        />
        <div
          className={`product-tags-add-info${
            addProductDataFetched || activeProduct._id
              ? " product-tags-add-info--opened"
              : ""
          }`}
        >
          <NewInput
            label="Product name"
            placeholder="Please enter product name"
            name="name"
            type="text"
            value={activeProduct.name}
            onChange={handleProductNameChange}
          />
          <NewInput
            label="Shopify product ID"
            placeholder="Please enter shopify product ID"
            name="shopifyId"
            type="text"
            value={activeProduct.shopifyId}
            onChange={handleProductShopifyIdChange}
          />
          <div className="product-tags-add-info__image">
            <div className="product-tags-add-info__image-container">
              {imageUploading ? (
                <div className="product-tags-add-info__image-loader">
                  <Loader size={30} />
                </div>
              ) : null}
              <img src={activeProduct.image} alt="product" />
            </div>

            <label htmlFor={`product-${cardId}`}>
              Change product image <UploadIcon />
            </label>
            <input
              type="file"
              accept="image/*"
              id={`product-${cardId}`}
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files[0]) {
                  uploadProductImage(e.target.files[0]);
                }
              }}
            />
          </div>
        </div>
        {addProductDataFetched || activeProduct._id ? (
          <Button
            text="Save"
            onClick={handleAddProduct}
            disabled={imageUploading}
          />
        ) : (
          <Button
            text="Get Product"
            onClick={handleFetchProductData}
            disabled={!activeProduct.url}
            loading={addProductDataFetching || imageUploading}
          />
        )}
        <div className="back__button" onClick={resetAddProduct}>
          <BackArrow /> <span>Back</span>
        </div>
      </div>
    );
  };

  return (
    <div className={`product-tags${isOpen ? " product-tags--active" : ""}`}>
      <div
        className="product-tags__close cursor-pointer"
        onClick={() => {
          resetAddProduct();
          onClose();
        }}
      >
        <Close />
      </div>
      <div className="product-tags__header">Add Product Tag</div>
      <div className="product-tags-container">{renderProducts()}</div>
      {renderProductAdding()}
    </div>
  );
};

export default ProductTags;
