import React, { useState } from "react";

const ProductCard = ({image, name, promo = { promo: 20, startDate: 1636627260698, endDate: 1636628260698 }, url, added,}) => {
    const [defimage, setImage] = useState(null);

    const isBetween = (promo) => {
        const today = new Date();
        const startDate = new Date(promo.startDate);
        const endDate = new Date(promo.endDate);
        return today >= startDate && today <= endDate;
    };

    return (
        <div
            className="live-product"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // setProduct();
            }}
        >
            <div className="live-product-img">
                {promo && isBetween(promo) ? (
                    <div className="live-product-promo">{`${promo.promo}%`}</div>
                ) : (
                    ``
                )}
                <img
                    src={defimage || image}
                    alt="defaul_image"
                    className="live-product-image"
                    onError={() =>
                        setImage(
                            `https://dashboard.raddcontent.com/images/placeholderimage.jpg`
                        )
                    }
                />
            </div>
            <div className="name-and-add-to-cart">
                <div className="new-live-product-name">
                    { name }
                </div>
                <a href={url} onClick={(e) => {
                    e.preventDefault()
                    window.open(url, '_blank');
                }} className={`product__buy product__but${added ? " added" : ""}`}>
                    Open Product
                </a>
            </div>
        </div>
    );
};

export default ProductCard;
