import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { fetchAuthInstagram } from "../redux/utils/fetchStories";

function Instagram({ history }) {
  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var code = url.searchParams.get("code");

    fetchAuthInstagram({
      authToken: localStorage.getItem(`idToken`),
      body: {
        code,
      },
    }).then((res) => {
      localStorage.setItem("instagramToken", res.result.access_token);
      history.push("/discover");
    });

    // fetch("https://api.dev.raddcontent.com/auth/instagram", {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/x-www-form-urlencoded",
    //     authorization: localStorage.getItem("idToken"),
    //   },
    //   body: JSON.stringify({
    //     code,
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     localStorage.setItem("instagramToken", res.result.access_token);
    //     history.push("/discover");
    //   });

    // const details = {
    //   client_id: process.env.REACT_APP_INSTAGRAM_ID,
    //   client_secret: process.env.REACT_APP_INSTAGRAM_SECRET,
    //   code,
    //   grant_type: "authorization_code",
    //   redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/instagram`,
    // };
    // var formBody = [];
    // for (var property in details) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(details[property]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }
    // formBody = formBody.join("&");
    // fetch("https://api.instagram.com/oauth/access_token", {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/x-www-form-urlencoded",
    //   },
    //   body: formBody,
    // }).then((response) =>
    //   response.json().then((e) => {
    //     fetch(
    //       `https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=${process.env.REACT_APP_INSTAGRAM_SECRET}&access_token=${e.access_token}`
    //     ).then((response) =>
    //       response.json().then((el) => {
    //         if (el.access_token) {
    //           localStorage.setItem("instagramToken", el.access_token);
    //         }
    //         history.push("/discover");
    //       })
    //     );
    //   })
    // );
  }, [history]);

  return <div></div>;
}

export default withRouter(Instagram);
