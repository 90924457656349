import React from "react";
import { Check } from "../../icons/icons";
import "../../scss/components/ThreeStep.scss";

const ThreeStep = ({ step, setStep }) => {
  return (
    <div className="three__step">
      <div className="step__line" />
      <div
        className="step__container cursor__button"
        onClick={() => setStep(1)}
      >
        <div
          className={`step ${step === 1 ? "current" : step > 1 ? "done" : ""}`}
        >
          <div className="step__check">
            <Check />
          </div>
        </div>
      </div>
      <div
        className="step__container cursor__button"
        onClick={() => setStep(2)}
      >
        <div
          className={`step ${step === 2 ? "current" : step > 2 ? "done" : ""}`}
        >
          <div className="step__check">
            <Check />
          </div>
        </div>
      </div>
      <div className="step__container cursor__button" onClick={() => {}}>
        <div
          className={`step ${step === 3 ? "current" : step > 3 ? "done" : ""}`}
        >
          <div className="step__check">
            <Check />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeStep;
