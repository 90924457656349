import Immutable from "immutable";

import { actions as A } from "../actions/storiesActions";

const initialState = Immutable.fromJS({
  fetching: false,
  fetched: false,
  data: {},
  error: null,
  analytics: {},
  createStoryFetched: false,
  createStoryFetching: false,
  lastCreatedStoryId: null,
  updateFetching: false,
  updateFetched: false,
  deleteStoryFetched: false,
  deleteStoryFetching: false,
  discover: null,
  discoverFetching: false,
  discoverFetched: false,
  discovererror: null,
  analyticsFetched: false,
  analyticsData: null,
});

export default function reducer(state = initialState, action) {
  let storyId, data;

  switch (action.type) {
    case A.RESET_STORIES_DATA:
      return initialState;
    case A.LIVE_LIST_PENDING:
      return state.set("fetched", false).set("fetching", true);
    case A.WHOLE_ANALYTICS_FULFILLED:
      return state
        .set("analyticsFetched", true)
        .set("analyticsData", Immutable.fromJS(action.data));
    case A.LIVE_LIST_FULFILLED:
      return state
        .set("fetched", true)
        .set("fetching", false)
        .set("data", Immutable.fromJS(action.data));
    case A.LIVE_LIST_REJECTED:
      return state
        .set("fetched", false)
        .set("fetching", false)
        .set("error", action.error);
    case A.DISCOVER_LIST_PENDING:
      return state.set("discoverFetched", false).set("discoverFetching", true);
    case A.DISCOVER_LIST_FULFILLED:
      return state
        .set("discoverFetched", true)
        .set("discoverFetching", false)
        .set("discover", Immutable.fromJS(action.data));
    case A.DISCOVER_LIST_REJECTED:
      return state
        .set("discoverFetched", false)
        .set("discoverFetching", false)
        .set("discovererror", action.error);
    case A.STORY_ANALYTICS_FULFILLED:
      ({ storyId, data } = action.data);

      return state.setIn(["analytics", storyId], Immutable.fromJS(data));
    case A.CREATE_STORY_PENDING:
      return state
        .set("createStoryFetched", false)
        .set("createStoryFetching", true);
    case A.CREATE_STORY_FULFILLED:
      return state
        .set("createStoryFetched", true)
        .set("createStoryFetching", false)
        .set("lastCreatedStoryId", action.data.id);
    case A.CREATE_STORY_REJECTED:
      return state
        .set("createStoryFetched", false)
        .set("createStoryFetching", false)
        .set("error", action.error);
    case A.UPDATE_STORIES_PENDING:
      return state.set("updateFetched", false).set("updateFetching", true);
    case A.UPDATE_STORIES_FULFILLED:
      return state
        .set("updateFetched", true)
        .set("updateFetching", false)
        .set("data", action.data);
    case A.UPDATE_STORIES_REJECTED:
      return state
        .set("updateFetched", false)
        .set("updateFetching", false)
        .set("error", action.error);
    case A.DELETE_STORY_PENDING:
      return state
        .set("deleteStoryFetched", false)
        .set("deleteStoryFetching", true);
    case A.DELETE_STORY_FULFILLED:
      return state
        .set("deleteStoryFetched", true)
        .set("deleteStoryFetching", false);
    case A.DELETE_STORY_REJECTED:
      return state
        .set("deleteStoryFetched", false)
        .set("deleteStoryFetching", false)
        .set("error", action.error);

    default:
      return state;
  }
}
