import React, { useState } from "react";
import { Button } from "../atoms";
import {
  PlanClose,
  Check,
  UploadIcon,
  PublishHome,
  PublishUrl,
} from "../../icons/icons";
import "../../scss/components/PublishModal.scss";

const PublishModal = ({ title, onPublish, onClose, preloader }) => {
  const [checked, setChecked] = useState(["home"]);
  const [url, setUrl] = useState("");

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setUrl(value);
  };

  const getPublish = (val) => {
    const arr = [...checked];
    if (arr.indexOf(val) === -1) {
      arr.push(val);
    } else {
      arr.splice(arr.indexOf(val), 1);
    }
    setChecked(arr);
  };

  return (
    <div className="publish-modal__wrapper">
      <div className="publish-modal">
        <div className="publish-modal__close" onClick={onClose}>
          <PlanClose />
        </div>
        <p className="publish-modal__title">{title}</p>
        <p className="publish-modal__text">Send to:</p>
        <p className="publish-modal__subtitle">Check at list one option:</p>
        <div className="publish-modal-options">
          <div className="publish-modal-options__item">
            <div
              className={`publish-modal-options__item-check${
                checked.includes("home")
                  ? " publish-modal-options__item-check--checked"
                  : ""
              }`}
              onClick={() => getPublish("home")}
            >
              {checked.includes("home") && <Check />}
            </div>
            <span className="publish-modal-options__item-icon">
              <PublishHome />
            </span>
            <p className="publish-modal-options__item-text">Home Page</p>
          </div>
          <div className="publish-modal-options__item">
            <div
              className={`publish-modal-options__item-check${
                checked.includes("url")
                  ? " publish-modal-options__item-check--checked"
                  : ""
              }`}
              onClick={() => getPublish("url")}
            >
              {checked.includes("url") && <Check />}
            </div>
            <span className="publish-modal-options__item-icon">
              <PublishUrl />
            </span>
            <input
              className="publish-modal-options__item-input"
              placeholder="URL"
              value={url}
              onChange={handleUrlChange}
            />
          </div>
          {/* <div className="publish-modal-options__item">
            <div
              className={`publish-modal-options__item-check${
                checked.includes("storybar")
                  ? " publish-modal-options__item-check--checked"
                  : ""
              }`}
            >
              {checked.includes("storybar") && <Check />}
            </div>
            <span className="publish-modal-options__item-icon">
              <PublishStorybar />
            </span>
            <input
              className="publish-modal-options__item-input"
              placeholder="URL"
              value={url}
              onChange={handleUrlChange}
            />
          </div> */}
        </div>
        <Button
          text={preloader ? "Publishing" : "Publish Highlight"}
          onClick={() => onPublish(checked, { url })}
          disabled={preloader}
          icon={preloader ? <img src="/images/loader-puprle.gif" className='loader_purple' alt="loader_purple"/> :
              <UploadIcon/>}
        />
      </div>
    </div>
  );
};

export default PublishModal;
