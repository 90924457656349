import React, { useEffect } from "react";
import { v1 as uuid } from "uuid";
import client from "../redux/utils/client";
import normalizeUrl from "normalize-url";
import { withRouter } from "react-router-dom";

const Shopify = ({ history }) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shopifyToken = urlParams.get("shopify_token");
    const shopUrl = urlParams.get("shop_url");
    const email = urlParams.get("email") || `${uuid()}@gomail.com`;
    const domain = urlParams.get("domain") || "domain";
    const company = urlParams.get("company") || "company";
    if (!shopifyToken || !shopUrl || !email || !domain || !company) {
      history.replace(`/`);
      return;
    }
    client
      .shopify({
        shopifyToken,
        shopUrl,
        email,
        domain:
          normalizeUrl(domain, {
            forceHttps: true,
            stripHash: true,
            removeQueryParameters: true,
            removeTrailingSlash: true,
            removeSingleSlash: true,
          }) + "/",
        company,
      })
      .then(() => history.replace("/"));
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default withRouter(Shopify);
