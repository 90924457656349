import React, { useEffect, useState } from "react";
import { Modal, Input, Loader, Avatar } from "../common";
import { Close } from "../../icons/icons";
import defaultImg from "../../images/default_upload.png";
import { connect } from "react-redux";
import { uploadFile } from "../../redux/utils/fileUpload";
import {
  createLiveStream,
  editLiveStream,
} from "../../redux/actions/livesActions";
import { defaultImage } from "../../globalConst";

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const CreateLive = ({
  children,
  profile,
  idToken,
  createLiveStream,
  editLiveStream,
  date = "",
  time = "",
  products = [],
  name = "",
  influencer = "",
  instagram = "",
  isEdit,
  status,
  liveId,
}) => {
  const [prodData, setProdData] = useState(() => products);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState(() => ({
    name,
    influencer,
    date,
    time,
    datetime: date + `T` + time,
    instagram,
  }));

  const [image, setImage] = useState(influencer);
  const [blob, setBlob] = useState(null);

  const defaultInfluencer = defaultImage;

  useEffect(() => {
    if (influencer !== "" && image !== defaultInfluencer) {
      setDisabled(false);
    }
  }, [influencer, image, defaultInfluencer]);

  const setData = (obj) => {
    setFormData((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const removeProduct = (index) => {
    let newArr = [...prodData];
    newArr.splice(index, 1);
    setProdData(newArr);
    console.log(newArr);
  };

  const getProduct = (url) => {
    setIsLoading(true);
    fetch(`https://api.dev.raddcontent.com/products`, {
      method: "POST",
      headers: {
        Authorization: idToken,
      },
      body: JSON.stringify({ url }),
    })
      .then((x) => x.json())
      .then(async (res) => {
        if (res.result && res.result.productId) {
          let shopifyId = "";
          let alternativeName = "";
          await fetch(url + ".json")
            .then((data) => {
              if (data.status === 200) {
                return data.json();
              }
            })
            .then((json) => {
              shopifyId = json?.product?.variants[0].id;
              alternativeName = json?.product?.title;
              console.log(json);
            });
          const { name, image, productId, price } = res.result;

          let filterProd = prodData.some((i) => i.name === alternativeName);
          if (!filterProd) {
            setProdData((prod) => [
              ...prod,
              {
                name: name || alternativeName,
                image,
                productId,
                price,
                url,
                shopifyId,
                isShow: false,
              },
            ]);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const createLive = async () => {
    let validTime = new Date(new Date().getTime() + 23 * 60 * 60 * 1000);
    if (!formData.name) {
      setErrors((prev) => ({ ...prev, name: "This field is required" }));
      return;
    } else {
      const er = { ...errors };
      delete er.name;
      setErrors(er);
    }

    if (!formData.instagram) {
      setErrors((prev) => ({ ...prev, instagram: "This field is required" }));
      return;
    } else {
      const er = { ...errors };
      delete er.instagram;
      setErrors(er);
    }

    if (isChrome) {
      if (!Date.parse(formData.datetime)) {
        setErrors((prev) => ({ ...prev, datetime: "This field is required" }));
        return;
      } else {
        const er = { ...errors };
        delete er.datetime;
        setErrors(er);
      }
      if (validTime < new Date(formData.datetime)) {
        setErrors((prev) => ({
          ...prev,
          datetime: "Start live should be less than 23 hours.",
        }));
        return;
      } else {
        const er = { ...errors };
        delete er.datetime;
        setErrors(er);
      }
    } else {
      if (!formData.date) {
        setErrors((prev) => ({ ...prev, date: "This field is required" }));
        return;
      } else {
        const er = { ...errors };
        delete er[name];
        setErrors(er);
      }
      if (!formData.time) {
        setErrors((prev) => ({ ...prev, time: "This field is required" }));
        return;
      } else {
        const er = { ...errors };
        delete er[name];
        setErrors(er);
      }
    }

    let url = image;

    if (blob) {
      url = await uploadFile({
        blob,
        authToken: idToken,
        mediaType: "cover",
      });
    }

    if (url === "") {
      url = defaultInfluencer;
    }

    const datetime = new Date(
      formData.date + `T` + formData.time
    ).toUTCString();

    const res = !isEdit
      ? await createLiveStream({
          authToken: idToken,
          liveData: {
            products: prodData,
            name: formData.name,
            instagram: formData.instagram,
            thumb: url,
            status: "publish",
            time: isChrome
              ? new Date(formData.datetime).toUTCString()
              : datetime,
            influencer: url || defaultInfluencer,
          },
        })
      : await editLiveStream({
          authToken: idToken,
          liveData: {
            products: prodData,
            name: formData.name,
            instagram: formData.instagram,
            thumb: url,
            status: status,
            time: isChrome ? formData.datetime : datetime,
            influencer: url,
          },
          liveId,
        });
    console.log(res);
    // if (res.result && !isEdit) {
    //   const Email = window.Email;

    //   const body2 = `<div style="white-space: pre-wrap;">Send this link to your influencer https://platform.dev2.raddcontent.com/lives/${res.result.id}</div>`;

    //   Email.send({
    //     SecureToken: process.env.REACT_APP_EMAIL_SECRET,
    //     To: profile.email,
    //     From: process.env.REACT_APP_EMAIL,
    //     Subject: "Live stream.",
    //     Body: body2,
    //   }).then(() => alert("Mail Sent"));
    // }
    clearData();
    return true;
  };

  const clearData = () => {
    setErrors({});
    setProdData([]);
    setFormData({});
  };

  return (
    <>
      <Modal
        toggle={children}
        title="Book LIVE session"
        titleMob="Book LIVE session"
        subtitle="Choose date/time and tag products for the LIVE session and share the URL with your Influencer"
        subtitleMob="Choose date/time and tag products for the LIVE session and share the URL with your Influencer"
        onSubmit={() => createLive()}
        onCancel={() => clearData()}
        isBook
        className="live__modal"
        submit={<>Book LIVE session</>}
        cancel={<>Cancel</>}
      >
        <div className="products__cont">
          <div className="book__live">
            <div>
              <Input
                label="Influencer name"
                placeholder="Enter influencer name"
                name="name"
                error={errors.name}
                value={formData.name}
                required
                onChange={(e) => setData({ [e.target.name]: e.target.value })}
              />
            </div>
            <div>
              <Input
                label="Instagram username"
                placeholder="@"
                name="instagram"
                error={errors.instagram}
                value={formData.instagram}
                required
                onChange={(e) => setData({ [e.target.name]: e.target.value })}
              />
            </div>
          </div>
          {!isChrome && (
            <div className="book__live">
              <div>
                <Input
                  label="Date"
                  placeholder="Enter date"
                  name="date"
                  type="date"
                  error={errors.date}
                  value={formData.date}
                  required
                  onChange={(e) => setData({ [e.target.name]: e.target.value })}
                />
              </div>
              <div>
                <Input
                  label="Time"
                  placeholder="Enter time"
                  name="time"
                  type="time"
                  error={errors.time}
                  value={formData.time}
                  required
                  onChange={(e) => setData({ [e.target.name]: e.target.value })}
                />
              </div>
            </div>
          )}
          {isChrome && (
            <div>
              <Input
                label="Time"
                placeholder="Choose time"
                name="datetime"
                type="datetime-local"
                error={errors.datetime}
                required
                value={formData.datetime}
                min={new Date().toISOString().slice(0, -8)}
                onChange={(e) => setData({ [e.target.name]: e.target.value })}
              />
            </div>
          )}
          {/* <div>
            <Input
              label="Influencer email"
              placeholder="Enter influncer email"
              name="email"
              onChange={(e) => setData({ [e.target.name]: e.target.value })}
            />
          </div> */}
          <div className="custom__input">
            <label className="input__label">Upload thumbnail</label>
            <div className="image__upload">
              <Avatar image={image ? image : defaultImg} />
              <div className="invite__buttons">
                <button
                  disabled={disabled}
                  className={`small__button  ${
                    disabled ? "disabled" : "active"
                  }`}
                  onClick={() => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", defaultImg, true);
                    xhr.responseType = "blob";
                    xhr.onload = function (e) {
                      if (this.status === 200) {
                        // var myBlob = this.response;
                        setBlob(null);
                        setDisabled(true);
                      }
                    };
                    xhr.send();

                    setImage("");
                  }}
                >
                  Remove thumb
                </button>
                <label htmlFor="files" className="small__button button__accent">
                  Add thumb
                </label>
              </div>
              <input
                type="file"
                accept="image/*"
                id="files"
                style={{ visibility: "hidden" }}
                onChange={(e) => {
                  setImage(URL.createObjectURL(e.target.files[0]));
                  setBlob(e.target.files[0]);
                  setDisabled(false);
                }}
              />
            </div>
          </div>
          <div>
            <Input
              label="Tag products"
              placeholder="Enter product name"
              id="prodLab"
            />
            <div
              className={`small__button button__accent${
                isLoading ? ` disabled` : ``
              }`}
              onClick={() => {
                getProduct(document.getElementById("prodLab").value);
                document.getElementById("prodLab").value = "";
              }}
            >
              Add product
            </div>
          </div>
          <div className="product__container">
            {prodData && prodData.length !== 0
              ? prodData.map((e, i) => {
                  return (
                    <div
                      className="product__analytic"
                      style={{ justifyContent: "flex-start" }}
                      key={i}
                    >
                      <a href={e.url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={e.image}
                          alt="coollwwowoowow"
                          className="pa__image"
                        />
                      </a>
                      <div className="pa__name">{e.name}</div>
                      <div
                        className="pa__close"
                        onClick={() => removeProduct(i)}
                      >
                        <Close />
                      </div>
                    </div>
                  );
                })
              : null}
            {isLoading && (
              <div style={{ position: "relative", height: 120, width: "100%" }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { profile, idToken } = auth;

  return {
    profile,
    idToken,
  };
};

const mapDispatchToProps = {
  createLiveStream,
  editLiveStream,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLive);
