import React, { useState, useEffect } from "react";
import { Dropdown, Hint2, Modal, ProductAnalytic } from "./";
import { TreeDot } from "../../icons/icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../../scss/components/StoryCard.scss";
import {deleteLive, editLiveStream} from "../../redux/actions/livesActions";
import CreateLive from "../hoc/createLive";


const LiveCard = ({
  image,
  title,
  live,
  background,
  id,
  editLiveStream,
  deleteLive,
  idToken,
  homepage,
  statistic: { unique, views, purchases },
}) => {
  const [analytics, setAnalytics] = useState({
    click: {},
    addToCart: {}
  });
  const [hoverAnalytics, setHoverAnalytics] = useState({
    click: 0,
    views: 0,
    purchases: 0
  })
  const [stateAnalytics, setStateAnalytics] = useState(false)
  const [dropOpen, setDropOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [localStatus, setLocalStatus] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false)

  useEffect(() => {
    setLocalStatus(live.status)
  }, [live])

  useEffect(() => {
      let time = new Date(live.time)
      let newTime = new Date(time.setMilliseconds(time.getMilliseconds() + 82800000))

      if(new Date() > newTime ) {
        return setDisabledBtn(true)
      }

    },[live.time])

    
  useEffect(() => {
    // if (live.products && live.products.length > 0) {
    //   live.products.forEach((el) => {
    //     if (el.productId) {
    //       fetch(
    //         `https://mixpanel.com/api/2.0/events/?interval=7&type=general&event=["Product Click", "Add To Cart"]&unit=day&where=(properties["story_id"]=="${id}" and properties["product_id"]=="${el.productId}")`,
    //         {
    //           headers: {
    //             Authorization: `Basic ZTc2MjdkY2Y0MGFlOTFhMzc3Zjg4ODYzYTE2ODZiY2Y6`,
    //           },
    //         }
    //       ).then((response) =>
    //         response.json().then((e) => {
    //           let dd = e.data.values["Product Click"];
    //           let addToCart = e.data.values["Add To Cart"];
    //           let cart = 0
    //           let count = 0;
    //
    //           for (const key in addToCart) {
    //             if (addToCart.hasOwnProperty(key)) {
    //               cart += addToCart[key];
    //             }
    //           }
    //
    //           for (const key in dd) {
    //             if (dd.hasOwnProperty(key)) {
    //               count += dd[key];
    //             }
    //           }
    //
    //
    //           setAnalytics((an) => ({
    //             ...an,
    //             click: {
    //               ...an.click,
    //               [el.productId]: count,
    //             },
    //             addToCart: {
    //               ...an.addToCart,
    //               [el.productId]: cart,
    //             }
    //           }));
    //         })
    //       );
    //     }
    //   });
    // }
    // eslint-disable-next-line
  }, []);

  const copyToClipboard = (url) => {
    let devcode
    if (url === 'influencer') {
      devcode = `${process.env.REACT_APP_BRAND_PLATFORM}/lives/${id}`;
    } else {
      devcode = `${homepage}?show_live=true&live_id=${id}`
    }
    var textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2px";
    textArea.style.height = "2px";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = devcode;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      alert(`Link Copied`);
      console.log("Copying text command was " + msg);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  const edit = async () => {
    const data = await editLiveStream({
      authToken: idToken,
      liveData: {
        status: localStatus === 'publish' ? "unpublish" : "publish"
      },
      liveId: live.id
    })
    console.log(data)
    setLocalStatus(data.result.status)
  }

  const getAnalytics = () => {
    if (live.products && live.products.length > 0 && !stateAnalytics) {
      live.products.forEach((el) => {
        if (el.productId) {
          fetch(
              `https://mixpanel.com/api/2.0/events/?interval=7&type=general&event=["Product Click", "Add To Cart", "Story View"]&unit=day&where=(properties["story_id"]=="${id}" and properties["product_id"]=="${el.productId}")`,
              {
                headers: {
                  Authorization: `Basic ZTc2MjdkY2Y0MGFlOTFhMzc3Zjg4ODYzYTE2ODZiY2Y6`,
                },
              }
          ).then((response) =>
              response.json().then((e) => {
                let dd = e.data.values["Product Click"];
                let addToCart = e.data.values["Add To Cart"];
                let cart = 0
                let count = 0;

                for (const key in addToCart) {
                  if (addToCart.hasOwnProperty(key)) {
                    cart += addToCart[key];
                  }
                }

                for (const key in dd) {
                  if (dd.hasOwnProperty(key)) {
                    count += dd[key];
                  }
                }


                setAnalytics((an) => ({
                  ...an,
                  click: {
                    ...an.click,
                    [el.productId]: count,
                  },
                  addToCart: {
                    ...an.addToCart,
                    [el.productId]: cart,
                  }
                }));
                setStateAnalytics(true)
              })
          ).catch(err => {
            setStateAnalytics(true)
            console.error(err)
          });
        }
      });
    }
    if (!stateAnalytics) {
      fetch(`https://mixpanel.com/api/2.0/events/?interval=7&type=general&event=["Story View"]&unit=day&where=(properties["story_id"]=="${id}")`, {
            headers: {
              Authorization: `Basic ZTc2MjdkY2Y0MGFlOTFhMzc3Zjg4ODYzYTE2ODZiY2Y6`,
            },
          }
      ).then((response) =>
          response.json().then((e) => {
            let views = e.data.values["Story View"];
            let count = 0;

            for (const key in views) {
              if (views.hasOwnProperty(key)) {
                count += views[key];
              }
            }

            setHoverAnalytics((an) => ({
              ...an,
              views: count
            }))
          })
      ).catch(err => {
        console.error(err)
      });
    }
  }

  useEffect(() => {
    const arr = Object.values(analytics.click)
    const arrPurchases = Object.values(analytics.addToCart)
    let click = 0;
    let purchases = 0

    arr.map((el) => click += el)
    arrPurchases.map((el) => purchases += el)

    setHoverAnalytics((an) => ({
      ...an,
      click: click,
      purchases: purchases
    }))
    // console.log(arr.reduce((a, b) => a + b))
  }, [analytics.click, analytics.addToCart])


  return (
    <>
      <div
        className="st__wrap"
        onMouseEnter={async () => {
          await getAnalytics()
          await setHover(true);
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
          setDropOpen(false);
        }}
      >
        <div className="stories__it">
          {hover && (
            <div className="st__drop">
              <Dropdown
                button={<TreeDot />}
                isOpen={dropOpen && hover}
                setIsOpen={setDropOpen}
                className="toMidRight"
                dropdown={
                  <div className="setting__block">
                    <div className="setting__part sp1">
                      <p className="setting__link fi1"
                        onClick={() => {
                          document.getElementById(`immClick2${id}`).click();
                        }}
                      >
                        Edit
                      </p>
                      <button disabled={disabledBtn} style={{border: 'none', background: "none", padding: 0}} className={`setting__link ${disabledBtn ? 'disabled' : ''}`} onClick={() => copyToClipboard('influencer')}>
                        Influencer link
                      </button>
                      <button disabled={disabledBtn} style={{border: 'none', background: "none", padding: 0}} className={`setting__link ${disabledBtn ? 'disabled' : ''}`} onClick={() => copyToClipboard('shopper_live')}>
                        Shopper link
                      </button>
                      <p
                        className="setting__link"
                        onClick={() => {
                          document.getElementById(`immClick${id}`).click();
                        }}
                      >
                        Product analytics
                      </p>
                      <p
                          className="setting__link"
                          onClick={edit}
                      >
                        { localStatus === 'publish' ? "Unpublish" : "Publish" }
                      </p>
                    </div>
                    <div className="setting__part">
                      <p
                        className="setting__link error"
                        onClick={() => {
                          deleteLive({ authToken: idToken, liveId: id });
                          setDropOpen(false);
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                }
              />
            </div>
          )}
          <div
            className={`story__card phh`}
            style={
              background && background[0] === "#"
                ? { backgroundColor: background }
                : { backgroundImage: background }
            }
          >
            {image ? (
              <div className="image__cont">
                <img src={image} alt="" />
              </div>
            ) : null}
          </div>
        </div>
        <div className="story__title phh">{title}</div>
        {hover && !dropOpen && (
          <Hint2>
            <div>
              <div className="statistic__name">Views</div>
              <div className="statistic__data">{ hoverAnalytics.views }</div>
              <div className="statistic__name">Clicks</div>
              <div className="statistic__data">{ hoverAnalytics.click }</div>
              <div className="statistic__name">Purchases</div>
              <div className="statistic__data">{ hoverAnalytics.purchases }</div>
            </div>
          </Hint2>
        )}
        <Modal
          toggle={<div id={`immClick${id}`} />}
          title="Product analytics"
          titleMob="Product analytics"
          subtitle="Your story analytics is displayed below"
          subtitleMob="Your story analytics is displayed below"
          className="product__modal"
          onSubmit={() => {}}
          onCancel={() => {}}
          submit={
            <>
              <span>Done</span>
            </>
          }
          cancel={<></>}
        >
          <div className="products__cont">
            {live.products && live.products.length > 0 ? (
              live.products.map((e) => (
                <ProductAnalytic
                  key={e.name}
                  image={e.image}
                  name={e.name}
                  url={e.url}
                  analytic={{
                    purchases: analytics.addToCart[e.productId] || 0,
                    clicks: analytics.click[e.productId] || 0,
                    conversion: (analytics.addToCart[e.productId] * 100) / analytics.click[e.productId],
                  }}
                />
              ))
            ) : (
              <div className="no__products">
                No Products tagged in this card
              </div>
            )}
          </div>
        </Modal>
        {live.id && (
          <CreateLive
            isEdit
            date={
              Date.parse(live.time)
                ? new Date(live.time).toISOString().split("T")[0]
                : ""
            }
            time={
              Date.parse(live.time)
                ? new Date(live.time).toISOString().split("T")[1].slice(0, 5)
                : ""
            }
            products={live.products}
            name={live.name}
            status={live.status}
            influencer={live.influencer}
            instagram={live.instagram}
            liveId={live.id}
          >
            <div id={`immClick2${id}`} />
          </CreateLive>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;

  return {
    idToken,
  };
};

const mapDispatchToProps = {
  deleteLive,
  editLiveStream
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveCard)
);
