import {
  fetchCreateLive,
  fetchDeleteLive,
  fetchLive,
  fetchLivesList,
  fetchEditLive,
  fetchContactLive,
} from "../utils/fetchLive";

export const actions = {
  RESET_STORIES_DATA: "RESET_STORIES_DATA",
  LIVE_LIST_PENDING: "LIVE_LIST_PENDING",
  LIVE_LIST_FULFILLED: "LIVE_LIST_FULFILLED",
  LIVE_LIST_REJECTED: "LIVE_LIST_REJECTED",
  DISCOVER_LIST_PENDING: "DISCOVER_LIST_PENDING",
  DISCOVER_LIST_FULFILLED: "DISCOVER_LIST_FULFILLED",
  DISCOVER_LIST_REJECTED: "DISCOVER_LIST_REJECTED",
  STORY_ANALYTICS_FULFILLED: "STORY_ANALYTICS_FULFILLED",
  CREATE_STORY_PENDING: "CREATE_STORY_PENDING",
  CREATE_STORY_FULFILLED: "CREATE_STORY_FULFILLED",
  CREATE_STORY_REJECTED: "CREATE_STORY_REJECTED",
  UPDATE_STORIES_PENDING: "UPDATE_STORIES_PENDING",
  UPDATE_STORIES_FULFILLED: "UPDATE_STORIES_FULFILLED",
  UPDATE_STORIES_REJECTED: "UPDATE_STORIES_REJECTED",
  DELETE_STORY_PENDING: "DELETE_STORY_PENDING",
  DELETE_STORY_FULFILLED: "DELETE_STORY_FULFILLED",
  DELETE_STORY_REJECTED: "DELETE_STORY_REJECTED",
  WHOLE_ANALYTICS_FULFILLED: "WHOLE_ANALYTICS_FULFILLED",
};

const options = { year: "numeric", month: "long", day: "numeric" };
export function updateLives({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_STORIES_PENDING,
    });

    try {
      let data = await fetchLivesList({ authToken });

      dispatch({
        type: actions.UPDATE_STORIES_FULFILLED,
        data: data.result.map((e) => ({
          ...e,
          date: new Date(e.time).toLocaleDateString("en-US", options),
        })),
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UPDATE_STORIES_REJECTED,
        error,
      });
    }
  };
}

export function getLivesList({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.LIVE_LIST_PENDING,
    });

    try {
      let data = await fetchLivesList({ authToken });

      dispatch({
        type: actions.LIVE_LIST_FULFILLED,
        data: data.result.map((e) => ({
          ...e,
          date: new Date(e.time).toLocaleDateString("en-US", options),
        })),
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.LIVE_LIST_REJECTED,
        error,
      });
    }
  };
}

export function getLiveStream({ authToken, liveId }) {
  return async () => {
    return fetchLive({ authToken, liveId });
  };
}

export function createLiveStream({ authToken, liveData }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CREATE_STORY_PENDING,
    });

    try {
      let data = await fetchCreateLive({ authToken, liveData });

      dispatch({
        type: actions.CREATE_STORY_FULFILLED,
        data: data.result,
      });

      dispatch(updateLives({ authToken }));

      return data;
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.CREATE_STORY_REJECTED,
        error,
      });
    }
  };
}

export function contactLiveStream({ authToken, liveData }) {
  return async () => {
    try {
      let data = await fetchContactLive({ authToken, liveData });
      return data;
    } catch (error) {}
  };
}

export function editLiveStream({ authToken, liveData, liveId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CREATE_STORY_PENDING,
    });

    try {
      let data = await fetchEditLive({ authToken, liveData, liveId });

      dispatch({
        type: actions.CREATE_STORY_FULFILLED,
        data: data.result,
      });

      dispatch(updateLives({ authToken }));

      return data;
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.CREATE_STORY_REJECTED,
        error,
      });
    }
  };
}

export function deleteLive({ liveId, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.DELETE_STORY_PENDING,
    });

    try {
      let data = await fetchDeleteLive({ liveId, authToken });

      dispatch({
        type: actions.DELETE_STORY_FULFILLED,
        data: data.result,
      });

      dispatch(updateLives({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.DELETE_STORY_REJECTED,
        error,
      });
    }
  };
}
