import { setAuthToken, instance, fetchRequest } from "./fetchData";

export function fetchStoriesList({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story`));
}

export function fetchCustomerStoriesList({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story/by/org`));
}

export function fetchStoryAnalytics({ authToken, storyId }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story/${storyId}/analytics`));
}

export function fetchStory({ storyId, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story/${storyId}`));
}

export function fetchStoryUpdate({ storyId, authToken, delta }) {
  setAuthToken(authToken);

  return fetchRequest(instance.put(`/story/${storyId}`, { delta }));
}

export function fetchStoryReorder({ storyId, authToken, cards }) {
  setAuthToken(authToken);

  return fetchRequest(instance.put(`/story/${storyId}/order`, { cards }));
}

export function fetchStoryProduct({ storyId, authToken, data }) {
  setAuthToken(authToken);

  return fetchRequest(instance.put(`/story/${storyId}/product`, data));
}

export function fetchCreateStory({ authToken, storyData }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post("/story", storyData));
}

export function fetchDeleteStory({ storyId, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.delete(`/story/${storyId}`));
}

export function fetchAuthInstagram({ authToken, body }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/auth/instagram`, body));
}

export function fetchDiscover({ authToken, data }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/instagram`, data));
}
