import { StartPage, Registration, SingIn } from "../pages";

import globalRoutes from "./globalRoutes";

export default [
  {
    path: "/",
    exact: true,
    withHeader: false,
    component: StartPage,
  },
  {
    path: "/registration",
    exact: true,
    withHeader: false,
    component: Registration,
  },
  {
    path: "/login",
    exact: true,
    withHeader: false,
    component: SingIn,
  },
  ...globalRoutes,
];
