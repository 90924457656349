import { instance, setAuthToken, fetchRequest } from "./fetchData";

export function fetchOrgInfo({ alias, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/organization/${alias}`));
}

export function fetchUserData({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get("/me"));
}

export function fetchSetUserData({ userId, authToken, data }) {
  setAuthToken(authToken);

  return fetchRequest(instance.put(`/user/${userId}`, data));
}

export function fetchLogin({ email, password }) {
  setAuthToken("anon");
  return fetchRequest(instance.post("/login", { email, password }));
}

export function fetchRegistration({ email, password, plan }) {
  setAuthToken("anon");
  return fetchRequest(instance.post("/registration", { email, password, plan }));
}

export function fetchShopifyLogin({ email, shopifyToken, shopUrl }) {
  setAuthToken("anon");
  return fetchRequest(
    instance.post("/login/shopify", { email, shopifyToken, shopUrl })
  );
}

export function fetchResetPassword({
  authToken,
  password,
  newPassword,
  repeatPassword,
}) {
  setAuthToken(authToken);
  return fetchRequest(
    instance.post("/reset-password", { password, newPassword, repeatPassword })
  );
}
