import React, { useRef, useState, useEffect } from "react";
import { Pencel, Plus } from "../../icons/icons";
import "../../scss/components/InputTitle.scss";

const InputTitle = ({ placeholder, setTitle, title }) => {
  const [width, setWidth] = useState(120);
  const [isFocus, setFocus] = useState(false);
  const [newTitle, setNewTitle] = useState(() => title);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth + 8);
  }, [ref]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth + 8);
  }, [newTitle]);

  return (
    <div className={`input__container ${isFocus ? "focused" : ""}`}>
      <div className="container__title">
        <span className="title__span" ref={ref}>
          {newTitle ? newTitle : placeholder}
        </span>
        <input
          className="title__input"
          style={{ width }}
          placeholder={placeholder}
          value={newTitle}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            setTitle(e.target.value);
            setFocus(false);
          }}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
        />
      </div>
      {isFocus ? <Plus /> : <Pencel />}
    </div>
  );
};

export default InputTitle;
