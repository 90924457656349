import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import domtoimage from "dom-to-image";
import { Text } from "../common";
import {
  removeText,
  setTextParam,
  setSnapshot,
} from "../../redux/actions/editStoryActions";
import { uploadFile } from "../../redux/utils/fileUpload";
import { storySize, textProps } from "../../globalConst";

const TextContainer = ({
  texts,
  storyId,
  storyData,
  cardId,
  idToken,
  size,
  activeText,
  setActiveText,
}) => {
  const dispatch = useDispatch();
  const textRef = useRef(null);

  //Update texts snapshot when texts changed
  const updateSnapshot = useCallback(async () => {
    const texts = textRef.current;
    const scale = 3
    const blob = await domtoimage.toBlob(texts, {
      filter: (node) => {
        return !node.classList || !node.classList.contains("text__control");
      },
      width: texts.clientWidth * scale,
      height: texts.clientHeight * scale,
      style: {
        transform: 'scale('+scale+')',
        transformOrigin: 'top left'
      }
    });

    console.log('update file', blob)

    dispatch(setSnapshot(cardId, URL.createObjectURL(blob), storyId, idToken));

    await uploadFile({
      blob,
      storyId: storyId,
      cardId: cardId,
      authToken: idToken,
      mediaType: "snapshot",
    });

    // eslint-disable-next-line
  }, [storyData, cardId, idToken, setSnapshot, textRef.current]);

  const updateText = useCallback(
    async (index, value) => {
      await dispatch(
        setTextParam(
          cardId,
          index,
          {
            ...value,
            isNew: false,
          },
          storyId,
          idToken
        )
      );
      updateSnapshot();
    },
    // eslint-disable-next-line
    [storyData, setTextParam, updateSnapshot]
  );

  const handleTextDelete = async (id) => {
    await dispatch(removeText(cardId, id, storyId, idToken));
    updateSnapshot();
  };

  return (
    <div className="text-container" ref={textRef}>
      {texts && texts.lenght !== 0
        ? texts.map((e, i) => {
            const props = { ...textProps, ...e };

            return (
              <Text
                {...props}
                updateText={updateText}
                screenSize={storySize}
                id={i}
                key={e._id}
                _id={e._id}
                size={size}
                isActive={activeText === i}
                setActiveText={setActiveText}
                setEdited={() => {}}
                handleTextDelete={handleTextDelete}
              />
            );
          })
        : null}
    </div>
  );
};

export default TextContainer;
