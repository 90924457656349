import React from "react";

import {
  fetchNotifications,
  fetchSendNotification,
} from "../utils/fetchNotifications";

export const actions = {
  GET_NOTIFICATIONS_PENDING: "GET_NOTIFICATIONS_PENDING",
  GET_NOTIFICATIONS_FULFILLED: "GET_NOTIFICATIONS_FULFILLED",
  GET_NOTIFICATIONS_REJECTED: "GET_NOTIFICATIONS_REJECTED",
  SEND_NOTIFICATION_PENDING: "SEND_NOTIFICATION_PENDING",
  SEND_NOTIFICATION_FULFILLED: "SEND_NOTIFICATION_FULFILLED",
  SEND_NOTIFICATION_REJECTED: "SEND_NOTIFICATION_REJECTED",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
};

export const NOTIFICATIONS = {
  APPROVE_STORY: (data, { sender, storyTitle }) => (
    <div>
      <div>{sender}'s story is waiting for approval:</div>
      <div className="story-title">{storyTitle}</div>
    </div>
  ),
};

export function getNotifications({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.GET_NOTIFICATIONS_PENDING,
    });

    try {
      let data = await fetchNotifications({ authToken });

      dispatch({
        type: actions.GET_NOTIFICATIONS_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.GET_NOTIFICATIONS_REJECTED,
        error,
      });
    }
  };
}

export function sendNotification({
  authToken,
  title,
  body,
  receivers,
  category,
}) {
  return async (dispatch) => {
    dispatch({
      type: actions.SEND_NOTIFICATION_PENDING,
    });

    try {
      let data = await fetchSendNotification({
        authToken,
        title,
        body: JSON.stringify(body),
        receivers,
        category,
      });

      dispatch({
        type: actions.SEND_NOTIFICATION_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SEND_NOTIFICATION_REJECTED,
        error,
      });
    }
  };
}
