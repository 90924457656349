import {
  fetchStoriesList,
  fetchStoryAnalytics,
  fetchCreateStory,
  fetchDeleteStory,
  fetchCustomerStoriesList,
} from "../utils/fetchStories";
import { getAnalytics } from "../utils/analytics";
import _ from "lodash";
import { getTotalInFormat, analyticNames } from "../../helpers/analytics";

import { updateStorybars } from "./storybarsActions";
import { showPlanPopupPopup } from "./infoPopupActions";

export const actions = {
  RESET_STORIES_DATA: "RESET_STORIES_DATA",
  STORIES_LIST_PENDING: "STORIES_LIST_PENDING",
  STORIES_LIST_FULFILLED: "STORIES_LIST_FULFILLED",
  STORIES_LIST_REJECTED: "STORIES_LIST_REJECTED",
  DISCOVER_LIST_PENDING: "DISCOVER_LIST_PENDING",
  DISCOVER_LIST_FULFILLED: "DISCOVER_LIST_FULFILLED",
  DISCOVER_LIST_REJECTED: "DISCOVER_LIST_REJECTED",
  STORY_ANALYTICS_FULFILLED: "STORY_ANALYTICS_FULFILLED",
  CREATE_STORY_PENDING: "CREATE_STORY_PENDING",
  CREATE_STORY_FULFILLED: "CREATE_STORY_FULFILLED",
  CREATE_STORY_REJECTED: "CREATE_STORY_REJECTED",
  UPDATE_STORIES_PENDING: "UPDATE_STORIES_PENDING",
  UPDATE_STORIES_FULFILLED: "UPDATE_STORIES_FULFILLED",
  UPDATE_STORIES_REJECTED: "UPDATE_STORIES_REJECTED",
  DELETE_STORY_PENDING: "DELETE_STORY_PENDING",
  DELETE_STORY_FULFILLED: "DELETE_STORY_FULFILLED",
  DELETE_STORY_REJECTED: "DELETE_STORY_REJECTED",
  DESKTOP_ANALYTICS_FULFILLED: "DESKTOP_ANALYTICS_FULFILLED",
  HOMEPAGE_ANALYTICS_FULFILLED: "HOMEPAGE_ANALYTICS_FULFILLED",
  DESKTOP_ANALYTICS_FETCHING: "DESKTOP_ANALYTICS_FETCHING",
  DESKTOP_ANALYTICS_FETCHED: "DESKTOP_ANALYTICS_FETCHED",
  MOBILE_ANALYTICS_FULFILLED: "MOBILE_ANALYTICS_FULFILLED",
  CUSTOMER_LIST_PENDING: "CUSTOMER_LIST_PENDING",
  CUSTOMER_LIST_FULFILLED: "CUSTOMER_LIST_FULFILLED",
  CUSTOMER_LIST_REJECTED: "CUSTOMER_LIST_REJECTED",
  SET_PRELOADER: "SET_PRELOADER",
};

export function setPreloader(payload) {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PRELOADER,
      payload
    })
  }
}

export function resetStoriesData() {
  return {
    type: actions.RESET_STORIES_DATA,
  };
}

export function getStoriesList({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.STORIES_LIST_PENDING,
    });

    try {
      let data = await fetchStoriesList({ authToken });

      if (data.result.draft && data.result.draft.length !== 0) {
        data.result.draft = [
          ...data.result.draft.map((e) => ({ ...e, isDraft: true })),
        ];
      }

      dispatch({
        type: actions.STORIES_LIST_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.STORIES_LIST_REJECTED,
        error,
      });
    }
  };
}

export function getCustomerStoriesList({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CUSTOMER_LIST_PENDING,
    });

    try {
      let data = await fetchCustomerStoriesList({ authToken });

      dispatch({
        type: actions.CUSTOMER_LIST_FULFILLED,
        data: data.result.Items,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.CUSTOMER_LIST_REJECTED,
        error,
      });
    }
  };
}

// function formatDate(date = null) {
//   var d = date ? new Date(date) : new Date(),
//     month = "" + (d.getMonth() + 1),
//     day = "" + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// }

const options = { year: "numeric", month: "long", day: "numeric" };
export function getDiscoverList() {
  return async (dispatch) => {
    dispatch({
      type: actions.DISCOVER_LIST_PENDING,
    });

    let responses = [];

    try {
      let data = await fetch(
        `https://graph.facebook.com/v7.0/me/accounts?access_token=${localStorage.getItem(
          `fb_token`
        )}`
      ).then((res) => res.json());
      if (data.data && data.data.length > 0) {
        responses = data.data.map((e) => {
          return fetch(
            `https://graph.facebook.com/v7.0/${
              e.id
            }?fields=instagram_business_account&access_token=${localStorage.getItem(
              `fb_token`
            )}`
          )
            .then((res) => res.json())
            .then(async (res) => {
              if (res.instagram_business_account) {
                let id = res.instagram_business_account.id;
                let feed = fetch(
                  `https://graph.facebook.com/v7.0/${id}/media?fields=id,media_type,thumbnail_url,media_url,timestamp&access_token=${localStorage.getItem(
                    `fb_token`
                  )}`
                )
                  .then((res) => res.json())
                  .then((e) => {
                    return {
                      id: 1,
                      title: "Feed",
                      data: e.data.map((el) => ({
                        mediaType: el.media_type.toLowerCase(),
                        image:
                          el.media_type.toLowerCase() === "image"
                            ? el.media_url
                            : el.thumbnail_url,
                        media: el.media_url,
                        platform: "instagram",
                        date: new Date(el.timestamp).toLocaleDateString(
                          "en-US",
                          options
                        ),
                      })),
                    };
                  });
                let stories = fetch(
                  `https://graph.facebook.com/v7.0/${id}/stories?fields=id,media_type,thumbnail_url,media_url,timestamp&access_token=${localStorage.getItem(
                    `fb_token`
                  )}`
                )
                  .then((res) => res.json())
                  .then((e) => {
                    return {
                      id: 2,
                      title: "Stories",
                      data: e.data.map((el) => ({
                        mediaType: el.media_type.toLowerCase(),
                        image:
                          el.media_type.toLowerCase() === "image"
                            ? el.media_url
                            : el.thumbnail_url,
                        media: el.media_url,
                        platform: "instagram",
                        date: new Date(el.timestamp).toLocaleDateString(
                          "en-US",
                          options
                        ),
                      })),
                    };
                  });

                return await Promise.all([feed, stories]);
              }
            });
        });

        let res = await Promise.all(responses);
        dispatch({
          data: _.flatten(res),
          type: actions.DISCOVER_LIST_FULFILLED,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: actions.DISCOVER_LIST_REJECTED,
        error,
      });
    }
  };
}

export function getHomepageAnalytics(orgId, fromDate, toDate) {
  return async (dispatch) => {
    dispatch({
      type: actions.DESKTOP_ANALYTICS_FETCHING,
    });
    try {
      const dataNew = await getAnalytics({ fromDate, toDate, orgId });
      let dd = [...dataNew[0]];
      dd[3] = dataNew[1][2];
      const result = {};
      analyticNames.forEach((name, i) => {
        result[name] = getTotalInFormat(dd[i]);
      });

      dispatch({
        type: actions.HOMEPAGE_ANALYTICS_FULFILLED,
        data: result,
      });
    } catch (error) {
      dispatch({
        type: actions.DESKTOP_ANALYTICS_FETCHED,
      });
      console.error(error);
    }
  };
}

export function getAllDeviceAnalytics(dataProp, orgId, fromDate, toDate) {
  return async (dispatch) => {
    try {
      const dataNew = await getAnalytics({ fromDate, toDate, orgId });
      let dd = [...dataNew[0]];
      dd[3] = dataNew[1][2];

      dispatch({
        type: actions.DESKTOP_ANALYTICS_FULFILLED,
        data: dd,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getDesktopAnalytics(dataProp, orgId, fromDate, toDate) {
  return async (dispatch) => {
    try {
      const dataNew = await getAnalytics({
        fromDate,
        toDate,
        orgId,
        isDesk: true,
      });
      let dd = [...dataNew[0]];
      dd[3] = dataNew[1][2];

      dispatch({
        type: actions.DESKTOP_ANALYTICS_FULFILLED,
        data: dd,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getMobileAnalytics(dataProp, orgId, fromDate, toDate) {
  return async (dispatch) => {
    try {
      const dataNew = await getAnalytics({
        fromDate,
        toDate,
        orgId,
        isMobile: true,
      });
      let dd = [...dataNew[0]];
      dd[3] = dataNew[1][2];

      // dispatch({
      //   type: actions.MOBILE_ANALYTICS_FULFILLED,
      //   data: dd,
      // });
      dispatch({
        type: actions.DESKTOP_ANALYTICS_FULFILLED,
        data: dd,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getStoriesAnalytics({ authToken, idList }) {
  return async (dispatch) => {
    for (let i = 0; i < idList.length; ++i) {
      let storyId = idList[i];

      try {
        let data = await fetchStoryAnalytics({ authToken, storyId });

        dispatch({
          type: actions.STORY_ANALYTICS_FULFILLED,
          data: {
            storyId,
            data: data.result,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export function getStoryAnalytics({ authToken, storyId }) {
  return async (dispatch) => {
    try {
      let data = await fetchStoryAnalytics({ authToken, storyId });

      dispatch({
        type: actions.STORY_ANALYTICS_FULFILLED,
        data: {
          storyId,
          data: data.result,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function createStory({ authToken, storyData }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CREATE_STORY_PENDING,
    });

    try {
      let data = await fetchCreateStory({ authToken, storyData });

      dispatch({
        type: actions.CREATE_STORY_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);
      if (error.code === "story_limit") {
        dispatch(
          showPlanPopupPopup({
            storiesText: "Upgrade to continue uploading stories",
            titleText: "You have reached your maximum story limit",
          })
        );
      }

      dispatch({
        type: actions.CREATE_STORY_REJECTED,
        error,
      });
      throw error
    }
  };
}

export function updateStories({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_STORIES_PENDING,
    });

    try {
      let data = await fetchStoriesList({ authToken });

      dispatch({
        type: actions.UPDATE_STORIES_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UPDATE_STORIES_REJECTED,
        error,
      });
    }
  };
}

export function deleteStory({ storyId, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.DELETE_STORY_PENDING,
    });

    try {
      let data = await fetchDeleteStory({ storyId, authToken });

      dispatch({
        type: actions.DELETE_STORY_FULFILLED,
        data: data.result,
      });

      dispatch(updateStories({ authToken }));
      dispatch(updateStorybars({ authToken }));
      dispatch(getCustomerStoriesList({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.DELETE_STORY_REJECTED,
        error,
      });
    }
  };
}

// await window.FB.api(
//   "me/accounts"
//   // if (response.data && response.data.length > 0) {
//   //   response.data.forEach((e) => {
//   //     window.FB.api(
//   //       `${e.id}?fields=instagram_business_account`,
//   //       function (response2) {
//   //         console.log(response2);
//   //       }
//   //     );
//   //   });
//   // }
// );
// console.log(data);
// window.FB.api(
//   "/17841439140502071/stories?fields=id,media_type,thumbnail_url,media_url,timestamp",
//   function (response) {
//     console.log(response);
//   }
// );

// fetch(`http://127.0.0.1:8080/instagram`, {
//   method: "POST",
//   headers: {
//     Authorization: localStorage.getItem(`idToken`),
//   },
//   body: JSON.stringify({
//     token: localStorage.getItem(`instagramToken`),
//   }),
// })
//   .then((res) => res.json())
//   .then((e) => {
//     dispatch({
//       data: e.result,
//       type: actions.DISCOVER_LIST_FULFILLED,
//     });
//   });

// let data = {
//   token: localStorage.getItem(`instagramToken`),
// };
// let data2 = await fetchDiscover({
//   authToken: localStorage.getItem(`idToken`),
//   data,
// });
// console.log(data2);
// dispatch({
//   data: data2.result,
//   type: actions.DISCOVER_LIST_FULFILLED,
// });

// fetch(
//   `https://graph.instagram.com/me/media?fields=id,media_type,caption,thumbnail_url,media_url,timestamp&access_token=${localStorage.getItem(
//     "instagramToken"
//   )}`
// ).then((response) =>
//   response.json().then((e) => {
//     dispatch({
//       data: e.data.map((el) => ({
//         mediaType: el.media_type.toLowerCase(),
//         image:
//           el.media_type.toLowerCase() === "image"
//             ? el.media_url
//             : el.thumbnail_url,
//         media: el.media_url,
//         platform: "instagram",
//         date: new Date(el.timestamp).toLocaleDateString(
//           "en-US",
//           options
//         ),
//       })),
//       type: actions.DISCOVER_LIST_FULFILLED,
//     });
//   })
// );
// fetch(
//           `https://i.instagram.com/api/v1/feed/user/253625977/reel_media/`, {
//             headers : {
//               "User-Agent" : "Instagram 9.5.1 (iPhone9,2; iOS 10_0_2; en_US; en-US; scale=2.61; 1080x1920) AppleWebKit/420+",
//               "Cookie" : "sessionid=9979146839%3AxBlxAaMexnMwTp%3A7; Path=/; Domain=.instagram.com; Expires=Sat, 17 Jul 2021 12:44:59 GMT;",
//             },
//             credentials: "include"
//           }
//         ).then((response) =>
//           response.json().then((e) => {
//             dispatch({
//               data: e.data.map((el) => ({
//                 mediaType: el.media_type.toLowerCase(),
//                 image:
//                   el.media_type.toLowerCase() === "image"
//                     ? el.media_url
//                     : el.thumbnail_url,
//                 media: el.media_url,
//                 platform: "instagram",
//                 date: new Date(el.timestamp).toLocaleDateString(
//                   "en-US",
//                   options
//                 ),
//               })),
//               type: actions.DISCOVER_LIST_FULFILLED,
//             });
//           })
//         );
