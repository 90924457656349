import { fetchSetUserData } from "../utils/fetchUserData";

export const actions = {
  SET_USER_DATA_PENDING: "SET_USER_DATA_PENDING",
  SET_USER_DATA_FULFILLED: "SET_USER_DATA_FULFILLED",
  SET_USER_DATA_REJECTED: "SET_USER_DATA_REJECTED",
};

export function setUserData({ userId, authToken, data }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_USER_DATA_PENDING,
    });

    try {
      let { result } = await fetchSetUserData({ userId, authToken, data });

      dispatch({
        type: actions.SET_USER_DATA_FULFILLED,
        data: result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SET_USER_DATA_REJECTED,
        error,
      });
    }
  };
}
