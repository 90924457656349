import React, { useState } from "react";
import { PlanCard, Transition } from "../components/common";
import basic from "../images/basic.svg";
import advanced from "../images/advanced.svg";
import professional from "../images/professional.svg";
// import enterprise from "../images/enterprise.svg";
import { connect, useSelector } from "react-redux";
import { BackArrow } from "../icons/icons";
import { withRouter } from "react-router-dom";
import { logout } from "../redux/actions/orgActions";
import client from "../redux/utils/client";




const PlanPage = ({ history, isBack = true }) => {
  const [plan, setPlan] = useState("pro");
  const { profile } = useSelector((state) => state.auth.toJS());

  const setChoosenPlan = (plan) => {
    if (profile.shop_url) {
      if (profile.storeDeleted) {
        window.top.location.replace(`https://app.raddcontent.com/shopapp/install.php?shop=${profile.shop_url}`);
        return;
      }
      window.top.location.replace(`https://${profile.shop_url}/admin/apps/radd-1/shopapp/payment.php?plan=${plan}`);
    } else {
      goContactUs();
    }
  };

  const goContactUs = () => {
    window.top.open(`https://raddcontent.com/contact.html`, "_blank");
  };

  return (
    <div className="page planpage withbutton">
      {isBack && (
        <div className="back__button" onClick={() => history.goBack()}>
          <BackArrow /> <span>Back</span>
        </div>
      )}

        <div className="logout__button" onClick={() => client.logout()} >
          <span>Logout</span>
        </div>
      <Transition>
        <div className="page__title">Your wish is our command!</div>
        <div className="page__subtitle">
          Your stories are waiting... please choose the plan that best meets
          your needs.
        </div>
        <div className="page__subtitle mobile__sub">
          Please choose the plan that best meets your needs
        </div>
        <div className="choose__plan">
            <PlanCard
                disabled={profile.plan === "freemium" && profile.planActive}
                isActive={plan === "freemium"}
                title="Freemium"
                subtitle="Immediately integrate radd. story reviews"
                image={basic}
                cardClick={setPlan}
                name="freemium"
                buttonClick={setChoosenPlan}
                price="for free"
                points={[
                    "3 self created stories",
                    "Shoppable stories",
                    "Video gallery",
                ]}
            />
          {/*<PlanCard*/}
          {/*  disabled={profile.plan === "booster" && profile.planActive}*/}
          {/*  isActive={plan === "booster"}*/}
          {/*  title="Booster"*/}
          {/*  subtitle="Immediately integrate radd. story reviews"*/}
          {/*  image={basic}*/}
          {/*  cardClick={setPlan}*/}
          {/*  name="booster"*/}
          {/*  buttonClick={setChoosenPlan}*/}
          {/*  price="$99.99"*/}
          {/*  points={[*/}
          {/*    "25 story reviews",*/}
          {/*    "Story review & ratings option",*/}
          {/*    "Shoppable stories",*/}
          {/*    "Multiple product tagging",*/}
          {/*  ]}*/}
          {/*/>*/}
          <PlanCard
            disabled={profile.plan === "advanced" && profile.planActive}
            isActive={plan === "advanced"}
            title="Advanced"
            subtitle="Add radd. story reviews with in-story promotions"
            image={advanced}
            cardClick={setPlan}
            name="advanced"
            buttonClick={setChoosenPlan}
            price="$299.99"
            points={[
              "100 story reviews",
              "All Basic features",
              "Offer in-story promotions",
            ]}
          />
          <PlanCard
            disabled={profile.plan === "pro" && profile.planActive}
            title="Pro"
            subtitle="Add radd. story reviews + enjoy all advanced features!"
            image={professional}
            name="pro"
            cardClick={setPlan}
            isActive={plan === "pro"}
            buttonClick={setChoosenPlan}
            price="$599.99"
            points={[
              "200 story reviews",
              "All Advanced features",
              "LIVE",
              "Place stories on product page",
              "Choose your story layout",
              "Full dashboard analytics",
              "No radd. Logo",
            ]}
          />
          {/* <PlanCard
            disabled={profile.plan === "enterprise" && profile.planActive}
            title=
            "Enterprise"
            subtitle="Leave us your details and an expert will be in touch"
            image={enterprise}
            isEnter
            name="enterprise"
            cardClick={setPlan}
            isActive={plan === "enterprise"}
            buttonClick={goContactUs}
            price="$199.99"
            points={[
              "Unlimited story upload",
              "All PRO plan features:",
              "",
              "Broadcast LIVE influencer shopping stories ",
              "Your very own customer success manager",
              "Your very own content creator",
            ]}
          /> */}
        </div>
      </Transition>
    </div>

  );
};


const mapDispatchToProps = {
  logout,
};

export default withRouter(connect(null, mapDispatchToProps)(PlanPage));

