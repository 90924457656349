import React from "react";
import { useSelector } from "react-redux";
import { Sidebar, Topbar } from "./common";

const MainLayout = ({ children, path, hideTopbar }) => {
  const { sidebarExpanded } = useSelector((state) => state.global.toJS());

  return (
    <main
      className={`main-wrapper${!sidebarExpanded ? " sidebar-expanded" : ""}`}
    >
      <Sidebar path={path} />
      <div className="content-wrapper">
        {!hideTopbar && <Topbar />}
        {children}
      </div>
    </main>
  );
};

export default MainLayout;
