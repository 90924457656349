import {setAuthToken, instance, fetchRequest} from './fetchData';

export function fetchPublishStory({authToken, storyId, publishData}) {
    setAuthToken(authToken);

    return fetchRequest(instance.post(`/story/${storyId}/publish`, {
        bars: publishData
    }));
}

export function fetchUnpublishStory({authToken, barId, storyId}) {
    setAuthToken(authToken);

    return fetchRequest(instance.post(`/story-bar/${barId}/remove`, {
        storyId
    }));
}