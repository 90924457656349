import React, { useState, useEffect } from "react";
import { Input, Avatar, Transition } from "../../components/common";
import defaultImg from "../../images/default_upload.png";
import { useSelector, useDispatch } from "react-redux";
import { singUpWithCred } from "../../redux/actions/authActions";
import normalizeUrl from "normalize-url";
import _ from "lodash";
import cn from 'classnames'
const RegistrationForm = ({ choosenPlan, shopUrl, shopifyToken }) => {
  const dispatch = useDispatch();
  const { regError } = useSelector((state) => state.auth.toJS());
  const [image, setImage] = useState(defaultImg);
  const [isRemoveButton, setIsRemoveButton] = useState(false)
  const [preloader, setPreloader] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm: "",
    company: "",
    homepage: "",
  });
  const [errors, setErrors] = useState({});
  const [blob, setBlob] = useState("");

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", defaultImg, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status === 200) {
        var myBlob = this.response;
        setBlob(myBlob);
      }
    };
    xhr.send();
  }, []);

  const validate = (name, val) => {
    let regex;
    let error;
    if (!val) {
      return { [name]: "This field is required" };
    }
    switch (name) {
      case "email":
        // eslint-disable-next-line
        regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( // eslint-disable-next-line
          // eslint-disable-next-line

          val
        )
          ? false
          : true;
        error = "Invalid value";
        break;
      case "password":
        regex = !new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(
          val
        );
        error = "Minimum 8 characters, 1 uppercase, 1 lowercase and 1 number";
        break;
      case "confirm":
        regex = !new RegExp(`^${formData.password}$`).test(val);
        error = "Passwords do not match";
        break;
      case "company":
        // eslint-disable-next-line
        regex = /\`|\(|\)|\[|\{|\]|\}|\||\\|\'|\<|\>|\?|\/|\"|\;/.test(val);
        error = "Invalid symbols ><?()/}{]]|\\;";
        break;
      case "homepage":
        // eslint-disable-next-line
        regex = !/^(ftp|http|https):\/\/[^ "]+$/.test(val);
        error = "Url in format https://example.com/";
        break;
      default:
        break;
    }

    if (regex) {
      return { [name]: error };
    } else {
      return null;
    }
  };

  const handleChange = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
    const err = validate(name, val);
    if (err) {
      setErrors((prev) => ({ ...prev, ...err }));
    } else {
      const er = { ...errors };
      delete er[name];
      setErrors(er);
    }
  };

  const createAccount = async () => {
    let err2 = {};
    for (const key in formData) {
      const err = validate(key, formData[key]);
      if (err) {
        err2 = { ...err2, ...err };
      }
    }

    if (!_.isEmpty(err2)) {
      setErrors(err2);
      return;
    }

    if (
      _.isEmpty(errors) &&
      formData.email &&
      formData.password &&
      formData.company &&
      formData.confirm &&
      formData.homepage
    ) {
      setPreloader(true)
      const getBase64 = (blob2) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob2);
        });
      const orgLogo = await getBase64(blob);
      console.log(orgLogo)
      await dispatch(
         singUpWithCred({
          email: formData.email,
          password: formData.password,
          plan: choosenPlan,
          orgLogo,
          orgName: formData.company,
          orgHomepage:
            normalizeUrl(formData.homepage, {
              forceHttps: true,
              stripHash: true,
              removeQueryParameters: true,
              removeTrailingSlash: true,
              removeSingleSlash: true,
            }) + "/",
        })
      );
      setPreloader(false)
    }
  };

  return (
    <Transition>
      <div className="page__title">Time to profile</div>
      <div className="page__subtitle">
        It’s super exciting to have you on board with us! You can now go ahead
        and create your brand profile
      </div>
      <div className="page__subtitle mobile__sub">
        You can now go ahead and create your brand profile
      </div>
      <div className="invite__group">
        <div className="invite__part">
          <Input
            label="E-mail"
            type="email"
            name="email"
            error={errors.email || regError}
            placeholder="Please enter e-mail"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Input
            label="Password"
            type="password"
            name="password"
            error={errors.password}
            placeholder="Please enter your password"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Input
            label="Confirm password"
            type="password"
            name="confirm"
            error={errors.confirm}
            placeholder="Please confirm your password"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="invite__part">
          <Input
            label="Company name"
            placeholder="Please enter your company name"
            type="text"
            name="company"
            error={errors.company}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Input
            label="Website home page URL"
            placeholder="Copy paste your home page URL"
            type="text"
            name="homepage"
            error={errors.homepage}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div className="image__upload">
            <Avatar image={image} />
            <div className="invite__buttons">
              { !isRemoveButton ||   <div
                  className="small__button button__default"
                  onClick={() => {
                    setImage(defaultImg);
                    setIsRemoveButton(false)
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", defaultImg, true);
                    xhr.responseType = "blob";
                    xhr.onload = function (e) {
                      if (this.status === 200) {
                        var myBlob = this.response;
                        setBlob(myBlob);
                      }
                    };
                    xhr.send();
                  }}
              >
                Remove logo
              </div>}

              <label htmlFor="files" className="small__button button__accent">
                Add logo
              </label>
            </div>
            <input
              type="file"
              accept="image/*"
              id="files"
              style={{ visibility: "hidden" }}
              onChange={(e) => {
                setImage(URL.createObjectURL(e.target.files[0]));
                setBlob(e.target.files[0]);
                setIsRemoveButton(true)
              }}
            />
          </div>
          <div className="skip__hint">
            *If you’d like, your logo will accompany your stories on top left
            corner. 2GB Max. size
          </div>
        </div>
      </div>
      <div className="page__button flex__center">
        <div className={cn("accent__button", {
          "disabled_button": preloader
        })} onClick={() => createAccount()}>
          { preloader && <img src="/images/loader-puprle.gif" className='loader_white' alt="loader_white"/>}
          create profile
        </div>
      </div>
    </Transition>
  );
};

export default RegistrationForm;
