import React from "react";
import { Loader } from ".";

const Button = ({
  text,
  type = "accent",
  loading,
  disabled,
  onClick = () => {},
  icon,
}) => {
  return (
    <button
      className={`button button--${type}${disabled ? " button--disabled" : ""}${
        loading ? " button--loading" : ""
      }${icon ? " button--icon" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <Loader size={20} />
      ) : (
        <>
          {text}
          {!!icon && <span>{icon}</span>}
        </>
      )}
    </button>
  );
};

export default Button;
