import React from "react";
import { useSelector } from "react-redux";
import { getStoriesCount } from "../../helpers/storiesHelper";

const StoriesCount = ({ isEdit }) => {
  const { profile } = useSelector((state) => state.auth.toJS());
  const { data } = useSelector((state) => state.stories.toJS());
  const { storyData } = useSelector((state) => state.editStory.toJS());

  const { total, max } = getStoriesCount(data, profile.plan, storyData, isEdit);

  return (
    <div className="storycount-widget">
      Total stories count
      <span>
        {total}/{max}
      </span>
    </div>
  );
};

export default StoriesCount;
