import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { StoryCardAnalytic } from "./";
import AddToHighlightModal from "../modal/AddToHighlightModal";
import { deleteStory } from "../../redux/actions/storiesActions";
import { removeStoryFromBar } from "../../redux/actions/storybarActions";
import { getStoryAnalytic } from "../../redux/utils/analytics";
import {
  TreeDot,
  MoveIcon,
  EditIcon,
  TrashIcon,
  UnpublishIcon,
  PlusIcon,
} from "../../icons/icons";
import "../../scss/components/StoryCard.scss";

const StoryCardMenu = ({
  isDraft,
  isReview,
  isLast,
  editUrl,
  onDelete,
  onUnpublish,
  onAdd,
  storyId,
}) => {
  return (
    <div
      className={`setting-block${isLast ? " setting-block--left" : ""}`}
      id={`menu-${storyId}`}
    >
      <ul>
        {isReview && (
          <li>
            <button className="setting-block__item" onClick={onAdd}>
              <div className="setting-block__item-icon">
                <PlusIcon />
              </div>
              Add to highlight
            </button>
          </li>
        )}
        <li>
          <Link className="setting-block__item" to={editUrl}>
            <div className="setting-block__item-icon">
              <EditIcon />
            </div>
            Edit
          </Link>
        </li>
        <li>
          <button
            className="setting-block__item setting-block__item--red"
            onClick={onDelete}
          >
            <div className="setting-block__item-icon">
              <TrashIcon />
            </div>
            Delete
          </button>
        </li>
        {!(isDraft || isReview) && (
          <li>
            <button
              className="setting-block__item setting-block__item--red"
              onClick={onUnpublish}
            >
              <div className="setting-block__item-icon">
                <UnpublishIcon />
              </div>
              Unpublish
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const StoryCard = ({
  image,
  title,
  background,
  isDraft = false,
  isReview = false,
  id,
  idToken,
  dragHandleProps,
  isLast,
  barId,
  story,
}) => {
  const dispatch = useDispatch();
  const [dropOpen, setDropOpen] = useState(false);
  const [addModalOpened, setAddModalOpened] = useState(false);
  const [analytic, setAnalytic] = useState(null);

  const handleAddToHighlight = () => {
    setAddModalOpened(true);
  };

  const handleDeleteStory = () => {
    dispatch(deleteStory({ authToken: idToken, storyId: id }));
    setDropOpen(false);
  };

  const handleUnpublishStory = () => {
    dispatch(
      removeStoryFromBar({
        authToken: idToken,
        storyId: id,
        barId,
      })
    );
    setDropOpen(false);
  };

  const handleMenuOpen = () => {
    if (dropOpen) return;
    setDropOpen(true);

    const handleMenuClose = (e) => {
      if (
        document.getElementById(`menu-${id}`) &&
        document.getElementById(`menu-${id}`).contains(e.target)
      ) {
        return;
      }
      setDropOpen(false);

      document.removeEventListener("mousedown", handleMenuClose);
      document.removeEventListener("touchstart", handleMenuClose);
    };

    document.addEventListener("mousedown", handleMenuClose);
    document.addEventListener("touchstart", handleMenuClose);
  };

  const getAnalytics = () => {
    getStoryAnalytic(id).then((response) =>
      response.json().then((e) => {
        setAnalytic({
          views: e.data.series
            .map((el) => e.data.values[`Story View`][el])
            .reduce((a, b) => a + b, 0),
          clicks: e.data.series
            .map((el) => e.data.values[`Product Click`][el])
            .reduce((a, b) => a + b, 0),
          purchases: e.data.series
            .map((el) => e.data.values[`Purchase Made`][el])
            .reduce((a, b) => a + b, 0),
        });
      })
    );
  };

  return (
    <div
      className="story-card"
      onMouseEnter={() => {
        if (!analytic && !(isDraft || isReview)) getAnalytics();
      }}
    >
      {dropOpen && (
        <StoryCardMenu
          isDraft={isDraft}
          isReview={isReview}
          isLast={isLast}
          editUrl={`/edit/${id}`}
          onDelete={handleDeleteStory}
          onUnpublish={handleUnpublishStory}
          onAdd={handleAddToHighlight}
          storyId={id}
        />
      )}
      {addModalOpened && isReview && (
        <AddToHighlightModal
          cardData={story.cards[0]}
          onClose={() => setAddModalOpened(false)}
        />
      )}
      {!(isDraft || isReview) && !dropOpen && (
        <StoryCardAnalytic analytic={analytic} />
      )}
      <div className="story-card-circle">
        <div
          className="story-card-circle__image phh"
          style={
            background && background[0] === "#"
              ? { backgroundColor: background }
              : { backgroundImage: background }
          }
        >
          {!!image && <img src={image} alt="" />}
          <div className="story-card-circle__hover">
            <span onClick={handleMenuOpen}>
              <TreeDot />
            </span>
          </div>
        </div>
        {!(isDraft || isReview) && (
          <div className="story-card-circle__icon" {...dragHandleProps}>
            <MoveIcon />
          </div>
        )}
      </div>
      {!isReview && <p className="story-card__title phh">{title}</p>}
    </div>
  );
};

export default withRouter(StoryCard);
