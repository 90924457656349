import React from "react";
import { checkPermission } from "../../helpers/accessControl";

const Component22 = () => {
  return null;
};

const withPermission = (plan, Component, Component2 = Component22) => () => {
  return checkPermission(plan) ? <Component /> : <Component2 />;
};

export default withPermission;
