import React, { useState, useEffect } from "react";

const LiveProd = ({image, promo, name, isOpen, url, added}) => {
    const [defimage, setImage] = useState(null);
    const isBetween = (promo) => {
        const today = new Date();
        const startDate = new Date(promo.startDate);
        const endDate = new Date(promo.endDate);
        return today >= startDate && today <= endDate;
    };

    useEffect(() => {
        var img = new Image();
        img.onerror = img.onabort = function () {
            setImage(
                `https://platform.dev2.raddcontent.com/images/placeholderimage.jpg`
            );
        };
        img.onload = function () {
            setImage(image);
        };
        img.src = image;
    }, [isOpen, setImage, image]);

    return (
        <div className={`live__view ${isOpen ? "opened" : ""}`}>
            <div className="pw__wrap">
                <div
                    className="pw__image"
                    style={{ backgroundImage: `url('${defimage || image}')` }}
                ></div>
                <img className="pw__img" src={defimage || image} alt="product" />
            </div>
            <div className="pw__info">
                <div className="pw__name">{name}</div>
                {promo && isBetween(promo) ? (
                    <div className="view__promo promo__item">{`-${promo.promo}%`}</div>
                ) : (
                    ``
                )}
            </div>
            <div className="product__button">
                <a href={url}
                    className={`product__buy product__but${added ? " added" : ""}`}>
                    Open Product
                </a>
            </div>
        </div>
    );
};

export default LiveProd;
