import React, { useState } from "react";
import { Check } from "../../../icons/icons";
import { onlyWithPermission } from "../../../helpers/accessControl";

const CheckBox = ({
  defaultCheck = false,
  getPublish,
  name,
  icon,
  title,
  noAnimate = false,
}) => {
  const [isChecked, setIsChecked] = useState(defaultCheck);

  const onClick = (e) => {
    const clickEvent = () => {
      setIsChecked((c) => !c);
      getPublish(name);
    };

    if (name === "product" || name === "url") {
      onlyWithPermission(e, "professional", clickEvent);
    } else {
      clickEvent();
    }
  };

  return (
    <div
      className={`custom__checkbox ${isChecked ? "checkbox__active" : ""} ${
        !noAnimate ? "checkbox__animate" : ""
      }`}
      onClick={onClick}
    >
      <div className="checkbox__icon">{icon}</div>
      <div className="checkbox__title">{title}</div>
      <div className="checkbox__check">{isChecked && <Check />}</div>
    </div>
  );
};

export default CheckBox;
