import React from "react";

const ProductAnalytic = ({ image, name, url, analytic }) => {
  return (
    <div className="product__analytic">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={image} alt="coollwwowoowow" className="pa__image" />
      </a>
      <div className="pa__name">{name}</div>
      <div className="pa__analytic">
        <div>Purchases</div>
        <div className="pa__title">{analytic.purchases} </div>
      </div>
      <div className="pa__analytic">
        <div>Clicks</div>
        <div className="pa__title">{analytic.clicks}</div>
      </div>
      <div className="pa__analytic">
        <div>Conversion</div>
        <div className="pa__title">{analytic.conversion ? `${(analytic.conversion).toFixed()} %` : '0%'}</div>
      </div>
    </div>
  );
};

export default ProductAnalytic;
