import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RaddLogo, RaddSmall, LeftArrow } from "../../icons/icons";
import { Navigation } from "./";
import { setSidebarExpand } from "../../redux/actions/globalActions";
import "../../scss/components/Sidebar.scss";

const Sidebar = ({ path }) => {
  const dispatch = useDispatch();
  const { sidebarExpanded } = useSelector((state) => state.global.toJS());

  const handleExpandClick = () => {
    dispatch(setSidebarExpand(!sidebarExpanded));
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-logo" onClick={handleExpandClick}>
        <span className="sidebar-logo--expanded">
          <RaddLogo />
        </span>
        <span className="sidebar-logo--shrinked">
          <RaddSmall />
        </span>
        <span className="sidebar-logo__arrow">
          <LeftArrow />
        </span>
      </div>

      <div className="sidebar-content">
        <Navigation path={path} />
      </div>
    </div>
  );
};

export default Sidebar;
