import React, { useState, useEffect, useRef, useCallback } from "react";
import classnames from "classnames";
import "../../scss/components/roundButton.scss";

//stroke is we need to change stroke color and not to fill
const RoundButton2 = ({
  children,
  icon,
  id,
  stroke,
  activeClass,
  tutotial,
  isTutorial,
  index,
  toBottom = false,
  isBottom = false,
  disabled,
  isActive,
  setActive,
  onOpen,
  onClose,
  isOpenProp,
  mustOpen,
  isRule = true,
  data = null,
  isColor = false,
  isBg,
}) => {
  const [pos, setPos] = useState(null); //centered position to button
  const [isOpen, setIsOpen] = useState(false);
  const [tutotialPos, setTutorialPos] = useState(null);
  const wrappedRef = useRef(null); //button ref
  const blockRef = useRef(null); //block ref

  //Close context block if click was outside block or button
  const handleClickOutside = (e) => {
    if (
      document.querySelector(".promo__modal") &&
      document.querySelector(".promo__modal").contains(e.target)
    ) {
      return;
    }
    if (!isColor) {
      if (wrappedRef.current && !wrappedRef.current.contains(e.target)) {
        if (!blockRef.current || !blockRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      }
    } else {
      if (
        document.getElementById("colorpicker__outer") &&
        !document.getElementById("colorpicker__outer").contains(e.target) &&
        wrappedRef.current &&
        !wrappedRef.current.contains(e.target)
      ) {
        setIsOpen(false);
        if (onClose) onClose();
      }
    }
  };

  useEffect(() => {
    if (!mustOpen) {
      if (isOpen) {
        setActive(index);
      } else {
        setActive(null);
      }
    }
  }, [isOpen, index, setActive, mustOpen]);

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  //On mount add events which handle click outside context block or button
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  //Get position to vertical align context block
  const getSize = useCallback(() => {
    setTimeout(() => {
      if (blockRef.current && wrappedRef.current) {
        if (isBottom) {
          setPos({ bottom: 0 });
        } else {
          const mintop = 75;
          const height = blockRef.current.offsetHeight;
          const btop = wrappedRef.current.getBoundingClientRect().top;
          const bheight = wrappedRef.current.offsetHeight;
          const top =
            btop + bheight / 2 - height / 2 >= mintop
              ? bheight / 2 - height / 2
              : mintop -
                (btop + bheight / 2 - height / 2) +
                (bheight / 2 - height / 2);
          setPos({ top, left: 20 });
        }
      }
    }, 100);
  }, [isBottom]);

  const getPos = useCallback(() => {
    const btop = wrappedRef.current.offsetTop;
    const bleft = wrappedRef.current.offsetLeft;
    !isBottom
      ? setTutorialPos({ top: btop + 15, left: bleft + 104, opacity: 1 })
      : setTutorialPos({ bottom: 60, left: bleft + 104, opacity: 1 });
  }, [isBottom]);

  //Call getSize when opens
  useEffect(() => {
    if ((isOpen || mustOpen) && blockRef.current && wrappedRef.current) {
      getSize();
    } else {
      setPos(null);
    }
  }, [isOpen, getPos, getSize, mustOpen]);

  useEffect(() => {
    isTutorial && wrappedRef.current && getPos();
  }, [isTutorial, getPos]);

  useEffect(() => {
    if (isOpen || mustOpen) {
      getSize();
    }
  }, [getSize, isOpen, mustOpen, data]);

  return (
    <div className="round" id={id}>
      <div
        className={classnames(
          `round__button ${
            isOpen || mustOpen || isTutorial ? activeClass : ""
          }`,
          {
            stroke: stroke,
            fill: !stroke,
            disabled: disabled,
          }
        )}
        onClick={() => {
          if (!isOpen) {
            if (onOpen && !mustOpen) onOpen();
            setIsOpen(true);
          } else {
            if (onClose && !mustOpen) onClose();
            setIsOpen(false);
          }
        }}
        ref={wrappedRef}
      >
        {icon}
      </div>
      {(isOpen || mustOpen) && isRule ? (
        <div
          className={classnames("round__contain", {
            hide: isBg,
            to__bottom: toBottom,
          })}
          style={pos ? { ...pos, opacity: 1 } : { opacity: 0 }}
          ref={blockRef}
        >
          {children}
        </div>
      ) : null}
      {isTutorial && (
        <div
          className={`round__tutorial ${
            isBottom ? "bottom__tutuorial" : "top__tutorial"
          }`}
          style={tutotialPos ? tutotialPos : { opacity: 0 }}
        >
          {tutotial}
        </div>
      )}
    </div>
  );
};

export default RoundButton2;
