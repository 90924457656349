import {
    fetchCampaigns,
    fetchCreateCampaign,
} from '../utils/fetchCampaigns';

export const actions = {
    GET_CAMPAIGNS_LIST_PENDING: 'GET_CAMPAIGNS_LIST_PENDING',
    GET_CAMPAIGNS_LIST_FULFILLED: 'GET_CAMPAIGNS_LIST_FULFILLED',
    GET_CAMPAIGNS_LIST_REJECTED: 'GET_CAMPAIGNS_LIST_REJECTED',
    CREATE_CAMPAIGN_PENDING: 'CREATE_CAMPAIGN_PENDING',
    CREATE_CAMPAIGN_FULFILLED: 'CREATE_CAMPAIGN_FULFILLED',
    CREATE_CAMPAIGN_REJECTED: 'CREATE_CAMPAIGN_REJECTED',
    GET_CAMPAIGN_PENDING: 'GET_CAMPAIGN_PENDING',
    GET_CAMPAIGN_FULFILLED: 'GET_CAMPAIGN_FULFILLED',
    GET_CAMPAIGN_REJECTED: 'GET_CAMPAIGN_REJECTED',
};

export function getCampaignsList({authToken}) {
    return async (dispatch) => {
        dispatch({
            type: actions.GET_CAMPAIGNS_LIST_PENDING,
        });

        try {
            let data = await fetchCampaigns({authToken});

            dispatch({
                type: actions.GET_CAMPAIGNS_LIST_FULFILLED,
                data: data.result,
            });
        }
        catch (error) {
            console.error(error);

            dispatch({
                type: actions.GET_CAMPAIGNS_LIST_REJECTED,
                error,
            });
        }
    };
}

export function createCampaign({authToken, data}) {
    return async (dispatch) => {
        dispatch({
            type: actions.CREATE_CAMPAIGN_PENDING,
        });

        try {
            let response = await fetchCreateCampaign({authToken, data});

            dispatch({
                type: actions.CREATE_CAMPAIGN_FULFILLED,
                data: response.result,
            });
        }
        catch (error) {
            console.error(error);

            dispatch({
                type: actions.CREATE_CAMPAIGN_REJECTED,
                error,
            });
        }
    };
}