import Immutable from 'immutable';

import {actions as A} from '../actions/acceptOrgInviteActions';

const initialState = Immutable.fromJS({
    fetched: false,
    fetching: false,
    error: null,
    data: {}
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case A.ACCEPT_ORG_INVITE_PENDING:
            return state.withMutations(state => (
                state
                    .set('fetched', false)
                    .set('fetching', true)
            ));
        case A.ACCEPT_ORG_INVITE_FULFILLED:
            return state.withMutations(state => (
                state
                    .set('fetched', true)
                    .set('fetching', false)
                    .set('data', action.data)
            ));
        case A.ACCEPT_ORG_INVITE_REJECTED:
            return state.withMutations(state => (
                state
                    .set('fetched', false)
                    .set('fetching', false)
                    .set('error', action.error)
            ));
        case A.RESET_ACCEPT_ORG_INVITE_DATA:
            return initialState;
        default:
            return state;
    }
}