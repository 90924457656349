import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NewStoryCard } from "../../components/common";
import {
  filterStories,
  filterCards,
  getCoverImage,
  getShortUrl,
} from "../../helpers/storiesHelper";
import { mockAnalytics } from "../../mocks";
import "../../scss/components/Storybar.scss";

const Storybar = ({ storyBarData, index, handleDragEnd, idToken }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const shortUrl = getShortUrl(storyBarData.title);

  return (
    <DragDropContext
      key={storyBarData.id}
      onDragEnd={(res) => handleDragEnd(res, index)}
    >
      <div className="storybar">
        <div className="storybar-header">
          <h6 className="phh">Storybar</h6>
          <p>{shortUrl}</p>
        </div>
        <Droppable
          droppableId={"droppable" + storyBarData.id}
          direction="horizontal"
        >
          {(provided, snapshot) => (
            <div
              className="storybar-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {filterStories(storyBarData).map((e, i, array) => {
                const cards = filterCards(e);

                return (
                  <Draggable
                    key={e.id + `-` + i}
                    draggableId={e.id + `-` + i}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        key={e.id + `-` + i}
                      >
                        <NewStoryCard
                          id={e.storyId}
                          barId={storyBarData.id}
                          story={e}
                          isDraft={e.isDraft}
                          title={e.title || ""}
                          snapshot={cards[0].snapshot}
                          image={getCoverImage(e, cards[0])}
                          isSnapshot={!e.coverImage}
                          background={cards[0].backgroundColor}
                          publishing={e.publishing}
                          statistic={mockAnalytics[0].statistic}
                          dragHandleProps={provided.dragHandleProps}
                          isLast={array.length - 1 === i && i !== 0}
                          idToken={idToken}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default Storybar;
