import React, { useState, useEffect } from 'react';
import '../../scss/components/Popup.scss';

let timeout = null;

const Popup = ({ children }) => {
	const [ isShow, setIsShow ] = useState(true);

	useEffect(() => {
		timeout = setTimeout(() => {
			setIsShow(false);
		}, 5000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return isShow ? (
		<div
			className="popup"
			onMouseEnter={() => {
				clearTimeout(timeout);
			}}
			onMouseLeave={() => {
				timeout = setTimeout(() => setIsShow(false), 3000);
			}}
		>
			{children}
		</div>
	) : null;
};

export default Popup;
