import _ from "lodash";
import axios from "axios";
import client from "./client";

import { setAuthToken, removeAuthToken, instance } from "./fetchData";

export async function uploadFile({
  blob,
  storyId,
  cardId,
  mediaType = "background",
  ext = "png",
  isBase64 = false,
}) {
  const authToken = client.getToken();

  setAuthToken(authToken);
  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }
  if (isBase64) blob = b64toBlob(blob);

  let filename = blob instanceof File ? blob.name : `original.${ext}`;

  console.log('file', blob)

  let cardData, request;

  switch (mediaType) {
    case "background":
    case "snapshot":
      if (mediaType === "cover") {
        cardData = {};
      } else if (mediaType === "background") {
        cardData = {
          backgroundMedia: "@FILE@",
        };
      } else {
        cardData = {
          snapshot: "@FILE@",
        };
      }

      request = instance.post(`/story/${storyId}/upload`, {
        filename,
        delta: {
          cards: [
            {
              _id: cardId,
              ...cardData,
            },
          ],
        },
      });

      break;
    default:
      request = instance.get(`/presign`, {
        params: {
          filename,
        },
      });
  }

  let response = await request;

  if (response.status !== 200) {
    console.error(response);
  }

  let {
    data: {
      result: { url, fields },
    },
  } = response;

  let formData = new FormData();

  _.forOwn(fields, (value, key) => {
    formData.append(key, value);
  });
  formData.append("file", blob, filename);

  removeAuthToken();

  await axios.post(url, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  if (mediaType === "cover") {
    return `https://cdn.dev.raddcontent.com/${fields.key}`;
  }

  return `{BASE}/${fields.key}`;
}
