import React from "react";
import "../../scss/components/AnalyticCard.scss";

const AnalyticCard = ({ icon, title, count, loading }) => {
  return (
    <div className={`analytic-card${loading ? " phh" : ""}`}>
      <div className="analytic-card__icon">{icon}</div>
      <p className="analytic-card__title">{title}</p>
      <p className="analytic-card__count">{count}</p>
    </div>
  );
};
export default AnalyticCard;
