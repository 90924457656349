import React, { memo } from "react";
import ColorCheckbox from "./ColorCheckbox";
import "../../../scss/components/Colorpicker.scss";

const Colorpicker = memo(({ colors, activeColor, setColor, isText }) => {
  return (
    <div id="colorpicker__outer" className={isText ? " isText" : ""}>
      <div className="colorpicker" id="colorpicker">
        {colors.map((col, i) => {
          return (
            <ColorCheckbox
              key={i}
              id={i}
              isActive={activeColor === i}
              color={col}
              onClick={setColor}
            />
          );
        })}
      </div>
    </div>
  );
});

export default Colorpicker;
