import React, { useEffect } from "react";
import { Transition } from "../../components/common";
import LivePage from "./LivePage";
import LivePageDefault from "./LivePageDefault";
import { DefaultPage, PageView } from "../../components/containers";
import { Link } from "react-router-dom";
import { StoryPage } from "../../icons/icons";
import { Button } from "../../components/atoms";
import { connect } from "react-redux";
import { getLivesList } from "../../redux/actions/livesActions";
import fakeData from "./fakeData";
import withPermission from "../../components/hoc/withPermission";

const LiveUpgrade = () => (
  <DefaultPage
    title="Get full dashboard analytics"
    subtitle="Upgrade your plan to pro to use dashboard analytic"
    icon={<StoryPage />}
    cta={
      <Link to="plans">
        <Button text="Upgrade plan" />
      </Link>
    }
  />
);

const Live = ({
  getLivesList,
  data,
  idToken,
  fetching,
  updateFetching,
  deleteStoryFetching,
  orgInfo,
}) => {
  useEffect(() => {
    getLivesList({ authToken: idToken });
    // eslint-disable-next-line
  }, []);

  return (
    <Transition transition={500} withbutton={!(data && data.length > 0)}>
      <PageView
        defaultView={<LivePageDefault orgInfo={orgInfo} />}
        isShow={data && data.length > 0}
        isLoading={fetching || updateFetching || deleteStoryFetching}
        loadingView={<LivePage data={fakeData} loading />}
      >
        <LivePage data={data} fetching={false} orgInfo={orgInfo} />
      </PageView>
    </Transition>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken, orgInfo } = auth;
  const lives = state.lives.toJS();
  let { data, fetching, updateFetching, deleteStoryFetching } = lives;

  return {
    data,
    idToken,
    fetching,
    updateFetching,
    deleteStoryFetching,
    orgInfo,
  };
};

const mapDispatchToProps = {
  getLivesList,
};

export default withPermission(
  "professional",
  connect(mapStateToProps, mapDispatchToProps, null)(Live),
  LiveUpgrade
);
