import { LiveStream, NoMatch, Shopify, ShopperLive } from "../pages";

export default [
  {
    path: "/lives/:id",
    exact: true,
    withHeader: false,
    component: LiveStream,
  },
  {
    path: "/shopper_live/:id",
    exact: true,
    withHeader: false,
    component: ShopperLive,
  },
  {
    path: "/shopify",
    exact: true,
    withHeader: false,
    component: Shopify,
  },
  {
    component: NoMatch,
  },
];
