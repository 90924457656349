import React from "react";

const LiveOffline = () => {
    return (
        <div className="live-offline">
            <div className="live-offline__container">
                <div className="live-offline-icon">
                    <svg
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="70" height="70" fill="url(#pattern0)" />
                        <defs>
                            <pattern
                                id="pattern0"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                            >
                                <use xlinkHref="#image0" transform="scale(0.02)" />
                            </pattern>
                            <image
                                id="image0"
                                width="50"
                                height="50"
                                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAB9ElEQVRoge3YvW4TQRSGYYOokJCIC1JBZC4AiT7CDQQh6LgI2ogiRBSR6HIFhNwCPVCQhJaaO7AiEFQoTiQUhPVQeAwrY8e7sz/Konkb/+ycM983s3t2ZjqdRCKRSCQS/yG4gwOcaJaT0O/DKkw8x6hhA7PYKjsTI5xiA1dLj0yx/pewHvofYTU20YcwGhsVayyqYzPo2C8YdwlPOjgOCZbqEplTUDfoGBaIuYtP0JncnHWKzEteLejjY/bhapUR3MKbjP4vrTKC69jFr9DkGNu40goj4bnZxo9w6TQYWv4n5jwawWU8w/fwe4TXuDkvppQR9MIIHeJn+HyFXkkjnzPf3+P2ophoI7jvb+meZoi1EkYYV6V+3pgoI2Em5pnImlmJNPIYF4rExBrZXWBiwk6MkaiYyODDnEYGTRm5WCQow7Wc7ZYXN6mGWCPfcrb7Gpm/MLFG3uVs9zYyf3Ei78uecVU6iyPciNGiqaoVEqydYeYI9yJyZqn/PZJJsoIdDIzXQQO8LDoT06I0+Wavg4z4ZtdaVTOtRdtXvzP+b/d+ZMb1du8QZ7Trm7Fnn5TQ1p+iHIQE63UKzSHqadCxVzDuz7nWo0xF2ES3LrFzhHSDidOg40GZZFvOx9nviypGZhX7Fu/8qmaIPWVmIpFIJBKJxJjfT3vHtRLjHdkAAAAASUVORK5CYII="
                            />
                        </defs>
                    </svg>
                </div>
                <div className="live-offline-title">
                    Are you here for a LIVE story broadcast?
                </div>
                <div className="live-offline-subtitle">
                    We are actually offline now but be sure to join our next session
                </div>
                <div
                    className="live-offline-button"
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    Refresh to join LIVE
                </div>
            </div>
        </div>
    );
};

export default LiveOffline;
