import React, { useState, useEffect } from "react";
import { Close } from "../../icons/icons";
import { Portal } from "react-portal";

const Modal = ({
  title,
  subtitle,
  children,
  submit,
  onSubmit,
  cancel,
  onCancel,
  onClose,
  subtitleMob,
  titleMob,
  toggle,
  isDisabled = false,
  isBook,
  className = "",
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal");
    } else {
      document.body.classList.remove("modal");
    }
    return () => {
      document.body.classList.remove("modal");
    };
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      if (isBook) {
        const res = await onSubmit();
        if (res) setOpen(false);
      } else {
        onSubmit();
        setOpen(false);
      }
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isDisabled) setOpen((o) => !o);
        }}
      >
        {toggle}
      </div>
      {open ? (
        <Portal>
          <div className={"publish " + className}>
            <div className="publish__container">
              <div
                className="publish__close cursor__button"
                onClick={handleClose}
              >
                <Close />
              </div>
              <div className="title desk">{title}</div>
              <div className="subtitle desk">{subtitle}</div>
              <div className="title mobile">{titleMob ? titleMob : title}</div>
              <div className="subtitle mobile">
                {subtitleMob ? subtitleMob : subtitle}
              </div>
              <div>{children}</div>
              <div className="modal__buttons">
                <div
                  className="cancel__button cursor__button"
                  onClick={handleCancel}
                >
                  {cancel}
                </div>
                <div
                  className="publish_button cursor__button"
                  onClick={handleSubmit}
                >
                  {submit}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default Modal;
