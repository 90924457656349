import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusWhite } from "../../icons/icons";
import { createStory } from "../../redux/actions/storiesActions";
import backgr from "../../images/WholeBackground.png";
import { useHistory } from "react-router-dom";
import { v1 as uuid } from "uuid";

const CreateStoryButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idToken } = useSelector((state) => state.auth.toJS());
  const { createStoryFetching, lastCreatedStoryId } = useSelector((state) =>
    state.stories.toJS()
  );

  const [state, setstate] = useState(false);

  useEffect(() => {
    if (state && lastCreatedStoryId) {
      history.push(`/edit/${lastCreatedStoryId}`);
      setstate(false);
    }

    // eslint-disable-next-line
  }, [state, lastCreatedStoryId]);

  const initNewStory = () => {
    if (!createStoryFetching) {
      let offsetTop =
        window.innerHeight < 800
          ? Math.round(80 + 16 + window.innerHeight / 20)
          : 180;
      let cardH = window.innerHeight - 50 - offsetTop;
      let cardW = Math.round((cardH * 414) / 736);
      dispatch(
        createStory({
          authToken: idToken,
          storyData: {
            cards: [
              {
                _id: uuid(),
                _index: 0,
                __new: true,
                backgroundColor: `url(${backgr})`,
                texts: [],
                products: [],
              },
            ],
            screenSize:
              cardH > 736
                ? {
                    width: 414,
                    height: 736,
                  }
                : {
                    width: cardW,
                    height: cardH,
                  },
            storyBarId: null,
          },
        })
      ).then(() => {
        setstate(true);
      });
    }
  };

  return (
    <button
      className="button button--accent button--icon"
      onClick={initNewStory}
    >
      Create Story
      <span>
        <PlusWhite />
      </span>
    </button>
  );
};

export default CreateStoryButton;
