import React from "react";
import {
  HomePageIcon,
  StoryPageIcon,
  AnalyticPageIcon,
  ReviewPageIcon,
  LivePageIcon,
  CustomizePageIcon,
  AccountPageIcon,
  PricingPageIcon,
} from "../icons/icons";

export default [
  {
    path: "/",
    title: "Home",
    icon: <HomePageIcon />,
  },
  {
    path: "/stories",
    title: "Stories",
    icon: <StoryPageIcon />,
  },
  {
    path: "/analytics",
    title: "Analytics",
    icon: <AnalyticPageIcon />,
  },
  {
    path: "/discover",
    title: "Story Reviews",
    icon: <ReviewPageIcon />,
    isReview: true,
  },
  {
    path: "/live",
    title: "LiveCast",
    icon: <LivePageIcon />,
    plan: "PRO",
  },
  {
    path: "/customization",
    title: "Customize",
    icon: <CustomizePageIcon />,
    plan: "PRO",
  },
  {
    path: "/manage-account",
    title: "Account",
    icon: <AccountPageIcon />,
  },
  {
    path: "/plans",
    title: "Pricing",
    icon: <PricingPageIcon />,
  },
];
