import React, { memo } from "react";
import {
  PenIcon,
  ItalianIcon,
  AlignCenter,
  AlignRight,
  AlignLeft,
} from "../../icons/icons";
import "../../scss/components/FontStyle.scss";

//Contine example block that show changes
const FontStyle = memo(
  ({ setFontStyle, fontStyle, fontWeight, color, textAlign }) => {
    return (
      <div className="font__style">
        <div className="font__border font__section">
          <div
            className="font__block"
            onClick={() =>
              setFontStyle({
                fontWeight: fontWeight === "normal" ? "bold" : "normal",
              })
            }
          >
            <div
              className="fold__picker"
              style={
                fontWeight === "bold"
                  ? { color: "#F351A9" }
                  : { color: "#20292C" }
              }
            >
              B
            </div>
          </div>
          <div
            className="font__block"
            onClick={() =>
              setFontStyle({
                fontStyle: fontStyle === "normal" ? "italic" : "normal",
              })
            }
          >
            <div className="italian__picker">
              <ItalianIcon
                color={fontStyle === "italic" ? "#F351A9" : "#20292C"}
              />
            </div>
          </div>
          <div className="font__block">
            <div className="font__colorpicker">
              <PenIcon color={color} />
            </div>
          </div>
        </div>
        <div className="font__section">
          <div
            className="font__block"
            onClick={() => setFontStyle({ textAlign: "left" })}
          >
            <div className="font__left">
              <AlignLeft color={textAlign === "left" ? "#F351A9" : "#20292C"} />
            </div>
          </div>
          <div
            className="font__block"
            onClick={() => setFontStyle({ textAlign: "center" })}
          >
            <div className="font__center">
              <AlignCenter
                color={textAlign === "center" ? "#F351A9" : "#20292C"}
              />
            </div>
          </div>
          <div
            className="font__block"
            onClick={() => setFontStyle({ textAlign: "right" })}
          >
            <div className="font__right">
              <AlignRight
                color={textAlign === "right" ? "#F351A9" : "#20292C"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FontStyle;
