import React from "react";
import "../../scss/components/Input.scss";

const Input = ({ label, error, required, disabled = false, ...props }) => {
  return (
    <div
      className={`custom__input ${error ? "invalid" : ""}${
        disabled ? " disabled" : ""
      }`}
    >
      <label className="input__label">
        {label}
        {required ? <span className="requiredspan">*</span> : ""}
      </label>
      <input className="input__custom" {...props} disabled={disabled} />
      {error ? <div className="input__error error">{error}</div> : null}
    </div>
  );
};

export default Input;
