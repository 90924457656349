import client from "../utils/client";
import { fetchOrgInfo, fetchUserData } from "../utils/fetchUserData";

export const actions = {
  USER_INFO_PENDING: "USER_INFO_PENDING",
  USER_INFO_FULFILLED: "USER_INFO_FULFILLED",
  USER_INFO_FULFILLED_NOT_LOGGED: "USER_INFO_FULFILLED_NOT_LOGGED",
  USER_INFO_REJECTED: "USER_INFO_REJECTED",
  LOGIN_START: "LOGIN_START",
  LOGIN_PRELOADER: "LOGIN_PRELOADER",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
  SET_REDIRECT_URL: "SET_REDIRECT_URL",
  REGISTRATION_FAILURE: "REGISTRATION_FAILURE",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
};

export function getUserInfo(token = null) {
  return async (dispatch) => {
    dispatch({
      type: actions.USER_INFO_PENDING,
    });

    let authToken = token || client.getToken();

    try {
      let profile = await fetchUserData({ authToken });
      profile = profile.result;

      let orgInfo;

      if (profile.organization) {
        orgInfo = await fetchOrgInfo({
          alias: profile.organization,
          authToken,
        });
        orgInfo = orgInfo.result;
      } else {
        orgInfo = {};
      }

      dispatch({
        type: actions.USER_INFO_FULFILLED,
        data: {
          profile,
          idToken: authToken,
          accessToken: "token",
          orgInfo,
        },
      });

      // if (redirectUrl && redirectUrl !== "/") {
      //   dispatch({
      //     type: actions.SET_REDIRECT_URL,
      //     data: redirectUrl,
      //   });
      // }
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.USER_INFO_FULFILLED_NOT_LOGGED,
      });
    }
  };
}

export function shopifyFetching() {
  return async (dispatch) => {
    dispatch({
      type: actions.USER_INFO_FULFILLED_NOT_LOGGED,
    });
  };
}

export function logout() {
  client.logout();

  return {
    type: actions.LOGOUT,
  };
}

export function singUpWithCred({
  email,
  password,
  orgLogo,
  orgName,
  orgHomepage,
    plan,
}) {
  return async (dispatch) => {
    dispatch({
      type: actions.LOGIN_START,
    });

    try {
      await client.signUp({ password, email, orgLogo, orgName, orgHomepage, plan });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.REGISTRATION_FAILURE,
        error: error.error,
      });
    }
  };
}

export function singInWithCred({ email, password }) {
  return async (dispatch) => {
    dispatch({
      type: actions.LOGIN_START,
    });
    dispatch({
      type: actions.LOGIN_PRELOADER,
      preloader: true
    });

    try {
      console.log('sing in')
      await client.signIn({ password, email });
      dispatch({
        type: actions.LOGIN_PRELOADER,
        preloader: false
      });
    } catch (error) {
      console.error('error', error);

      dispatch({
        type: actions.LOGIN_FAILURE,
        error: error.error,
      });
      dispatch({
        type: actions.LOGIN_PRELOADER,
        preloader: false
      });
    }
  };
}

export function resetPassword({ password, newPassword, repeatPassword }) {
  return async (dispatch) => {
    dispatch({
      type: actions.RESET_PASSWORD_START,
    });

    try {
      await client.resetPassword({ password, newPassword, repeatPassword });
      dispatch({
        type: actions.RESET_PASSWORD_SUCCES,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.RESET_PASSWORD_FAILURE,
        error: error.error,
      });
    }
  };
}
