import Immutable from "immutable";

import { actions as A } from "../actions/notificationsActions";

const initialState = Immutable.fromJS({
  fetched: false,
  fetching: false,
  data: [],
  error: null,
  sendNotificationFetched: false,
  sendNotificationFetching: false,
  notifications: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case A.GET_NOTIFICATIONS_PENDING:
      return state.set("fetched", false).set("fetching", true);
    case A.GET_NOTIFICATIONS_FULFILLED:
      return state
        .set("fetched", true)
        .set("fetching", false)
        .set("data", Immutable.fromJS(action.data));
    case A.ADD_NOTIFICATION:
      return state.set(
        "notifications",
        Immutable.fromJS([...state.notifications.toJS(), action.data])
      );
    case A.GET_NOTIFICATIONS_REJECTED:
      return state
        .set("fetched", false)
        .set("fetching", false)
        .set("error", action.error);
    case A.SEND_NOTIFICATION_PENDING:
      return state
        .set("sendNotificationFetched", false)
        .set("sendNotificationFetching", true);
    case A.SEND_NOTIFICATION_FULFILLED:
      return state
        .set("sendNotificationFetched", true)
        .set("sendNotificationFetching", false);
    case A.SEND_NOTIFICATION_REJECTED:
      return state
        .set("sendNotificationFetched", false)
        .set("sendNotificationFetching", false)
        .set("error", action.error);
    default:
      return state;
  }
}
