export const actions = {
  SET_SIDEBAR_EXPAND: "SET_SIDEBAR_EXPAND",
};

export function setSidebarExpand(expand) {
  return async (dispatch) => {
    localStorage.setItem("sidebar-expanded", expand);
    dispatch({
      type: actions.SET_SIDEBAR_EXPAND,
      payload: expand,
    });
  };
}
