import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStory } from "../../redux/actions/storiesActions";
import backgr from "../../images/WholeBackground.png";
import { withRouter } from "react-router-dom";
import { v1 as uuid } from "uuid";

const CreateNewStory = ({
  children,
  createStory,
  idToken,
  lastCreatedStoryId,
  createStoryFetching,
  history,
  className = ``,
  image = null,
  isVideo = false,
}) => {
  const [state, setstate] = useState(false);

  useEffect(() => {
    if (state && lastCreatedStoryId) {
      const url = image ? `?fromDiscover=true` : "";
      try {
        localStorage.setItem("discoverUrl", image);
        localStorage.setItem("isVideo", isVideo);
      } catch (e) {}
      history.push(`/create-stories/${lastCreatedStoryId}${url}`);
      setstate(false);
    }

    // eslint-disable-next-line
  }, [state, lastCreatedStoryId]);

  const initNewStory = () => {
    if (!createStoryFetching) {
      let offsetTop =
        window.innerHeight < 800
          ? Math.round(80 + 16 + window.innerHeight / 20)
          : 180;
      let cardH = window.innerHeight - 50 - offsetTop;
      let cardW = Math.round((cardH * 414) / 736);
      createStory({
        authToken: idToken,
        storyData: {
          cards: [
            {
              _id: uuid(),
              _index: 0,
              __new: true,
              backgroundColor: `url(${backgr})`,
              texts: [],
              products: [],
            },
          ],
          screenSize:
            cardH > 736
              ? {
                  width: 414,
                  height: 736,
                }
              : {
                  width: cardW,
                  height: cardH,
                },
          storyBarId: null,
        },
      }).then(() => {
        setstate(true);
      }).catch(() => {
        setstate(false);
      })
    }
  };

  return (
    <div onClick={initNewStory} className={className}>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;
  const stories = state.stories.toJS();
  let { createStoryFetched, createStoryFetching, lastCreatedStoryId } = stories;

  return {
    idToken,
    createStoryFetched,
    createStoryFetching,
    lastCreatedStoryId,
  };
};

const mapDispatchToProps = {
  createStory,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateNewStory)
);
