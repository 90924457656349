import React from "react";
import "../../scss/components/AccountCard.scss";

const AccountCard = ({ title, content }) => (
  <div className="account-card">
    <div className="account-card__header">
      <p>{title}</p>
    </div>
    <div className="account-card__content">{content}</div>
  </div>
);

export default AccountCard;
