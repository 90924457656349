import React, { useState, useRef, useEffect } from "react";
import autosize from "autosize";

const ChatMessage = ({ name, text, time, sendMessage, id, editValue}) => {
  return (
    <div className="chat__message">
      <div className="chat__name">
        {name}
        <div className="icons">
          <svg onClick={() => sendMessage('', 'remove', id)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="rgba(0, 0, 0, 0.65)">
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
          </svg>
          <svg onClick={() => editValue({text, id})} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="rgba(0, 0, 0, 0.65)">
            <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
          </svg>
        </div>
      </div>
      <div className="chat__text">{text}</div>
      <div className="chat__time">{time}</div>
    </div>
  );
};

const Chat = ({
  messages,
  sendMessage,
  name,
  setBarOpen,
  isMuted,
  setIsMuted,
  org,
  minimized,
  mute,
  unmute,
  setMicro,
}) => {
  useEffect(() => {
    const chat = document.getElementById("chat__container");
    if (chat.scrollHeight - chat.scrollTop - chat.offsetHeight < 200)
      chat.scrollTop = chat.scrollHeight;
    //autosize.update(ref.current);
  }, [messages]);

  const [text, setText] = useState("");
  const [isChat, setIsChat] = useState(true);
  const [status, setStatus] = useState({
    status: 'add',
    id: null
  });

  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      autosize.update(ref.current);
    }, 300);
  }, [minimized]);

  useEffect(() => {
    autosize.update(ref.current);
  }, [text]);

  useEffect(() => {
    if (ref.current) {
      autosize(ref.current);
    }
  }, [ref]);

  const editValue = ({text, id}) => {
    console.log(text)
    console.log(id)
    setStatus( {
      status: 'edit',
      id
    })
    setText(text)
  }
  return (
    <div className="chat__wrapper">
      <div className="arrow-hide-chat">
        <p className="arrow-hide-chat__line"></p>
        <div className="chat-button" onClick={() => setIsChat((c) => !c)}>
        Live Chat
          <div className={`chat-icon${isChat ? "" : " closed"}`}>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00021 2.33983L1.52709 6.08212C1.27002 6.35889 0.854399 6.35884 0.600136 6.08201C0.343139 5.80517 0.345928 5.35758 0.600259 5.08081L4.53555 0.840917C4.78441 0.57298 5.18363 0.56714 5.44063 0.820416L9.40222 5.07895C9.53071 5.21737 9.59358 5.39994 9.59356 5.57957C9.59354 5.7592 9.53062 5.94176 9.40209 6.08015C9.14503 6.35692 8.7294 6.35687 8.47514 6.08003L5.00021 2.33983Z"
                fill="#F351A9"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="chat__input">

        <div className={`chat__wrap${!isChat ? " closed" : ""}`}>
          <div className="chat__container" id="chat__container">
            {messages && messages.length > 0
              ? messages.map((e) => {
                  return <ChatMessage {...e} sendMessage={sendMessage} editValue={editValue}/>;
                })
              : null}
          </div>
        </div>

        <div className='wrapper-chat-input'>
          <div className="chat__middle">
            <div className="chat__logo">
              {/* <div className="chat__logo-name">{org}</div> */}
              <div className="chat__logo-powered">
                Powered by
                <div className="chat__logo-radd">
                  <img
                    src="https://raddcontent.com/media/Radd%20Logo_NEW_radd%20logo.svg"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="custom__input">
            <div className="texarea__wrapper">
              <textarea
                className="input__custom"
                placeholder={name ? `Type your message ...` : `Type your name`}
                ref={ref}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                onKeyPress={(e) => {
                  let value = e.target.value || text;
                  if (text) {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      sendMessage(value, status.status, status.id);
                      setStatus({
                        id: null,
                        status: 'add'
                      })
                      setText("");
                    }
                  }
                }}
              />
            </div>
            <div
              className="send__button"
              onClick={() => {
                if (text) {
                  sendMessage(text, status.status, status.id);
                  setStatus({
                    id: null,
                    status: 'add'
                  })
                  setText("");
                }
              }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.193 8.60782L19.1864 8.60445L1.38971 0.0878377C1.08566 -0.0589922 0.738042 -0.0199861 0.46421 0.191687C0.174873 0.41043 0.000393467 0.782509 2.28287e-07 1.18162V6.62843C0.000163496 7.18249 0.34163 7.65821 0.81341 7.76164L10.5197 9.83237C10.5984 9.84958 10.6554 9.92892 10.6554 10.0213C10.6554 10.1137 10.5984 10.1931 10.5197 10.2103L0.813826 12.281C0.342189 12.3842 0.000601491 12.8594 2.28287e-07 13.4133V18.861C-0.000224572 19.2421 0.165588 19.5978 0.441291 19.8077C0.604556 19.9328 0.79616 19.9997 0.992176 20C1.12845 19.9999 1.26332 19.9683 1.38888 19.9072L19.1851 11.4392L19.193 11.4348C19.6827 11.192 20 10.6362 20 10.0213C20 9.40644 19.6827 8.8506 19.193 8.60782Z" fill="#F351C6"/>
                </svg>
            </div>
          </div>
        </div>

        {/* <div className="input__buttons"> */}
          {/* <div className="input-icon__wrapper">
            <svg
              width="11"
              height="3"
              viewBox="0 0 11 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="white" />
              <circle cx="5.5" cy="1.5" r="1.5" fill="white" />
              <circle cx="9.5" cy="1.5" r="1.5" fill="white" />
            </svg>
          </div> */}
          {/* <div
            className="input-icon__wrapper"
            onClick={() => {
              setMicro(isMuted);
              setIsMuted((m) => !m);
            }}
          >
            {!isMuted ? (
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                id="mute-btn"
              >
                <path
                  d="M1.75 8.75123C1.75 7.96819 2.38478 7.33341 3.16781 7.33341H5.60943C5.94248 7.33341 6.26489 7.21617 6.52014 7.00225L9.33574 4.64251C9.7971 4.25585 10.5 4.58387 10.5 5.18583V16.8143C10.5 17.4163 9.7971 17.7443 9.33574 17.3577L6.52014 14.9979C6.26489 14.784 5.94248 14.6667 5.60943 14.6667H3.16781C2.38478 14.6667 1.75 14.032 1.75 13.2489V8.75123Z"
                  fill="white"
                />
                <path
                  d="M13.125 7.33325C13.125 7.33325 14.4375 8.70825 14.4375 10.9999C14.4375 13.2916 13.125 14.6666 13.125 14.6666"
                  stroke="white"
                  stroke-width="1.27603"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.75 3.66675C15.75 3.66675 18.55 6.41675 18.55 11.0001C18.55 15.5834 15.75 18.3334 15.75 18.3334"
                  stroke="white"
                  stroke-width="1.27603"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="21"
                height="20"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: 2 }}
                id="unmute-btn"
              >
                <path
                  d="M3 5.08448C3 4.30144 3.63478 3.66667 4.41781 3.66667H6.85943C7.19248 3.66667 7.51489 3.54943 7.77014 3.3355L10.5857 0.975763C11.0471 0.589097 11.75 0.917118 11.75 1.51909V13.1476C11.75 13.7495 11.0471 14.0776 10.5857 13.6909L7.77014 11.3312C7.51489 11.1172 7.19248 11 6.85943 11H4.41781C3.63478 11 3 10.3652 3 9.58219V5.08448Z"
                  fill="white"
                />
                <path
                  d="M3 14L16 1"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 18L17 2"
                  stroke="#313232"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div> */}
          {/* <div className="input-icon__wrapper" onClick={() => setBarOpen(true)}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="19" height="19" fill="url(#pattern1)" />
              <defs>
                <pattern
                  id="pattern1"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use xlinkHref="#image1" transform="scale(0.02)" />
                </pattern>
                <image
                  id="image1"
                  width="50"
                  height="50"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAC4ElEQVRoge2Zv2sUQRTHv5MzIhixEqOpLTTpBTsN2lgkSCytJTEiVmdln3RiJZjOwr9BQcHOlEkwkrPQQrAQCYQUJ5iPxb6Nu5fdvd3bmeUO84Uhc2/f/Pjw3szOTqRj/YcCzgFrwDdgn3rqAuvA7aYhJoFPNSefp/mmIKaAnUAQAB/jsVxICEnvJV0KNYakrnPulCSNhegdmJT0VmEhJOlkXPEOYhDvJF323XeRvIJYOn1QPYjfktqSpqw8MVuW/C8Njwu7ndF3O8/ZN4TPLfZCTv9ZOoh9aqcW/tdEVrq0+jWqBYKfNdGreyVtko81QriXXZdoTVy00jZbYWoNRBQgnQYVzrkxaYDUGiKIlCqBDCuEVAFkmCGkkiDDDiGVABkFCEk6UfSQZo7iXpQbEZo7itfRflzJjMgIReJXXDkSkRGCkKQ3cSX1Zh8xiI6ka865n1IiIsB5jRbE9RhCSqfWc40WxPek8TC1gF1JZ5ueVUVlQkjpiPxobj4D6YtyIKQ0yFNJfr+B/Wpc0tVSnsAj4CDAx1IZbdj408BpK9Nm20z4rQL9z4g0D9MFloomB7SAB/z7UlwZtsh0gRulJhXNazYBU+4CG7jfAMxizthA9p0VsGyPO8B4WZiQkdkgJ536gLSALXNZiO2Fi8Y590zSosLsZi+dcwf93dJyzv2RtGY/q/1/JFBkrhSMlxsRez5jLp8rgVhj32tmonfi/ZTwP2OmvdhW+vLBOfdCftOszi1h3LZyah4Kf2nmNbUqX9B53ABu1mh7y/6u15yDl8hsApm37EURIWf7rQszyAbQAb5afWkAkIeJfsq9EAPA7BDd4t+x311gtsJY1Y8oAWB2iO4E4jarCZhlctLMfFsWiWqHxgAwKQjzH0vAQJT3j4l2pAkrM2bbSvitUOYYHwDmCERPm3mifO+nDjAXFKBnYneBbWAXeE10O9OvzTiwALyytntWts22gM+FfawG9BfABesc32Li/gAAAABJRU5ErkJggg=="
                />
              </defs>
            </svg>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Chat;
