import React, { useEffect, useState } from "react";
import { PageView } from "../../components/containers";
import { Transition } from "../../components/common";
import DiscoverPage from "./DiscoverPage";
import DiscoverPageDefault from "./DiscoverPageDefault";
import {
  getDiscoverList,
  getCustomerStoriesList,
} from "../../redux/actions/storiesActions";
import { connect } from "react-redux";

const fakeData = [
  {
    id: 1,
    title: `Stories`,
    data: [
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
    ],
  },
  {
    id: 2,
    title: `Stories`,
    data: [
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
      {
        date: "April 23, 2020",
        image: "",
        media: "",
        mediaType: "video",
        platform: "instagram",
      },
    ],
  },
];

const fakeData2 = [
  {
    cards: [
      {
        snapshot: "",
        backgroundMedia: { thumb: "" },
      },
    ],
    createdAt: "2020-04-17T09:39:28.336Z",
    id: "55063412342340-808f-11ea-ae73-sdff2345be3688290cf",
    owner: "google-oauth2|104505801059652296797",
    screenSize: { width: 414, height: 736 },
    storyBarId: null,
    title: "Brown",
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
];

const Discover = ({
  discover,
  customerData,
  idToken,
  discoverFetched,
  getCustomerStoriesList,
  customerFetched,
  discoverFetching,
  getDiscoverList,
  customerFetching,
  deleteStoryFetching,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    try {
      window.FB.getLoginStatus(function (response) {
        getCustomerStoriesList({ authToken: idToken });
        if (response.status === "connected") {
          localStorage.setItem("fb_token", response.authResponse.accessToken);
          getDiscoverList();
        } else {
          setLoaded(true);
        }
      });
    } catch (e) {}
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timeout = null;
    if (!discoverFetching && discoverFetched) {
      if (discover && discover.length !== 0) {
        discover.forEach((e) => {
          new Image().src = e.image;
        });
      }
      timeout = setTimeout(() => {
        setLoaded(true);
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [discoverFetched, discoverFetching, discover]);

  return customerFetched && loaded ? (
    <Transition
      transition={500}
      withbutton={
        !(discover && discover.length !== 0) &&
        !(customerData && customerData.length !== 0)
      }
    >
      <PageView
        defaultView={<DiscoverPageDefault getDiscoverList={getDiscoverList} />}
        isShow={
          (discover && discover.length !== 0) ||
          (customerData && customerData.length !== 0)
        }
      >
        <DiscoverPage
          data={discover}
          customerData={customerData}
          fetching={customerFetching || discoverFetching || deleteStoryFetching}
        />
      </PageView>
    </Transition>
  ) : (
    <DiscoverPage data={fakeData} customerData={fakeData2} loading />
  );
};

const mapStateToProps = (state) => {
  const stories = state.stories.toJS();
  let {
    discover,
    discoverFetched,
    discoverFetching,
    customerData,
    customerFetching,
    customerFetched,
    deleteStoryFetching,
  } = stories;

  const auth = state.auth.toJS();
  let { idToken } = auth;

  return {
    discover,
    idToken,
    customerData,
    discoverFetched,
    discoverFetching,
    customerFetching,
    deleteStoryFetching,
    customerFetched,
  };
};

const mapDispatchToProps = {
  getDiscoverList,
  getCustomerStoriesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
