import Immutable, { List, Iterable } from "immutable";
import _ from "lodash";

import { actions as storiesActions } from "../actions/storiesActions";

const A = Object.assign(
  {},
  {
    STORY_PENDING: "STORY_PENDING",
    STORY_FULFILLED: "STORY_FULFILLED",
    STORY_REJECTED: "STORY_REJECTED",
    RESET_STORY_DATA: "RESET_STORY_DATA",
    ADD_STORY_DELTA: "ADD_STORY_DELTA",
    SEND_STORY_DELTA_REJECTED: "SEND_STORY_DELTA_REJECTED",
    SET_STORY_TITLE: "SET_STORY_TITLE",
    SET_CARD_BACKGROUND_IMAGE: "SET_CARD_BACKGROUND_IMAGE",
    REMOVE_CARD: "REMOVE_CARD",
    ADD_CARD: "ADD_CARD",
    SET_ACTIVE_CARD: "SET_ACTIVE_CARD",
    CARDS_REORDER: "CARDS_REORDER",
    SET_TEXT_PARAM: "SET_TEXT_PARAM",
    CREATE_TEXT: "CREATE_TEXT",
    SET_STORY_DELTA_RAW: "SET_STORY_DELTA_RAW",
    UPLOAD_CARD_BACKGROUND_PENDING: "UPLOAD_CARD_BACKGROUND_PENDING",
    UPLOAD_CARD_BACKGROUND_FULFILLED: "UPLOAD_CARD_BACKGROUND_FULFILLED",
    UPLOAD_CARD_BACKGROUND_REJECTED: "UPLOAD_CARD_BACKGROUND_REJECTED",
    UPLOAD_CARD_SNAPSHOT_PENDING: "UPLOAD_CARD_SNAPSHOT_PENDING",
    UPLOAD_CARD_SNAPSHOT_FULFILLED: "UPLOAD_CARD_SNAPSHOT_FULFILLED",
    UPLOAD_CARD_SNAPSHOT_REJECTED: "UPLOAD_CARD_SNAPSHOT_REJECTED",
    SET_CARD_EMBED_DATA: "SET_CARD_EMBED_DATA",
    IS_VALID_INSTAGRAM: "IS_VALID_INSTAGRAM",
    REMOVE_LOCAL_CARD: "REMOVE_LOCAL_CARD",
    GET_PRODUCT_DATA: "GET_PRODUCT_DATA",
    GET_PRODUCT_DATA_SUCCESS: "GET_PRODUCT_DATA_SUCCESS",
    GET_PRODUCT_DATA_FAILURE: "GET_PRODUCT_DATA_FAILURE",
    RESET_PRODUCT_DATA: "RESET_PRODUCT_DATA",
    ADD_CUSTOMER_DATA: "ADD_CUSTOMER_DATA",
    ADD_CUSTOMER_DATA_SUCCES: "ADD_CUSTOMER_DATA_SUCCES",
  },
  storiesActions
);

const initialState = Immutable.fromJS({
  fetched: false,
  fetching: false,
  storyData: {},
  error: null,
  storyDelta: new Immutable.Map(),
  activeCardInd: 0,
  uploadCardBackgroundFetched: false,
  uploadCardBackgroundFetching: false,
  isValidInstagram: false,
  addProductData: null,
  addProductDataFetched: false,
  addProductDataFetching: false,
  addCustomerFetching: false,
  addCustomerFetched: false,
});

function mergeMaps(obj1, obj2, applyDelta = false) {
  if (obj1 == null) {
    return obj2;
  }
  if (obj2 === undefined) {
    return obj1;
  }

  if (obj2 === null) {
    return obj2;
  }

  obj2.mapEntries(([key, value]) => {
    if (!Iterable.isIterable(value)) {
      obj1 = obj1.set(key, value);
    } else {
      if (List.isList(value)) {
        obj1 = obj1.update(key, (oldValue) =>
          mergeLists(oldValue, value, applyDelta)
        );
      } else {
        obj1 = obj1.update(key, (oldValue) =>
          mergeMaps(oldValue, value, applyDelta)
        );
      }
    }
  });

  return obj1;
}

function mergeLists(arr1, arr2, applyDelta = false) {
  if (!Iterable.isIterable(arr1)) {
    arr1 = new List();
  }

  if (Iterable.isIterable(arr2)) {
    arr2.forEach((item) => {
      let arr1_itemInd;

      if (item.has("_id")) {
        if (!applyDelta && item.get("_action") === "delete") {
          // if we operate on delta object - always add deletion actions separately
          arr1_itemInd = -1;
        } else {
          arr1_itemInd = arr1.findIndex(
            (arr1_item) => arr1_item.get("_id") === item.get("_id")
          );
        }

        if (arr1_itemInd !== -1) {
          if (!Iterable.isIterable(item)) {
            arr1 = arr1.set(arr1_itemInd, item);
          } else if (Array.isArray(item)) {
            arr1 = arr1.update(arr1_itemInd, (oldItem) =>
              mergeLists(oldItem, item, applyDelta)
            );
          } else {
            arr1 = arr1.update(arr1_itemInd, (oldItem) =>
              mergeMaps(oldItem, item, applyDelta)
            );
          }
        } else {
          arr1_itemInd = arr1.count();
          arr1 = arr1.push(item);
        }
      } else {
        arr1_itemInd = arr1.count();
        arr1 = arr1.push(item);
      }

      item = arr1.get(arr1_itemInd);

      if (applyDelta && typeof item.get("_index") === "number") {
        // if we operate on cards object - change item index if it has _index directive
        let index = item.get("_index");

        item = item.remove("_index");

        arr1 = arr1.remove(arr1_itemInd).insert(index, item);

        /*if (index < arr1_itemInd) {
                    arr1 = arr1.remove(arr1_itemInd).insert(index, item);
                }
                else if (index > arr1_itemInd) {
                    arr1 = arr1.insert(index, item).remove(arr1_itemInd);
                }*/
        arr1_itemInd = index;
      }

      if (applyDelta && item.get("_action") === "delete") {
        // if we operate on cards object - remove items that have remove action
        arr1 = arr1.remove(arr1_itemInd);
      }
    });
  }

  return arr1;
}

export default function reducer(state = initialState, action) {
  let data, params, newState;

  switch (action.type) {
    case A.ADD_CUSTOMER_DATA:
      return state.withMutations((state) =>
        state.set("addCustomerFetching", true).set("addCustomerFetched", false)
      );
    case A.ADD_CUSTOMER_DATA_SUCCES:
      return state.withMutations((state) =>
        state.set("addCustomerFetching", false).set("addCustomerFetched", true)
      );
    case A.GET_PRODUCT_DATA_SUCCESS:
      return state.withMutations((state) =>
        state
          .set("addProductData", Immutable.fromJS(action.data))
          .set("addProductDataFetched", true)
          .set("addProductDataFetching", false)
      );
    case A.GET_PRODUCT_DATA:
      return state.withMutations((state) =>
        state
          .set("addProductData", null)
          .set("addProductDataFetched", false)
          .set("addProductDataFetching", true)
      );
    case A.GET_PRODUCT_DATA_FAILURE:
      return state.withMutations((state) =>
        state
          .set("addProductData", null)
          .set("addProductDataFetched", true)
          .set("addProductDataFetching", false)
      );
    case A.RESET_PRODUCT_DATA:
      return state.withMutations((state) =>
        state
          .set("addProductData", null)
          .set("addProductDataFetched", false)
          .set("addProductDataFetching", false)
      );
    case A.STORY_PENDING:
      return state.withMutations((state) =>
        state.set("fetched", false).set("fetching", true)
      );
    case A.STORY_FULFILLED:
      return state.withMutations((state) =>
        state
          .set("fetched", true)
          .set("fetching", false)
          .set("storyData", Immutable.fromJS(action.data))
      );
    case A.STORY_REJECTED:
      return state.withMutations((state) =>
        state
          .set("fetched", false)
          .set("fetching", false)
          .set("error", action.error)
      );
    case A.ADD_STORY_DELTA:
      data = Immutable.fromJS(action.data);
      params = action.params;
      newState = state.update("storyData", _.partial(mergeMaps, _, data, true));

      if (!params.noDelta) {
        newState = newState.update("storyDelta", _.partial(mergeMaps, _, data));
      }

      return newState;
    case A.REMOVE_LOCAL_CARD:
      data = Immutable.fromJS(action.data);
      newState = state.update("storyData", _.partial(mergeMaps, _, data, true));

      return newState;
    case A.CARDS_REORDER:
      data = Immutable.fromJS(action.data);
      params = action.params;
      //let itemmm = Immutable.fromJS({ cards: data.get("cards") });
      newState = state.set("storyData", data);
      //newState = newState.set("storyDelta", itemmm);

      return newState;
    case A.RESET_STORY_DATA:
      return initialState;
    case A.SET_ACTIVE_CARD:
      return state.set("activeCardInd", action.data);
    case A.SET_STORY_DELTA_RAW:
      return state.set("storyDelta", Immutable.fromJS(action.data));
    case A.SEND_STORY_DELTA_REJECTED:
      return state.set("error", action.error);
    case A.UPLOAD_CARD_BACKGROUND_PENDING:
      return state
        .set("uploadCardBackgroundFetched", false)
        .set("uploadCardBackgroundFetching", true);
    case A.UPLOAD_CARD_BACKGROUND_FULFILLED:
      return state
        .set("uploadCardBackgroundFetched", true)
        .set("uploadCardBackgroundFetching", false);
    case A.UPLOAD_CARD_BACKGROUND_REJECTED:
      return state
        .set("uploadCardBackgroundFetched", false)
        .set("uploadCardBackgroundFetching", false)
        .set("error", action.error);
    default:
      return state;
  }
}
