import React from "react";
import { StoryPage } from "../../icons/icons";
import CreateStory from "../../components/hoc/createStory";
import "../../scss/pages/StoriesPage.scss";

const StoriesPageDefault = () => {
  return (
    <div className="stories__page page withbutton">
      <div className="page__title">You have no stories yet</div>
      <div className="page__subtitle">
        All your stories and highlight will be displayed here, you can sort them
        as you like
      </div>
      <div className="page__subtitle mobile__sub">
        Stories and highlight will be displayed here, you can sort them as you
        like
      </div>
      <div className="page__image">
        <StoryPage />
      </div>
      <div className="page__button">
        <CreateStory>
          <div className="accent__button">Create Stories</div>
        </CreateStory>
      </div>
    </div>
  );
};

export default StoriesPageDefault;
