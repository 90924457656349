import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js";
import {
  Unique,
  Views,
  Clicks,
  Purchases,
  Calendar,
  DropdownArrow,
} from "../../icons/icons";
import { Dropdown } from "./";
import "../../scss/components/AnalyticChart.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
Chart.defaults.global.defaultFontFamily = "Lato";
Chart.defaults.global.defaultFontColor = "#20292C";
Chart.defaults.global.defaultFontSize = 16;
const days = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "San",
];

const colors = ["#A251F3", "#F3ED51", "#51F375", "#5192F3"];
const colorsDark = ["#C0C4C5", "#C0C4C5", "#C0C4C5", "#C0C4C5"];

const names = [
  "Unique visitors to page",
  "Story views",
  "Story clicks",
  "Story purchases",
];

//Get total count and format in to 10,100
const getTotalInFormat = (data) => {
  return data
    .reduce(function (a, b) {
      return a + b;
    }, 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getIcon = (num, active) => {
  switch (num) {
    case 0:
      return <Unique color={active === 0 ? "#5192f3" : "#777F81"} />;
    case 1:
      return <Views color={active === 1 ? "#5192f3" : "#777F81"} />;
    case 2:
      return <Clicks color={active === 2 ? "#5192f3" : "#777F81"} />;
    case 3:
      return <Purchases color={active === 3 ? "#5192f3" : "#777F81"} />;
    default:
      return null;
  }
};

const AnalyticChart = ({
  data,
  title,
  getAnalytic,
  setAnalytics,
  analytic,
}) => {
  const [colors2, setColors] = useState([
    "#A251F3",
    "#F3ED51",
    "#51F375",
    "#5192F3",
  ]);
  const [active, setActive] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });
  const ctx = useRef(null);
  const date = useRef(null);
  const dateTrigger = useRef(null);

  const setAnalytic = (val) => {
    setDropOpen(false);
    setAnalytics(val);
  };

  const setActiveLine = (index) => {
    if (index === active) {
      setActive(null);
      setColors(colors);
    } else {
      const cl = [...colorsDark];
      cl[index] = colors[index];
      setActive(index);
      setColors(cl);
    }
  };

  const handleClickOutside = (e) => {
    if (date.current && !date.current.contains(e.target)) {
      if (!dateTrigger.current || !dateTrigger.current.contains(e.target)) {
        setDateOpen(false);
      }
    }
  };

  //On mount add events which handle click outside context block or button
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  useEffect(() => {
    let range = [0, 10, 100, 500, 1000];
    const datamax = Math.max.apply(null, data.flat());

    if (datamax > 1000 && datamax < 10000) {
      range = [0, 10, 100, 1000, Math.ceil(datamax / 1000) * 1000];
    } else if (datamax >= 10000 && datamax < 100000) {
      range = [0, 100, 1000, 10000, Math.ceil(datamax / 1000) * 10000];
    } else if (datamax > 100000 && datamax < 1000000) {
      range = [0, 100, 1000, 10000, 100000, Math.ceil(datamax / 1000) * 100000];
    } else if (datamax > 1000000) {
      range = [
        0,
        100,
        1000,
        10000,
        100000,
        1000000,
        Math.ceil(datamax / 1000) * 1000000,
      ];
    }

    const dataset = data.map((e, i) => ({
      data: e,
      label: names[i],
      borderColor: colors2[i],
      fill: false,
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
    }));

    const d = new Date(ranges.startDate);
    const n = d.getDay();

    const labelsDay = days.slice(n - 1, n + data[0].length - 1);

    new Chart(ctx.current, {
      type: "line",
      data: {
        labels: labelsDay,
        datasets: dataset,
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
          },
        },
        elements: {
          point: {
            radius: 5,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                zeroLineColor: "rgba(0, 0, 0, 0)",
                color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                padding: 20,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                zeroLineColor: "#E7E9EA",
                drawBorder: false,
              },
              type: "logarithmic",
              position: "left",
              ticks: {
                padding: 20,
                min: 0,
                callback: function (value, index, values) {
                  return Number(value.toString())
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","); //pass tick values as a string into Number function
                },
              },
              afterBuildTicks: function (chartObj) {
                //Build ticks labelling as per your need
                chartObj.ticks = range;
              },
            },
          ],
        },
      },
    });
    // eslint-disable-next-line
  }, [data, colors2]);

  return (
    <>
      {/*<div className="chart__title phh">{title}</div>*/}
      <div className="chart__container">
        <div className="chart__header phh">
          <div className="chart__type">
            <Dropdown
              button={
                <div className="chart__item">
                  {analytic} <DropdownArrow />
                </div>
              }
              isOpen={dropOpen}
              setIsOpen={setDropOpen}
              className="setting"
              noOver
              toRight
              dropdown={
                <div className="setting__block">
                  <div className="setting__part">
                    <p
                      className="setting__link"
                      onClick={() => setAnalytic("All activity")}
                    >
                      All activity
                    </p>
                    <p
                      className="setting__link"
                      onClick={() => setAnalytic("Mobile")}
                    >
                      Mobile
                    </p>
                    <p
                      className="setting__link"
                      onClick={() => setAnalytic("Desktop")}
                    >
                      Desktop
                    </p>
                  </div>
                </div>
              }
            />
          </div>

          <div
            className="chart__item"
            ref={dateTrigger}
            onClick={() => setDateOpen(!dateOpen)}
          >
            Choose date <Calendar />
          </div>
          {dateOpen && (
            <div ref={date} className="calendarselect">
              <DateRangePicker
                ranges={[ranges]}
                onChange={(ranges) => {
                  console.log(ranges);
                  if (
                    ranges.selection.startDate.toDateString() !==
                    ranges.selection.endDate.toDateString()
                  ) {
                    getAnalytic(
                      ranges.selection.startDate,
                      ranges.selection.endDate
                    );
                  }
                  setRanges({
                    startDate: ranges.selection.startDate,
                    endDate: ranges.selection.endDate,
                    key: "selection",
                  });
                }}
                minDate={new Date(Date.now() - 57 * 24 * 60 * 60 * 1000)}
                maxDate={new Date()}
              />
            </div>
          )}
        </div>
        <div className="chart__chart phh">
          <canvas ref={ctx} className="chart__stat" />
        </div>
        <div className="chart__legend">
          {names.map((e, i) => {
            return (
              <div className="legend__item phh" key={i}>
                <span style={{ backgroundColor: colors2[i] }} />
                {e}
              </div>
            );
          })}
        </div>
        <div className="stat__aside">
          {data.map((e, i) => (
            <div
              key={i}
              className={`statistic__card phh${
                active === i ? " active" : active !== null ? " disabled" : ""
              }`}
              onClick={() => setActiveLine(i)}
            >
              <div className="stat__value">
                {getTotalInFormat(e)}
                <span className="small__stat">
                  {!!i
                    ? `(${(
                        (100 * getTotalInFormat(e)) /
                          getTotalInFormat(data[i !== 3 ? i - 1 : 1]) || 0
                      ).toFixed(1)}%)`
                    : ``}
                </span>
              </div>
              <div className="stat__name">{names[i]}</div>
              <div className="stat__icon">{getIcon(i, active)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AnalyticChart;
