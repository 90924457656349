import React, { useState, useEffect } from "react";
import LiveProduct from "./LiveProduct";
import classnames from "classnames";
import { editLiveStream } from "../../redux/actions/livesActions";
import { connect } from "react-redux";

const BackArrow = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.51176 7L7.47659 2.03906C7.84378 1.67188 7.84378 1.07813 7.47659 0.714846C7.10941 0.347658 6.51566 0.351564 6.14847 0.714846L0.523482 6.33594C0.168014 6.69141 0.160202 7.26172 0.496139 7.62891L6.14457 13.2891C6.32816 13.4727 6.57035 13.5625 6.80863 13.5625C7.04691 13.5625 7.28909 13.4727 7.47269 13.2891C7.83987 12.9219 7.83987 12.3281 7.47269 11.9648L2.51176 7Z"
      fill="#0BB6EC"
    />
  </svg>
);

const LiveBar = ({
  products,
  title,
  avatar,
  name,
  isOpen,
  setOpen,
  prod,
  storyId,
  barId,
  cardId,
  showPrice,
  setMinimized,
  editLiveStream,
  idToken,
  socket,
  switchProductFunc,
}) => {
  const [active, setActive] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [top, setTop] = useState(260);
  const [shadow, setShadow] = useState({ top: false, bottom: true });
  const [isProd, setIsProd] = useState(false);
  const [isNewScroll, setIsNewScroll] = useState(true);
  const [show, setShow] = useState(false);
  const [arrId, setArrId] = useState([]);

  useEffect(() => {
    const ids = [];
    if (Array.isArray(products) && products.length !== 0) {
      products.map(({ productId, isShow }) => {
        if (isShow) {
          ids.push(productId);
        }
        return ids;
      });
    }
    setArrId(ids);
  }, [products]);

  const addIdToArray = async (checked, id) => {
    if (checked) {
      setArrId((prev) => {
        return [...prev, id];
      });
      await editStream(id, true);
    } else {
      setArrId((prev) => prev.filter((el) => el !== id));
      await editStream(id, false);
    }
  };

  const editStream = async (id, value) => {
    const newProducts = products.map((el) => {
      if (el.productId === id) {
        el.isShow = value;
      }
      return el;
    });

    await editLiveStream({
      authToken: idToken,
      liveData: {
        products: newProducts,
      },
      liveId: storyId,
    });
  };

  useEffect(() => {
    if (isOpen) {
      setShow(true);
    }
  }, [isOpen]);

  const handleDragStart = (e, isTouch = true) => {
    e.stopPropagation();
    const startY = e.touches[0].pageY;
    let currtop = top;

    const handleMove = (e) => {
      e.stopPropagation();
      currtop = e.touches[0].pageY - 10;
      setIsDrag(true);
      setTop(e.touches[0].pageY - 10);
    };

    const handleUp = () => {
      if (currtop < startY - 50) {
        setActive(true);
      }
      if (currtop > startY + 50) {
        setTimeout(() => {
          setOpen(false);
          setActive(false);
          setIsProd(false);
          setTimeout(() => {
            setShow(false);
          }, 400);
        }, 1);
      }
      setIsDrag(false);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleUp);
    };

    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handleUp);
  };

  return (
    <div
      className={classnames("pb__overlay", { opened: show })}
      onClick={() => {
        setOpen(false);
        setActive(false);
        setTimeout(() => {
          setShow(false);
        }, 400);
      }}
    >
      <div
        className={classnames("product__bar live__bar", {
          opened: isOpen,
          active,
          closed: !isOpen,
        })}
        style={
          isDrag
            ? {
                top,
                transition: `none`,
              }
            : {}
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={classnames("product__header", { over: shadow.top })}>
          {!(!isProd && window.innerWidth < 480) ? (
            <div
              className="back__button"
              onClick={(e) => {
                e.stopPropagation();
                if (isProd) {
                  setIsProd(false);
                } else {
                  setOpen(false);
                  setActive(false);
                  setTimeout(() => {
                    setShow(false);
                  }, 400);
                }
              }}
            >
              <BackArrow /> <span>Back</span>
            </div>
          ) : (
            <div></div>
          )}
          <div className="scrolldown mobile" onTouchStart={handleDragStart}>
            <div className="scrolldown__item" />
          </div>
          {/* {!isProd && products.length > 1 && (
            <div
              className="button__primary small__button"
              onClick={() => {
                products.forEach((element) => {
                  window.open(element.url);
                });
              }}
            >
              Open all products
            </div>
          )} */}
        </div>
        <div
          className="product__container"
          id="product__container"
          onWheel={(e) => {
            if (e.deltaY < 0) {
              if (
                (active && isNewScroll) ||
                e.target.scrollHeight - e.target.scrollTop <=
                  e.target.clientHeight
              ) {
                setActive(false);
              }
            }
          }}
          onScroll={(e) => {
            if (!active) {
              setActive(true);
              e.stopPropagation();
              setIsNewScroll(false);
            }
            if (e.target.scrollTop !== 0) {
              setShadow((prev) => ({ ...prev, top: true }));
            } else {
              setTimeout(() => {
                setIsNewScroll(true);
              }, 300);
              setShadow((prev) => ({ ...prev, top: false }));
            }
            if (
              e.target.scrollHeight - e.target.scrollTop <=
              e.target.clientHeight
            ) {
              setShadow((prev) => ({ ...prev, bottom: false }));
            } else {
              setShadow((prev) => ({ ...prev, bottom: true }));
            }
          }}
        >
          <div className="live__grid">
            {products
              ? products.map((e, i) => {
                  return (
                    <LiveProduct
                      key={i}
                      {...e}
                      storyId={storyId}
                      barId={barId}
                      cardId={cardId}
                      setMinimized={setMinimized}
                      arrId={arrId}
                      isShow={e.isShow}
                      id={e.productId}
                      socket={socket}
                      addIdToArray={addIdToArray}
                      switchProductFunc={switchProductFunc}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;

  return { idToken };
};

const mapDispatchToProps = { editLiveStream };

export default connect(mapStateToProps, mapDispatchToProps, null)(LiveBar);
