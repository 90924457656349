import React from "react";
import LiveOffline from "./LiveOffline";
import LiveNotStart from "./LiveNotStart";
import LiveEnd from "./LiveEnd";
import Countdown from "react-countdown";

const renderer = (props) => {
    console.log(props)
    if (props.completed || props.isLive) {
        return <LiveOffline />;
    } else {
        return (
            <LiveNotStart
                hours={props.formatted.hours}
                minutes={props.formatted.minutes}
                seconds={props.formatted.seconds}
                org={props.org}
            />
        );
    }
};

const LiveError = ({ time, isLive, org, wasLive }) => {
    return wasLive ? (
        <LiveEnd />
    ) : (
        <Countdown
            date={time}
            renderer={(props) => renderer({ ...props, isLive, org })}
            zeroPadDays={2}
            zeroPadTime={2}
        />
    );
};

export default LiveError;
