import React from "react";
import ImageCard from "./ImageCard";
import { Loader } from "../";
import "../../../scss/components/Gallery.scss";

const Gallery = ({
  images,
  loading,
  loaded,
  discoverClick,
  customerClick,
  customerData,
}) => {
  console.log(images);
  return (
    <>
      <div className="gallery">
        {loaded &&
        ((images && images.length !== 0) ||
          (customerData && customerData.length !== 0)) ? (
          <>
            {images &&
              images.length !== 0 &&
              images.map((e, i) => {
                return (
                  <ImageCard
                    key={i}
                    image={e.image}
                    discoverClick={() =>
                      discoverClick(e.media, e.mediaType === "video")
                    }
                  />
                );
              })}
            {customerData &&
              customerData.length !== 0 &&
              customerData
                .filter((e) => e.cards.every((e) => e.backgroundMedia))
                .map((e, i) => {
                  return (
                    <ImageCard
                      key={i}
                      image={e.cards[0].backgroundMedia.thumb}
                      discoverClick={() => customerClick(e.cards)}
                    />
                  );
                })}
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <div className="gallery__empty">Nothing to discover</div>
        )}
      </div>
      <div className="gallery__bottom">
        <a target="_blank" rel="noopener noreferrer" href="/discover">
          Go to discover
        </a>
      </div>
    </>
  );
};

export default Gallery;
