import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

function Youtube({ history }) {
  useEffect(() => {
    history.push("/discover");
  }, [history]);

  return <div></div>;
}

export default withRouter(Youtube);
