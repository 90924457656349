import React, { useState, useEffect } from "react";
import { Preview, Close } from "../../icons/icons";
import { StoriesSaga } from "./";
import "../../scss/components/Preview.scss";

const PreviewPage = ({ story, setClose }) => {
  const [pause, setPause] = useState(false);

  useEffect(() => {
    const int = setInterval(() => {
      document.body.classList.add("modal");
    }, 100);

    return () => {
      clearInterval(int);
      document.body.classList.remove("modal");
    };
  });

  return (
    <div className="preview">
      <div className="preview__header">
        <div className="ph__preview" onClick={() => setPause(false)}>
          <Preview /> Preview
        </div>
        <div className="ph__close" onClick={() => setClose(false)}>
          Close <Close />
        </div>
      </div>
      <div className="preview__container">
        <StoriesSaga story={story} setClose={setClose} isPause={pause} />
      </div>
    </div>
  );
};

export default PreviewPage;
