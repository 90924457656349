import Immutable from "immutable";

import { actions as A } from "../actions/storybarsActions";

const initialState = Immutable.fromJS({
  fetched: false,
  fetching: false,
  data: [],
  analytics: {},
  error: null,
  devCodeFetched: false,
  devCodeFetching: false,
  storybarNameFetching: false,
  storybarNameFetched: false,
  updateFetched: false,
  updateFetching: false,
  removeStorybarFetched: false,
  removeStorybarFetching: false,
  createStorybarFetched: false,
  createStorybarFetching: false,
});

export default function reducer(state = initialState, action) {
  let storybarId, data;
  switch (action.type) {
    case A.STORYBARS_LIST_PENDING:
      return state.set("fetched", false).set("fetching", true);
    case A.SET_STORYBAR_ORDER:
      return state.set("data", action.data);
    case A.STORYBARS_LIST_FULFILLED:
      return state
        .set("fetched", true)
        .set("fetching", false)
        .set("data", action.data);
    case A.STORYBARS_LIST_REJECTED:
      return state
        .set("fetched", false)
        .set("fetching", false)
        .set("error", action.error);
    case A.STORYBAR_ANALYTICS_FULFILLED:
      ({ storybarId, data } = action.data);

      return state.setIn(["analytics", storybarId], Immutable.fromJS(data));
    case A.RESET_STORYBARS_DATA:
      return initialState;
    case A.SEND_DEV_CODE_PENDING:
      return state.set("devCodeFetched", false).set("devCodeFetching", true);
    case A.SEND_DEV_CODE_FULFILLED:
      return state.set("devCodeFetched", true).set("devCodeFetching", false);
    case A.SEND_DEV_CODE_REJECTED:
      return state
        .set("devCodeFetched", false)
        .set("devCodeFetching", false)
        .set("error", action.error);
    case A.SET_STORYBAR_NAME_PENDING:
      return state
        .set("storybarNameFetched", false)
        .set("storybarNameFetching", true);
    case A.SET_STORYBAR_NAME_FULFILLED:
      return state
        .set("storybarNameFetched", true)
        .set("storybarNameFetching", false);
    case A.SET_STORYBAR_NAME_REJECTED:
      return state
        .set("storybarNameFetched", false)
        .set("storybarNameFetching", false)
        .set("error", action.error);
    case A.UPDATE_STORYBAR_PENDING:
      return state
        .set("storybarUpdateFetched", false)
        .set("storybarUpdateFetching", true);
    case A.UPDATE_STORYBAR_FULFILLED:
      return state
        .set("storybarUpdateFetched", true)
        .set("storybarUpdateFetching", false);
    case A.UPDATE_STORYBAR_REJECTED:
      return state
        .set("storybarUpdateFetched", false)
        .set("storybarUpdateFetching", false)
        .set("error", action.error);
    case A.UPDATE_STORYBARS_LIST_PENDING:
      return state.set("updateFetched", false).set("updateFetching", true);
    case A.UPDATE_STORYBARS_LIST_FULFILLED:
      return state
        .set("updateFetched", true)
        .set("updateFetching", false)
        .set("data", action.data);
    case A.UPDATE_STORYBARS_LIST_REJECTED:
      return state
        .set("updateFetched", false)
        .set("updateFetching", false)
        .set("error", action.error);
    case A.REMOVE_STORYBAR_PENDING:
      return state
        .set("removeStorybarFetched", false)
        .set("removeStorybarFetching", true);
    case A.REMOVE_STORYBAR_FULFILLED:
      return state
        .set("removeStorybarFetched", true)
        .set("removeStorybarFetching", false);
    case A.REMOVE_STORYBAR_REJECTED:
      return state
        .set("removeStorybarFetched", false)
        .set("removeStorybarFetching", false)
        .set("error", action.error);
    case A.CREATE_STORYBAR_PENDING:
      return state
        .set("createStorybarFetched", false)
        .set("createStorybarFetching", true);
    case A.CREATE_STORYBAR_FULFILLED:
      return state
        .set("createStorybarFetched", true)
        .set("createStorybarFetching", false);
    case A.CREATE_STORYBAR_REJECTED:
      return state
        .set("createStorybarFetched", false)
        .set("createStorybarFetching", false)
        .set("error", action.error);
    default:
      return state;
  }
}
