import React, { useState, useEffect, useCallback } from "react";
import { StoriesEditor, AddStory } from "./";
import "../../scss/components/EditStoriesContainer.scss";
import { connect } from "react-redux";
import { setActiveCard } from "../../redux/actions/editStoryActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import move from "../../images/move.svg";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const EditStoriesContainer = ({
  hideSound,
  cards,
  tutorial,
  setTutorial,
  updateText,
  activeText,
  setEdited,
  storyData,
  hovered,
  hoveredInst,
  edited,
  isHint,
  handleTextDelete,
  setActiveText,
  addCard,
  activeCard,
  colorOpen,
  setActiveCard,
  setColorTarget,
  setColorOpen,
  setStories,
  size,
  setSize,
}) => {
  const [left, setLeft] = useState(500);
  const [isDrag, setIsDrag] = useState(false);
  const [transition, setTransition] = useState(false);

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      ...draggableStyle,
    };
  };

  const onDragStart = (params) => {
    // const cardWidth = document.getElementById("activeCard").offsetWidth;
    // const startTrans =
    //   document.getElementById("storiesedit__container").offsetWidth / 2 -
    //   cardWidth / 2;
    // setLeft(startTrans);
    // document.getElementById("storiesedit__container").scrollLeft =
    //   -left + startTrans * 2;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const items = reorder(cards, result.source.index, result.destination.index);

    setStories(items, result.destination.index, result.source.index);
    setAct(result.destination.index);
  };

  const addNewCard = () => {
    if (edited !== null) return;
    addCard();
    setAct(cards.length);
  };

  const setAct = useCallback(
    (index) => {
      try {
        const cardWidth = document.getElementById("activeCard").offsetWidth;
        setTransition(true);
        const startTrans =
          document.getElementById("storiesedit__container").offsetWidth / 2 -
          cardWidth / 2;
        const el = document.getElementById("activeCard");
        var style = el.currentStyle || window.getComputedStyle(el);
        const offset = parseInt(style.marginRight);
        setLeft(startTrans - index * (cardWidth + offset));
        setActiveCard(index);
        setTimeout(() => {
          setTransition(false);
        }, 200);
      } catch (e) {}
    },
    [setActiveCard]
  );

  const findActive = useCallback(() => {
    if (edited !== null) return;
    if (document.getElementById("activeCard")) {
      const cardWidth = document.getElementById("activeCard").offsetWidth;
      const el = document.getElementById("activeCard");
      var style = el.currentStyle || window.getComputedStyle(el);
      const startTrans =
        document.getElementById("storiesedit__container").offsetWidth / 2 -
        cardWidth / 2;
      const offset = parseInt(style.marginRight);

      if (left > startTrans) {
        setAct(0);
      } else {
        const act = Math.round((startTrans - left) / (cardWidth + offset));
        if (act < cards.length - 1) {
          setAct(act);
        } else {
          setAct(cards.length - 1);
        }
      }
    }
  }, [left, cards.length, setAct, edited]);

  useEffect(() => {
    if (!isDrag) findActive();
  }, [isDrag, findActive, edited]);

  useEffect(() => {
    setActiveText(null);
  }, [activeCard, setActiveText]);

  useEffect(() => {
    setAct(0);
  }, [setAct]);

  useEffect(() => {
    if (activeCard + 1 > storyData?.cards?.length) {
      setActiveCard(storyData?.cards?.length - 1);
      setTimeout(() => {
        setAct(storyData?.cards?.length - 1);
      }, 1);
    }
    // eslint-disable-next-line
  }, [storyData, activeCard]);

  //Handle start dragging if is not currently editing
  const handleDragStart = (e, isTouch = false) => {
    if (edited !== null) return;
    if (e.target.className === "drag__arrow") return;

    const start = !isTouch ? e.pageX : e.touches[0].pageX;
    const startLeft = left;
    const handleMove = (e) => {
      e.stopPropagation();
      const cur = !isTouch ? e.pageX : e.touches[0].pageX;
      setIsDrag(true);
      //Finding new position
      setLeft(startLeft + cur - start);
    };
    const handleUp = (e) => {
      e.stopPropagation();
      setIsDrag(false);
      if (isTouch) {
        document.removeEventListener("touchmove", handleMove);
        document.removeEventListener("touchend", handleUp);
      } else {
        document.removeEventListener("mousemove", handleMove);
        document.removeEventListener("mouseup", handleUp);
      }
    };
    if (isTouch) {
      document.addEventListener("touchmove", handleMove);
      document.addEventListener("touchend", handleUp);
    } else {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleUp);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            className="storiesedit__container"
            id="storiesedit__container"
            onMouseDown={handleDragStart}
            onTouchStart={(e) => handleDragStart(e, true)}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div
              className={`stories__container__inner ${
                transition ? "transition" : ""
              }`}
              style={{ left, paddingRight: 43 + size.width }}
            >
              {cards
                ? cards.map((e, i) => {
                    return (
                      <Draggable key={e._id} draggableId={e._id} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="move__arrow">
                              <img
                                src={move}
                                alt="move"
                                {...provided.dragHandleProps}
                                className="drag__arrow"
                              />
                            </div>
                            <div
                              className={`stories__item ${
                                activeCard === i ? "activeCard" : ""
                              }`}
                              id={activeCard === i ? "activeCard" : ""}
                              key={i}
                              onClick={(e) => {
                                if (
                                  !isDrag &&
                                  e.nativeEvent.which === 1 &&
                                  !isHint &&
                                  edited === null
                                ) {
                                  setAct(i);
                                }
                              }}
                            >
                              <div
                                style={
                                  activeCard === i
                                    ? { pointerEvents: "all" }
                                    : { pointerEvents: "none" }
                                }
                              >
                                <StoriesEditor
                                  hideSound={hideSound}
                                  _id={e._id}
                                  index={i}
                                  story={e}
                                  hovered={hovered}
                                  isActive={activeCard === i}
                                  tutorial={tutorial}
                                  setTutorial={setTutorial}
                                  updateText={updateText}
                                  activeText={activeText}
                                  setAct={setAct}
                                  hoveredInst={hoveredInst}
                                  setActiveText={setActiveText}
                                  setColorTarget={setColorTarget}
                                  setEdited={setEdited}
                                  isHint={isHint}
                                  handleTextDelete={handleTextDelete}
                                  setColorOpen={setColorOpen}
                                  colorOpen={colorOpen}
                                  size={size}
                                  setSize={setSize}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                : null}
              <div className={`stories__item add__card`}>
                <AddStory onClick={addNewCard} size={size} />
              </div>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapDispatchToProps = {
  setActiveCard,
};

export default connect(null, mapDispatchToProps)(EditStoriesContainer);
