import React, { useEffect, useCallback } from "react";
import { Switch, withRouter } from "react-router-dom";
import loginRoutes from "./loginRoutes";
import noLoginRoutes from "./noLoginRoutes";
import WithoutHeader from "./WithoutHeader";
import WithHeader from "./WithHeader";
import { connect } from "react-redux";
import { StartScreen } from "../components/common";
import { PlanPage, Shopify } from "../pages";

import { getStoriesList } from "../redux/actions/storiesActions";
import { getStorybars } from "../redux/actions/storybarsActions";
import { getUserInfo } from "../redux/actions/authActions";
import { setUserData } from "../redux/actions/userActions";
import { getCustomerStoriesList } from "../redux/actions/storiesActions";

import client from "../redux/utils/client";

const Routes = ({
  isLoggedIn,
  userInfoFetched,
  userInfoFetching,
  idToken,
  getStorybars,
  history,
  hasOrg,
  profile,
  getCustomerStoriesList,
  getStoriesList,
}) => {
  useEffect(() => {
    try {
      if (client.isLoggedIn()) {
        client.getMe();
      }
      if (!localStorage.getItem("isNew")) {
        localStorage.setItem("isNew", "new");
      }
      if (
        localStorage.getItem("lastUrl") &&
        window.location.href === process.env.REACT_APP_REDIRECT_URL + `/`
      ) {
        let lastUrl = localStorage.getItem("lastUrl");
        localStorage.removeItem("lastUrl");
        history.push(lastUrl);
      }
    } catch (e) {}
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoggedIn && hasOrg) {
      getStorybars({ authToken: idToken });
      getCustomerStoriesList({ authToken: idToken });
      getStoriesList({ authToken: idToken });
    }
  }, [
    getStorybars,
    idToken,
    hasOrg,
    isLoggedIn,
    getCustomerStoriesList,
    getStoriesList,
  ]);

  const getContent = useCallback(() => {
    if (userInfoFetching) {
      return <StartScreen />;
    }
    if (userInfoFetched || !client.isLoggedIn()) {
      if (isLoggedIn) {
        if (!(profile.plan && profile.planActive)) {
          return (
            <>
              <div style={{ display: "none" }}>
                <Shopify />
              </div>
              <PlanPage isBack={false} />
            </>
          );
        }
        return (
          <Switch>
            {loginRoutes.map((route, i) => {
              if (route.withHeader) {
                return <WithHeader key={i} {...route} />;
              } else {
                return <WithoutHeader key={i} {...route} />;
              }
            })}
          </Switch>
        );
      } else {
        return (
          <>
            <Switch>
              {noLoginRoutes.map((route, i) => {
                if (route.withHeader) {
                  return <WithHeader key={i} {...route} />;
                } else {
                  return <WithoutHeader key={i} {...route} />;
                }
              })}
            </Switch>
          </>
        );
      }
    }
  }, [userInfoFetched, isLoggedIn, userInfoFetching, profile]);

  return <> {getContent()} </>;
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let {
    userInfoFetched,
    userInfoFetching,
    profile,
    loggedIn,
    orgInfo,
    idToken,
    hasOrg,
  } = auth;

  return {
    userInfoFetched,
    userInfoFetching,
    isLoggedIn: loggedIn,
    profile,
    orgInfo,
    idToken,
    hasOrg,
  };
};

const mapDispatchToProps = {
  getUserInfo,
  getStorybars,
  setUserData,
  getCustomerStoriesList,
  getStoriesList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null)(Routes)
);
