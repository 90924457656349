import React, { useState, useRef, useEffect } from "react";
import { Input, Modal } from "./";
import { DeleteIcon } from "../../icons/icons";
import { onlyWithPermission } from "../../helpers/accessControl";
import "../../scss/components/ProductUrl.scss";

const PromoModal = ({
  product,
  setData,
  formData,
  setPublished,
  handleSubmit,
}) => {
  return (
    <Modal
      toggle={
        <div
          className="promo__button"
          onClick={(e) => onlyWithPermission(e, "advanced", setPublished(true))}
        >
          {product.promo ? product.promo.promo + `%` : `PROMO`}
        </div>
      }
      title="What PROMO do you want to add?"
      titleMob="Add Promo"
      subtitle=" Select your product PROMO and set the PROMO period"
      subtitleMob=" Select your product PROMO and set the PROMO period"
      onSubmit={() => handleSubmit()}
      isBook
      onCancel={() => setPublished(false)}
      onClose={() => setPublished(false)}
      className="promo__modal"
      submit={<>Create PROMO</>}
      cancel={<>Cancel</>}
    >
      <div className="products__cont" id="promo__modal">
        <div>
          <Input
            label="Type of PROMO"
            placeholder="Choose discount size"
            name="promo"
            type="number"
            min="1"
            max="100"
            value={formData.promo}
            required
            onChange={(e) => {
              if (+e.target.value > 100) {
                setData({ [e.target.name]: 100 });
              } else if (+e.target.value < 1) {
                setData({ [e.target.name]: 1 });
              } else {
                setData({ [e.target.name]: e.target.value });
              }
            }}
          />
        </div>
        <div className="date__box">
          <div>
            <Input
              label="Start date"
              placeholder="Choose date"
              name="startDate"
              type="date"
              value={formData.startDate}
              required
              onChange={(e) => setData({ [e.target.name]: e.target.value })}
            />
          </div>
          <div>
            <Input
              label="End date"
              placeholder="Choose date"
              name="endDate"
              type="date"
              value={formData.endDate}
              required
              onChange={(e) => setData({ [e.target.name]: e.target.value })}
            />
          </div>
        </div>
        <div>
          <div className="product__analytic">
            <a href={product.url} target="_blank" rel="noopener noreferrer">
              <img
                src={product.image}
                alt="coollwwowoowow"
                className="pa__image"
              />
            </a>
            <div className="pa__name">{product.name}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

//Event just imitate some rules
const ProductUrl = ({
  text,
  handleChange,
  id,
  handleDelete,
  setHovered,
  index,
  isInst = false,
  isError,
  placeholder = "Please paste the URL",
  name = "URL",
  product,
}) => {
  const [formData, setFormData] = useState(() => ({
    promo: product.promo ? product.promo.promo : 0,
    startDate: product.promo ? product.promo.startDate : ``,
    endDate: product.promo ? product.promo.endDate : ``,
  }));
  const [errors, setErrors] = useState({});
  const [focused, setFocused] = useState(true);
  const [published, setPublished] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isInst) {
      ref.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  const setData = (obj) => {
    setFormData((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const handleSubmit = () => {
    if (!formData.promo) {
      setErrors((prev) => ({
        ...prev,
        promo: "You need to set discount size",
      }));
    } else {
      const er = { ...errors };
      delete er.promo;
      setErrors(er);
    }
    if (!formData.startDate) {
      setErrors((prev) => ({ ...prev, startDate: "Enter start date" }));
    } else {
      const er = { ...errors };
      delete er.startDate;
      setErrors(er);
    }
    if (!formData.endDate) {
      setErrors((prev) => ({ ...prev, endDate: "Enter end date" }));
    } else {
      const er = { ...errors };
      delete er.endDate;
      setErrors(er);
    }

    if (formData.promo && formData.startDate && formData.endDate) {
      handleChange(id, { promo: formData });
      setPublished(false);
      return true;
    } else {
      return;
    }
  };

  return (
    <div
      id={`prodUrl${index}`}
      className="product_url"
      onMouseEnter={() => setHovered(index)}
      onMouseLeave={() => setHovered(null)}
    >
      {!isError && !isInst && focused && (
        <div
          className="promo__overlay"
          onClick={() => {
            if (!published) {
              setFocused(false);
              ref.current.focus();
            }
          }}
        >
          <PromoModal
            product={product}
            formData={formData}
            setData={setData}
            setPublished={setPublished}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
      <div className="url_label">{name}</div>
      <input
        ref={ref}
        className="url_input"
        placeholder={placeholder}
        value={text ? text : ""}
        onChange={(e) => {
          handleChange(id, { url: e.target.value });
        }}
        onBlur={() => setFocused(true)}
      />

      <div className="delete_button" onClick={() => handleDelete(id)}>
        <DeleteIcon />
      </div>
    </div>
  );
};

export default ProductUrl;
