import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SketchPicker } from "react-color";
import { NewInput, Transition } from "../../components/common";
import { DefaultPage } from "../../components/containers";
import { Button } from "../../components/atoms";
import { Slash, StoryPage } from "../../icons/icons";
import { setOrgData } from "../../redux/actions/orgActions";
import withPermission from "../../components/hoc/withPermission";
import customization from "../../images/customization.jpg";
import "../../scss/pages/Customization.scss";

const CustomizationUpgrade = () => (
  <DefaultPage
    title="Customize you stories"
    subtitle="Upgrade your plan to pro to use customization"
    icon={<StoryPage />}
    cta={
      <Link to="plans">
        <Button text="Upgrade plan" />
      </Link>
    }
  />
);

const Customization = () => {
  const dispatch = useDispatch();
  const { idToken, orgInfo, setOrgDataFetching } = useSelector((state) =>
    state.auth.toJS()
  );
  const [color, setColor] = useState(orgInfo.color || "#e1569a");
  const [shape, setShape] = useState(orgInfo.type || "circle");
  const [size, setSize] = useState("");

  const handleColorChange = (c) => {
    setColor(c.hex);
  };

  const handleNoOutlineClick = () => {
    setColor("transparent");
  };

  const handleCustomSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleCustomizationSave = () => {
    const value =
      document.querySelector('input[name="size"]:checked') &&
      document.querySelector('input[name="size"]:checked').value;
    const orgData = { color, type: shape };
    if (value === "custom" && size) {
      orgData.size = size;
    } else if (value) {
      orgData.size = value;
    }
    dispatch(
      setOrgData({ authToken: idToken, orgAlias: orgInfo.alias, orgData })
    );
  };

  return (
    <Transition>
      <div className="page-wrapper customization-page">
        <div className="customization-container">
          <div className="customization-container__item">
            <p className="customization-container__item-title">
              Choose outline color
            </p>
            <div className="customization-container__item-color">
              <SketchPicker
                disableAlpha
                presetColors={[]}
                width={180}
                color={color}
                onChangeComplete={handleColorChange}
              />
              <div className="customization-container__item-example">
                <div
                  className="customization-container__item-example__item customization-container__item-example__item--rounded"
                  style={{
                    borderColor: color,
                    background: `url('${customization}')`,
                  }}
                ></div>
                <div
                  className="customization-container__item-example__item"
                  style={{
                    borderColor: color,
                    background: `url('${customization}')`,
                  }}
                ></div>
              </div>
            </div>
            <button
              className="button button--icon no-outline"
              onClick={handleNoOutlineClick}
            >
              <span>
                <Slash />
              </span>
              No outline color
            </button>
          </div>
          <div className="customization-container__item">
            <p className="customization-container__item-title">
              Choose Shape & size
            </p>
            <div className="customization-container__item-shape">
              <div className="customization-container__item-example">
                <div
                  className={`customization-container__item-example__item customization-container__item-example__item--rounded${
                    shape !== "circle"
                      ? " customization-container__item-example__item--disabled"
                      : ""
                  }`}
                  style={{
                    borderColor: color,
                    background: `url('${customization}')`,
                  }}
                  onClick={() => setShape("circle")}
                />
                <div
                  className={`customization-container__item-example__item${
                    shape !== "square"
                      ? " customization-container__item-example__item--disabled"
                      : ""
                  }`}
                  style={{
                    borderColor: color,
                    background: `url('${customization}')`,
                  }}
                  onClick={() => setShape("square")}
                />
              </div>
            </div>
            <div className="customization-container__item-size">
              <div className="customization-container__item-size-container">
                <label for="small">
                  <input type="radio" id="small" name="size" value="75" />
                  <p>
                    <b>S</b>
                  </p>
                  <p>75px</p>
                </label>
                <label for="middle">
                  <input type="radio" id="middle" name="size" value="100" />
                  <p>
                    <b>M</b>
                  </p>
                  <p>100px</p>
                </label>
                <label for="large">
                  <input type="radio" id="small" name="size" value="125" />
                  <p>
                    <b>L</b>
                  </p>
                  <p>125px</p>
                </label>
              </div>
              <label for="custom">
                <input type="radio" id="custom" name="size" value="custom" />
                <NewInput
                  placeholder="Pixel size"
                  name="size"
                  type="text"
                  label="Customize your size"
                  onChange={handleCustomSizeChange}
                  value={size}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="customization-page__button">
          <Button
            type="outline"
            text="Save changes"
            onClick={handleCustomizationSave}
            loading={setOrgDataFetching}
          />
        </div>
      </div>
    </Transition>
  );
};

export default withPermission(
  "professional",
  Customization,
  CustomizationUpgrade
);
