import Immutable from "immutable";

import { actions as A } from "../actions/infoPopupActions";

const initialState = Immutable.fromJS({
  id: "",
  name: "",
  titleText: "",
  isVisible: false,
  primaryText: "",
  secondaryText: "",
  backgroundColor: "#263ba5",
  text: "",
  storiesText: "",
});

export default function reducer(state = initialState, action) {
  let id, name, type, primaryText, secondaryText, backgroundColor;

  switch (action.type) {
    case A.CREATE_POPUP:
      ({ id, name, type, primaryText, secondaryText, backgroundColor } =
        action.data);

      return state
        .set("id", id)
        .set("name", name)
        .set("type", type)
        .set("primaryText", primaryText)
        .set("secondaryText", secondaryText)
        .set("backgroundColor", backgroundColor)
        .set("isVisible", true);
    case A.SHOW_INFO_POPUP:
      return state
        .set("isVisible", true)
        .set("text", action.data.text)
        .set("titleText", action.data.titleText)
        .set("storiesText", action.data.storiesText);
    case A.HIDE_INFO_POPUP:
      return initialState;
    case A.SET_INFO_POPUP_PRIMARY_TEXT:
      return state.set("primaryText", action.data);
    case A.SET_INFO_POPUP_SECONDARY_TEXT:
      return state.set("secondaryText", action.data);
    case A.SET_INFO_POPUP_BACKGROUND_COLOR:
      return state.set("backgroundColor", action.data);
    default:
      return state;
  }
}
