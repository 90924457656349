import { instance, setAuthToken, fetchRequest } from "./fetchData";

export function fetchStorybarsList({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get("/story-bar"));
}

export function fetchStorybarAnalytics({ authToken, storybarId }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story-bar/${storybarId}/analytics`));
}

export function fetchSendDevCode({ storybarId, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/story-bar/${storybarId}/code`));
}

export function fetchSetStorybarName({ storybarId, name, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post(`/story-bar/${storybarId}/title`, {
      title: name,
    })
  );
}

export function fetchSetStorybarCaption({ storybarId, name, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post(`/story-bar/${storybarId}/caption`, {
      caption: name,
    })
  );
}

export function fetchSetStorybarOrder({ storybarId, order, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post(`/story-bar/${storybarId}/order`, {
      order,
    })
  );
}

export function fetchUpdateStorybar({ storybarId, updateBody, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/story-bar/${storybarId}`, updateBody));
}

export function fetchRemoveStorybar({ storybarId, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.delete(`/story-bar/${storybarId}`));
}

export function fetchCreateStorybar({ name, authToken }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post(`/story-bar`, {
      title: name,
    })
  );
}
