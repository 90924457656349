import React from "react";

const PageView = ({
  children,
  isShow = false,
  defaultView,
  isLoading = false,
  loadingView = null,
}) => {
  return isShow ? (
    isLoading ? (
      <>{loadingView}</>
    ) : (
      <>{children}</>
    )
  ) : (
    <>{defaultView}</>
  );
};

export default PageView;
