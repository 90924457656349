import React from "react";
import _ from "lodash";
import LiveCard from "../../components/common/LiveCard";
import CreateLive from "../../components/hoc/createLive";
import ContactLive from "../../components/hoc/contactLive";
import { isHasAccessToLive } from "../../helpers/liveAccess";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const LivePage = ({ data, loading = false, fetching, orgInfo = {} }) => {
  return (
    <div
      className={`stories__page2 page${loading ? " pholder_item" : ""}${
        fetching ? " blicking" : ""
      }`}
    >
      {!loading && (
        <div className="createLive__button">
          {isHasAccessToLive(orgInfo.alias) ? (
            <CreateLive>
              <div className="accent__button">Book LIVE Session</div>
            </CreateLive>
          ) : (
            <ContactLive>
              <div className="accent__button">Book LIVE Session</div>
            </ContactLive>
          )}
        </div>
      )}

      <div className="container">
        {_.uniq(data.map((e) => e.date)).map((el, i) => {
          return (
            <div className="stories__line" key={i}>
              <div className="stories__date phh">{el}</div>
              <div className="stories__container">
                {data
                  .filter((e) => e.date === el)
                  .map((e, i) => (
                    <LiveCard
                      key={i}
                      id={e.id}
                      live={e}
                      homepage={orgInfo.homepage || ""}
                      isDraft={true}
                      title={e.name || ""}
                      image={e.influencer || e.thumb}
                      background={"red"}
                      publishing={false}
                      statistic={{
                        unique: "87,232",
                        views: "21,423",
                        clicks: "9,147",
                        purchases: "245",
                      }}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LivePage;
