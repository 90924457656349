import React, { useState, useRef, useEffect, memo, useMemo } from "react";
import classnames from "classnames";
import { Check, Trash2 } from "../../icons/icons";
import "../../scss/components/Text.scss";

// const useWindowSize = ({ width, height }) => {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     const updateSize = () => {
//       const card = document.getElementById("st1");
//       setSize([card.offsetWidth, card.offsetHeight]);
//     };
//     window.addEventListener("resize", updateSize);
//     updateSize();
//     return () => window.removeEventListener("resize", updateSize);
//   }, []);
//   return [size[0] / width, size[1] / height];
// };

const Text = memo(
  ({
    styles: {
      textAlign = "left",
      fontStyle = "normal",
      fontWeight = "left",
      color = "black",
    },
    isActive,
    updateText,
    _id,
    position,
    text,
    rotation,
    screenSize = { width: 414, height: 736 },
    scale,
    id,
    setActiveText,
    setEdited,
    isNew = false,
    handleTextDelete,
    isHint,
    size,
    isInst,
    newStyle = false,
  }) => {
    const [isDrag, setIsDrag] = useState(false);
    const [posi, setPosi] = useState(position);
    const [isFocus, setIsFocus] = useState(false);
    const [textSize, setTextSize] = useState({ width: `auto`, height: `auto` });
    const [texti, setText] = useState(text);
    const [degreei, setDegree] = useState(rotation);
    const [scalei, setScale] = useState(scale);
    const textarea = useRef(null); //textarea
    const textRef = useRef(null); //span for autoresize
    const center = useRef(null); //span for center
    const containerRef = useRef(null); //container for text
    const editorRef = useRef(null); //text
    const wrapp = useRef(null); //text

    const [width, height] = useMemo(() => {
      return [size.width / screenSize.width, size.height / screenSize.height];
    }, [size, screenSize]);

    //Get real offset
    const getOffset = (item) => {
      return [
        item.getBoundingClientRect().left + document.documentElement.scrollLeft,
        item.getBoundingClientRect().top + document.documentElement.scrollTop,
      ];
    };

    useEffect(() => {
      if (isFocus) {
        setEdited(id);
      } else setEdited(null);
    }, [isFocus, id, setEdited]);

    useEffect(() => {
      if (isNew) {
        textarea.current.focus();
        setIsFocus(true);
      }
    }, [isNew]);

    //Resize textarea on text change
    useEffect(() => {
      if (typeof textSize.width === "number") {
        if (textRef.current.offsetWidth !== textSize.width) {
          if (textAlign === "center") {
            setPosi((prev) => ({
              ...prev,
              left:
                prev.left - (textRef.current.offsetWidth - textSize.width) / 2,
            }));
          } else if (textAlign === "right") {
            setPosi((prev) => ({
              ...prev,
              left: prev.left - (textRef.current.offsetWidth - textSize.width),
            }));
          }
        }
      }
      setTextSize({
        width: textRef.current.offsetWidth,
        height: textRef.current.offsetHeight,
      });
    }, [texti, textAlign, textSize.width]);

    //Handle resize click
    const handleRotateStart = (e, isTouch = false) => {
      const [center_x, center_y] = getOffset(center.current); //Get offset of block center
      let currdegree = 0;
      e.stopPropagation();
      //Calculating degree on mouse move
      const handleRotation = (e) => {
        setIsDrag(true);
        if (!isActive) setActiveText(id);
        const pageX = !isTouch ? e.pageX : e.touches[0].pageX;
        const pageY = !isTouch ? e.pageY : e.touches[0].pageY;
        const degree = Math.atan2(
          pageX - center_x + 1 * scale,
          -(pageY - center_y + 1 * scale)
        );
        currdegree = degree;
        setDegree(degree);
      };

      const handleUp = (e) => {
        e.stopPropagation();
        setIsDrag(false);
        updateText(_id, {
          rotation: currdegree,
        });
        if (isTouch) {
          document.removeEventListener("touchmove", handleRotation);
          document.removeEventListener("touchend", handleUp);
        } else {
          document.removeEventListener("mousemove", handleRotation);
          document.removeEventListener("mouseup", handleUp);
        }
      };
      if (isTouch) {
        document.addEventListener("touchmove", handleRotation);
        document.addEventListener("touchend", handleUp);
      } else {
        document.addEventListener("mousemove", handleRotation);
        document.addEventListener("mouseup", handleUp);
      }
    };

    //Handle start dragging if is not currently editing
    const handleDragStart = (e, isTouch = false) => {
      e.stopPropagation();
      const startX = !isTouch ? e.pageX : e.touches[0].pageX;
      const startLeft = posi.left || 0;
      const startY = !isTouch ? e.pageY : e.touches[0].pageY;
      const startTop = posi.top || 0;
      let currpos = {
        top: 0,
        left: 0,
      };

      const handleMove = (e) => {
        e.stopPropagation();
        if (!isActive) setActiveText(id);
        const pageX = !isTouch ? e.pageX : e.touches[0].pageX;
        const pageY = !isTouch ? e.pageY : e.touches[0].pageY;
        setIsDrag(true);
        currpos = {
          top: startTop - startY / height + pageY / height,
          left: startLeft - startX / width + pageX / width,
        };
        setPosi({
          top: startTop - startY / height + pageY / height,
          left: startLeft - startX / width + pageX / width,
        });
      };

      const handleUp = () => {
        updateText(_id, {
          position: currpos,
        });
        setIsDrag(false);
        if (isTouch) {
          document.removeEventListener("touchmove", handleMove);
          document.removeEventListener("touchend", handleUp);
        } else {
          document.removeEventListener("mousemove", handleMove);
          document.removeEventListener("mouseup", handleUp);
        }
      };

      if (!isFocus) {
        if (isTouch) {
          document.addEventListener("touchmove", handleMove);
          document.addEventListener("touchend", handleUp);
        } else {
          document.addEventListener("mousemove", handleMove);
          document.addEventListener("mouseup", handleUp);
        }
      }
    };

    //Handle resize element click
    const handleStartResize = (e, isTouch = false) => {
      e.stopPropagation();
      const [center_x, center_y] = getOffset(center.current); //Get offset of block center
      const [leftOffset, topOffset] = getOffset(e.target); //Get offset of current resize element
      //Get center of resize element
      const left = leftOffset + 4 * scale;
      const top = topOffset + 4 * scale;
      //Find resize element position to center
      const tt = top - center_y + 1 * scale;
      const ll = left - center_x + 1 * scale;
      const isTop = tt <= 0;
      const isLeft = ll <= 0;
      //Set distance to center before resize
      const resize = Math.sqrt(tt * tt + ll * ll);
      let currscale = 0;

      const handleResize = (e) => {
        setIsDrag(true);
        if (!isActive) setActiveText(id);
        const [center_x, center_y] = getOffset(center.current); //Get offset
        const pageX = !isTouch ? e.pageX : e.touches[0].pageX;
        const pageY = !isTouch ? e.pageY : e.touches[0].pageY;
        //Handling mouse position relatively to center
        const scale_x = pageX - (center_x + 1 * scale);
        const scale_y = pageY - (center_y + 1 * scale);
        //Scale rule based on where to resize
        const sx = isLeft
          ? scale_x > 0
            ? 0
            : scale_x
          : scale_x < 0
          ? 0
          : scale_x;
        const sy = isTop
          ? scale_y > 0
            ? 0
            : scale_y
          : scale_y < 0
          ? 0
          : scale_y;
        //Find new ditanse to center
        const distance = Math.sqrt(sy * sy + sx * sx);
        //Set new scale what > 0.5  f(currentScale * newDistance / oldDistance)
        currscale = Math.max((scale * distance) / resize, 0.5);
        setScale(Math.max((scale * distance) / resize, 0.5));
      };

      const handleUp = () => {
        setIsDrag(false);
        updateText(_id, {
          scale: currscale,
        });
        if (isTouch) {
          document.removeEventListener("touchmove", handleResize);
          document.removeEventListener("touchend", handleUp);
        } else {
          document.removeEventListener("mousemove", handleResize);
          document.removeEventListener("mouseup", handleUp);
        }
      };

      if (isTouch) {
        document.addEventListener("touchmove", handleResize);
        document.addEventListener("touchend", handleUp);
      } else {
        document.addEventListener("mousemove", handleResize);
        document.addEventListener("mouseup", handleUp);
      }
    };

    //Set editing to false
    const handleDone = () => {
      if (texti) {
        updateText(_id, {
          text: texti,
        });
      } else {
        handleDelete();
      }
      setIsFocus(false);
    };

    //Handle start editing text if block not dragging now
    const handleTextClick = () => {
      if (!isDrag && !isHint && isActive) {
        setIsFocus(true);
        textarea.current.focus();
      } else {
        if (!isActive) setActiveText(id);
      }
    };

    const handleDelete = () => {
      handleTextDelete(_id);
      setActiveText(null);
      setEdited(null);
    };

    return (
      <div
        className={classnames("text__wrapper", {
          editing: isFocus,
          active: isActive && !isFocus,
          dragged: isDrag,
        })}
        ref={wrapp}
        onScroll={(e) => {
          e.target.scrollLeft = 0;
          e.target.scrollTop = 0;
        }}
      >
        {isFocus ? (
          <React.Fragment>
            <div className="text__done cursor__button" onClick={handleDone}>
              <Check />
            </div>
            <div
              className="text__delete cursor__button error"
              onClick={handleDelete}
            >
              <Trash2 />
            </div>
          </React.Fragment>
        ) : null}
        <div
          className={`text__container${isInst ? " inst" : ""}${
            newStyle ? " new-text" : ""
          }`}
          ref={containerRef}
          style={{
            transform: `scale(${width},${height}) translate(${
              posi.left || 0
            }px,${posi.top || 0}px)`,
            width: textSize.width,
            height: textSize.height,
          }}
        >
          <div
            className="text__editor"
            style={{ transform: `rotate(${degreei}rad) scale(${scalei})` }}
            ref={editorRef}
          >
            <div
              className="text__cover"
              onMouseUp={handleTextClick}
              onMouseDown={handleDragStart}
              onTouchStart={(e) => handleDragStart(e, true)}
            />
            <div className="text__control">
              <span
                className="text__rotate"
                onMouseDown={handleRotateStart}
                onTouchStart={(e) => handleRotateStart(e, true)}
              >
                <i className="fas fa-undo"></i>
              </span>
              <span
                className="text__resize"
                id="tlresize"
                onMouseDown={handleStartResize}
                onTouchStart={(e) => handleStartResize(e, true)}
              />
              <span
                className="text__resize"
                id="trresize"
                onMouseDown={handleStartResize}
                onTouchStart={(e) => handleStartResize(e, true)}
              />
              <span
                className="text__resize"
                id="blresize"
                onMouseDown={handleStartResize}
                onTouchStart={(e) => handleStartResize(e, true)}
              />
              <span
                className="text__resize"
                id="brresize"
                onMouseDown={handleStartResize}
                onTouchStart={(e) => handleStartResize(e, true)}
              />
              <span className="text__center" ref={center} />
            </div>
            <span
              className="text__span"
              ref={textRef}
              style={{ textAlign, fontStyle, fontWeight, color }}
            >
              {texti
                ? texti + " "
                : isInst
                ? "Tag yourself(optional)"
                : "Comment (optional)"}
            </span>
            {isInst ? <span className="inst-start">@</span> : null}
            <textarea
              className="title__input textarea"
              placeholder={
                isInst ? "Tag yourself(optional)" : "Comment (optional)"
              }
              ref={textarea}
              spellcheck="false"
              style={{
                textAlign,
                fontStyle,
                fontWeight,
                color,
              }}
              value={texti}
              onChange={(e) => {
                e.stopPropagation();
                setText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default Text;
