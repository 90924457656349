import React, { useState } from "react";
import { StoryBarCard, Dropdown, StoryCard } from "../../components/common";
import { Sort } from "../../icons/icons";

const DiscoverPage = ({ data, loading = false, customerData, fetching }) => {
  const [dropOpen, setDropOpen] = useState(false);
  const [show, setShow] = useState("stories");
  // const dates = [...new Set(data.map((e) => e.date))];
  return (
    <div
      className={`stories__page2 page${loading ? " pholder_item" : ""}${
        fetching ? " blicking" : ""
      }`}
    >
      <div className="storiespage__settings">
        <Dropdown
          button={<Sort />}
          isOpen={dropOpen}
          setIsOpen={setDropOpen}
          dropdown={
            <div className="setting__block">
              <div className="setting__part">
                <p className="setting__title">Show</p>
                <p
                  className={`setting__link ${
                    show === "social" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (show !== "social") setShow("social");
                  }}
                >
                  Social
                </p>
                <p
                  className={`setting__link ${
                    show === "stories" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (show !== "stories") setShow("stories");
                  }}
                >
                  Influencers
                </p>
              </div>
            </div>
          }
        />
      </div>
      <div className="container">
        {show === "social" && data && data.length > 0
          ? data.map((e) => {
              if (e.data.length > 0) {
                return (
                  <div className="stories__line" key={e.id}>
                    <div className="stories__date phh">{e.title}</div>
                    <div className="stories__container">
                      {e.data.map((e, i) => (
                        <StoryBarCard
                          key={i}
                          type={e.mediaType}
                          image={e.image}
                          media={e.media}
                          deleteStory={() => {}}
                          platform={e.platform}
                          id={i}
                          isBig
                        />
                      ))}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          : null}
        {show === "stories" && customerData && customerData.length > 0 ? (
          <div className="stories__line">
            <div className="stories__date phh">Influencer Stories</div>
            <div className="stories__container">
              {customerData.map((e, i) => {
                const cards = e.cards;

                return (
                  <div key={i} className="st__wrap">
                    <StoryCard
                      id={e.id}
                      barId={e.storyBarId}
                      story={e}
                      isDraft={true}
                      title={e.title || ""}
                      snapshot={cards[0].snapshot}
                      image={
                        e.coverImage
                          ? e.coverImage
                          : cards[0].backgroundMedia
                          ? cards[0].backgroundMedia.thumb
                            ? cards[0].backgroundMedia.thumb
                            : cards[0].backgroundMedia.original
                            ? cards[0].backgroundMedia.original
                            : null
                          : null
                      }
                      isSnapshot={!e.coverImage}
                      background={cards[0].backgroundColor}
                      publishing={e.publishing}
                      statistic={{
                        unique: "87,232",
                        views: "21,423",
                        clicks: "9,147",
                        purchases: "245",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DiscoverPage;
