import React, { useState, useCallback, useEffect } from "react";
import {
  Dropdown,
  TutorialCard,
  InputTitle,
  Transition,
  Input,
  Modal,
  Colorpicker,
  CheckGroup,
} from "../components/common";
import domtoimage from "dom-to-image";
import { PreviewPage, EditStoriesContainer } from "../components/containers";
import { openStory } from "../redux/utils/embedApi";
import normalizeUrl from "normalize-url";
import _ from "lodash";
import { FontStyle, LoadImage, Share, Links } from "../components/roundContain";
import {
  BackArrow,
  TreeDot,
  Publish,
  ArrowIcon,
  PictureIcon,
  TextIcon,
  PackIcon,
  Preview,
  Instagram,
  CoverPhoto,
  HomePage,
  ProductPage,
  PasteUrl,
  Close,
} from "../icons/icons";
import "../scss/pages/CreateStory.scss";
import { connect } from "react-redux";
import {
  getStory,
  setStoryTitle,
  setCardBackgroundImage,
  removeText,
  setTextParam,
  createText,
  makeTextActive,
  fetchProduct,
  resetStoryData,
  removeInstagram,
  setStoryCover,
  createInstagram,
  addStoryDelta,
  setInstagramParams,
  setActiveCard,
  setSnapshot,
  setCardBackgroundColor,
  addCard,
  removeCard,
  uploadCardBackground,
  removeProduct,
  createProduct,
  setProdParams,
  setCardOrder,
  addCustomerCard,
} from "../redux/actions/editStoryActions";
import { resetStoriesData } from "../redux/actions/storiesActions";
import { publishStory } from "../redux/actions/publishStoryActions";
import {
  getDiscoverList,
  getCustomerStoriesList,
} from "../redux/actions/storiesActions";
import { createStorybar } from "../redux/actions/storybarsActions";
import RoundButton2 from "../components/common/RB2";

import { uploadFile } from "../redux/utils/fileUpload";

//Some colors for example. In the future will come from props
const colors = [
  "#FFFFFF",
  "#51F357",
  "#5451F3",
  "#F38B51",
  "#51F3E9",
  "#8F51F3",
  "#F3CF51",
  "#51B9F3",
  "#F051F3",
  "#C9F351",
  "#5192F3",
  "#20292C",
];

// const removeFromArray = (index, array) => {
//   let newArr = [...array];
//   newArr.splice(index, 1);
//   return [...newArr];
// };

const newText = {
  isNew: true,
  position: { left: 0, top: 323 },
  text: "",
  rotation: 0,
  scale: 1,
  styles: {
    fontWeight: "normal",
    fontStyle: "normal",
    color: "black",
    textAlign: "left",
  },
};

// const newCard = {
// 	backgroundMedia: null,
// 	backgroundColor: `url(${backgr})`,
// 	texts: [],
// 	products: [],
// 	intsgrams: []
// };

// const storiesArray = [
//   { id: "0", image: st1 },
//   { id: "1", image: st2 },
//   { id: "2", image: st2 },
//   { id: "3", image: st1 },
//   { id: "4", image: st2 },
//   { id: "5", image: st1 },
//   { id: "6", image: st2 },
//   { id: "7", image: st1 }
// ];

const filterCards = (obj) => {
  if (obj.cardOrder) {
    let cardOrder = JSON.parse(obj.cardOrder).order;
    let unionArr = _.union(
      cardOrder,
      obj.cards.map((e) => e._id)
    );

    return _.compact(
      unionArr.map((el) => {
        let isStory = _.findIndex(obj.cards, function (o) {
          return o._id === el;
        });
        if (isStory !== -1) return obj.cards[isStory];
        return undefined;
      })
    );
  } else {
    return obj.cards;
  }
};

const CreateStory = ({
  getStory,
  storyData,
  fetching,
  idToken,
  authToken,
  fetched,
  orgInfo,
  setStoryTitle,
  setCardBackgroundImage,
  removeText,
  customerFetching,
  customerFetched,
  customerData,
  setTextParam,
  removeProduct,
  createProduct,
  setCardOrder,
  publishStory,
  setActiveCard,
  createStorybar,
  fetchProduct,
  getDiscoverList,
  setSnapshot,
  resetStoriesData,
  resetStoryData,
  setStoryCover,
  setProdParams,
  createText,
  activeCardInd,
  addStoryDelta,
  removeInstagram,
  createInstagram,
  addCustomerCard,
  setInstagramParams,
  discover,
  discoverFetched,
  discoverFetching,
  getCustomerStoriesList,
  addCard,
  makeTextActive,
  setCardBackgroundColor,
  uploadCardBackground,
  match,
  data,
  removeCard,
  history,
  isValidInstagram,
  ...props
}) => {
  const [pastedUrl, setPastedUrl] = useState("");
  const [pasted, setPasted] = useState([]);
  const [scaleSize, setScaleSize] = useState(null);
  const [isHint, setHint] = useState(false);
  const [isHint2, setHint2] = useState(false);
  const [tutorial, setTutorial] = useState(0);
  const [publish, setPublish] = useState([`home`]);
  const [preview, setPreview] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [activeText, setActiveText] = useState(null);
  const [edited, setEdited] = useState(null);
  const [colorOpen, setColorOpen] = useState(false);
  const [colorTarget, setColorTarget] = useState("back");
  const [active, setActive] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [hoveredInst, setHoveredInst] = useState(null);
  const [size, setSize] = useState({ width: 414, height: 736 });
  const [hideSound, setHideSound] = useState(false);
  // const [stories, setStories] = useState(storiesArray);

  //Effects --------------------------------------------------------

  useEffect(() => {
    const resize = () => {
      if (
        window.innerWidth < 500 &&
        window.innerHeight > 550 &&
        document.getElementById("createstory_page") &&
        document.querySelector(".editor__container")
      ) {
        document.getElementById("createstory_page").style.height =
          window.innerHeight + "px";
        document.querySelectorAll(".editor__container").forEach((item) => {
          item.style.height = window.innerHeight + "px";
        });
      }
    };
    resize();
  });

  //Get story on mount
  useEffect(() => {
    window.FB.getLoginStatus(function (response) {
      getCustomerStoriesList({ authToken: idToken });
      if (response.status === "connected") {
        localStorage.setItem("fb_token", response.authResponse.accessToken);
        getDiscoverList();
      }
    });
    try {
      if (
        localStorage.getItem("isNew") &&
        localStorage.getItem("isNew") === "new"
      ) {
        setTutorial(1);
        localStorage.setItem("isNew", `not`);
      }
    } catch (e) {}
    //resetStoryData();
    getStory({
      storyId: match.params.id,
      authToken: idToken,
    });
    if (!discover) getDiscoverList();
    return () => {
      resetStoryData();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setScaleSize([size.width / 414, size.height / 736]);

    // eslint-disable-next-line
  }, [size]);

  //Close colorpicker when finish text editing
  useEffect(() => {
    if (edited === null) {
      setColorOpen(false);
    }
  }, [edited]);

  useEffect(() => {
    try {
      if (!_.isEmpty(storyData)) {
        const urlParams = new URLSearchParams(window.location.search);
        const fromDiscover = urlParams.get("fromDiscover");
        const isVideo = localStorage.getItem("isVideo") === "true";
        const url = localStorage.getItem("discoverUrl");
        if (fromDiscover) {
          discoverClick(url, isVideo);
          localStorage.removeItem("isVideo");
          localStorage.removeItem("discoverUrl");
          history.replace(`/create-stories/${storyData.id}`);
        }
      }
    } catch (e) {}
    // eslint-disable-next-line
  }, [storyData, scaleSize]);

  //Prevent all actions when tutorial start
  useEffect(() => {
    const tutorialActive = (e) => {
      if (
        document.getElementsByClassName("tutorial__card")[tutorial - 1] &&
        !document
          .getElementsByClassName("tutorial__card")
          [tutorial - 1].contains(e.target)
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    };
    if (tutorial !== 0) {
      document.addEventListener("mouseup", tutorialActive, true);
      document.addEventListener("mousedown", tutorialActive, true);
      document.addEventListener("click", tutorialActive, true);
      document.addEventListener("touchstart", tutorialActive, true);
      document.addEventListener("touchend", tutorialActive, true);
    }
    return () => {
      document.removeEventListener("mouseup", tutorialActive, true);
      document.removeEventListener("mousedown", tutorialActive, true);
      document.removeEventListener("click", tutorialActive, true);
      document.removeEventListener("touchstart", tutorialActive, true);
      document.removeEventListener("touchend", tutorialActive, true);
    };
  }, [tutorial]);

  //Cards actions --------------------------------------------------
  const addNewCard = async () => {
    await addCard(storyData.cards.length);
    setActiveCard(storyData.cards.length);
  };

  const setStories = (cards, newIndex, oldIndex) => {
    if (oldIndex === activeCardInd) {
      setActiveCard(newIndex);
    } else if (newIndex <= activeCardInd) {
      setActiveCard(newIndex + 1);
    }
    setCardOrder(JSON.stringify({ order: cards.map((e) => e._id) }));
  };

  //Set card background/ upload media
  const setImage = async ({ isVideo, full, blob }) => {
    setActive(null);
    setHideSound(isVideo);
    setCardBackgroundImage(storyData.cards[activeCardInd]._id, full, {
      isVideo,
    });
    if (storyData.cards[activeCardInd].backgroundMedia === null) {
      setCardBackgroundColor(storyData.cards[activeCardInd]._id, "#F8F9FA");
    }
    await uploadFile({
      blob,
      storyId: storyData.id,
      cardId: storyData.cards[activeCardInd]._id,
      authToken: idToken,
    });
  };

  const setCover = async (blob) => {
    const url = await uploadFile({
      blob,
      storyId: storyData.id,
      cardId: storyData.cards[activeCardInd]._id,
      authToken: idToken,
      mediaType: "cover",
    });
    setStoryCover(url);
  };

  //Set text or background color
  const setColor = (id) => {
    if (colorTarget === "back") {
      setCardBackgroundColor(
        storyData.cards[activeCardInd]._id,
        colors[id],
        id
      );
    } else {
      updateText(storyData.cards[activeCardInd].texts[activeText]._id, {
        styles: { colorId: id, color: colors[id] },
      });
    }
  };

  //Texts action
  const addText = async () => {
    const acttxt = activeText == null ? 0 : activeText + 1;
    await createText(storyData.cards[activeCardInd]._id);
    setActiveText(acttxt);
  };

  //Update texts snapshot when texts changed
  const updateSnapshot = useCallback(async () => {
    const texts = document.querySelector("#activeCard #texts__container");

    const blob = await domtoimage
      .toBlob(texts, {
        filter: (node) => {
          return !node.classList || !node.classList.contains("text__control");
        },
      })
      .then((a) => {
        console.log(a);
        return a;
      });

    setSnapshot(storyData.cards[activeCardInd]._id, URL.createObjectURL(blob));

    await uploadFile({
      blob,
      storyId: storyData.id,
      cardId: storyData.cards[activeCardInd]._id,
      authToken: idToken,
      mediaType: "snapshot",
    });
  }, [storyData, activeCardInd, idToken, setSnapshot]);

  const setFontStyle = (value) => {
    updateText(storyData.cards[activeCardInd].texts[activeText]._id, {
      styles: value,
    });
  };

  const updateText = useCallback(
    async (index, value) => {
      setTextParam(storyData.cards[activeCardInd]._id, index, {
        ...value,
        isNew: false,
      });
      updateSnapshot();
    },
    [storyData, activeCardInd, setTextParam, updateSnapshot]
  );

  const handleTextDelete = async (id) => {
    await removeText(storyData.cards[activeCardInd]._id, id);
    updateSnapshot();
  };

  //Products action
  const addProduct = () => {
    setHint(true);
    document.addEventListener("mouseup", handleClickStory);
  };
  const titleChange = (title) => {
    setStoryTitle(title);
  };

  //Add product at the click site on story card
  const handleClickStory = async (e) => {
    e.stopPropagation();
    if (document.getElementById("activeCard").contains(e.target)) {
      await createProduct(storyData.cards[activeCardInd]._id, {
        url: "",
        pos: {
          left:
            (e.pageX -
              document.getElementById("activeCard").getBoundingClientRect()
                .left -
              document.documentElement.scrollLeft) /
            scaleSize[0],
          top:
            (e.pageY -
              document.getElementById("activeCard").getBoundingClientRect()
                .top -
              document.documentElement.scrollTop) /
            scaleSize[1],
        },
        error: true,
      });
    } else {
      document.removeEventListener("mouseup", handleClickStory);
      setHint(false);
    }
  };

  const updateProduct = async (index, value) => {
    setProdParams(storyData.cards[activeCardInd]._id, index, value);
    if (value.url) {
      let shopifyId = "";
      await fetch(value.url + ".json")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          shopifyId = data.product.variants[0].id;
        })
        .catch((e) => {
          console.log(e);
        });
      fetchProduct(
        shopifyId,
        value.url,
        storyData.cards[activeCardInd]._id,
        index
      );
    }
  };

  const handleProductDelete = (id) => {
    removeProduct(storyData.cards[activeCardInd]._id, id);
  };

  //Instargams actions
  const addInstagram = () => {
    setHint2(true);
    document.addEventListener("mouseup", handleInstagramStory);
  };

  //Add product at the click site on story card
  const handleInstagramStory = async (e) => {
    e.stopPropagation();
    if (document.getElementById("activeCard").contains(e.target)) {
      await createInstagram(storyData.cards[activeCardInd]._id, {
        url: "",
        name: "",
        pos: {
          left:
            (e.pageX -
              document.getElementById("activeCard").getBoundingClientRect()
                .left -
              document.documentElement.scrollLeft) /
            scaleSize[0],
          top:
            (e.pageY -
              document.getElementById("activeCard").getBoundingClientRect()
                .top -
              document.documentElement.scrollTop) /
            scaleSize[1],
        },
      });
    } else {
      document.removeEventListener("mouseup", handleInstagramStory);
      setHint2(false);
    }
  };

  const updateInstagram = (index, value) => {
    setInstagramParams(storyData.cards[activeCardInd]._id, index, {
      name: `https://www.instagram.com/${value.url}`,
      url: value.url,
    });
  };

  const handleInstagramDelete = (id) => {
    removeInstagram(storyData.cards[activeCardInd]._id, id);
  };

  // const handleStoryDelete = id => {
  //   setStories(removeFromArray(id, stories));
  // };

  const discoverClick = (url, isVideo = false) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status === 200) {
        var myBlob = this.response;
        setImage({ isVideo, full: url, blob: myBlob });
      }
    };
    xhr.send();
  };

  const customerClick = (cards) => {
    cards.forEach((e, i) => {
      addCustomerCard(storyData.cards.length + i, e);
    });
  };

  const publishNewStory = async () => {
    const publ = [];

    if (publish.includes(`home`)) {
      if (orgInfo && orgInfo.homepage) {
        publ.push(orgInfo.homepage);
      }
    }
    if (publish.includes(`product`))
      publ.push(
        storyData.cards.map((e) =>
          e.products
            ? e.products.map(
                (el) =>
                  normalizeUrl(el.url, {
                    forceHttps: true,
                    stripHash: true,
                    removeQueryParameters: true,
                    removeTrailingSlash: true,
                    removeSingleSlash: true,
                  }) + "/"
              )
            : null
        )
      );
    if (publish.includes(`url`))
      publ.push(
        pasted.map(
          (e) =>
            normalizeUrl(e, {
              forceHttps: true,
              stripHash: true,
              removeQueryParameters: true,
              removeTrailingSlash: true,
              removeSingleSlash: true,
            }) + "/"
        )
      );

    const barsData = await Promise.all(
      _.uniq(_.flattenDeep(publ)).map(
        (e) =>
          new Promise(async (resolve, reject) => {
            const result = data.filter((el) => el.title === e);
            if (result.length !== 0) {
              resolve(result[0].id);
            } else {
              var barId = await createStorybar({ name: e, authToken: idToken });
              resolve(barId);
            }
          })
      )
    );

    await publishStory({
      authToken: idToken,
      storyId: match.params.id,
      publishData: barsData.map((e) => ({ id: e, duration: 0 })),
    });

    history.replace("/stories");
  };

  useEffect(() => {
    if (active === 1) {
      console.log("123");
    } else {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
  }, [active]);

  return !_.isEmpty(storyData) ? (
    <Transition>
      <div className="createstory_page" id="createstory_page">
        {preview ? (
          <PreviewPage story={storyData} setClose={setPreview} />
        ) : null}
        {colorOpen || edited !== null ? (
          <Colorpicker
            colors={colors}
            setColor={setColor}
            isText={colorTarget !== "back"}
            activeColor={
              colorTarget === "back"
                ? storyData.cards[activeCardInd].backgroundId || 0
                : _.hasIn(
                    storyData,
                    `cards.[${activeCardInd}].texts[${activeText}].styles`
                  )
                ? storyData.cards[activeCardInd].texts[activeText].styles
                    .colorId || 0
                : 0
            }
          />
        ) : null}
        <div className="container">
          <div className="createstory__header">
            <div className="back__button" onClick={() => history.goBack()}>
              <BackArrow /> <span>Back</span>
            </div>

            <div className="createstory__title">
              <InputTitle
                title={storyData.title || ""}
                setTitle={titleChange}
                placeholder="Highlight title"
              />
            </div>

            <div className="createstory_settings">
              <div className="setting_button">
                <Dropdown
                  button={<TreeDot />}
                  isOpen={dropOpen}
                  setIsOpen={setDropOpen}
                  noOver
                  className="toRightTop"
                  dropdown={
                    <div className="setting__block">
                      <div className="setting__part sp1">
                        <p className="setting__link fi1">Support</p>
                        <p
                          className="setting__link"
                          onClick={() => {
                            setTutorial(1);
                            setDropOpen(false);
                          }}
                        >
                          View tutorial
                        </p>
                      </div>
                      <div className="setting__part">
                        <p className="setting__link">FAQ</p>
                      </div>
                    </div>
                  }
                />
              </div>
              {edited === null ? (
                <Modal
                  toggle={
                    <div
                      className={`publish_button cursor__button${
                        !storyData.cards.every((e) => e.backgroundMedia)
                          ? " disabled"
                          : ""
                      }`}
                    >
                      <Publish /> <span>Publish</span>
                    </div>
                  }
                  isDisabled={!storyData.cards.every((e) => e.backgroundMedia)}
                  title="Where do you want to publish stories?"
                  titleMob="Publish story"
                  subtitle="Easily mark where you would like your stories to publish"
                  subtitleMob="Easily mark where you would like your stories to publish"
                  onSubmit={publishNewStory}
                  onCancel={() => openStory({ storyData })}
                  className="publish__modal"
                  submit={
                    <>
                      <Publish /> <span>Publish</span>
                    </>
                  }
                  cancel={
                    <>
                      <Preview /> <span>Preview</span>
                    </>
                  }
                >
                  <p className="publish__pinktext">
                    Stories will be published on product pages - only if tagged
                  </p>
                  <CheckGroup
                    className="publish__checkgroup"
                    onChange={setPublish}
                    current={publish}
                    items={[
                      {
                        name: "home",
                        title: "Home page",
                        icon: <HomePage />,
                        defaultCheck: true,
                      },
                      {
                        name: "product",
                        title: "Product page",
                        icon: <ProductPage />,
                      },
                      { name: "url", title: "Paste URL", icon: <PasteUrl /> },
                    ]}
                  />
                  {publish.includes(`url`) ? (
                    <>
                      <div className="publish_input_container">
                        <Input
                          label="Add URL"
                          type="text"
                          name="url"
                          placeholder="Please enter the URL"
                          value={pastedUrl}
                          onChange={(e) => setPastedUrl(e.target.value)}
                        />
                        <div
                          className="small__button button__default"
                          onClick={() => {
                            if (pastedUrl) {
                              const arr = [...pasted];
                              arr.push(pastedUrl);
                              setPastedUrl("");
                              setPasted(arr);
                            }
                          }}
                        >
                          Save URL
                        </div>
                      </div>
                      <div className="publish__urls">
                        {pasted.map((e) => (
                          <p className="publish__urltext">{e}</p>
                        ))}
                      </div>
                    </>
                  ) : null}
                </Modal>
              ) : null}
            </div>
            <div className="close__button" onClick={() => history.goBack()}>
              <Close />
            </div>
          </div>
          <div className="createstory_container">
            <div
              className={`roundbuttons${
                storyData.cards[activeCardInd]?.backgroundMedia
                  ? " withMedia"
                  : ""
              }`}
            >
              <RoundButton2
                id="b1"
                activeClass="active__round"
                toBottom
                isAuto
                index={1}
                setActive={setActive}
                isActive={active === 1}
                isOpenProp={active === 1}
                onClose={() => {
                  console.log("close");
                }}
                icon={<PictureIcon />}
                isTutorial={tutorial === 1}
                disabled={active !== 1 && active !== null}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Upload a photo/video or take live"
                    hint="Upload a photo/video from your device or take live (max. size of file is 128Mb)."
                    mobileHint="Upload a photo/video from your device or take live."
                  />
                }
              >
                <LoadImage setImage={setImage} discoverClick={discoverClick} />
              </RoundButton2>
              <RoundButton2
                icon={<TextIcon />}
                id="b2"
                activeClass="active__round"
                isTutorial={tutorial === 2}
                setActive={setActive}
                index={2}
                isText
                onOpen={() => {
                  setColorOpen(true);
                  setColorTarget("text");
                  addText();
                }}
                onClose={() => {
                  setColorOpen(false);
                }}
                isAuto
                isOpen={edited !== null}
                mustOpen={edited !== null}
                disabled={active !== 2 && active !== null}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Add and edit story text"
                    hint="Select this section to add text, edit its size, style, color and position."
                    mobileHint="Add text, edit its size, style, color and position."
                  />
                }
              >
                <FontStyle
                  setFontStyle={setFontStyle}
                  setColorTarget={setColorTarget}
                  {...Object.assign(
                    newText.styles,
                    _.hasIn(
                      storyData,
                      `cards.[${activeCardInd}].texts[${activeText}].styles`
                    )
                      ? storyData.cards[activeCardInd].texts[activeText].styles
                      : {}
                  )}
                  activeText={activeText}
                />
              </RoundButton2>

              <RoundButton2
                icon={<ArrowIcon />}
                id="b3"
                setActive={setActive}
                index={3}
                isText
                onClick={() => {}}
                isAuto
                isActive={active === 3}
                activeClass="active__round"
                stroke
                isOpenProp={active === 3}
                isTutorial={tutorial === 3}
                disabled={active !== 3 && active !== null}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Upload Image/Video from Discover"
                    hint="Select pre-made stories and just add them here."
                    mobileHint="Select pre-made stories and just add them here."
                  />
                }
              >
                <Share
                  data={
                    discover ? _.flatten(discover.map((e) => e.data)) : null
                  }
                  dataFetched={discoverFetched && customerFetched}
                  dataFetching={discoverFetching && customerFetching}
                  discoverClick={discoverClick}
                  customerData={customerData}
                  customerClick={customerClick}
                />
              </RoundButton2>

              <RoundButton2
                icon={<PackIcon />}
                id="b4"
                activeClass="active__round"
                isTutorial={tutorial === 4}
                disabled={active !== 4 && active !== null}
                isColor={isHint}
                isAuto
                isRule={
                  storyData.cards[activeCardInd]?.products &&
                  storyData.cards[activeCardInd].products.length !== 0
                }
                isActive={active === 4}
                setActive={setActive}
                onOpen={addProduct}
                index={4}
                data={storyData.cards[activeCardInd]?.products}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Tag products"
                    hint="Tag the product, paste the URL and make this story shoppable."
                    mobileHint="Paste the URL and make this story shoppable."
                  />
                }
              >
                <Links
                  products={storyData.cards[activeCardInd]?.products}
                  handleChange={updateProduct}
                  handleDelete={handleProductDelete}
                  setHovered={setHovered}
                />
              </RoundButton2>

              <RoundButton2
                id="b5"
                activeClass="active__round"
                toBottom
                isBottom
                isAuto
                index={5}
                setActive={setActive}
                isActive={active === 5}
                isOpenProp={active === 5}
                data={null}
                icon={
                  <CoverPhoto
                    color={
                      active === 5 || tutorial === 5 ? "#F8F9FA" : "#20292C"
                    }
                  />
                }
                isTutorial={tutorial === 5}
                disabled={active !== 5 && active !== null}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Upload a cover photo"
                    hint="Upload a cover photo which will be shown on your story preview."
                    mobileHint="Upload a cover photo which will be shown on your story preview."
                  />
                }
              >
                <div className="setting__block">
                  <div className="setting__part sp1">
                    <p className="setting__link fi1">
                      <label htmlFor="device" style={{ width: 34, height: 34 }}>
                        Upload
                        <input
                          type="file"
                          accept="image/*"
                          id="device"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setActive(null);
                            if (e.target.files[0]) {
                              setCover(e.target.files[0]);
                            }
                          }}
                        />
                      </label>
                    </p>
                  </div>
                  <div className="setting__part">
                    <p
                      className="setting__link error"
                      onClick={() => {
                        setActive(null);
                        setStoryCover(false);
                      }}
                    >
                      Remove
                    </p>
                  </div>
                </div>
              </RoundButton2>

              <RoundButton2
                icon={<Instagram />}
                id="b6"
                activeClass="instagram__contain"
                isBottom
                disabled={active !== 6 && active !== null}
                isAuto
                isColor={isHint2}
                isRule={
                  !!(
                    storyData.cards[activeCardInd]?.instagrams &&
                    storyData.cards[activeCardInd].instagrams.length !== 0
                  )
                }
                isActive={active === 6}
                setActive={setActive}
                onOpen={addInstagram}
                index={6}
                data={storyData.cards[activeCardInd]?.instagrams}
                isTutorial={tutorial === 6}
                tutotial={
                  <TutorialCard
                    setTutorial={setTutorial}
                    title="Tag instagram profiles"
                    hint="Insert instagram profile name here to tag customers."
                    mobileHint="Insert instagram profile name here to tag customers."
                  />
                }
              >
                <Links
                  products={storyData.cards[activeCardInd]?.instagrams}
                  handleChange={updateInstagram}
                  handleDelete={handleInstagramDelete}
                  setHovered={setHoveredInst}
                  par={{
                    placeholder: "Type Instagram user name",
                    name: "Name",
                  }}
                  isInst
                />
              </RoundButton2>
            </div>
            <EditStoriesContainer
              hideSound={hideSound}
              storyData={storyData}
              cards={storyData.cards}
              tutorial={tutorial}
              setTutorial={setTutorial}
              setStories={(a, b, c) => setStories(a, b, c)}
              updateText={updateText}
              addCard={addNewCard}
              activeText={activeText}
              hovered={hovered}
              hoveredInst={hoveredInst}
              setActiveText={setActiveText}
              setEdited={setEdited}
              edited={edited}
              isHint={isHint || isHint2}
              handleTextDelete={handleTextDelete}
              activeCard={activeCardInd}
              setColorOpen={setColorOpen}
              colorOpen={active}
              setColorTarget={setColorTarget}
              size={size}
              setSize={setSize}
            />

            {isHint && (
              <>
                <div className="hint desk">
                  Please click on the product you wish to tag
                </div>
                <div className="hint mobile">
                  Click on the product you wish to tag
                </div>
              </>
            )}
            {isHint2 && (
              <>
                <div className="hint desk">Tap the photo to tag people</div>
                <div className="hint mobile">Tap the photo to tag people</div>
              </>
            )}
          </div>
        </div>
      </div>
    </Transition>
  ) : null;
};

const mapStateToProps = (state) => {
  const editStory = state.editStory.toJS();
  let { fetched, fetching, storyData, activeCardInd, isValidInstagram } =
    editStory;
  let newStoryData = { ...storyData };
  if (newStoryData.cardOrder) {
    newStoryData.cards = filterCards(storyData);
  }

  const auth = state.auth.toJS();
  let { idToken, authToken, orgInfo } = auth;

  const storybars = state.storybars.toJS();
  let { data } = storybars;

  const stories = state.stories.toJS();
  let {
    discover,
    discoverFetched,
    discoverFetching,
    customerFetching,
    customerFetched,
    customerData,
  } = stories;

  return {
    orgInfo,
    fetched,
    customerFetching,
    customerFetched,
    customerData,
    fetching,
    activeCardInd,
    storyData: newStoryData,
    authToken,
    idToken,
    data,
    discover,
    discoverFetched,
    discoverFetching,
    isValidInstagram,
  };
};

const mapDispatchToProps = {
  getStory,
  setStoryTitle,
  setCardBackgroundImage,
  removeInstagram,
  createInstagram,
  setInstagramParams,
  removeText,
  setTextParam,
  setSnapshot,
  getCustomerStoriesList,
  createText,
  makeTextActive,
  removeProduct,
  createProduct,
  setActiveCard,
  publishStory,
  setProdParams,
  addCustomerCard,
  fetchProduct,
  getDiscoverList,
  createStorybar,
  resetStoryData,
  setStoryCover,
  setCardOrder,
  resetStoriesData,
  setCardBackgroundColor,
  uploadCardBackground,
  addStoryDelta,
  addCard,
  removeCard,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ..._.mapValues(dispatchProps, (actionCreator) =>
    _.partialRight(actionCreator, stateProps.storyData.id, stateProps.idToken)
  ),
  ..._.mapValues(
    {
      setCardBackgroundImage,
      removeText,
      setTextParam,
      createText,
      makeTextActive,
      removeProduct,
      createProduct,
      setSnapshot,
      setProdParams,
      fetchProduct,
      removeInstagram,
      createInstagram,
      setInstagramParams,
      setCardBackgroundColor,
      addCustomerCard,
      createStorybar,
      addCard,
    },
    (action, actionName) =>
      _.partialRight(
        dispatchProps[actionName],
        stateProps.storyData.id,
        stateProps.idToken
      )
  ),
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateStory);
