import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
  StoryCard,
  Dropdown,
  Switcher,
  HighlightCard,
  Input,
} from "../../components/common";
import { Sort, SortBy } from "../../icons/icons";
import story from "../../images/story.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const filterCards = (obj) => {
  if (obj.cardOrder) {
    let cardOrder = JSON.parse(obj.cardOrder).order;
    let unionArr = _.union(
      cardOrder,
      obj.cards.map((e) => e._id)
    );

    return _.compact(
      unionArr.map((el) => {
        let isStory = _.findIndex(obj.cards, function (o) {
          return o._id === el;
        });
        if (isStory !== -1) return obj.cards[isStory];
        return undefined;
      })
    );
  } else {
    return obj.cards;
  }
};

const fetchData = [
  {
    date: "Today",
    title: "Title",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
    publishing: true,
  },
  {
    date: "Today",
    title: "Title",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 21, 2019",
    title: "Cream",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 21, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 22, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 22, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const StoriesPage = ({
  data2,
  storyData,
  loading = false,
  fetching,
  setStorybarOrder,
  handleCaptionSet,
}) => {
  const [dropOpen, setDropOpen] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [sortBy, setSortBy] = useState("date");
  const [show, setShow] = useState("stories");
  const [caption, setCaption] = useState(() => {
    return Object.fromEntries(
      data2.map((e) => {
        return [e.id, e.caption || ""];
      })
    );
  });

  const setData = (id, value) => {
    setCaption((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const filterObject = (obj) => {
    if (obj.storyOrder) {
      let unionArr = _.union(
        obj.storyOrder,
        obj.live.map((e) => e.storyId)
      );

      return _.compact(
        unionArr.map((el) => {
          let isStory = _.findIndex(obj.live, function (o) {
            return o.storyId === el;
          });
          if (isStory !== -1) return obj.live[isStory];
          return undefined;
        })
      );
    } else {
      return obj.live;
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const handleDragEnd = (result, index) => {
    if (!result.destination) {
      return;
    }

    const items2 = [...data2];

    const items = reorder(
      filterObject(items2[index]),
      result.source.index,
      result.destination.index
    );

    items2[index].live = items;
    items2[index].storyOrder = items.map((e) => e.storyId);

    setStorybarOrder(items2[index].storyOrder, items2[index].id, items2);
  };

  const draft = useMemo(
    () =>
      storyData ? _.concat(storyData.draft || [], storyData.history || []) : [],
    [storyData]
  );

  return (
    <div
      className={`stories__page2 page${loading ? " pholder_item" : ""}${
        fetching ? " blicking" : ""
      }`}
    >
      <div className="storiespage__settings">
        <Dropdown
          button={<Sort />}
          isOpen={dropOpen}
          setIsOpen={setDropOpen}
          className="setting"
          noOver
          dropdown={
            <div className="setting__block">
              <div className="setting__part">
                <p className="setting__title">Show</p>
                <p
                  className={`setting__link ${
                    show === "stories" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (show !== "stories") setShow("stories");
                  }}
                >
                  Stories
                </p>
                <p
                  className={`setting__link ${
                    show === "highlight" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (show !== "highlight") setShow("highlight");
                  }}
                >
                  Highlight
                </p>
              </div>
              <div className="setting__part">
                <p className="setting__title between">
                  Sort by <SortBy />
                </p>
                <p
                  className={`setting__link ${
                    sortBy === "date" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (sortBy !== "date") setSortBy("date");
                  }}
                >
                  Date created
                </p>
                <p
                  className={`setting__link ${
                    sortBy === "views" ? "active__link" : ""
                  }`}
                  onClick={() => {
                    if (sortBy !== "views") setSortBy("views");
                  }}
                >
                  Most views
                </p>
                <p
                  className="setting__link between"
                  onClick={() => setIsLive((o) => !o)}
                >
                  Live only
                  <Switcher isOpen={isLive} setIsOpen={() => null} />
                </p>
              </div>
              <div className="setting__part">
                <p
                  className="setting__link error"
                  onClick={() => {
                    setSortBy("date");
                    setShow("stories");
                    setIsLive(false);
                  }}
                >
                  Clear all filter
                </p>
              </div>
            </div>
          }
        />
      </div>
      <div className="container">
        {show === `stories` ? (
          <>
            {data2.map((el, i) =>
              el.live && el.live.length !== 0 ? (
                <DragDropContext
                  key={el.id + `-` + i}
                  onDragEnd={(res) => handleDragEnd(res, i)}
                >
                  <div className="stories__line">
                    <div className="stories__date phh underline flex-between">
                      <div>{el.title}</div>
                      <div className="input__button">
                        <Input
                          type="text"
                          name="caption"
                          placeholder="Story bar title"
                          value={caption[el.id] || ""}
                          onChange={(e) => {
                            setData(el.id, e.target.value);
                          }}
                        />
                        <div
                          className="small__button button__default"
                          onClick={() => {
                            if (caption[el.id] || caption[el.id] === "") {
                              handleCaptionSet(caption[el.id], el.id);
                            }
                          }}
                        >
                          Place
                        </div>
                      </div>
                    </div>
                    <Droppable
                      droppableId={"droppable" + i}
                      direction="horizontal"
                    >
                      {(provided, snapshot) => (
                        <div
                          className="stories__container"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {filterObject(el).map((e, i) => {
                            const cards = filterCards(e);
                            return (
                              <Draggable
                                key={e.id + `-` + i}
                                draggableId={e.id + `-` + i}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    key={e.id + `-` + i}
                                    className="st__wrap"
                                  >
                                    <StoryCard
                                      id={e.storyId}
                                      barId={el.id}
                                      story={e}
                                      isDraft={e.isDraft}
                                      title={e.title || ""}
                                      snapshot={cards[0].snapshot}
                                      image={
                                        e.coverImage
                                          ? e.coverImage
                                          : cards[0].backgroundMedia
                                          ? cards[0].backgroundMedia.thumb
                                            ? cards[0].backgroundMedia.thumb
                                            : cards[0].backgroundMedia.original
                                            ? cards[0].backgroundMedia.original
                                            : null
                                          : null
                                      }
                                      isSnapshot={!e.coverImage}
                                      background={cards[0].backgroundColor}
                                      publishing={e.publishing}
                                      statistic={fetchData[0].statistic}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              ) : null
            )}
            {draft && draft.length !== 0 ? (
              <div className="stories__line">
                <div className="stories__date phh">Draft</div>
                <div className="stories__container">
                  {draft.map((e, i) => {
                    const cards = filterCards(e);
                    return (
                      <div className="st__wrap" key={e.id}>
                        <StoryCard
                          id={e.id}
                          story={e}
                          isDraft={true}
                          title={e.title || ""}
                          snapshot={cards[0].snapshot}
                          image={
                            e.coverImage
                              ? e.coverImage
                              : cards[0].backgroundMedia
                              ? cards[0].backgroundMedia.thumb
                                ? cards[0].backgroundMedia.thumb
                                : cards[0].backgroundMedia.original
                                ? cards[0].backgroundMedia.original
                                : null
                              : null
                          }
                          background={cards[0].backgroundColor}
                          publishing={e.publishing}
                          statistic={fetchData[0].statistic}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </>
        ) : !_.isEmpty(_.concat(storyData.live, draft)) ? (
          _.compact(_.concat(storyData.live, draft)).map((el) => (
            <div className="stories__line" key={el.id}>
              <div className="stories__date phh">{el.title || ""}</div>
              <div className="stories__container">
                {el.cards.map((e, i) => (
                  <HighlightCard
                    key={el.id}
                    info={el}
                    products={e.products}
                    image={
                      e.backgroundMedia
                        ? e.backgroundMedia.thumb
                          ? e.backgroundMedia.thumb
                          : e.backgroundMedia.original
                          ? e.backgroundMedia.original
                          : null
                        : null
                    }
                    snapshot={e.snapshot}
                  />
                ))}
              </div>
            </div>
          ))
        ) : null}
      </div>
    </div>
  );
};

export default StoriesPage;
