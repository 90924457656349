import React, { useState } from "react";
import { Modal, Input } from "../common";
import { connect } from "react-redux";
import { contactLiveStream } from "../../redux/actions/livesActions";

const ContactLive = ({
  children,
  profile,
  idToken,
  contactLiveStream,
  editLiveStream,
  date = "",
  time = "",
  products = [],
  name = "",
  influencer = "",
  instagram = "",
  isEdit,
  liveId,
}) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(() => ({
    name: "",
    email: "",
  }));

  const setData = (obj) => {
    setFormData((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const createLive = async () => {
    if (!formData.name) {
      setErrors((prev) => ({ ...prev, name: "This field is required" }));
      return;
    } else {
      const er = { ...errors };
      delete er.name;
      setErrors(er);
    }

    if (!formData.email) {
      setErrors((prev) => ({ ...prev, email: "This field is required" }));
      return;
    } else {
      const er = { ...errors };
      delete er.email;
      setErrors(er);
    }

    const res = await contactLiveStream({
      authToken: idToken,
      liveData: {
        name: formData.name,
        email: formData.email,
      },
    });

    console.log(res);

    clearData();
    return true;
  };

  const clearData = () => {
    setErrors({});
    setFormData({});
  };

  return (
    <>
      <Modal
        toggle={children}
        title="Book LIVE session"
        titleMob="Book LIVE session"
        subtitle="Interested in booking a LIVE session? Contact us and we’d love to help you out!"
        subtitleMob="Interested in booking a LIVE session? Contact us and we’d love to help you out!"
        onSubmit={() => createLive()}
        onCancel={() => clearData()}
        isBook
        className="live__modal-2"
        submit={<>Book LIVE session</>}
        cancel={<>Cancel</>}
      >
        <div className="live__reserv">
          <Input
            label="Full name"
            placeholder="Enter full name"
            name="name"
            error={errors.name}
            value={formData.name}
            required
            onChange={(e) => setData({ [e.target.name]: e.target.value })}
          />

          <Input
            label="Email address"
            placeholder="Enter email address"
            name="email"
            type="email"
            error={errors.email}
            value={formData.email}
            required
            onChange={(e) => setData({ [e.target.name]: e.target.value })}
          />
        </div>
      </Modal>
    </>
  );
};

// const Moo = () => (
//   <Modal
//     toggle={children}
//     title="Book LIVE session"
//     titleMob="Book LIVE session"
//     subtitle="Choose date/time and tag products for the LIVE session and share the URL with your Influencer"
//     subtitleMob="Choose date/time and tag products for the LIVE session and share the URL with your Influencer"
//     onSubmit={() => createLive()}
//     onCancel={() => clearData()}
//     isBook
//     className="live__modal"
//     submit={<>Book LIVE session</>}
//     cancel={<>Cancel</>}
//   >
//     <div className="products__cont">
//       <div className="book__live">
//         <div>
//           <Input
//             label="Influencer name"
//             placeholder="Enter influencer name"
//             name="name"
//             error={errors.name}
//             value={formData.name}
//             required
//             onChange={(e) => setData({ [e.target.name]: e.target.value })}
//           />
//         </div>
//         <div>
//           <Input
//             label="Instagram username"
//             placeholder="@"
//             name="instagram"
//             error={errors.instagram}
//             value={formData.instagram}
//             required
//             onChange={(e) => setData({ [e.target.name]: e.target.value })}
//           />
//         </div>
//       </div>
//       {!isChrome && (
//         <div className="book__live">
//           <div>
//             <Input
//               label="Date"
//               placeholder="Enter date"
//               name="date"
//               type="date"
//               error={errors.date}
//               value={formData.date}
//               required
//               onChange={(e) => setData({ [e.target.name]: e.target.value })}
//             />
//           </div>
//           <div>
//             <Input
//               label="Time"
//               placeholder="Enter time"
//               name="time"
//               type="time"
//               error={errors.time}
//               value={formData.time}
//               required
//               onChange={(e) => setData({ [e.target.name]: e.target.value })}
//             />
//           </div>
//         </div>
//       )}
//       {isChrome && (
//         <div>
//           <Input
//             label="Time"
//             placeholder="Choose time"
//             name="datetime"
//             type="datetime-local"
//             error={errors.datetime}
//             required
//             value={formData.datetime}
//             min={new Date().toISOString().slice(0, -8)}
//             onChange={(e) => setData({ [e.target.name]: e.target.value })}
//           />
//         </div>
//       )}
//       {/* <div>
//       <Input
//         label="Influencer email"
//         placeholder="Enter influncer email"
//         name="email"
//         onChange={(e) => setData({ [e.target.name]: e.target.value })}
//       />
//     </div> */}
//       <div className="custom__input">
//         <label className="input__label">Upload thumbnail</label>
//         <div className="image__upload">
//           <Avatar image={image} />
//           <div className="invite__buttons">
//             <div
//               className="small__button button__default"
//               onClick={() => {
//                 setImage(defaultImg);
//                 var xhr = new XMLHttpRequest();
//                 xhr.open("GET", defaultImg, true);
//                 xhr.responseType = "blob";
//                 xhr.onload = function (e) {
//                   if (this.status === 200) {
//                     var myBlob = this.response;
//                     setBlob(myBlob);
//                   }
//                 };
//                 xhr.send();
//               }}
//             >
//               Remove thumb
//             </div>
//             <label htmlFor="files" className="small__button button__accent">
//               Add thumb
//             </label>
//           </div>
//           <input
//             type="file"
//             accept="image/*"
//             id="files"
//             style={{ visibility: "hidden" }}
//             onChange={(e) => {
//               setImage(URL.createObjectURL(e.target.files[0]));
//               setBlob(e.target.files[0]);
//             }}
//           />
//         </div>
//       </div>
//       <div>
//         <Input
//           label="Tag products"
//           placeholder="Enter product name"
//           id="prodLab"
//         />
//         <div
//           className={`small__button button__accent${
//             isLoading ? ` disabled` : ``
//           }`}
//           onClick={() => {
//             getProduct(document.getElementById("prodLab").value);
//             document.getElementById("prodLab").value = "";
//           }}
//         >
//           Add product
//         </div>
//       </div>
//       <div className="product__container">
//         {prodData && prodData.length !== 0
//           ? prodData.map((e, i) => {
//               return (
//                 <div
//                   className="product__analytic"
//                   style={{ justifyContent: "flex-start" }}
//                   key={i}
//                 >
//                   <a href={e.url} target="_blank" rel="noopener noreferrer">
//                     <img
//                       src={e.image}
//                       alt="coollwwowoowow"
//                       className="pa__image"
//                     />
//                   </a>
//                   <div className="pa__name">{e.name}</div>
//                   <div className="pa__close" onClick={() => removeProduct(i)}>
//                     <Close />
//                   </div>
//                 </div>
//               );
//             })
//           : null}
//         {isLoading && (
//           <div style={{ position: "relative", height: 120, width: "100%" }}>
//             <Loader />
//           </div>
//         )}
//       </div>
//     </div>
//   </Modal>
// );

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { profile, idToken } = auth;

  return {
    profile,
    idToken,
  };
};

const mapDispatchToProps = {
  contactLiveStream,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactLive);
