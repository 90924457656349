import React, { useState, useEffect } from "react";
import {
  Transition,
  Input,
  Switcher,
  Modal,
  Avatar,
  Colorpicker,
  CheckBox,
} from "../components/common";
import {setOrgData, updateOrdData} from "../redux/actions/orgActions";
import { connect } from "react-redux";
import { getStorybars } from "../redux/actions/storybarsActions";
import { uploadFile } from "../redux/utils/fileUpload";
import "../scss/pages/ManageAccount.scss";
import { PhotosIcon } from "../icons/icons";
import background from "../images/WholeBackground.png";
import story from "../images/sroty.png";
import { onlyWithPermission } from "../helpers/accessControl";
import { Link } from "react-router-dom";
import cn from 'classnames'

let timeout;

const colors = [
  "#F8F9FA",
  "#51F357",
  "#5451F3",
  "#F38B51",
  "#51F3E9",
  "#8F51F3",
  "#F3CF51",
  "#51B9F3",
  "#F051F3",
  "#C9F351",
  "#5192F3",
  "#20292C",
];

const ManageAccount = ({
  profile,
  getStorybars,
  setOrgData,
  fetched,
  orgInfo,
  data,
  idToken,
  updateOrdData,
}) => {
  // const [state, setstate] = useState(true);
  const [price, setPrice] = useState(orgInfo.price);
  const [isPurchaseEmail, setIsPurchaseEmail] = useState(orgInfo.isPurchaseEmail);
  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const [back, setBack] = useState("color");
  const [image, setImage] = useState(background);
  const [color, setColor] = useState(0);
  const [error, setError] = useState(null);
  const [link, setLink] = useState("");
  const [color2, setColor2] = useState(orgInfo.color || "");
  const [type, setType] = useState(orgInfo.type || "circle");
  const [colorError, setColorError] = useState("");
  const [isOpen, setOpen] = useState(orgInfo.color === "none");
  const [validPreloader, setValidPreloader] = useState(false)
  const [savePreloader, setSavePreloader] = useState(false)
  useEffect(() => {
    if (!fetched) getStorybars({ authToken: idToken });
  }, [getStorybars, idToken, fetched]);



  const changeLink = (val) => {
    setLink(val);
    setValidPreloader(true)
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fetch(`https://api.dev.raddcontent.com/organization/story/validate`, {
        method: "POST",
        headers: {
          Authorization: idToken,
        },
        body: JSON.stringify({ invite: val }),
      })
        .then((x) => x.json())
        .then((res) => {
          setError(String(!res.result.valid));
          setValidPreloader(false)
        });
    }, 600);
  };

  const saveLink = () => {
    console.log('save link')
    if (link && error === "false") {
      setSavePreloader(true)
      fetch(
        `https://api.dev.raddcontent.com/organization/${orgInfo.alias}/story`,
        {
          method: "POST",
          headers: {
            Authorization: idToken,
          },
          body: JSON.stringify({ invite: link }),
        }
      ).then((x) => x.json())
          .then((res) => {
            console.log('res', res.result)
            updateOrdData(res.result)
            setSavePreloader(false)
          })
    }
  };

  const setNewOrgData = (orgData) => {
    setOrgData({
      authToken: idToken,
      orgAlias: orgInfo.alias,
      orgData,
    });
  };

  const setOrgLogo = async (file) => {
    let url = await uploadFile({
      authToken: idToken,
      blob: file,
      mediaType: "org_logo",
    });

    setNewOrgData({
      logo: url,
    });
  };

  const saveChanges = () => {
    if (isOpen) {
      setNewOrgData({
        color: "none",
        type,
      });
      return true;
    } else if (/^#([0-9a-f]{3}){1,2}$/i.test(color2)) {
      setNewOrgData({
        color: color2,
        type,
      });
      return true;
    } else {
      setColorError(`Invalid hex value`);
      return;
    }
  };

  const handlePrice = (e) => {
    setPrice(!price);
    setNewOrgData({
      price: !price,
    });
  };

  const handlePurchaseEmail = () => {
    setIsPurchaseEmail(!isPurchaseEmail);
    setNewOrgData({
      isPurchaseEmail: !isPurchaseEmail,
    });
  }

  const copyToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2px";
    textArea.style.height = "2px";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Copying text command was " + msg);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  return fetched ? (
    <Transition>
      <div className="account__page page">
        <div className="container">
          <div className="manage__account">
            <div className="personal__info">
              <div className="headline3">Personal info</div>
              <div className="account__container">
                <div>
                  <Input
                    label="E-mail"
                    placeholder="Please enter e-mail"
                    defaultValue={profile.email}
                  />
                  <Input
                    label="Company name"
                    placeholder="Please enter e-mail"
                    defaultValue={orgInfo.name}
                    onChange={(e) => console.log(e.target.value)}
                  />

                  <div className="image__upload" style={{ marginBottom: 50 }}>
                    <Avatar image={orgInfo.logo} />
                    <div className="invite__buttons">
                      <div className="small__button button__default">
                        Remove logo
                      </div>
                      <label
                        htmlFor="files"
                        className="small__button button__accent"
                      >
                        Add logo
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="files"
                        style={{ visibility: "hidden" }}
                        onChange={(e) => {
                          setOrgLogo(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  {/* <Input
                    label="Password"
                    type="password"
                    placeholder="Please enter e-mail"
                    defaultValue="000000"
                    onChange={(e) => console.log(e.target.value)}
                  /> */}
                </div>
              </div>
            </div>
            <div className="link__container">
              <div
                className={`custom__input ${
                  error ? (error === "true" ? "invalid" : "valid") : ""
                }`}
              >
                <label className="input__label">Invite link</label>
                <div className="link__show">
                  <div>{`${process.env.REACT_APP_CUSTOMER_PLATFORM}/brand/`}</div>
                  <input
                    className="new__input"
                    placeholder={orgInfo.invite || orgInfo.alias}
                    value={link}
                    onChange={(e) =>
                      changeLink(e.target.value.replace(/[^A-Za-z0-9]/gi, ""))
                    }
                  />
                  <div
                    className={cn("small__button button__accent", {
                      "disabled_button": validPreloader || savePreloader
                    })}
                    onClick={(e) => saveLink()}
                  >
                    { (validPreloader || savePreloader) && <img src="/images/loader_white.gif" className='loader_white' alt="loader_white"/>}
                    { validPreloader && 'Checking link' }
                    { savePreloader && 'Saving' }
                    { (!savePreloader && !validPreloader) && 'Save' }
                  </div>
                  <div
                    className="small__button button__default"
                    onClick={() =>
                      copyToClipboard(
                        `${process.env.REACT_APP_CUSTOMER_PLATFORM}/brand/${
                          orgInfo.invite || orgInfo.alias
                        }`
                      )
                    }
                  >
                    Copy
                  </div>
                </div>
              </div>
            </div>
            <div className="user__permission">
              <div className="headline3">Plan</div>
              <div className="plan-item">
                You current plan is{" "}
                <span className="plan-plan">{profile.plan}</span>{" "}
                <div className="small__button button__accent">
                  <Link to="plans">Upgrade plan</Link>
                </div>
              </div>
            </div>
            <div className="user__permission">
              <div className="headline3">User permission</div>
              <div className="account__container">
                <div>
                  <div className="permission__item">
                    <div>Hide product price</div>
                    <Switcher isOpen={price} setIsOpen={handlePrice}/>
                  </div>
                  <div className="permission__item">
                    <div>Automatic post-purchase invitation</div>
                    <Switcher isOpen={isPurchaseEmail} setIsOpen={handlePurchaseEmail}/>
                  </div>
                  {/* <div className="permission__item">
                    <div>Allow users to download stories</div>
                    <Switcher
                      isOpen={state}
                      setIsOpen={() => setstate((s) => !s)}
                    />
                  </div>
                  <div className="permission__item">
                    <div>Allow users to change background in stories</div>
                    <Switcher
                      isOpen={state}
                      setIsOpen={() => setstate((s) => !s)}
                    />
                  </div>
                  <div className="permission__item">
                    <div>Allow users to add text to stories</div>
                    <Switcher
                      isOpen={state}
                      setIsOpen={() => setstate((s) => !s)}
                    />
                  </div> */}
                </div>
              </div>
              <div className="account__buttons">
                {/* <div className="selectbar">
                    <div className="tt">Home page bar:</div>
                    <select className="small__button button__default">
                      {data.map((e, i) => (
                        <option key={i}>{e.title}</option>
                      ))}
                    </select>
                  </div> */}
                <div className="selectbar">
                  <div
                    className={`small__button${
                      !copy ? " button__default" : " button__success"
                    }`}
                    onClick={() => {
                      copyToClipboard(
                        `<div data-org-id='${profile.organization}'></div><script src='${process.env.REACT_APP_BRAND_PLATFORM}/assets/barloader.bundle.js' defer></script>`
                      );

                      setCopy(true);

                      setTimeout(() => {
                        setCopy(false);
                      }, 3000);
                    }}
                  >
                    {!copy ? "Copy dev code" : "Copied!"}
                  </div>
                </div>
                <div className="selectbar">
                  <div
                    className={`small__button${
                      !copy2 ? " button__default" : " button__success"
                    }`}
                    onClick={() => {
                      copyToClipboard(
                        `<div purchase-org-id='${profile.organization}'></div><script src='${process.env.REACT_APP_BRAND_PLATFORM}/assets/barloader.bundle.js' defer></script>`
                      );
                      setCopy2(true);

                      setTimeout(() => {
                        setCopy2(false);
                      }, 3000);
                    }}
                  >
                    {!copy2 ? "Copy purchase code" : "Copied!"}
                  </div>
                </div>
                <div className="selectbar">
                  <Modal
                    toggle={
                      <div className="small__button button__default">
                        Set default background
                      </div>
                    }
                    title="Set default background"
                    subtitle="Choose a background color or upload an image that customers will use when creating their stories"
                    onSubmit={() => {}}
                    onCancel={() => {}}
                    className="background__modal"
                    submit={<>Save background</>}
                    cancel={<>Cancel</>}
                  >
                    <div className="set__background">
                      <div
                        className={`radio__background${
                          back === "color" ? " active" : ""
                        }`}
                        onClick={() => setBack("color")}
                      >
                        <Colorpicker
                          colors={colors}
                          setColor={setColor}
                          activeColor={color}
                        />
                      </div>
                      <div
                        className={`radio__background${
                          back === "image" ? " active" : ""
                        }`}
                        onClick={() => setBack("image")}
                      >
                        <label className="upload_half" htmlFor="device">
                          <div className="upload_icon">
                            <PhotosIcon />
                          </div>
                          <p className="upload_subtitle">Upload image</p>
                          <input
                            type="file"
                            accept="image/*"
                            id="device"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setImage(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="back__example">
                        <div
                          className="story__example"
                          style={
                            back === "image"
                              ? { backgroundImage: `url("${image}")` }
                              : { backgroundColor: colors[color] }
                          }
                        >
                          <img src={story} alt="back__example" />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="selectbar">
                  <Modal
                    toggle={
                      <div className="small__button button__default">
                        Set default emoji
                      </div>
                    }
                    title="Set default emoji"
                    subtitle="Choose the emoji and reactions that users can post under your stories"
                    className="emoji__modal"
                    onSubmit={() => {}}
                    onCancel={() => {}}
                    submit={<>Save emoji</>}
                    cancel={<>Cancel</>}
                  >
                    <div className="emoji__container">
                      <Input label="Emoji" placeholder="Please choose emoji" />
                      <div className="small__button button__default">
                        Add more
                      </div>
                      <Input
                        label="Hashtag"
                        placeholder="Please enter hashtag"
                      />
                      <div className="small__button button__default">
                        Add more
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="selectbar">
                  <Modal
                    toggle={
                      <div
                        className="small__button button__default"
                        onClick={(e) => onlyWithPermission(e, "basic")}
                      >
                        Set tiles style
                      </div>
                    }
                    title="Set default HEX colour & story shape"
                    subtitle="Choose the emoji and reactions that users can post under your stories"
                    onSubmit={saveChanges}
                    onCancel={() => {}}
                    isBook
                    className="tiles-format__modal"
                    submit={<>Save changes</>}
                    cancel={<>Cancel</>}
                  >
                    <div className="tiles-format__container">
                      <Input
                        label="Story circle color"
                        placeholder="#FFFFFF"
                        onChange={(e) => setColor2(e.target.value)}
                        value={color2}
                        error={colorError}
                        disabled={isOpen}
                      />
                      <CheckBox
                        isOpen={isOpen}
                        setOpen={() => setOpen(!isOpen)}
                        text="No highlight frame"
                      />
                      <div className="tiles-format">
                        <div className="tiles-format__header">
                          <div className="tiles-fromat__title">
                            Tiles format
                          </div>
                          <div className="tiles-fromat__button button__primary small__button">
                            Premium
                          </div>
                        </div>
                        <div className="tiles-fromat__choose">
                          <div
                            className={`tiles-fromat__item bordered${
                              type !== "square" ? " active" : ""
                            }`}
                            onClick={() => setType("circle")}
                          />
                          <div
                            className={`tiles-fromat__item${
                              type === "square" ? " active" : ""
                            }`}
                            onClick={() => setType("square")}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  ) : null;
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { profile, idToken, orgInfo, error } = auth;

  const storybars = state.storybars.toJS();
  let { fetched, fetching, data } = storybars;

  return {
    profile,
    fetched,
    fetching,
    orgInfo,
    data,
    idToken,
    error,
  };
};

const mapDispatchToProps = {
  getStorybars,
  setOrgData,
  updateOrdData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
