import React, { useState } from "react";
import "../../scss/components/PlanCard.scss";
import check from "../../images/checkch.svg";

const PlanCard = ({
  isActive,
  disabled = false,
  name,
  title,
  subtitle,
  image,
  buttonClick,
  cardClick,
  price,
  points,
  isEnter = false,
}) => {
  const [opened, setOpened] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    if (disabled) return;
    cardClick(name);
    setOpened(!opened)
  };

  // useEffect(() => {
  //   setOpened(false);
  // }, [isActive]);

  return (
    <div
      className={`plan__card ${
        disabled
          ? "card__disabled card__active"
          : opened
          ? "card__opened"
          : "card__active"
      }`}
      onClick={handleClick}
      onMouseLeave={() => document.documentElement.clientWidth > 992 ? setOpened(false) : null}
      onMouseEnter={() => document.documentElement.clientWidth > 992 ? setOpened(true) : null}
    >
      {!opened ? (
        <>
          <div>
            <div className="card__title">{title}</div>
            <div className="card__subtitle">{subtitle}</div>
          </div>
          <div className="card__image">
            <img src={image} alt="" />
          </div>
          <div className="card__button">
            {isEnter ? "Contact us" : `${name === 'freemium' ? 'Click to start' : `${price}/mo`}`}
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="card__plan">{title}</div>
            <div className="card__price">
              {isEnter ? "Starting from " : ""}
              { price === 'for free' && 'free to use' }
              { price !== 'for free' &&  `${price}/month`}
            </div>
          </div>
          <div className="card__points">
            {points.map((e) => (
              <div className="card__points-item" key={e}>
                {e ? (
                  <>
                    <div className="card__points-item__check">
                      <img src={check} alt="" />
                    </div>
                    <div className="card__points-item__text">{e}</div>
                  </>
                ) : (
                  <>
                    <div
                      className="card__points-item__text"
                      style={{ marginLeft: 23 }}
                    >
                      PLUS
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="card__button" onClick={() => buttonClick(name)}>
            {isEnter ? "Contact us" : "Select plan"}
          </div>
        </>
      )}
    </div>
  );
};

export default PlanCard;
