import React from "react";
import { useSelector } from "react-redux";
import "../../scss/components/PricingCard.scss";

const PricingCard = ({ title, features, price, subprice, plan }) => {
  const { profile } = useSelector((state) => state.auth.toJS());

  const handlePlanUpgrade = () => {
    if (profile.shop_url) {
      if (profile.storeDeleted) {
        window.top.location.replace(
          `https://app.raddcontent.com/shopapp/install.php?shop=${profile.shop_url}`
        );
        return;
      }
      window.top.location.replace(
        `https://${
          profile.shop_url
        }/admin/apps/radd-1/shopapp/payment.php?plan=${title.toLowerCase()}`
      );
    } else {
      window.top.open(`https://raddcontent.com/contact.html`, "_blank");
    }
  };

  return (
    <div className="pricing-card">
      <div className="pricing-card__title">{ title }</div>
      <div className="pricing-card__content">
        <ul>
          {features.map((feature) => (
            <li>{ feature }</li>
          ))}
        </ul>
        <div className="pricing-card__content-info">
          <p className="pricing-card__content-price">{ price }</p>
          <p className="pricing-card__content-subprice">{ subprice }</p>
          {plan !== profile.plan &&
           <button className="button button--accent" onClick={handlePlanUpgrade}>
            Upgrade to {title}
          </button>
          }
          {plan === profile.plan &&
              <button className="button button--disabled">
                Current plan
              </button>
          }
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
