import { setAuthToken, instance, fetchRequest } from "./fetchData";

export function fetchStorybarData({ authToken, barId }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/story-bar/${barId}`));
}

export function fetchRemoveStorybarData({ authToken, barId, storyId }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/story-bar/${barId}/remove`, { storyId }));
}
