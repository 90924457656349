import React from "react";
import { Transition, PricingCard } from "../../components/common";
import { plans } from "../../helpers/storiesHelper";
import "../../scss/pages/Pricing.scss";

const Pricing = () => {
  return (
    <Transition>
      <div className="page-wrapper pricing-page">
        <h2 className="pricing-page__title">Pricing</h2>
        <div className="pricing-page__content">
            <PricingCard
                title="Freemium"
                plan={plans.freemium.name}
                features={[
                    `Up to ${plans.freemium.storyLimitation} Shoppable story reviews`,
                    "Shoppable stories",
                    "Video gallery",
                ]}
                price="free to use"
            />
          {/*<PricingCard*/}
          {/*  title="Booster"*/}
          {/*  features={[*/}
          {/*    `Up to ${plans.booster.storyLimitation} Shoppable story reviews`,*/}
          {/*    "All Basic Features",*/}
          {/*    "Offer in-story promotions",*/}
          {/*  ]}*/}
          {/*  price="$150/month"*/}
          {/*  subprice="or $1,500/year and save 17%"*/}
          {/*/>*/}
          <PricingCard
            title="Advanced"
            plan={plans.advanced.name}
            features={[
              `Up to ${plans.advanced.storyLimitation} Shoppable story reviews`,
              "All Basic Features",
              "Place Stories on product pages",
              "Choose Layout",
              "Full analytics",
              "No radd. Logo",
            ]}
            price="$250/month"
            subprice="or $2,000/year and save 33%"
          />
          <PricingCard
            title="Pro"
            plan={plans.pro.name}
            features={[
              `Up to ${plans.pro.storyLimitation} Shoppable story reviews`,
              "All Advanced Features",
              "Choose YOUR Layout",
              "Full analytics",
              "No radd. Logo",
              "SCHEDULE LIVE STORIES",
            ]}
            price="$600/month"
            subprice="or or $5,500/year and save 24%"
          />
        </div>
        <p className="pricing-page__note">
          All charges are billed in USD. Recurrent and usage-based charges are
          billed every 30 days
        </p>
      </div>
    </Transition>
  );
};

export default Pricing;
