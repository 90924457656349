import React from 'react';
import '../../scss/components/Switcher.scss';

const Switcher = ({ isOpen, setIsOpen }) => (
	<span className={`switch ${isOpen ? 'switch__active' : ''}`} onClick={() => setIsOpen((o) => !o)}>
		<span className="switch__thumb" />
	</span>
);

export default Switcher;
