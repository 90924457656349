import React from "react";

const ImageCard = ({ image, discoverClick }) => (
  <div
    className="gallery__item"
    style={{ backgroundImage: `url('${image}')` }}
    onClick={discoverClick}
  />
);

export default ImageCard;
