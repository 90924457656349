import story from "../images/story.png";

export const mockAnalytics = [
  {
    date: "Today",
    title: "Title",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
    publishing: true,
  },
  {
    date: "Today",
    title: "Title",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 21, 2019",
    title: "Cream",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 21, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 22, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 22, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Nails",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
  {
    date: "December 28, 2019",
    title: "Lipstick",
    image: story,
    statistic: {
      unique: "87,232",
      views: "21,423",
      clicks: "9,147",
      purchases: "245",
    },
  },
];

export const mockStories = [
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "1",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "2",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "3",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "4",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550634sdf2312312310-808f-11ea-ae73-45b3e68348290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550634sdf2312312310-808f-11ea-ae73-45b3e68348290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550634sdf2312312310-808f-11ea-ae73-45b3e68348290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550634sdf2312312310-808f-11ea-ae73-45b3e68348290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
];
