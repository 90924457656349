import React, { useState, useEffect } from "react";
import { ThreeStep } from "../../components/common";
import { Header, NoLoginHeader } from "../../components/containers";
import { useLocation } from "react-router-dom";
import PlanChoose from "./PlanChoose";
import RegistrationForm from "./RegistrationForm";
import { Link } from "react-router-dom";
import { HomeIcon } from "../../icons/icons";
import "../../scss/pages/Registration.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Registration = ({ match }) => {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState("pro");
  const [choosenPlan, setChoosenPlan] = useState("");
  const query = useQuery();

  useEffect(() => {
    const lock = document.querySelector(`.auth0-lock-overlay`);
    const closeLock = () => {
      document.querySelector(".auth0-lock-container").remove();
    };
    if (lock) {
      lock.addEventListener(`click`, closeLock);
    }
    return () => {
      if (lock) {
        lock.removeEventListener(`click`, closeLock);
      }
    };
  }, []);

  const planChoose = (name) => {
    setChoosenPlan(name);
    setStep(2);
  };

  return (
    <React.Fragment>
      {step < 3 ? <NoLoginHeader /> : <Header />}
      <div className="page registration withbutton">
        <div className="step__progress">
          <ThreeStep step={step} setStep={setStep} />
        </div>
        {step === 1 ? (
          <PlanChoose
            setPlan={setPlan}
            plan={plan}
            setChoosenPlan={planChoose}
            setStep={setStep}
          />
        ) : step === 2 ? (
          <RegistrationForm
            choosenPlan={choosenPlan}
            shopUrl={query.get("shop_url")}
            shopifyToken={query.get("shopify_token")}
          />
        ) : (
          <React.Fragment>
            <div className="page__text">
              <div className="page__title">Let the magic begin</div>
              <div className="page__subtitle">
                This is where you can start creating beautiful stories for your
                brand
              </div>
              <div className="page__subtitle mobile__sub">
                This is where you can start creating beautiful stories for your
                brand
              </div>
            </div>
            <div className="page__image">
              <HomeIcon />
            </div>
            <div className="page__button">
              <Link className="accent__button" to="/create-stories">
                Create Stories
              </Link>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Registration;
