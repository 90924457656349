import React from "react";
import { Link } from "react-router-dom";
import { StoriesCount } from "./";
import CreateStoryButton from "./CreateStoryButton";
import "../../scss/components/Topbar.scss";

const Topbar = () => {
  return (
    <div className="topbar-wrapper">
      <CreateStoryButton />
      <Link className="button button--secondary" to="/plans">
        Pricing
      </Link>
      <StoriesCount />
    </div>
  );
};

export default Topbar;
