import Immutable from "immutable";

import { actions } from "../actions/globalActions";

const initialState = Immutable.fromJS({
  sidebarExpanded:
    localStorage.getItem("sidebar-expanded") === "true" ? true : false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SIDEBAR_EXPAND:
      return state.set("sidebarExpanded", action.payload);

    default:
      return state;
  }
}
