const fetchProps = {
  headers: {
    Authorization: `Basic ZTc2MjdkY2Y0MGFlOTFhMzc3Zjg4ODYzYTE2ODZiY2Y6`,
  },
};

function formatDate(date = null) {
  var d = date ? new Date(date) : new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const getAnalytics = ({
  fromDate,
  toDate,
  orgId,
  isDesk = false,
  isMobile = false,
}) => {
  try {
    const data = new Promise((resolve, reject) => {
      fetch(
        `https://mixpanel.com/api/2.0/arb_funnels/?length=7&from_date=${formatDate(
          fromDate
        )}&to_date=${formatDate(
          toDate
        )}&events=%5B%7B%22event%22%3A%22Page loaded%22%7D%2C%7B%22event%22%3A%22Story View%22%7D%2C%7B%22event%22%3A%22Product+Click%22%7D%2C%7B%22event%22%3A%22Purchase+Made%22%7D%5D&where=(properties["org_id"]=="${orgId}")${
          isDesk
            ? ' and (not defined (properties["$device"]))'
            : isMobile
            ? ' and (defined (properties["$device"]))'
            : ""
        }`,
        fetchProps
      ).then((response) =>
        response.json().then((e) => {
          resolve([
            e.meta.dates.map((el) => e.data[el].steps[0].count),
            e.meta.dates.map((el) => e.data[el].steps[1].count),
            e.meta.dates.map((el) => e.data[el].steps[2].count),
            e.meta.dates.map((el) => e.data[el].steps[3].count),
          ]);
        })
      );
    });

    const data2 = new Promise((resolve, reject) => {
      fetch(
        `https://mixpanel.com/api/2.0/arb_funnels/?length=7&from_date=${formatDate(
          fromDate
        )}&to_date=${formatDate(
          toDate
        )}&events=%5B%7B%22event%22%3A%22Page loaded%22%7D%2C%7B%22event%22%3A%22Story View%22%7D%2C%7B%22event%22%3A%22Purchase+Made%22%7D%5D&where=(properties["org_id"]=="${orgId}")${
          isDesk
            ? ' and (not defined (properties["$device"]))'
            : isMobile
            ? ' and (defined (properties["$device"]))'
            : ""
        }`,
        fetchProps
      ).then((response) =>
        response.json().then((e) => {
          resolve([
            e.meta.dates.map((el) => e.data[el].steps[0].count),
            e.meta.dates.map((el) => e.data[el].steps[1].count),
            e.meta.dates.map((el) => e.data[el].steps[2].count),
          ]);
        })
      );
    });

    return Promise.all([data, data2]);
  } catch (e) {
    return;
  }
};

export const getStoryAnalytic = (id) => {
  return fetch(
    `https://mixpanel.com/api/2.0/events/?interval=60&type=general&event=%5B%22Product Click%22%2C+%22Story View%22%2C+%22Purchase Made%22%5D&unit=day&where=properties["story_id"]=="${id}"`,
    fetchProps
  );
};
