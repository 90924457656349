import React, { useState, useEffect } from "react";

const Transition = ({ children, transition = 1500, withbutton = false }) => {
  const [mount, setMount] = useState(false);

  useEffect(() => {
    const tt = setTimeout(() => {
      setMount(true);
    }, 0);
    return () => {
      clearTimeout(tt);
    };
  }, []);

  return (
    <div
      className={`transition__div${withbutton ? " startcht" : ""}`}
      style={
        !mount
          ? {width: '100%', opacity: 0, transition: `${transition / 1000}s` }
          : {width:'100%', opacity: 1, transition: `${transition / 1000}s` }
      }
    >
      {children}
    </div>
  );
};

export default Transition;
