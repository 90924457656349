import _ from "lodash";

export const filterStories = (obj) => {
  if (obj.storyOrder) {
    let unionArr = _.union(
      obj.storyOrder,
      obj.live.map((e) => e.storyId)
    );

    return _.compact(
      unionArr.map((el) => {
        let isStory = _.findIndex(obj.live, function (o) {
          return o.storyId === el;
        });
        if (isStory !== -1) return obj.live[isStory];
        return undefined;
      })
    );
  } else {
    return obj.live;
  }
};

export const filterCards = (obj) => {
  if (obj.cardOrder) {
    let cardOrder = JSON.parse(obj.cardOrder).order;
    let unionArr = _.union(
      cardOrder,
      obj.cards.map((e) => e._id)
    );

    return _.compact(
      unionArr.map((el) => {
        let isStory = _.findIndex(obj.cards, function (o) {
          return o._id === el;
        });
        if (isStory !== -1) return obj.cards[isStory];
        return undefined;
      })
    );
  } else {
    return obj.cards;
  }
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getCoverImage = (story, firstCard) => {
  return story.coverImage
    ? story.coverImage
    : firstCard.backgroundMedia
    ? firstCard.backgroundMedia.thumb
      ? firstCard.backgroundMedia.thumb
      : firstCard.backgroundMedia.original
      ? firstCard.backgroundMedia.original
      : null
    : null;
};

export const plans = {
  freemium: {
    name: "freemium",
    storyLimitation: 3,
    price: 0,
  },
  booster: {
    name: "booster",
    storyLimitation: 25,
    price: 99.99,
  },
  advanced: {
    name: "advanced",
    storyLimitation: 100,
    price: 299.99,
  },
  pro: {
    name: "pro",
    storyLimitation: 200,
    price: 599.99,
  },
  enterprise: {
    name: "enterprise",
    storyLimitation: 1000,
    price: 599.99,
  },
};

export const isStoryLimit = (stories, plan) => {
  const { total, max } = getStoriesCount(stories, plan);
  return total >= max;
};

export const getStoriesCount = (stories, plan, storyData, isEdit) => {
  let editStoryId = "";
  if (isEdit) {
    if (_.isEmpty(storyData)) {
      return { total: 0, max: 0 };
    }
    editStoryId = storyData.id;
  }

  if (_.isEmpty(stories) || !plan) {
    return { total: 0, max: 0 };
  }

  const filterFunc = (e) => {
    return e.id !== editStoryId;
  };

  const liveStories =
    stories.live?.filter(filterFunc).reduce((acc, el) => {
      return acc + (el.cards ? el.cards.length : 0);
    }, 0) || 0;
  const draftStories =
    stories.draft?.filter(filterFunc).reduce((acc, el) => {
      return acc + (el.cards ? el.cards.length : 0);
    }, 0) || 0;
  const historytStories =
    stories.history?.filter(filterFunc).reduce((acc, el) => {
      return acc + (el.cards ? el.cards.length : 0);
    }, 0) || 0;
  const editStories = isEdit ? storyData.cards.length : 0;
  const total = liveStories + draftStories + historytStories + editStories;
  const max = plans[plan].storyLimitation;

  return { total, max };
};

export const getShortUrl = (url) => {
  try {
    const { pathname } = new URL(url);
    return pathname?.slice(0, -1);
  } catch (e) {
    return "";
  }
};
