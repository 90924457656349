import {
  fetchStorybarsList,
  fetchSendDevCode,
  fetchUpdateStorybar,
  fetchRemoveStorybar,
  fetchCreateStorybar,
  fetchStorybarAnalytics,
  fetchSetStorybarName,
  fetchSetStorybarOrder,
  fetchSetStorybarCaption,
} from "../utils/fetchStorybars";

export const actions = {
  STORYBARS_LIST_PENDING: "STORYBARS_LIST_PENDING",
  STORYBARS_LIST_FULFILLED: "STORYBARS_LIST_FULFILLED",
  STORYBARS_LIST_REJECTED: "STORYBARS_LIST_REJECTED",
  RESET_STORYBARS_DATA: "RESET_STORYBARS_DATA",
  SEND_DEV_CODE_PENDING: "SEND_DEV_CODE_PENDING",
  SEND_DEV_CODE_FULFILLED: "SEND_DEV_CODE_FULFILLED",
  SEND_DEV_CODE_REJECTED: "SEND_DEV_CODE_REJECTED",
  SET_STORYBAR_NAME_PENDING: "SET_STORYBAR_NAME_PENDING",
  SET_STORYBAR_NAME_FULFILLED: "SET_STORYBAR_NAME_FULFILLED",
  SET_STORYBAR_NAME_REJECTED: "SET_STORYBAR_NAME_REJECTED",
  UPDATE_STORYBARS_LIST_PENDING: "UPDATE_STORYBARS_LIST_PENDING",
  UPDATE_STORYBARS_LIST_FULFILLED: "UPDATE_STORYBARS_LIST_FULFILLED",
  UPDATE_STORYBARS_LIST_REJECTED: "UPDATE_STORYBARS_LIST_REJECTED",
  UPDATE_STORYBAR_PENDING: "UPDATE_STORYBAR_PENDING",
  UPDATE_STORYBAR_FULFILLED: "UPDATE_STORYBAR_FULFILLED",
  UPDATE_STORYBAR_REJECTED: "UPDATE_STORYBAR_REJECTED",
  REMOVE_STORYBAR_PENDING: "REMOVE_STORYBAR_PENDING",
  REMOVE_STORYBAR_FULFILLED: "REMOVE_STORYBAR_FULFILLED",
  REMOVE_STORYBAR_REJECTED: "REMOVE_STORYBAR_REJECTED",
  CREATE_STORYBAR_PENDING: "CREATE_STORYBAR_PENDING",
  CREATE_STORYBAR_FULFILLED: "CREATYE_STORYBAR_FULFILLED",
  CREATE_STORYBAR_REJECTED: "CREATE_STORYBAR_REJECTED",
  STORYBAR_ANALYTICS_FULFILLED: "STORYBAR_ANALYTICS_FULFILLED",
  SET_STORYBAR_ORDER: "SET_STORYBAR_ORDER",
};

export function getStorybars({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.STORYBARS_LIST_PENDING,
    });

    try {
      let data = await fetchStorybarsList({ authToken });

      dispatch({
        type: actions.STORYBARS_LIST_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.STORYBARS_LIST_REJECTED,
        error,
      });
    }
  };
}

export function getStorybarsAnalytics({ authToken, idList }) {
  return async (dispatch) => {
    for (let i = 0; i < idList.length; ++i) {
      let storybarId = idList[i];

      try {
        let data = await fetchStorybarAnalytics({ authToken, storybarId });

        dispatch({
          type: actions.STORYBAR_ANALYTICS_FULFILLED,
          data: {
            storybarId,
            data: data.result,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export function resetStorybarsData() {
  return {
    type: actions.RESET_STORYBARS_DATA,
  };
}

export function sendDevCode({ storybarId, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SEND_DEV_CODE_PENDING,
    });

    try {
      let data = await fetchSendDevCode({ storybarId, authToken });

      dispatch({
        type: actions.SEND_DEV_CODE_FULFILLED,
        data,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SEND_DEV_CODE_REJECTED,
        error,
      });
    }
  };
}

export function setStorybarName({ storybarId, name, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_STORYBAR_NAME_PENDING,
    });

    try {
      let data = await fetchSetStorybarName({ storybarId, name, authToken });

      dispatch({
        type: actions.SET_STORYBAR_NAME_FULFILLED,
        data,
      });

      dispatch(updateStorybars({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SET_STORYBAR_NAME_REJECTED,
        error,
      });
    }
  };
}

export function setStorybarCaption({ storybarId, name, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_STORYBAR_NAME_PENDING,
    });

    try {
      let data = await fetchSetStorybarCaption({ storybarId, name, authToken });

      dispatch({
        type: actions.SET_STORYBAR_NAME_FULFILLED,
        data,
      });

      dispatch(updateStorybars({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.SET_STORYBAR_NAME_REJECTED,
        error,
      });
    }
  };
}

export function setStorybarOrder({ storybarId, order, newdata, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_STORYBAR_ORDER,
      data: newdata,
    });

    try {
      await fetchSetStorybarOrder({ storybarId, order, authToken });
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateStorybar({ storybarId, updateBody, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_STORYBAR_PENDING,
    });

    try {
      let data = await fetchUpdateStorybar({
        storybarId,
        updateBody,
        authToken,
      });

      dispatch({
        type: actions.UPDATE_STORYBAR_FULFILLED,
        data,
      });

      dispatch(updateStorybars({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UPDATE_STORYBAR_REJECTED,
        error,
      });
    }
  };
}

export function updateStorybars({ authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_STORYBARS_LIST_PENDING,
    });

    try {
      let data = await fetchStorybarsList({ authToken });

      dispatch({
        type: actions.UPDATE_STORYBARS_LIST_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UPDATE_STORYBARS_LIST_REJECTED,
        error,
      });
    }
  };
}

export function removeStorybar({ storybarId, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.REMOVE_STORYBAR_PENDING,
    });

    try {
      let data = await fetchRemoveStorybar({ storybarId, authToken });

      dispatch({
        type: actions.REMOVE_STORYBAR_FULFILLED,
        data,
      });

      dispatch(updateStorybars({ authToken }));
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.REMOVE_STORYBAR_REJECTED,
        error,
      });
    }
  };
}

export function createStorybar({ name, authToken }) {
  return async (dispatch) => {
    dispatch({
      type: actions.CREATE_STORYBAR_PENDING,
    });

    try {
      let data = await fetchCreateStorybar({ name, authToken });

      dispatch({
        type: actions.CREATE_STORYBAR_FULFILLED,
        data,
      });

      dispatch(updateStorybars({ authToken }));

      return Promise.resolve(data.result.id);
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.CREATE_STORYBAR_REJECTED,
        error,
      });
    }
  };
}
