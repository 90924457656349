import React from "react";
import { useSelector } from "react-redux";
import NavigationItem from "./NavigationItem";
import navigationRoutes from "../../../routes/navigationRoutes";
import client from "../../../redux/utils/client";
import { LogOut } from "../../../icons/icons";

const Navigation = ({ path }) => {
  const { customerData } = useSelector((state) => state.stories.toJS());

  const handleLogout = () => {
    client.logout();
  };

  return (
    <ul className="sidebar-navigation">
      {navigationRoutes.map((e) => (
        <NavigationItem
          url={e.path}
          title={e.title}
          icon={e.icon}
          isActive={path === e.path}
          plan={e.plan}
          isReview={e.isReview}
          reviewCount={customerData?.length}
        />
      ))}
      <li onClick={handleLogout}>
        <div className="sidebar-navigation__item sidebar-navigation__item--logout cursor-pointer">
          <div className="sidebar-navigation__item-link">
            <span className="sidebar-navigation__item-icon">
              <LogOut />
            </span>
            <span className="sidebar-navigation__item-title">Log Out</span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default Navigation;
