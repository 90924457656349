import React from "react";
import { Tutorials } from "../components/containers";
import { Transition } from "../components/common";

const ViewTutorial = () => {
  return (
    <Transition withbutton>
      <div className="viewtutorial__page page withbutton">
        <div className="page__title">Would you like to view a tutorial?</div>
        <div className="page__subtitle">
          The tutorial helps you to understand how to use the service and what
          are our advantages
        </div>
        <div className="page__subtitle mobile__sub">
          It helps to understand how to use the service and what are our
          advantages
        </div>
        <Tutorials />
      </div>
    </Transition>
  );
};

export default ViewTutorial;
