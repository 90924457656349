export const storySize = { width: 1242, height: 2208 };

export const textProps = {
  position: { left: 0, top: 323 },
  text: "",
  rotation: 0,
  scale: 1,
  styles: {
    fontWeight: "normal",
    fontStyle: "normal",
    color: "black",
    textAlign: "left",
  },
};

export const defaultImage =
  "https://cdn.dev.raddcontent.com/833faa90-d150-11ed-865d-03b245ba711c/64a2cb57-5a44-44df-9704-5ad92ec828ce.png";
