import React from "react";
import "../../scss/components/DefaultPage.scss";

const DefaultPage = ({ title, subtitle, icon, cta }) => {
  return (
    <div className="page-wrapper default-page">
      <div className="default-page__title">{title}</div>
      <div className="default-page__subtitle">{subtitle}</div>
      <div className="default-page__image">{icon}</div>
      <div className="default-page__cta">{cta}</div>
    </div>
  );
};

export default DefaultPage;
