import Immutable from 'immutable';

import {actions as A} from '../actions/campaignsActions';

const initialState = Immutable.fromJS({
    fetched: false,
    fetching: false,
    data: [],
    error: null,
    createCampaignFetched: false,
    createCampaignFetching: false,
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case A.GET_CAMPAIGNS_LIST_PENDING:
            return state
                .set('fetched', false)
                .set('fetching', true);
        case A.GET_CAMPAIGNS_LIST_FULFILLED:
            return state
                .set('fetched', true)
                .set('fetching', false)
                .set('data', action.data);
        case A.GET_CAMPAIGNS_LIST_REJECTED:
            return state
                .set('fetched', false)
                .set('fetching', false)
                .set('error', action.error);
            case A.CREATE_CAMPAIGN_PENDING:
            return state
                .set('createCampaignFetched', false)
                .set('createCampaignFetching', true);
        case A.CREATE_CAMPAIGN_FULFILLED:
            return state
                .set('createCampaignFetched', true)
                .set('createCampaignFetching', false);
        case A.CREATE_CAMPAIGN_REJECTED:
            return state
                .set('createCampaignFetched', false)
                .set('createCampaignFetching', false)
                .set('error', action.error);
        default:
            return state;
    }
}