import React from 'react'
import { RaddLogo } from '../../icons/icons'
import { Avatar, Transition } from '../common'
import EndedStreamImg from '../../images/endedStream.png'
import '../../scss/components/EndedStream.scss'

export const EndedStream = ({endStream, orgInfo}) => {
  return (
    <Transition withbutton>
      <div className="endedStream" style={{visibility: endStream ? "visible" : "hidden"}}>
        <div className="endedStream__powered">
          {orgInfo?.logo ?  <Avatar image={orgInfo.logo} /> : null}
          <p className='endedStream__powered--name'>{orgInfo?.name ? `${orgInfo.name}` : 'YOUR BRAND NAME'} </p>
          <p className='endedStream__powered--powered'>powered by </p>
          <RaddLogo  />
        </div>
        <div className="basicInformation">
          <div className="basicInformation__img">
            <img src={EndedStreamImg} alt={'ended stream'} />
          </div>
          <div className="basicInformation__text">
            <h2 className='basicInformation__text--h2'>Your LIVE stream has ended</h2>
            <p className='basicInformation__text--p'>Thank you for hosting this awesome live broadcast</p>
          </div>
        </div>
      </div>
    </Transition>
  )
}
