import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { CreateStoryButton, NewStoryCard } from "../../components/common";
import { Storybar, DefaultPage } from "../../components/containers";
import {
  getStorybars,
  setStorybarOrder,
} from "../../redux/actions/storybarsActions";
import { getStoriesList } from "../../redux/actions/storiesActions";
import {
  filterCards,
  filterStories,
  reorder,
  getCoverImage,
} from "../../helpers/storiesHelper";
import { StoryPage } from "../../icons/icons";
import { mockAnalytics, mockStories } from "../../mocks";

const Stories = () => {
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.auth.toJS());
  const { data, fetched, fetching, updateFetching } = useSelector((state) =>
    state.storybars.toJS()
  );
  const {
    data: storyData,
    fetched: storyFetched,
    fetching: storyFetching,
    deleteStoryFetching,
    updateFetching: updateStoryFetching,
  } = useSelector((state) => state.stories.toJS());

  const draft = useMemo(
    () =>
      storyData && (storyData.draft || storyData.history)
        ? _.concat(storyData.draft || [], storyData.history || [])
        : null,
    [storyData]
  );

  const draftData = useMemo(
    () => (storyFetched ? draft : mockStories[0].live),
    [draft, storyFetched]
  );

  const storybarData = useMemo(
    () => (fetched ? (data && data.length ? data : null) : mockStories),
    [data, fetched]
  );

  //Fetching storybars and stories
  useEffect(() => {
    dispatch(getStorybars({ authToken: idToken }));
    dispatch(getStoriesList({ authToken: idToken }));
    // eslint-disable-next-line
  }, [idToken, getStorybars, getStoriesList]);

  const handleDragEnd = (result, index) => {
    if (!result.destination) {
      return;
    }

    const items2 = [...data];

    const items = reorder(
      filterStories(items2[index]),
      result.source.index,
      result.destination.index
    );

    items2[index].live = items;
    items2[index].storyOrder = items.map((e) => e.storyId);

    dispatch(
      setStorybarOrder({
        storybarId: items2[index].id,
        order: items2[index].storyOrder,
        newdata: items2,
        authToken: idToken,
      })
    );
  };

  const isDataUpdating =
    deleteStoryFetching || updateFetching || updateStoryFetching;

  return storybarData || draftData ? (
    <div
      className={`page-wrapper stories-page${
        isDataUpdating ? " blicking" : ""
      }`}
    >
      <h2 className="stories-page__title">Stories</h2>
      <div
        className={`stories-page-storybars${fetching ? " pholder_item" : ""}`}
      >
        {storyData ? (
          <>
            {storybarData.map(
              (el, i) =>
                el.live &&
                el.live.length !== 0 && (
                  <Storybar
                    storyBarData={el}
                    index={i}
                    handleDragEnd={handleDragEnd}
                    idToken={idToken}
                  />
                )
            )}
          </>
        ) : (
          <div className="stories-page-storybars__no-storybars">
            <p>You currently have no published stories.</p>
          </div>
        )}
      </div>
      <div
        className={`stories-page-drafts${storyFetching ? " pholder_item" : ""}`}
      >
        {draftData ? (
          <>
            <h6 className="stories-page-drafts__title phh-hidden">
              Available drafts: {draftData.length}
            </h6>
            <div className="stories-page-drafts__container">
              {draftData.map((e, i) => {
                const cards = filterCards(e);
                return (
                  <NewStoryCard
                    key={e.id}
                    id={e.id}
                    story={e}
                    isDraft={true}
                    title={e.title || ""}
                    snapshot={cards[0].snapshot}
                    image={getCoverImage(e, cards[0])}
                    background={cards[0].backgroundColor}
                    publishing={e.publishing}
                    statistic={mockAnalytics[0].statistic}
                    idToken={idToken}
                    isLast={i % 2}
                  />
                );
              })}
            </div>
            <p className="stories-page-drafts__footer phh-hidden">
              <b>Please Note!</b> Drafts are included in your total story quota
            </p>
          </>
        ) : (
          <div className="stories-page-drafts__no-draft">
            <p>You currently have no story drafts</p>
            <CreateStoryButton />
          </div>
        )}
      </div>
    </div>
  ) : (
    <DefaultPage
      title="Are you ready to create your first radd. story?"
      subtitle="This is where you can start creating beautiful stories for your brand"
      icon={<StoryPage />}
      cta={<CreateStoryButton />}
    />
  );
};

export default Stories;
