import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import { ProductTag, ProductBox, InstagramTag } from "../common";
import { ProductBar } from "./";
import { BigBagIcon } from "../../icons/icons";
import "../../scss/components/Story.scss";
import classnames from "classnames";

const useWindowSize = ({ width, height }) => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      const card = document.getElementById("story__item");
      setSize([card.offsetWidth, card.offsetHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return [size[0] / width, size[1] / height];
};

const Story = ({
  title,
  story: { backgroundMedia, snapshot, backgroundColor, products, instagrams },
  screenSize,
  avatar,
  setPause,
  pause,
  setCanChange,
  setInter,
  setWidth,
}) => {
  const [width, height] = useWindowSize(screenSize);
  const [active, setActive] = useState(false);
  const [activeI, setActiveI] = useState(false);
  const [show, setShow] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [animate2, setAnimate2] = useState(false);
  const [prod, setProd] = useState(null);
  const video = useRef(null);
  const isVideo = useMemo(
    () =>
      backgroundMedia && backgroundMedia.full
        ? backgroundMedia.full.split(".").pop() === "mp4" ||
          backgroundMedia.isVideo
        : false,
    [backgroundMedia]
  );

  const getMedia = () => {
    if (!isVideo) {
      return (
        <img
          className="image__story"
          src={
            backgroundMedia.full
              ? backgroundMedia.full
              : backgroundMedia.original
          }
          alt=""
        />
      );
    } else {
      return (
        <video
          id="video__cont"
          ref={video}
          autoPlay
          muted
          src={backgroundMedia.full}
        />
      );
    }
  };

  useEffect(() => {
    if (isVideo && video.current) {
      const vid = document.getElementById("video__cont");
      if (vid) {
        vid.addEventListener("timeupdate", (e) => {
          const vv = e.target;
          setInter(true);
          setWidth((100 * vv.currentTime) / vv.duration + 1);
        });
      }
    } else {
      setInter(false);
    }
    return () => {};
  }, [isVideo, video, setInter, setWidth]);

  useEffect(() => {
    let tt = null;
    let tt2 = null;
    const timeout = setTimeout(() => {
      setAnimate(true);
      tt = setTimeout(() => {
        setAnimate2(true);
      }, 400);
      tt2 = setTimeout(() => {
        setAnimate2(false);
      }, 2000);
    }, 2000);
    return () => {
      clearTimeout(timeout);
      clearTimeout(tt);
      clearTimeout(tt2);
    };
  }, []);

  useEffect(() => {
    if (isVideo) {
      if (pause) {
        video.current.pause();
      } else {
        video.current.play();
      }
    }
  }, [pause, isVideo]);

  return (
    <div
      className="story__item"
      id="story__item"
      style={{ background: backgroundColor }}
    >
      <div
        className={classnames("product__tags", {
          disabled: !active,
          anim: animate,
        })}
      >
        {products && products.length !== 0
          ? products
              .filter((el) => !el.error)
              .map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <ProductTag
                      pos={e.pos}
                      product={e}
                      active={active}
                      scale={{ width, height }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow(true);
                        setProd(i);
                      }}
                    />
                    <ProductBox pos={e.pos} scale={{ width, height }} />
                  </React.Fragment>
                );
              })
          : null}
      </div>
      <div>
        {instagrams && instagrams.length !== 0
          ? instagrams
              .filter((el) => !el.error)
              .map((e, i) => {
                return (
                  <InstagramTag
                    {...e}
                    key={i}
                    active={activeI}
                    scale={{ width, height }}
                  />
                );
              })
          : null}
      </div>
      {snapshot ? (
        <div
          className="snapshot"
          style={{ background: `url("${snapshot}")` }}
        />
      ) : null}
      {products && products.length !== 0 ? (
        <div
          className={classnames("story__viewproducts", {
            active: animate2 || active,
            anim: animate,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setActive(!active);
            setPause(!active || activeI);
            setCanChange(active);
          }}
        >
          <div className="viewproduct__icon">
            <BigBagIcon />
          </div>
          <div className="viewproduct__text">View Products</div>
        </div>
      ) : null}
      {instagrams && instagrams.length !== 0 ? (
        <div
          className="tagged__user"
          onClick={(e) => {
            e.stopPropagation();
            setActiveI(!activeI);
            setPause(active || !activeI);
            setCanChange(activeI);
          }}
        >
          <i className="fa fa-user"></i>
        </div>
      ) : null}
      <div className="image__cont">{backgroundMedia ? getMedia() : null}</div>
      <ProductBar
        isOpen={show}
        setOpen={setShow}
        avatar={avatar}
        prod={prod}
        title={title}
        products={products.filter((el) => !el.error)}
      />
    </div>
  );
};

export default Story;
