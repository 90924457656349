import { setAuthToken, instance, fetchRequest } from "./fetchData";

export function fetchSetOrgData({ authToken, orgAlias, orgData }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post(`/organization/${orgAlias}`, orgData));
}

export function fetchOrgMembers({ authToken, orgAlias }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/organization/${orgAlias}/members`));
}

export function fetchSetOrgAdmin({ authToken, orgAlias, userId, value }) {
  setAuthToken(authToken);

  let request;

  if (value) {
    request = instance.post(`/organization/${orgAlias}/admin`, {
      userId,
    });
  } else {
    request = instance.delete(`/organization/${orgAlias}/admin/${userId}`);
  }

  return fetchRequest(request);
}

export function fetchInviteToOrg({ authToken, orgAlias, email }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post(`/organization/${orgAlias}/invite`, {
      email,
    })
  );
}

export function fetchRemoveUserFromOrg({ authToken, orgAlias, userId }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.delete(`/organization/${orgAlias}/user/${userId}`)
  );
}

export function fetchCreateOrganization({ authToken, name, logo, homepage }) {
  setAuthToken(authToken);

  return fetchRequest(
    instance.post("/organization", {
      name,
      logo,
      homepage,
    })
  );
}

export function fetchDeclineOrgInvite({ authToken, inviteToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.get(`/invitations/${inviteToken}/decline`));
}

export function fetchLeaveOrg({ authToken }) {
  setAuthToken(authToken);

  return fetchRequest(instance.post("/organization/leave"));
}
