import React from "react";
import { EditCardEdit, EditCardDelete } from "../../icons/icons";
import "../../scss/components/ProductTagsCard.scss";

const ProductTagsCard = ({ image, name, onEdit, onDelete }) => {
  return (
    <div className="product-tags-card">
      <img className="product-tags-card__image" src={image} alt="product" />
      <p className="product-tags-card__name">{name}</p>
      <span className="cursor-pointer" onClick={onEdit}>
        <EditCardEdit />
      </span>
      <span className="cursor-pointer" onClick={onDelete}>
        <EditCardDelete />
      </span>
    </div>
  );
};

export default ProductTagsCard;
