import React from "react";
import { StoryPage } from "../../icons/icons";
import CreateLive from "../../components/hoc/createLive";
import ContactLive from "../../components/hoc/contactLive";
import { isHasAccessToLive } from "../../helpers/liveAccess";

const LivePageDefault = ({ orgInfo }) => {
  return (
    <div className="stories__page page withbutton">
      <div className="page__title">You have no LIVE stories yet</div>
      <div className="page__subtitle">
        All your LIVE stories will be displayed here, you can sort them as you
        like
      </div>
      <div className="page__subtitle mobile__sub">
        All your LIVE stories will be displayed here, you can sort them as you
        like
      </div>
      <div className="page__image">
        <StoryPage />
      </div>
      {isHasAccessToLive(orgInfo.alias) ? (
        <CreateLive>
          <div className="page__button">
            <div className="accent__button">Book LIVE Session</div>
          </div>
        </CreateLive>
      ) : (
        <ContactLive>
          <div className="page__button">
            <div className="accent__button">Book LIVE Session</div>
          </div>
        </ContactLive>
      )}
    </div>
  );
};

export default LivePageDefault;
