import React from 'react';
import { BackArrow } from '../../icons/icons';
import story from '../../images/product.png';

const OrderPageDefault = () => {
	return (
		<div className="orderdet__page page">
			<div className="orderid__mobile">Order #4515977TL — 17 Jan 08:34</div>
			<div className="container">
				<div className="stories__line">
					<div className="storyline__header">
						<div className="back__button">
							<BackArrow /> <span>Back</span>
						</div>
						<div className="stories__date">Products</div>
						<div className="order__id">Order #4515977TL — 17 Jan 08:34</div>
						<div className="invite__buttons">
							<div className="small__button button__accent">Write review</div>
						</div>
					</div>
					<div className="stories__date">Products</div>
					<div className="stories__container">
						<div className="order__card">
							<img src={story} alt="" />
							<p>DIP POWDER - D625 FIRST Liquid</p>
						</div>
						<div className="order__card">
							<img src={story} alt="" />
							<p>DIP POWDER - D625 FIRST Liquid</p>
						</div>
					</div>
					<div className="order__info">
						<div className="billing">
							<div className="order__title">Billing address</div>
							<div className="order__data">
								<div className="order__row">Viktoriia Vorobiova</div>
								<div className="order__row">27 Merry Lane NP000227692</div>
								<div className="order__row">East Hanover, New Jersey</div>
								<div className="order__row">United States</div>
								<div className="order__row">07936 1 973-947-6185</div>
							</div>
						</div>
						<div className="shipping">
							<div className="order__title">Shipping address</div>
							<div className="order__data">
								<div className="order__row">Viktoriia Vorobiova</div>
								<div className="order__row">27 Merry Lane NP000227692</div>
								<div className="order__row">East Hanover, New Jersey</div>
								<div className="order__row">United States</div>
								<div className="order__row">07936 1 973-947-6185</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderPageDefault;
