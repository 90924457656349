import React from "react";
import MainLayout from "../components/MainLayout";
import { Route } from "react-router-dom";

const WithHeader = ({
  component: Component,
  headerTitle,
  isCustomer,
  hideTopbar,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout
          path={rest.path}
          isCustomer={isCustomer}
          hideTopbar={hideTopbar}
        >
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

export default WithHeader;
