import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Popup, Hint2, Loader } from "./";
import { PreviewPage } from "../containers";
import {
  deleteStory,
  getStoryAnalytics,
} from "../../redux/actions/storiesActions";
import { removeStoryFromBar } from "../../redux/actions/storybarActions";
import { openStory } from "../../redux/utils/embedApi";
import { getStoryAnalytic } from "../../redux/utils/analytics";
import { TreeDot, Close, Purchases, Clicks } from "../../icons/icons";
import congr from "../../images/congr.svg";
import "../../scss/components/StoryCard.scss";

const StoryCardDropdown = ({
  isOpen,
  setDropOpen,
  editStory,
  handleDeleteStory,
  unpublishStory,
  isDraft,
  className,
}) => {
  return (
    <div className="st__drop">
      <Dropdown
        button={<TreeDot />}
        isOpen={isOpen}
        noOver
        className={className}
        setIsOpen={setDropOpen}
        dropdown={
          <div className="setting__block">
            <div className="setting__part sp1">
              <p className="setting__link fi1">Download</p>
              <p className="setting__link" onClick={editStory}>
                Edit
              </p>
            </div>
            <div className="setting__part">
              <p className="setting__link error" onClick={handleDeleteStory}>
                Delete
              </p>
              {!isDraft ? (
                <p className="setting__link error" onClick={unpublishStory}>
                  Unpublish
                </p>
              ) : null}
            </div>
          </div>
        }
      />
    </div>
  );
};

const StoryCard = ({
  image,
  snapshot,
  title,
  story,
  background,
  barId,
  isDraft = false,
  getStoryAnalytics,
  removeStoryFromBar,
  id,
  publishing = false,
  deleteStory,
  idToken,
  statistic: { unique, views, clicks, purchases },
  history,
  isSnapshot,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [publ, setPubl] = useState(publishing);
  const [dropOpen, setDropOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hover, setHover] = useState(false);
  const [preview, setPreview] = useState(false);
  const [analytic, setAnalytic] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (publishing) {
        setProgress((p) => {
          if (p < 87) {
            return p + Math.floor(Math.random() * 20);
          } else {
            setTimeout(() => setPubl(false), 1000);
            clearInterval(interval);
            setShowPopup(true);
            return 100;
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [publishing]);

  const getAnalytics = () => {
    getStoryAnalytic(id).then((response) =>
      response.json().then((e) => {
        setAnalytic({
          views: e.data.series
            .map((el) => e.data.values[`Story View`][el])
            .reduce((a, b) => a + b, 0),
          clicks: e.data.series
            .map((el) => e.data.values[`Product Click`][el])
            .reduce((a, b) => a + b, 0),
          purchases: e.data.series
            .map((el) => e.data.values[`Purchase Made`][el])
            .reduce((a, b) => a + b, 0),
        });
      })
    );
  };

  const editStory = () => {
    history.push(`/create-stories/${id}`);
  };

  const handleDeleteStory = () => {
    deleteStory({ authToken: idToken, storyId: id });
    setHover(false);
    setDropOpen(false);
    setPreview(false);
  };

  const unpublishStory = () => {
    removeStoryFromBar({
      authToken: idToken,
      storyId: id,
      barId,
    });
    setDropOpen(false);
    setPreview(false);
  };

  useEffect(() => {
    // const circle = document.querySelector("circle");
    // const radius = circle.r.baseVal.value;
    // const circumference = radius * 1.2 * Math.PI;
    // const offset = circumference - (progress / 100) * circumference;
    // setProg({
    //   strokeDasharray: `${circumference}, ${circumference}`,
    //   strokeDashoffset: `${offset}`,
    // });
  }, [progress]);

  return (
    <>
      {preview ? (
        <>
          {window.innerWidth > 500 ? (
            <div className="desk">
              <PreviewPage story={story} setClose={setPreview} />
            </div>
          ) : null}
          <div className="mobile__hover mobile">
            <div className="overlay">
              <div className="hover__analytic">
                <div className="statistic__item">
                  <div className="stat_icon">
                    <Clicks />
                  </div>
                  <div className="stat">{clicks}</div>
                </div>
                <div className="statistic__item">
                  <div className="stat_icon">
                    <Purchases />
                  </div>
                  <div className="stat">{purchases}</div>
                </div>
              </div>
              <div className="hover__close" onClick={() => setPreview(false)}>
                <Close />
              </div>

              <StoryCardDropdown
                isOpen={dropOpen}
                setDropOpen={setDropOpen}
                editStory={editStory}
                className="toLeftTop"
                handleDeleteStory={handleDeleteStory}
                unpublishStory={unpublishStory}
                isDraft={isDraft}
              />
            </div>
            <div
              className="hover__story"
              style={
                background && background[0] === "#"
                  ? { backgroundColor: background }
                  : { backgroundImage: background }
              }
            >
              {image ? (
                <div className="image__cont">
                  <img src={image} alt="" />
                </div>
              ) : null}
              {snapshot && isSnapshot ? (
                <div className="snapshot__cont">
                  <img src={snapshot} alt="" />
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      <div
        onMouseEnter={() => {
          setHover(true);
          if (!analytic && !isDraft) getAnalytics();
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
          setDropOpen(false);
        }}
      >
        <div className="stories__it">
          {hover && (
            <StoryCardDropdown
              isOpen={dropOpen && hover}
              setDropOpen={setDropOpen}
              editStory={editStory}
              className="toMidRight"
              handleDeleteStory={handleDeleteStory}
              unpublishStory={unpublishStory}
              isDraft={isDraft}
            />
          )}
          <div
            className={`story__card phh${publ ? " publishing" : ""}${
              isDraft ? " draft" : ""
            }`}
            style={
              background && background[0] === "#"
                ? { backgroundColor: background }
                : { backgroundImage: background }
            }
            onClick={() => openStory({ storyData: story })}
          >
            {image ? (
              <div className="image__cont">
                <img src={image} alt="" />
              </div>
            ) : null}
            {snapshot ? (
              <div className="snapshot__cont">
                <img src={snapshot} alt="" />
              </div>
            ) : null}
            {publ ? (
              <React.Fragment>
                <svg className="progressring" width="52" height="52">
                  <circle
                    className="progressring__circle"
                    stroke="#0BB6EC"
                    strokeWidth="2"
                    fill="transparent"
                    r="21"
                    cx="26"
                    cy="26"
                  />
                </svg>
                <div
                  className="story__publishing"
                  style={progress === 100 ? { opacity: 0 } : null}
                >
                  <div className="publishing__title">Publish...</div>
                  <div
                    className="publishing__progress"
                    style={{ width: progress + "%" }}
                  />
                </div>
              </React.Fragment>
            ) : null}

            {!publ ? (
              <div className="story__statistic">
                <div className="settings"></div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="story__title phh">{title}</div>
      </div>
      {hover && !dropOpen && !isDraft && (
        <Hint2>
          {analytic ? (
            <div>
              <div className="statistic__name">Views</div>
              <div className="statistic__data">{analytic.views || 0}</div>
              <div className="statistic__name">Clicks</div>
              <div className="statistic__data">{analytic.clicks || 0}</div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 124,
                height: 134,
              }}
            >
              <Loader />
            </div>
          )}
        </Hint2>
      )}
      {showPopup && publishing ? (
        <Popup>
          <div className="popup__message">
            Congratulations! Your story is published
            <div>
              <img src={congr} alt="congr" width="20" height="20" />
            </div>
            <span className="cursor__button" onClick={() => setPreview(true)}>
              View story
            </span>
          </div>
        </Popup>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;

  return {
    idToken,
  };
};

const mapDispatchToProps = {
  getStoryAnalytics,
  deleteStory,
  removeStoryFromBar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoryCard)
);
