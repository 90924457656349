import React from "react";

const ProductView = ({ image, name, price, isOpen }) => {
  return (
    <div className={`product__view ${isOpen ? "opened" : ""}`}>
      <div
        className="pw__image"
        style={{ backgroundImage: `url('${image}')` }}
      />
      <div className="pw__info">
        <div className="pw__name">{name}</div>
        <div className="pw__price">{price}</div>
      </div>
      <div className="product__button">
        <div className="accent__button">Add to Cart</div>
      </div>
    </div>
  );
};

export default ProductView;
