import store from "../redux/reducers";
import { openPlanModal } from "../components/modal/PlanModal";

export const plans = ["basic", "booster", "advanced", "professional", "pro", "enterprise"];
const access = {
  basic: ["basic", "booster", "advanced", "professional", "pro", "enterprise"],
  advanced: ["advanced", "professional", "pro", "enterprise"],
  professional: ["professional", "pro", "enterprise"],
  enterprise: ["pro","enterprise"]
};

export const checkPermission = (plan) => {
  const userPlan = store.getState().auth.toJS().profile.plan;
  return access[plan] && access[plan].includes(userPlan);
};

export const onlyWithPermission = (e, plan, func = () => {}) => {
  if (checkPermission(plan)) {
    func();
  } else {
    e.stopPropagation();
    openPlanModal(plan);
  }
};
