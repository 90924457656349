import React from 'react';

const TutorialItem = ({ img, caption }) => {
	return (
		<div className="tutorial__item cursor__button">
			<div className="tutorial__image" style={{ backgroundImage: `url('${img}')` }} />
			<div className="tutorial__caption">{caption}</div>
		</div>
	);
};

export default TutorialItem;
