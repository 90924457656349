import uuid from "uuid/v1";
import _ from "lodash";

export const actions = {
  SHOW_INFO_POPUP: "SHOW_INFO_POPUP",
  HIDE_INFO_POPUP: "HIDE_INFO_POPUP",
  CREATE_POPUP: "CREATE_POPUP",
  SET_INFO_POPUP_PRIMARY_TEXT: "SET_INFO_POPUP_PRIMARY_TEXT",
  SET_INFO_POPUP_SECONDARY_TEXT: "SET_INFO_POPUP_SECONDARY_TEXT",
  SET_INFO_POPUP_BACKGROUND_COLOR: "SET_INFO_POPUP_BACKGROUND_COLOR",
};

export const INFO_POPUPS = _.mapValues(
  {
    DEV_CODE_FETCHING: {
      primaryText: "Sending...",
      secondaryText: "Please wait while we are sending you the dev code.",
    },
    DEV_CODE_FETCHED: {
      type: "success",
      primaryText: "Success!",
      secondaryText: "The code was sent to your email.",
    },
    REMOVE_STORYBAR_FETCHING: {
      primaryText: "Removing...",
    },
    STORYBAR_NAME_FETCHING: {
      primaryText: "Saving...",
    },
    STORYBAR_UPDATE_FETCHING: {
      primaryText: "Saving...",
    },
    CREATE_STORYBAR_FETCHING: {
      primaryText: "Creating...",
    },
    UPDATE_FETCHING: {
      primaryText: "Updating...",
    },
    ERROR: {
      type: "error",
      primaryText: "Error!",
      secondaryText: (props) =>
        props.error.error || props.error.message || props.error,
    },
    CREATE_STORY_FETCHING: {
      primaryText: "Creating Story...",
    },
    STORY_FETCHING: {
      primaryText: "Loading Story...",
    },
    STORIES_FETCHING: {
      primaryText: "Loading Stories...",
    },
    BARS_FETCHING: {
      primaryText: "Loading Bars...",
    },
    ERROR_CUSTOM: ({ primaryText, secondaryText }) => ({
      type: "error",
      primaryText,
      secondaryText,
    }),
    DELETE_STORY_FETCHING: {
      primaryText: "Deleting...",
    },
    DELETE_STORY_FETCHED: {
      type: "success",
      primaryText: "Story was deleted.",
    },
    PUBLISH_STORY_DATA_FETCHING: {
      primaryText: "Loading data...",
    },
    PUBLISH_STORY_FETCHING: {
      primaryText: "Publishing...",
      secondaryText: "Please wait while your story is being published.",
    },
    SET_USER_INFO_FETCHING: {
      primaryText: "Saving changes...",
    },
    PROFILE_IMAGE_UPLOADING: {
      primaryText: "Uploading image...",
    },
    SET_ORG_DATA_FETCHING: {
      primaryText: "Saving changes...",
    },
    ORG_IMAGE_UPLOADING: {
      primaryText: "Uploading image...",
    },
    ORG_MEMBERS_FETCHING: {
      primaryText: "Loading organization members...",
    },
    SET_ORG_ADMIN_FETCHING: {
      primaryText: "Saving changes...",
    },
    ORG_MEMBERS_UPDATING: {
      primaryText: "Updating data...",
    },
    INVITE_TO_ORG_FETCHING: {
      primaryText: "Sending invite...",
    },
    INVITE_TO_ORG_FETCHED: {
      type: "success",
      primaryText: "Success!",
      secondaryText: (props) =>
        `Invite was successfully sent to ${props.inviteToOrgData.email}`,
    },
    REMOVE_USER_FROM_ORG_FETCHING: {
      primaryText: "Sending request...",
    },
    REMOVE_USER_FROM_ORG_FETCHED: {
      type: "success",
      primaryText: "Success!",
      secondaryText: (props) =>
        `User ${props.removeUserFromOrgData.name} was removed from this Organization.`,
    },
    CREATE_ORGANIZATION_FETCHING: {
      primaryText: "Creating your Organization...",
    },
    DECLINE_ORG_INVITE_FETCHING: {
      primaryText: "Declining invite...",
    },
    DECLINE_ORG_INVITE_FETCHED: {
      type: "success",
      primaryText: "Success!",
      secondaryText: (props) =>
        `Invite to ${props.declineOrgInviteData.email} was declined.`,
    },
    LEAVE_ORG_FETCHING: {
      primaryText: "Leaving...",
    },
    LEAVE_ORG_FETCHED: {
      type: "success",
      primaryText: "Success!",
      secondaryText: "You have successfully left the Organization.",
    },
    PENDING_APPROVAL: {
      type: "pending",
      primaryText: "Pending approval",
    },
    STORYBAR_DATA_FETCHING: {
      primaryText: "Loading Bar...",
    },
    SHOW_STORY_DEMO_LOADING: {
      primaryText: "Loading demo...",
    },
    GET_CAMPAIGNS_FETCHING: {
      primaryText: "Loading campaigns...",
    },
    CREATE_CAMPAIGN_FETCHING: {
      primaryText: "Creating campaign...",
    },
  },
  (popup, name) => {
    if (_.isFunction(popup)) {
      return _.flow(popup, (result) => _.merge({}, result, { name }));
    }
    return _.merge({}, popup, { name });
  }
);

function getColor(color) {
  let colors = {
    default: "#263BA5",
    success: "#339A1B",
    error: "red",
    pending: "#339A1B",
  };

  if (color in colors) {
    color = colors[color];
  }

  return color;
}

export function createInfoPopup(popupData, props) {
  return (dispatch) => {
    dispatch(hideInfoPopup());

    let newPopupData = {};

    _.defaultsDeep(newPopupData, popupData, {
      id: uuid(),
      type: "default",
      color: "#ffffff",
      primaryText: "",
      secondaryText: "",
    });
    _.defaultsDeep(newPopupData, {
      backgroundColor: getColor(newPopupData.type),
    });

    popupData = newPopupData;

    popupData = _.mapValues(popupData, (param) => {
      if (typeof param === "function") {
        return param(props);
      }
      return param;
    });

    dispatch({
      type: actions.CREATE_POPUP,
      data: popupData,
    });
  };
}

export function showPlanPopupPopup(text) {
  return (dispatch) => {
    dispatch(hideInfoPopup());
    dispatch({
      type: actions.SHOW_INFO_POPUP,
      data: text,
    });
  };
}

export function hideInfoPopup() {
  return {
    type: actions.HIDE_INFO_POPUP,
  };
}
