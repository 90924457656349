import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import editStory from "./editStoryReducer";
import auth from "./authReducer";
import storybars from "./storybarsReducer";
import infoPopup from "./infoPopupReducer";
import stories from "./storiesReducer";
import publishStory from "./publishStoryReducer";
import notifications from "./notificationsReducer";
import storybar from "./storybarReducer";
import campaigns from "./campaignsReducer";
import orgInvite from "./orgInviteReducer";
import acceptOrgInvite from "./acceptOrgInviteReducer";
import lives from "./livesReducer";
import global from "./globalReducer";

const reducer = combineReducers({
  editStory,
  auth,
  storybars,
  infoPopup,
  stories,
  publishStory,
  notifications,
  storybar,
  campaigns,
  orgInvite,
  acceptOrgInvite,
  lives,
  global,
});

const composeEnhansers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhanser = composeEnhansers(applyMiddleware(thunk));

export default createStore(reducer, enhanser);
