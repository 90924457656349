import React, { useState, useEffect } from 'react';
import CheckBox from './CheckBox';
import '../../../scss/components/CheckGroup.scss';

const CheckGroup = ({ className = '', items, onChange, current }) => {
	const [ publish, setPublish ] = useState(current);

	useEffect(
		() => {
			onChange(publish);
		},
		[ publish, onChange ]
	);

	const getPublish = (val) => {
		const arr = [ ...current ];
		if (arr.indexOf(val) === -1) {
			arr.push(val);
		} else {
			arr.splice(arr.indexOf(val), 1);
		}
		setPublish(arr);
	};

	return (
		<div className={`checkgroup ${className}`}>
			{items && items.length !== 0 ? (
				items.map((e, i) => {
					return <CheckBox key={i} {...e} getPublish={getPublish} />;
				})
			) : null}
		</div>
	);
};

export default CheckGroup;
