import React, { useState, useEffect } from "react";
import LiveProduct from "./LiveProduct";
import LiveBarProduct from "./LiveBarProduct";
import classnames from "classnames";

const LiveBar = ({
                     products,
                     title,
                     avatar,
                     name,
                     isOpen,
                     setOpen,
                     prod,
                     storyId,
                     barId,
                     cardId,
                     showPrice,
                     setMinimized,
                     allAdded,
                     setAllAdded,
                     addedProd,
                     setAddedProd
                 }) => {
    const [active, setActive] = useState(false);
    const [isDrag, setIsDrag] = useState(false);
    const [top, setTop] = useState(260);
    const [shadow, setShadow] = useState({ top: false, bottom: true });
    const [product, setProduct] = useState(null);
    const [isProd, setIsProd] = useState(false);
    const [isNewScroll, setIsNewScroll] = useState(true);
    const [show, setShow] = useState(false);


    useEffect(() => {
        setProduct(prod);
    }, [prod]);

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        }
    }, [isOpen]);

    const setAdd = (id) => {
        const addedCopy = { ...addedProd };
        addedCopy[id] = true;
        setAddedProd(addedCopy);
    };

    const handleDragStart = (e, isTouch = true) => {
        e.stopPropagation();
        const startY = e.touches[0].pageY;
        let currtop = top;

        const handleMove = (e) => {
            e.stopPropagation();
            currtop = e.touches[0].pageY - 10;
            setIsDrag(true);
            setTop(e.touches[0].pageY - 10);
        };

        const handleUp = () => {
            if (currtop < startY - 50) {
                setActive(true);
            }
            if (currtop > startY + 50) {
                setTimeout(() => {
                    setOpen(false);
                    setActive(false);
                    setIsProd(false);
                    setTimeout(() => {
                        setShow(false);
                    }, 400);
                }, 1);
            }
            setIsDrag(false);
            document.removeEventListener("touchmove", handleMove);
            document.removeEventListener("touchend", handleUp);
        };

        document.addEventListener("touchmove", handleMove);
        document.addEventListener("touchend", handleUp);
    };

    return (
        <div
            className={classnames("pb__overlay", { opened: show })}
            onClick={() => {
                setOpen(false);
                setActive(false);
                setTimeout(() => {
                    setShow(false);
                }, 400);
            }}
        >
            <div
                className={classnames("product__bar live__bar", {
                    opened: isOpen,
                    active,
                    closed: !isOpen,
                })}
                style={
                    isDrag
                        ? {
                            top,
                            transition: `none`,
                        }
                        : {}
                }
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={classnames("product__header", { over: shadow.top })}>
                    <div className="wrapper-for-drag" onTouchStart={handleDragStart} >
                        <span className='like-iphone' />
                    </div>
                    <div className="title-for-products">
                        <p>SHOP ITEMS</p>
                    </div>
                    {/*{!(!isProd && window.innerWidth < 480) ? (*/}
                    {/*  <div*/}
                    {/*    className="back__button"*/}
                    {/*    onClick={(e) => {*/}
                    {/*      e.stopPropagation();*/}
                    {/*      if (isProd) {*/}
                    {/*        setIsProd(false);*/}
                    {/*      } else {*/}
                    {/*        setOpen(false);*/}
                    {/*        setActive(false);*/}
                    {/*        setTimeout(() => {*/}
                    {/*          setShow(false);*/}
                    {/*        }, 400);*/}
                    {/*      }*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <BackArrow /> <span>Back</span>*/}
                    {/*  </div>*/}
                    {/*) : (*/}
                    {/*  <div></div>*/}
                    {/*)}*/}
                    {/*<div className="scrolldown mobile" onTouchStart={handleDragStart}>*/}
                    {/*  <div className="scrolldown__item" />*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*  className={`live__bar-button${allAdded ? " added" : ""}`}*/}
                    {/*  onClick={addAllProducts}*/}
                    {/*>*/}
                    {/*  {allAdded ? "Added" : "Add all to cart"}*/}
                    {/*</div>*/}
                </div>
                <div
                    className="product__container"
                    id="product__container"
                    onWheel={(e) => {
                        const cont = document.getElementById("product__container");
                        if (e.deltaY < 0) {
                            if (
                                (active && isNewScroll) ||
                                cont.target.scrollHeight - cont.target.scrollTop <=
                                cont.target.clientHeight
                            ) {
                                setActive(false);
                            }
                        }
                    }}
                    onScroll={(e) => {
                        if (!active) {
                            setActive(true);
                            e.stopPropagation();
                            setIsNewScroll(false);
                        }
                        if (e.target.scrollTop !== 0) {
                            setShadow((prev) => ({ ...prev, top: true }));
                        } else {
                            setTimeout(() => {
                                setIsNewScroll(true);
                            }, 300);
                            setShadow((prev) => ({ ...prev, top: false }));
                        }
                        if (
                            e.target.scrollHeight - e.target.scrollTop <=
                            e.target.clientHeight
                        ) {
                            setShadow((prev) => ({ ...prev, bottom: false }));
                        } else {
                            setShadow((prev) => ({ ...prev, bottom: true }));
                        }
                    }}
                >
                    <div className="live__grid">
                        {products
                            ? products.map((e, i) => {
                                return (
                                    <LiveProduct
                                        key={i}
                                        {...e}
                                        id={i}
                                        storyId={storyId}
                                        barId={barId}
                                        cardId={cardId}
                                        added={e ? addedProd[e.productId] : false}
                                        setAdded={() => setAdd(e.productId)}
                                        setMinimized={setMinimized}
                                        setProduct={() => {
                                            setIsProd(true);
                                            setProduct(e);
                                        }}
                                    />
                                );
                            })
                            : null}
                    </div>
                </div>

                <LiveBarProduct
                    {...product}
                    added={product ? addedProd[product.productId] : false}
                    setAdded={() => setAdd(product.productId)}
                    isOpen={isProd}
                    storyId={storyId}
                    barId={barId}
                    cardId={cardId}
                    setMinimized={setMinimized}
                />
            </div>
        </div>
    );
};

export default LiveBar;
