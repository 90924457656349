import React from "react";

const Hint2 = ({ children }) => {
  return (
    <div className="hint2">
      {children}
      <span className="triangle" />
      <span className="hide" />
    </div>
  );
};

export default Hint2;
