import React from "react";
import { Link } from "react-router-dom";

const NavigationItem = ({
  url,
  icon,
  title,
  isActive,
  plan,
  isReview,
  reviewCount,
}) => {
  return (
    <li>
      <div
        className={`sidebar-navigation__item${
          isActive ? " sidebar-navigation__item--active" : ""
        }`}
      >
        <Link to={url} className="sidebar-navigation__item-link">
          <span className="sidebar-navigation__item-icon">{icon}</span>
          <span className="sidebar-navigation__item-title">{title}</span>
          {!!plan && (
            <span className="sidebar-navigation__item-plan">{plan}</span>
          )}
          {isReview && !!reviewCount && (
            <span className="sidebar-navigation__item-review">
              {reviewCount}
            </span>
          )}
        </Link>
      </div>
    </li>
  );
};

export default NavigationItem;
