import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import "../../scss/components/dropdown.scss";

const Dropdown = ({
  button,
  dropdown,
  isOpen = false,
  setIsOpen,
  toRight = false,
  inRight = false,
  noOver,
  className,
}) => {
  const wrappedRef = useRef(null);
  const blockRef = useRef(null);

  const handleClickOutside = (e) => {
    if (wrappedRef.current && !wrappedRef.current.contains(e.target)) {
      if (!blockRef.current || !blockRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("mobilemodal");
    } else {
      document.body.classList.remove("mobilemodal");
    }
    return () => {
      document.body.classList.remove("mobilemodal");
    };
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  });

  return (
    <div className={`dropdown${noOver ? " noOver" : ""}`}>
      <div
        onClick={() => setIsOpen((o) => !o)}
        ref={wrappedRef}
        className={`cursor__button icon__button ${isOpen ? "active" : ""}`}
      >
        {button}
      </div>
      {isOpen && (
        <div className="dropdown__ovrelay">
          <div
            ref={blockRef}
            className={classnames(
              `dropdown__item ${className ? className : ""}`,
              {
                to__left: !(toRight || inRight),
                to__right: toRight,
                in__right: inRight,
              }
            )}
          >
            {dropdown}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
