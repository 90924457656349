import React from 'react';
import { OrderCard } from '../../components/common';
import story from '../../images/story.png';

const fetchData = {
	Yesterday: [
		{
			id: '1',
			image: story,
			platform: 'youtube'
		},
		{
			id: '2',
			image: story,
			platform: 'tiktok'
		}
	],
	'December 21': [
		{
			id: '3',
			image: story,
			platform: 'youtube'
		},
		{
			id: '4',
			image: story,
			platform: 'instagram'
		}
	]
};

const OrderPage = () => {
	return (
		<div className="order__page page">
			<div className="container">
				{Object.getOwnPropertyNames(fetchData).map((e, i) => (
					<div className="stories__line" key={i}>
						<div className="storyline__header">
							<div className="stories__date">{e}</div>
							<div className="invite__buttons">
								<div className="small__button button__default">Details</div>
							</div>
						</div>
						<div className="stories__container">
							{fetchData[e].map(({ id, platform, image }, j) => (
								<div key={j}>
									<OrderCard image={image} id={id} prod="MECCA MSX Over T..." price="$32.99" />
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default OrderPage;
