import React, { useState } from "react";
import { PhotosIcon, CameraIcon } from "../../icons/icons";
import { Modal } from "../common/";
import "../../scss/components/LoadImage.scss";

const LoadImage = ({ setImage, discoverClick }) => {
  const [device, setDevice] = useState(false);
  const [over, setOver] = useState(false);
  const [data, setData] = useState(null);
  const [recording, setRecording] = useState(false);

  const initVideo = (must = false) => {
    if (!device || must) {
      getStream().then(getDevices);
      setDevice(true);
    }
  };

  function getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  function getStream() {
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch(handleError);
  }

  function gotStream(stream) {
    var videoElement = document.querySelector("#video");
    var recordd = document.getElementById(`record`);
    let recorder = new MediaRecorder(stream);
    let chunks = [];
    let rec = false;

    recordd.addEventListener(`click`, () => {
      if (rec) {
        setRecording(false);
        recorder.stop();
        rec = false;
      } else {
        setRecording(true);
        recorder.start();
        rec = true;
      }
    });

    recorder.ondataavailable = (ev) => {
      chunks.push(ev.data);
    };

    recorder.onstop = (ev) => {
      let blob = new Blob(chunks, { type: "video/mp4" });
      chunks = [];
      let videoUrl = window.URL.createObjectURL(blob);
      setData({ full: videoUrl, blob, type: true });
      setOver(true);
    };

    window.stream = stream; // make stream available to console
    videoElement.srcObject = stream;
  }

  function takepicture() {
    var video = document.querySelector("#video");
    var canvas = document.querySelector("canvas");
    var context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, 480, 320);
    setOver(true);
    var data = canvas.toDataURL("image/png");
    setData({ full: data, type: false });
  }

  function handleError(error) {
    console.error("Error: ", error);
  }


  return (
    <div className="upload">
      <label className="upload_half" htmlFor="device">
        <div className="upload_icon">
          <PhotosIcon />
        </div>
        <p className="upload_subtitle">Upload from device</p>
        <input
          type="file"
          accept="image/*,video/*"
          id="device"
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files[0]) {
              setImage({
                isVideo: e.target.files[0].type.split("/")[0] === "video",
                full: URL.createObjectURL(e.target.files[0]),
                blob: e.target.files[0],
              });
            }
          }}
        />
      </label>

      <div className="upload_half">
        <Modal
          toggle={
            <>
              <div className="upload_icon">
                <CameraIcon />
              </div>
              <p className="upload_subtitle">Take Live</p>
            </>
          }
          isDisabled={false}
          title="Take photo or video to upload"
          titleMob="Take photo or video"
          subtitle=""
          subtitleMob=""
          onSubmit={() => {
            if (data) {
              if (data.type) {
                discoverClick(data.full, true);
              } else {
                discoverClick(data.full);
              }
            }
          }}
          onCancel={() => console.log('close')}
          submit={`Upload`}
          cancel={`Cancel`}
        >
          <div id="container" onMouseMove={() => initVideo(false)}>
            <video id="video" autoPlay muted playsInline></video>
            <canvas className={over ? "show" : ""}></canvas>
            {data && data.type ? (
              <video
                autoPlay
                loop
                src={data.full}
                id="hoverVideo"
                className={over ? "show" : ""}
              ></video>
            ) : null}

            <div className="video__buttons">
              <div
                className={`small__button button__default${
                  over ? " show" : ""
                }`}
                onClick={takepicture}
              >
                Take photo
              </div>
              <div
                className="small__button button__default"
                onClick={() => {
                  setData(null);
                  setOver(false);
                }}
              >
                Clear
              </div>
              <div
                id="record"
                className={`small__button button__default${
                  over ? " show" : ""
                }`}
              >
                {!recording ? `Start video` : `Stop video`}
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="upload_divider">
        <div className="divider_inner">or</div>
      </div>
    </div>
  );
};

export default LoadImage;
