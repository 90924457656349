import Immutable from "immutable";

import { actions as A } from "../actions/storiesActions";

const initialState = Immutable.fromJS({
  fetching: false,
  fetched: false,
  data: {},
  error: null,
  customerFetching: false,
  customerFetched: false,
  customerData: null,
  customerError: null,
  analytics: {},
  createStoryFetched: false,
  createStoryFetching: false,
  lastCreatedStoryId: null,
  updateFetching: false,
  updateFetched: false,
  deleteStoryFetched: false,
  deleteStoryFetching: false,
  discover: null,
  discoverFetching: false,
  discoverFetched: false,
  discovererror: null,
  desktopAnalyticsFetched: false,
  desktopAnalytic: null,
  mobileAnalyticsFetched: false,
  mobileAnalytic: null,
  homepageAnalytics: null,
  homepageAnalyticsFetching: false,
  homepageAnalyticsFetched: false,
  publishPreloader: false,
});

export default function reducer(state = initialState, action) {
  let storyId, data;

  switch (action.type) {
    case A.RESET_STORIES_DATA:
      return initialState;
    case A.STORIES_LIST_PENDING:
      return state.set("fetched", false).set("fetching", true);
    case A.SET_PRELOADER:
      console.log('action.payload', action)
      return state.set('publishPreloader', action.payload)
    case A.HOMEPAGE_ANALYTICS_FULFILLED:
      return state
        .set("homepageAnalytics", Immutable.fromJS(action.data))
        .set("homepageAnalyticsFetched", true)
        .set("homepageAnalyticsFetching", false);
    case A.DESKTOP_ANALYTICS_FETCHING:
      return state
        .set("homepageAnalyticsFetching", true)
        .set("homepageAnalyticsFetched", false);
    case A.DESKTOP_ANALYTICS_FETCHED:
      return state
        .set("homepageAnalyticsFetched", true)
        .set("homepageAnalyticsFetching", false)
        .set("homepageAnalytics", null);
    case A.DESKTOP_ANALYTICS_FULFILLED:
      return state
        .set("desktopAnalyticsFetched", true)
        .set("desktopAnalytic", Immutable.fromJS(action.data));
    case A.MOBILE_ANALYTICS_FULFILLED:
      return state
        .set("mobileAnalyticsFetched", true)
        .set("mobileAnalytic", Immutable.fromJS(action.data));
    case A.STORIES_LIST_FULFILLED:
      return state
        .set("fetched", true)
        .set("fetching", false)
        .set("data", Immutable.fromJS(action.data));
    case A.STORIES_LIST_REJECTED:
      return state
        .set("fetched", false)
        .set("fetching", false)
        .set("error", action.error);
    case A.CUSTOMER_LIST_PENDING:
      return state.set("customerFetched", false).set("customerFetching", true);
    case A.CUSTOMER_LIST_FULFILLED:
      return state
        .set("customerFetched", true)
        .set("customerFetching", false)
        .set("customerData", Immutable.fromJS(action.data));
    case A.CUSTOMER_LIST_REJECTED:
      return state
        .set("customerFetched", false)
        .set("customerFetching", false)
        .set("customerError", action.error);
    case A.DISCOVER_LIST_PENDING:
      return state.set("discoverFetched", false).set("discoverFetching", true);
    case A.DISCOVER_LIST_FULFILLED:
      return state
        .set("discoverFetched", true)
        .set("discoverFetching", false)
        .set("discover", Immutable.fromJS(action.data));
    case A.DISCOVER_LIST_REJECTED:
      return state
        .set("discoverFetched", false)
        .set("discoverFetching", false)
        .set("discovererror", action.error);
    case A.STORY_ANALYTICS_FULFILLED:
      ({ storyId, data } = action.data);

      return state.setIn(["analytics", storyId], Immutable.fromJS(data));
    case A.CREATE_STORY_PENDING:
      return state
        .set("createStoryFetched", false)
        .set("createStoryFetching", true);
    case A.CREATE_STORY_FULFILLED:
      return state
        .set("createStoryFetched", true)
        .set("createStoryFetching", false)
        .set("lastCreatedStoryId", action.data.id);
    case A.CREATE_STORY_REJECTED:
      return state
        .set("createStoryFetched", false)
        .set("createStoryFetching", false)
        .set("error", action.error);
    case A.UPDATE_STORIES_PENDING:
      return state.set("updateFetched", false).set("updateFetching", true);
    case A.UPDATE_STORIES_FULFILLED:
      return state
        .set("updateFetched", true)
        .set("updateFetching", false)
        .set("data", action.data);
    case A.UPDATE_STORIES_REJECTED:
      return state
        .set("updateFetched", false)
        .set("updateFetching", false)
        .set("error", action.error);
    case A.DELETE_STORY_PENDING:
      return state
        .set("deleteStoryFetched", false)
        .set("deleteStoryFetching", true);
    case A.DELETE_STORY_FULFILLED:
      return state
        .set("deleteStoryFetched", true)
        .set("deleteStoryFetching", false);
    case A.DELETE_STORY_REJECTED:
      return state
        .set("deleteStoryFetched", false)
        .set("deleteStoryFetching", false)
        .set("error", action.error);

    default:
      return state;
  }
}
