import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PlanClose, UploadIcon } from "../../icons/icons";
import { Button } from "../atoms";
import { filterCards, getCoverImage } from "../../helpers/storiesHelper";
import { addCustomerCard } from "../../redux/actions/editStoryActions";

const AddToHighlightModal = ({ cardData, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState({});
  const { data } = useSelector((state) => state.stories.toJS());
  const { idToken } = useSelector((state) => state.auth.toJS());
  const { addCustomerFetching, addCustomerFetched } = useSelector((state) =>
    state.editStory.toJS()
  );

  const handleAddToHighlight = () => {
    dispatch(
      addCustomerCard(active.cards.length, cardData, active.id, idToken)
    );
  };

  const storyData = useMemo(() => {
    if (data && (data.live || data.draft)) {
      return [...(data.live || []), ...(data.draft || [])];
    }
    return null;
  }, [data]);

  useEffect(() => {
    if (addCustomerFetched && !addCustomerFetching) {
      history.push(`/edit/${active.id}`);
    }
    // eslint-disable-next-line
  }, [addCustomerFetched, addCustomerFetching]);

  return (
    <div className="publish-modal__wrapper">
      <div className="publish-modal">
        <div className="publish-modal__close cursor-pointer" onClick={onClose}>
          <PlanClose />
        </div>
        <p className="publish-modal__title">Add to highlight</p>
        <p className="publish-modal__subtitle">Check at list one option:</p>
        <div className="publish-modal-stories">
          {storyData ? (
            storyData.map((story) => {
              const cards = filterCards(story);
              const image = getCoverImage(story, cards[0]);
              return (
                <div
                  className={`publish-modal-stories__item cursor-pointer${
                    active.id === story.id
                      ? " publish-modal-stories__item--active"
                      : ""
                  }`}
                  onClick={() => setActive(story)}
                >
                  <div className="story-card">
                    <div className="story-card-circle">
                      <div className="story-card-circle__image">
                        {!!image && <img src={image} alt="" />}
                      </div>
                    </div>
                    <p className="story-card__title phh">{story.title}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="publish-modal-stories__empty">
              <p>You currently have no stories</p>
            </div>
          )}
        </div>
        <Button
          text="Add to Highlight"
          onClick={handleAddToHighlight}
          icon={<UploadIcon />}
          disabled={!active}
          loading={addCustomerFetching}
        />
      </div>
    </div>
  );
};

export default AddToHighlightModal;
