import React, { useState, useEffect } from "react";
import { TreeDot } from "../../icons/icons";
import { Dropdown, Modal, ProductAnalytic } from "./";
import "../../scss/components/HighlightCard.scss";

const HighlightCard = ({ image, snapshot, products, info }) => {
  const [dropOpen, setDropOpen] = useState(false);
  const [analytics, setAnalytics] = useState({});

  useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      info.published &&
      info.published[0]
    ) {
      products.forEach((el) => {
        if (el.productId) {
          fetch(
            `https://mixpanel.com/api/2.0/events/?interval=7&type=general&event=%5B%22Product Click%22%5D&unit=day&where=(properties["story_bar_id"]=="${info.published[0].barId}" and properties["story_id"]=="${info.id}" and properties["product_id"]=="${el.productId}")`,
            {
              headers: {
                Authorization: `Basic ZTc2MjdkY2Y0MGFlOTFhMzc3Zjg4ODYzYTE2ODZiY2Y6`,
              },
            }
          ).then((response) =>
            response.json().then((e) => {
              let dd = e.data.values["Product Click"];
              let count = 0;
              for (const key in dd) {
                if (dd.hasOwnProperty(key)) {
                  count += dd[key];
                }
              }

              setAnalytics((an) => ({
                ...an,
                [el.productId]: count,
              }));
            })
          );
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="highlight__card"
      style={{ backgroundImage: `url('${image}')` }}
      onMouseLeave={() => setDropOpen(false)}
    >
      <div className="highlight__hover">
        <div className="highlight__drop">
          <Dropdown
            button={<TreeDot />}
            isOpen={dropOpen}
            noOver
            toRight
            setIsOpen={setDropOpen}
            dropdown={
              <div className="setting__block">
                <div className="setting__part">
                  <p className="setting__link fi1">Download</p>
                  <p
                    className="setting__link fi1"
                    //onClick={() => history.push(`/create-stories/${id}`)}
                  >
                    Edit
                  </p>
                  <p
                    className="setting__link"
                    onClick={() => {
                      document.getElementById(`immClick${info.id}`).click();
                    }}
                  >
                    Product analytics
                  </p>
                </div>
                {/* <div className="setting__part">
                {!isDraft ? (
                  <p
                    className="setting__link error"
                    // onClick={() => {
                    //   removeStoryFromBar({
                    //     authToken: idToken,
                    //     storyId: id,
                    //     barId,
                    //   });
                    //   setDropOpen(false);
                    //   setPreview(false);
                    // }}
                  >
                    Unpublish
                  </p>
                ) : null}
              </div> */}
              </div>
            }
          />
        </div>
      </div>
      <Modal
        toggle={<div id={`immClick${info.id}`} />}
        title="Product analytics"
        titleMob="Product analytics"
        subtitle="Your story analytics is displayed below"
        subtitleMob="Your story analytics is displayed below"
        className="product__modal"
        onSubmit={() => {}}
        onCancel={() => {}}
        submit={
          <>
            <span>Done</span>
          </>
        }
        cancel={<></>}
      >
        <div className="products__cont">
          {products && products.length > 0 ? (
            products.map((e) => (
              <ProductAnalytic
                key={e.name}
                image={e.image}
                name={e.name}
                url={e.url}
                analytic={{
                  purchases: 0,
                  clicks: analytics[e.productId] || 0,
                  conversion: "0%",
                }}
              />
            ))
          ) : (
            <div className="no__products">No Products tagged in this card</div>
          )}
        </div>
      </Modal>
      {snapshot ? (
        <img src={snapshot} alt="snapshot" style={{ width: "100%" }} />
      ) : null}
    </div>
  );
};

export default HighlightCard;
