import React from "react";
import background from "../../images/header__background.png";
import { RaddLogo } from "../../icons/icons";
import { getUserInfo, logout } from "../../redux/actions/authActions";
import "../../scss/components/mainHeader.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

const NoLoginHeader = ({ isBack = true, isLogin = false }) => {
  return (
    <div
      className="header nologin__header"
      style={isBack ? { backgroundImage: `url('${background}')` } : null}
    >
      <div className="container">
        <div className="header__top">
          <div className="header__logo">
            <RaddLogo />
          </div>
          <div className="sing__in">
            {isLogin ? (
              <>
                <span className="singin__text">First time here?</span>
                <Link
                  className="singin__link cursor__button"
                  to="/registration"
                >
                  Sign up
                </Link>
              </>
            ) : (
              <>
                <span className="singin__text">Already have an account?</span>
                <Link className="singin__link cursor__button" to="/login">
                  Sign in
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="header__bottom">
          {!isBack ? "Welcome to radd.! " : ""}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getUserInfo,
  logout,
};

export default withRouter(connect(null, mapDispatchToProps)(NoLoginHeader));
