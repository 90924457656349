import React from "react";
import { RaddLogo } from "../../icons/icons";
import ssIcon from "../../images/sssvg.svg";

const StartScreen = () => (
  <div className="loading-screen">
    <div className="loading-screen-icon--left">
      <svg
        width="310"
        height="572"
        viewBox="0 0 310 572"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="45.5"
          cy="312.5"
          r="249"
          stroke="#E1569A"
          stroke-width="21"
        />
        <g clip-path="url(#clip0_2826_2601)">
          <path
            d="M154.507 132C117.769 132 88 161.768 88 198.492C88 235.218 117.769 265 154.507 265C191.239 265 221 235.218 221 198.492C221 161.768 191.24 132 154.507 132ZM187.32 203.404H157.447V230.814C157.447 233.405 155.443 235.503 152.854 235.503C150.267 235.503 148.263 233.405 148.263 230.814V203.404H121.68C119.091 203.404 116.992 201.019 116.992 198.43C116.992 195.84 119.091 193.455 121.68 193.455H148.263V165.18C148.263 162.59 150.267 160.491 152.854 160.491C155.443 160.491 157.447 162.59 157.447 165.18V193.455H187.32C189.909 193.455 192.008 195.839 192.008 198.429C192.008 201.019 189.909 203.404 187.32 203.404Z"
            fill="#E2579A"
          />
        </g>
        <circle cx="200.5" cy="20.5" r="20.5" fill="#35B4E8" />
        <circle cx="247.5" cy="96.5" r="10.5" fill="#35B4E8" />
        <circle cx="279" cy="31" r="31" fill="#35B4E8" />
        <defs>
          <clipPath id="clip0_2826_2601">
            <rect
              width="133"
              height="133"
              fill="white"
              transform="translate(88 132)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div className="loading-screen-icon--right">
      <img src={ssIcon} alt="start screen" />
    </div>
    <div></div>
    <div className="loading-screen-content">
      <div className="loading-screen-content__logo">
        <RaddLogo />
      </div>
      <h1 className="loading-screen-content__title">
        Let Your Customers Tell Your Story
      </h1>
      <h6 className="loading-screen-content__subtitle">
        Through Shoppable Story Reviews
      </h6>
    </div>
  </div>
);

export default StartScreen;
