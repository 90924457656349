import React, { useState } from "react";
import { Input, Transition } from "../components/common";
import NoLoginHeader from "../components/containers/NoLoginHeader";
import { useSelector, useDispatch } from "react-redux";
import { singInWithCred } from "../redux/actions/authActions";
import _ from "lodash";
import cn from 'classnames'
const RegistrationForm = () => {
  const dispatch = useDispatch();
  const { loginError, preloader } = useSelector((state) => state.auth.toJS());
  const [formData, setFormData] = useState({ email: ``, password: `` });
  const [errors, setErrors] = useState({});

  const validate = (name, val) => {
    if (!val) {
      return { [name]: "This field is required" };
    }
  };

  const handleChange = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
    const err = validate(name, val);
    if (err) {
      setErrors((prev) => ({ ...prev, ...err }));
    } else {
      const er = { ...errors };
      delete er[name];
      setErrors(er);
    }
  };

  const singIn = async () => {
    let err2 = {};
    for (const key in formData) {
      const err = validate(key, formData[key]);
      if (err) {
        err2 = { ...err2, ...err };
      }
    }

    if (!_.isEmpty(err2)) {
      setErrors(err2);
      return;
    }

    dispatch(
      singInWithCred({
        email: formData.email,
        password: formData.password,
      })
    );
  };

  return (
    <>
      <NoLoginHeader isLogin />
      <Transition withbutton>
        <div className="page singup withbutton">
          <div>
            <div className="page__title">Sign in to profile</div>
            <div className="page__subtitle">
              We are very happy to see you again! Please enter your details
              below to continue
            </div>
            <div className="page__subtitle mobile__sub">
              We are very happy to see you again! Please enter your details
              below to continue
            </div>
          </div>
          <div className="invite__group">
            <div className="invite__part">
              <Input
                label="E-mail"
                type="email"
                name="email"
                error={errors.email}
                placeholder="Please enter e-mail"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <Input
                label="Password"
                type="password"
                name="password"
                error={errors.password}
                placeholder="Please enter your password"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              {loginError && (
                <div className="input__error error">
                  Wrong email or password
                </div>
              )}
            </div>
          </div>
          <div className="page__button">
            <div className={cn('accent__button', {
              'disabled_button': preloader
            })} onClick={() => singIn()}>
              { preloader && <img src="/images/loader-puprle.gif" className='loader_purple' alt="loader_purple"/>}
              Sign In
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default RegistrationForm;
