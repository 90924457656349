import React, { useEffect } from "react";
import { PageView } from "../../components/containers";
import { Transition } from "../../components/common";
import StoriesPage from "./StoriesPage";
import StoriesPageDefault from "./StoriesPageDefault";
import { connect } from "react-redux";
import {
  getStorybars,
  setStorybarOrder,
  setStorybarCaption,
} from "../../redux/actions/storybarsActions";
import { getStoriesList } from "../../redux/actions/storiesActions";

const fakeData = [
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "5506sdf32410-808f-11ea-ae73-45be6388dasd290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550634sdf2312312310-808f-11ea-ae73-45b3e68348290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "3455f0sdfsdf63sdf410-808f-11ea-ae73-45be3688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "550263sdf3sf410-808f-11ea-ae73-45be688sdf290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
  {
    title: "https://barrym.com",
    live: [
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063412342340-808f-11ea-ae73-sdff2345be3688290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
      {
        cards: [
          {
            snapshot: "",
            backgroundMedia: { thumb: "" },
          },
        ],
        createdAt: "2020-04-17T09:39:28.336Z",
        id: "55063sdf410-8wfefw8f-1ef1ea-ae73-45be6s88290cf",
        owner: "google-oauth2|104505801059652296797",
        screenSize: { width: 414, height: 736 },
        storyBarId: null,
        title: "Brown",
        user: {
          id: "google-oauth2|104505801059652296797",
          name: "awesome best",
          organization: "orga-3744b4",
          picture: "",
        },
      },
    ],
    user: {
      id: "google-oauth2|104505801059652296797",
      name: "awesome best",
      organization: "orga-3744b4",
      picture: "",
    },
  },
];

const Stories = ({
  getStorybars,
  getStoriesList,
  data,
  idToken,
  fetched,
  fetching,
  data2,
  fetched2,
  fetching2,
  deleteStoryFetching,
  setStorybarCaption,
  updateFetching,
  updateFetching2,
  setStorybarOrder,
}) => {
  useEffect(() => {
    getStorybars({ authToken: idToken });
    getStoriesList({ authToken: idToken });
  }, [idToken, getStorybars, getStoriesList]);

  const handleCaptionSet = (name, storybarId) => {
    setStorybarCaption({ authToken: idToken, name, storybarId });
  };

  return !fetching && fetched && !fetching2 && fetched2 ? (
    <Transition
      transition={500}
      withbutton={
        !((data && data.length !== 0) || (data2 && data2.length !== 0))
      }
    >
      <PageView
        defaultView={<StoriesPageDefault />}
        isShow={(data && data.length !== 0) || (data2 && data2.length !== 0)}
      >
        <StoriesPage
          data2={data}
          storyData={data2}
          fetching={
            fetching ||
            fetching2 ||
            updateFetching2 ||
            updateFetching ||
            deleteStoryFetching
          }
          handleCaptionSet={handleCaptionSet}
          setStorybarOrder={(order, storybarId, data) =>
            setStorybarOrder({
              storybarId,
              order,
              newdata: data,
              authToken: idToken,
            })
          }
        />
      </PageView>
    </Transition>
  ) : (
    <StoriesPage data2={fakeData} loading />
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  let { idToken } = auth;
  const storybars = state.storybars.toJS();
  let { data, fetched, fetching, updateFetching } = storybars;
  const stories = state.stories.toJS();
  let {
    data: data2,
    fetched: fetched2,
    fetching: fetching2,
    deleteStoryFetching,
    updateFetching: updateFetching2,
  } = stories;

  return {
    data,
    data2,
    fetched2,
    fetching2,
    idToken,
    fetched,

    fetching,
    deleteStoryFetching,
    updateFetching,
    updateFetching2,
  };
};

const mapDispatchToProps = {
  getStorybars,
  getStoriesList,
  setStorybarOrder,
  setStorybarCaption,
};

export default connect(mapStateToProps, mapDispatchToProps, null)(Stories);
