import React from "react";

const ProductBox = ({ pos, scale }) => {
  return (
    <div
      className="product__box"
      style={{
        left: (pos.left - 10) * scale.width,
        top: (pos.top - 10) * scale.height,
      }}
    />
  );
};

export default ProductBox;
