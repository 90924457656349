import React, { useRef } from "react";
//import Truncate from "react-truncate";
import { RightArrow } from "../../icons/icons";
import "../../scss/components/ProductTag.scss";

const ProductTag = ({ pos, product, onClick, active, scale }) => {
  const priceRef = useRef(null);

  return (
    <div
      className="product__tag"
      style={Object.assign(
        active
          ? {
              top: (pos.top - 80) * scale.height,
              left: (pos.left - 87) * scale.width,
              opacity: 1,
            }
          : {
              top: (pos.top - 150) * scale.height,
              left: (pos.left - 87) * scale.height,
              opacity: 0,
            },
        {
          transform: `scale(${scale.width},${scale.height})`,
        }
      )}
      onClick={onClick}
    >
      <div className="product__name">{product.name.substring(0, 15)}</div>

      <div className="product__price" ref={priceRef}>
        {product.price}
        <RightArrow />
      </div>
    </div>
  );
};

export default ProductTag;

// <Truncate
// lines={2}
// ellipsis={
//   <>
//     <span>...</span>
//     <div
//       style={{
//         width: priceRef.current
//           ? priceRef.current.offsetWidth + 7
//           : 11,
//       }}
//     ></div>
//   </>
// }
// >
// {/* 14 spaces */}
// {product.name + ""}
// </Truncate> }
