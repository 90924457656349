import React, { useMemo } from "react";

const ProductLabel = ({
  pos,
  screenSize = { width: 414, height: 736 },
  error,
  hovered,
  isInst = false,
  size,
}) => {
  const [width, height] = useMemo(() => {
    return [size.width / screenSize.width, size.height / screenSize.height];
  }, [size, screenSize]);

  return (
    <div
      className={`product__label${error ? ` errorBord` : ``}${
        hovered ? ` hovered` : ``
      }${isInst ? ` inst` : ``}`}
      style={
        pos
          ? {
              top: pos.top * height - (window.innerWidth < 500 ? 13 : 20),
              left: pos.left * width - (window.innerWidth < 500 ? 13 : 20),
            }
          : { top: 0, left: 0 }
      }
    />
  );
};

export default ProductLabel;
