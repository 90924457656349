import React from "react";

const NewInput = ({ label, error, required, disabled = false, ...props }) => {
  return (
    <div
      className={`new-input ${error ? "invalid" : ""}${
        disabled ? " new-input--disabled" : ""
      }`}
    >
      <label className="new-input__label">
        {label}
        {required ? <span className="requiredspan">*</span> : ""}
      </label>
      <input
        className="new-input__input"
        {...props}
        disabled={disabled}
        autocomplete="off"
      />
      {error ? <div className="new-input__error error">{error}</div> : null}
    </div>
  );
};

export default NewInput;
