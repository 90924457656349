import React, { memo } from "react";

//Return color id when become active
const ColorCheckbox = memo(({ id, color, isActive, onClick }) => {
  return !isActive ? (
    <div
      className="color__checkbox"
      style={{ background: color }}
      onClick={() => onClick(id)}
    />
  ) : (
    <div
      className="active__checkbox color__checkbox"
      style={{ background: "#F8F9FA" }}
      onClick={() => onClick(id)}
    >
      <div className="active__color" style={{ background: color }} />
    </div>
  );
});

export default ColorCheckbox;
