import React from "react";
import { Plus } from "../../icons/icons";

const AddStory = ({ onClick, size }) => {
  return (
    <div
      className="new__story"
      style={{
        width: size.width,
        height: size.height,
      }}
    >
      <div className="plus" onClick={onClick}>
        <Plus />
      </div>
    </div>
  );
};

export default AddStory;
