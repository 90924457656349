import React from 'react';
import '../../scss/components/ProductCard.scss';

const ProductCard = ({ image, name, id, setProduct }) => {
	return (
		<div className="product__card" onClick={() => setProduct(id)}>
			<div className="product__image" style={{ backgroundImage: `url('${image}')` }} />
			<div className="product__name">{name}</div>
		</div>
	);
};

export default ProductCard;
