import React, { useState } from "react";
import background from "../../images/header__background.png";
import { withRouter } from "react-router-dom";
import {
  RaddLogo,
  Notification,
  CreateStoryButton,
  Menu,
  AvatarIcon,
} from "../../icons/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Avatar } from "../common";
import { logout } from "../../redux/actions/orgActions";
import client from "../../redux/utils/client";
import CreateStory from "../hoc/createStory";
import "../../scss/components/mainHeader.scss";

const Header = ({
  headerTitle,
  profile,
  orgInfo,
  history,
  logout,
  isCustomer,
  customerData,
}) => {
  const [dropOpen, setDropOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="header" style={{ backgroundImage: `url('${background}')` }}>
      <div className="container">
        <div className="header__top">
          <div className="header__logo">
            <RaddLogo />
          </div>
          <div className="mobile__navigation">
            <Dropdown
              button={<Menu />}
              isOpen={menuOpen}
              setIsOpen={setMenuOpen}
              dropdown={
                <div className="setting__block">
                  <div className="mobile__logo">
                    <RaddLogo />
                  </div>
                  <div className="setting__part">
                    <p className="setting__link">
                      <Link
                        to="/"
                        className="nav__link"
                        id={headerTitle === "Home" ? "active__nav" : null}
                      >
                        Home
                      </Link>
                    </p>
                    <p className="setting__link">
                      <Link
                        to="/stories"
                        className="nav__link"
                        id={headerTitle === "Stories" ? "active__nav" : null}
                      >
                        Stories
                      </Link>
                    </p>
                    <p className="setting__link">
                      <Link
                        to="/live"
                        className="nav__link"
                        id={headerTitle === "LIVE" ? "active__nav" : null}
                      >
                        Live
                      </Link>
                    </p>
                    <p className="setting__link">
                      <Link
                        to="/discover"
                        className="nav__link"
                        id={headerTitle === "Discover" ? "active__nav" : null}
                      >
                        Discover
                      </Link>
                    </p>
                    <p className="setting__link">
                      <Link
                        to="/invite"
                        className="nav__link"
                        id={headerTitle === "Invite" ? "active__nav" : null}
                      >
                        Invite
                      </Link>
                    </p>
                  </div>
                  {/* <div className="setting__part">
                     <p className="setting__link viewtutorial">
                      <Link
                        to="/view-tutorial"
                        className={`viewtutorial__button nav__link ${
                          headerTitle === "View tutorial" ? "active" : ""
                        }`}
                      >
                        View tutorial
                      </Link>
                    </p> 
                  </div> */}
                </div>
              }
            />
          </div>
          <div className="header__navigation">
            <Link
              to="/"
              className="nav__link"
              id={headerTitle === "Home" ? "active__nav" : null}
            >
              Home
            </Link>
            <Link
              to="/stories"
              className="nav__link"
              id={headerTitle === "Stories" ? "active__nav" : null}
            >
              Stories
            </Link>
            <Link
              to="/live"
              className="nav__link"
              id={headerTitle === "LIVE" ? "active__nav" : null}
            >
              LIVE
            </Link>
            <Link
              to="/discover"
              className="nav__link"
              id={headerTitle === "Discover" ? "active__nav" : null}
            >
              Discover{" "}
              {customerData && customerData.length > 99 ? (
                <div className="link-notification">
                  {Math.min(customerData.length, 99) + "+"}
                </div>
              ) : null}
              {customerData && customerData.length <= 99 ? (
                <div className="link-notification">
                  {Math.min(customerData.length, 99)}
                </div>
              ) : null}
            </Link>
            <Link
              to="/invite"
              className="nav__link"
              id={headerTitle === "Invite" ? "active__nav" : null}
            >
              Invite
            </Link>
            <Link
              to="/analytics"
              className="nav__link"
              id={headerTitle === "Analytics" ? "active__nav" : null}
            >
              Analytics
            </Link>
          </div>
          <div className="header__tutorial">
            {/* <Link
              to="/view-tutorial"
              className={`viewtutorial__button nav__link ${
                headerTitle === "View tutorial" ? "active" : ""
              }`}
            >
              View tutorial
            </Link> */}
          </div>
          <div className="header__profile">
            <Dropdown
              button={<AvatarIcon />}
              isOpen={dropOpen}
              setIsOpen={setDropOpen}
              dropdown={
                <div className="profile__dropdown">
                  <Link to="/plans" style={{ color: "inherit" }}>
                    <div className="profile__plan">
                      radd.
                      <span>{profile.plan}</span>
                    </div>
                  </Link>

                  <div className="profile__info">
                    <div className="avatar">
                      <Avatar
                        image={orgInfo.logo ? orgInfo.logo : profile.picture}
                      />
                    </div>
                    <div className="info">
                      <div className="name">{profile.name}</div>
                      <div className="email">{profile.email}</div>
                    </div>
                  </div>
                  <div className="profile__settings">
                    <div
                      className="profile__link"
                      onClick={() => {
                        history.push(`/manage-account`);
                      }}
                    >
                      Manage account
                    </div>
                    <a
                      href="mailto:info@raddcontent.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="profile__link"
                      style={{ display: "block" }}
                    >
                      Contact Us
                    </a>

                    {/* <div className="profile__link">Get help</div>
                    <div className="profile__link">How to use</div> */}
                    {!profile.shop_url ? (
                      <div
                        className="profile__link error"
                        onClick={() => client.logout()}
                      >
                        Log out
                      </div>
                    ) : null}
                  </div>
                </div>
              }
            />
            <div className="profile__divider" />
            <div className="profile__notification">
              <Notification />
            </div>
          </div>
        </div>
        <div className="header__bottom">{headerTitle}</div>
        <CreateStory className="createstory__headerbutton cursor__button">
          <CreateStoryButton />
        </CreateStory>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth.toJS();
  const stories = state.stories.toJS();
  let { profile, orgInfo } = auth;
  let { customerData } = stories;

  return {
    profile,
    orgInfo,
    customerData,
  };
};
const mapDispatchToProps = {
  logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
