import { v1 as uuid } from "uuid";
import _ from "lodash";

import {
  fetchStory,
  fetchStoryUpdate,
  fetchStoryReorder,
  fetchStoryProduct,
} from "../utils/fetchStories";
import { fetchProductData } from "../utils/fetchProduct";
import store from "../reducers";
import { uploadFile } from "../utils/fileUpload";
import { showPlanPopupPopup } from "./infoPopupActions";

export const actions = {
  STORY_PENDING: "STORY_PENDING",
  STORY_FULFILLED: "STORY_FULFILLED",
  STORY_REJECTED: "STORY_REJECTED",
  RESET_STORY_DATA: "RESET_STORY_DATA",
  CARDS_REORDER: "CARDS_REORDER",
  ADD_STORY_DELTA: "ADD_STORY_DELTA",
  SEND_STORY_DELTA_REJECTED: "SEND_STORY_DELTA_REJECTED",
  SET_STORY_TITLE: "SET_STORY_TITLE",
  SET_CARD_BACKGROUND_IMAGE: "SET_CARD_BACKGROUND_IMAGE",
  REMOVE_CARD: "REMOVE_CARD",
  ADD_CARD: "ADD_CARD",
  SET_ACTIVE_CARD: "SET_ACTIVE_CARD",
  SET_TEXT_PARAM: "SET_TEXT_PARAM",
  CREATE_TEXT: "CREATE_TEXT",
  SET_STORY_DELTA_RAW: "SET_STORY_DELTA_RAW",
  UPLOAD_CARD_BACKGROUND_PENDING: "UPLOAD_CARD_BACKGROUND_PENDING",
  UPLOAD_CARD_BACKGROUND_FULFILLED: "UPLOAD_CARD_BACKGROUND_FULFILLED",
  UPLOAD_CARD_BACKGROUND_REJECTED: "UPLOAD_CARD_BACKGROUND_REJECTED",
  UPLOAD_CARD_SNAPSHOT_PENDING: "UPLOAD_CARD_SNAPSHOT_PENDING",
  UPLOAD_CARD_SNAPSHOT_FULFILLED: "UPLOAD_CARD_SNAPSHOT_FULFILLED",
  UPLOAD_CARD_SNAPSHOT_REJECTED: "UPLOAD_CARD_SNAPSHOT_REJECTED",
  SET_CARD_EMBED_DATA: "SET_CARD_EMBED_DATA",
  CREATE_STORY_REJECTED: "CREATE_STORY_REJECTED",
  REMOVE_LOCAL_CARD: "REMOVE_LOCAL_CARD",
  GET_PRODUCT_DATA: "GET_PRODUCT_DATA",
  GET_PRODUCT_DATA_SUCCESS: "GET_PRODUCT_DATA_SUCCESS",
  GET_PRODUCT_DATA_FAILURE: "GET_PRODUCT_DATA_FAILURE",
  RESET_PRODUCT_DATA: "RESET_PRODUCT_DATA",
  ADD_CUSTOMER_DATA: "ADD_CUSTOMER_DATA",
  ADD_CUSTOMER_DATA_SUCCES: "ADD_CUSTOMER_DATA_SUCCES",
};

export function getStory({ storyId, authToken, isNewStory = false }) {
  return async (dispatch) => {
    dispatch({
      type: actions.STORY_PENDING,
    });

    try {
      let data;

      if (isNewStory) {
        data = { result: {} };
      } else {
        data = await fetchStory({ storyId, authToken });
      }

      dispatch({
        type: actions.STORY_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.STORY_REJECTED,
        error,
      });
    }
  };
}

export function fetchProduct(
  shopifyId,
  url,
  cardId,
  prodId,
  storyId,
  authToken
) {
  return async (dispatch) => {
    try {
      let data = await fetchStoryProduct({
        storyId,
        data: { url, cardId, prodId },
        authToken,
      });
      const cardInd = _.findIndex(data.result.cards, (o) => o._id === cardId);
      let prodInd = undefined;
      if (cardInd !== -1) {
        prodInd = _.findIndex(
          data.result.cards[cardInd].products,
          (o) => o._id === prodId
        );
      }

      if (cardInd !== -1 && prodInd !== -1) {
        let deltaTa = data.result.cards[cardInd].products[prodInd];

        if (deltaTa) {
          delete deltaTa._id;
          deltaTa.url = url;
          deltaTa.error = deltaTa.error || false;
          deltaTa.shopifyId = shopifyId;
          console.log(deltaTa);
          dispatch(
            addStoryDelta(
              {
                cards: [
                  {
                    _id: cardId,
                    products: [
                      {
                        _id: prodId,
                        ...deltaTa,
                      },
                    ],
                  },
                ],
              },
              storyId,
              authToken
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
    // let data = await getData(url);
    // console.log(data);

    // var diffbot = new Diffbot();
    // diffbot.product(url, function (err, response) {
    //   if (err) {
    //     dispatch(
    //       setProdParams(cardId, prodId, { error: true }, storyId, authToken)
    //     );
    //     return;
    //   }
    //   dispatch(
    //     setProdParams(
    //       cardId,
    //       prodId,
    //       {
    //         image: _.hasIn(response, `images[0].url`)
    //           ? response.images[0].url
    //           : ``,
    //         name: response.title || ``,
    //         price: response.offerPrice || ``,
    //         error: false,
    //       },
    //       storyId,
    //       authToken
    //     )
    //   );
    // });
  };
}

export function resetStoryData() {
  return {
    type: actions.RESET_STORY_DATA,
  };
}

// send updates no more than once per 250 ms
const sendDeltaToServer = _.debounce(
  async ({ authToken, storyId, dispatch }) => {
    const delta = store.getState().editStory.get("storyDelta").toJS();

    console.log("delta:", delta);

    if (_.isEmpty(delta)) return;

    try {
      await fetchStoryUpdate({
        authToken,
        storyId,
        delta,
      });

      store.dispatch({
        type: actions.SET_STORY_DELTA_RAW,
        data: {},
      });
    } catch (error) {
      console.error(error);

      store.dispatch({
        type: actions.SET_STORY_DELTA_RAW,
        data: {},
      });

      if (delta.cards && delta.cards.findIndex((e) => e.__new) !== -1) {
        store.dispatch({
          type: actions.REMOVE_LOCAL_CARD,
          data: {
            cards: [
              {
                _id: delta.cards[delta.cards.findIndex((e) => e.__new)]._id,
                _action: "delete",
              },
            ],
          },
        });
      }
      if (error.code === "story_limit") {
        dispatch(
          showPlanPopupPopup({
            storiesText: "Upgrade to continue uploading stories",
            titleText: "You have reached your maximum story limit",
          })
        );
      }

      dispatch({
        type: actions.CREATE_STORY_REJECTED,
        error,
      });

      dispatch({
        type: actions.SEND_STORY_DELTA_REJECTED,
        error,
      });
    }
  },
  250
);

export function getProductData(url) {
  return async (dispatch) => {
    dispatch({
      type: actions.GET_PRODUCT_DATA,
    });

    try {
      const data = await fetchProductData({ url });
      dispatch({
        type: actions.GET_PRODUCT_DATA_SUCCESS,
        data: data.result,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_PRODUCT_DATA_FAILURE,
      });
    }
  };
}

export function resetProductData() {
  return async (dispatch) => {
    dispatch({
      type: actions.RESET_PRODUCT_DATA,
    });
  };
}

export function setCardsOrder({ storyId, cards, authToken }) {
  return async (dispatch) => {
    try {
      let data = await fetchStoryReorder({ storyId, cards, authToken });
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
}

export function addStoryDelta(data, storyId, authToken, params = {}) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_STORY_DELTA,
      data,
      params,
    });

    await sendDeltaToServer({ authToken, storyId, dispatch });
  };
}

export function setStoryTitle(title, storyId, authToken) {
  return addStoryDelta(
    {
      title,
    },
    storyId,
    authToken
  );
}

export function setStorySound(cardId, sound, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          sound,
        },
      ],
    },
    storyId,
    authToken
  );
}

export function removeRating(cardId, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          rating: 0,
        },
      ],
    },
    storyId,
    authToken
  );
}

export function setCardOrder(data, storyId, authToken) {
  return addStoryDelta(
    {
      cardOrder: data,
    },
    storyId,
    authToken
  );
}

export function setStoryPublishing(publishing, storyId, authToken) {
  return addStoryDelta(
    {
      publishing,
    },
    storyId,
    authToken
  );
}

export function setStoryCover(coverImage, storyId, authToken) {
  return addStoryDelta(
    {
      coverImage,
    },
    storyId,
    authToken
  );
}

export function setSnapshot(cardId, snapshot, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          snapshot,
        },
      ],
    },
    storyId,
    authToken
  );
}

export function setCardBackgroundImage(
  cardId,
  src,
  { isVideo = false },
  storyId,
  authToken
) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          backgroundMedia: {
            isVideo,
            full: src,
          },
        },
      ],
    },
    storyId,
    authToken,
    { noDelta: true }
  );
}

export function removeCard(cardId, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          _action: "delete",
        },
      ],
    },
    storyId,
    authToken
  );
}

export function addCard(cardInd, storyId, authToken) {
  let cardData = {
    _id: uuid(),
    _index: cardInd,
    __new: true,
    backgroundColor: "#bec3c9",
    texts: [],
    products: [],
  };

  return addStoryDelta(
    {
      cards: [cardData],
    },
    storyId,
    authToken
  );
}

export function addCustomerCard(cardInd, cardData, storyId, authToken) {
  return async (dispatch) => {
    dispatch({
      type: actions.ADD_CUSTOMER_DATA,
    });

    let cardData2 = {
      _index: cardInd,
      __new: true,
      ...cardData,
      _id: uuid(),
    };

    dispatch(
      addStoryDelta(
        {
          cards: [cardData2],
        },
        storyId,
        authToken
      )
    );

    setTimeout(() => {
      dispatch({
        type: actions.ADD_CUSTOMER_DATA_SUCCES,
      });
    }, 2000);
  };
}

export function setActiveCard(cardInd) {
  return {
    type: actions.SET_ACTIVE_CARD,
    data: cardInd,
  };
}

export function setTextParam(cardId, textId, delta, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          texts: [
            {
              _id: textId,
              ...delta,
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function setProdParams(cardId, prodId, delta, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          products: [
            {
              _id: prodId,
              ...delta,
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function setInstagramParams(cardId, prodId, delta, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          instagrams: [
            {
              _id: prodId,
              ...delta,
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function createText(cardId, storyId, authToken) {
  let newText = {
    _id: uuid(),
    __new: true,
    isNew: true,
    position: { left: 1, top: 323 },
    text: "",
    rotation: 0,
    scale: 1,
    styles: {
      fontWeight: "normal",
      fontStyle: "normal",
      color: "black",
      textAlign: "left",
      colorId: 0,
    },
  };

  return (dispatch) => {
    dispatch(
      addStoryDelta(
        {
          cards: [
            {
              _id: cardId,
              texts: [newText],
            },
          ],
        },
        storyId,
        authToken
      )
    );

    return Promise.resolve(newText._id);
  };
}

export function createProduct(cardId, data, storyId, authToken) {
  let newProd = Object.assign(
    {
      _id: uuid(),
      __new: true,
    },
    data
  );

  return (dispatch) => {
    dispatch(
      addStoryDelta(
        {
          cards: [
            {
              _id: cardId,
              products: [newProd],
            },
          ],
        },
        storyId,
        authToken
      )
    );

    return Promise.resolve(newProd._id);
  };
}

export function createInstagram(cardId, data, storyId, authToken) {
  let newProd = Object.assign(
    {
      _id: uuid(),
      __new: true,
    },
    data
  );

  return (dispatch) => {
    dispatch(
      addStoryDelta(
        {
          cards: [
            {
              _id: cardId,
              instagrams: [newProd],
            },
          ],
        },
        storyId,
        authToken
      )
    );

    return Promise.resolve(newProd._id);
  };
}

export function removeText(cardId, textId, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          texts: [
            {
              _id: textId,
              _action: "delete",
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function removeProduct(cardId, prodId, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          products: [
            {
              _id: prodId,
              _action: "delete",
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function removeInstagram(cardId, prodId, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          instagrams: [
            {
              _id: prodId,
              _action: "delete",
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function makeTextActive(cardId, textId, textsCount, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          texts: [
            {
              _id: textId,
              _index: textsCount,
            },
          ],
        },
      ],
    },
    storyId,
    authToken
  );
}

export function setCardBackgroundColor(
  cardId,
  color,
  backgroundId,
  storyId,
  authToken
) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          backgroundColor: color,
          backgroundId,
        },
      ],
    },
    storyId,
    authToken
  );
}

export function uploadCardBackground({
  blob,
  storyId,
  cardId,
  authToken,
  ext,
}) {
  return async (dispatch) => {
    dispatch({
      type: actions.UPLOAD_CARD_BACKGROUND_PENDING,
    });

    try {
      await uploadFile({
        blob,
        storyId,
        cardId,
        authToken,
        ext,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UPLOAD_CARD_BACKGROUND_REJECTED,
        error,
      });
    }
  };
}

export function setCardEmbedData(cardId, data, storyId, authToken) {
  return addStoryDelta(
    {
      cards: [
        {
          _id: cardId,
          embed: data,
        },
      ],
    },
    storyId,
    authToken
  );
}
