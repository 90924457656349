//Get total count and format in to 10,100
export const getTotalInFormat = (data) => {
  return data
    .reduce(function (a, b) {
      return a + b;
    }, 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const analyticNames = [
  "Unique visitors",
  "Story views",
  "Story clicks",
  "Purchases",
];
