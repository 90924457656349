import React from 'react';
import '../../scss/components/Loader.scss';

const Loader = () => (
	<div className="spinner">
		<div className="spinner__circle">
			<div className="spinner__circle-gradient" />

			<div className="spinner__circle-inner" />
		</div>
	</div>
);

export default Loader;
