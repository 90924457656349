import React, { useState } from "react";
import "../../scss/components/InfoPreviewStartStream.scss";
import Countdown from "react-countdown";

export const InfoPreviewStartStream = ({ data, started }) => {
  const [startLiveStream, setStartLiveSteam] = useState(false);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setStartLiveSteam(true);
      return null;
    } else {
      // Render a countdown
      return (
        <p className={`stream__timer ${startLiveStream}`}>
          {hours}:{minutes}:{seconds}
        </p>
      );
    }
  };

  return (
    <div
      className="InfoPreviewStartStream desk"
      style={{ visibility: started ? "visible" : "hidden" }}
    >
      <h3 className="InfoPreviewStartStream__title">
        Are you ready to start your LIVE stream?
      </h3>
      <div className="checkSettings">
        <p>Before you start your LIVE stream please check:</p>
        <ol>
          <li>Your camera and microphone are turned on</li>
          <li>You have sufficient lighting</li>
          <li>
            No naked relatives in the background{" "}
            <span aria-label="donut" role="img">
              😅
            </span>
          </li>
        </ol>
      </div>
      <div className="startLive">
        <h3>Good luck and have fun!</h3>
        <button
          className={`start__stream ${startLiveStream} ${
            started ? "visible" : "hidden"
          }`}
          id="start-stream"
        >
          Start LIVE now
        </button>
        <Countdown date={new Date(data.time)} renderer={renderer} />
      </div>
    </div>
  );
};
