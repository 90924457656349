import React from "react";
import ProductUrl from "../common/ProductUrl";
import "../../scss/components/Links.scss";

const Links = ({
  products,
  handleChange,
  handleDelete,
  setHovered,
  isInst = false,
  par = {},
}) => {
  return (
    <div className="links_block">
      {products && products.length !== 0
        ? products.map((e, i) => {
            return (
              <ProductUrl
                {...par}
                key={i}
                index={i}
                id={e._id}
                text={e.url}
                isInst={isInst}
                isError={e.error || false}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setHovered={setHovered}
                product={e}
              />
            );
          })
        : null}
    </div>
  );
};

export default Links;
