import { fetchStory } from "../utils/fetchStories";
import { fetchStorybarsList } from "../utils/fetchStorybars";
import { fetchStorybarData } from "../utils/fetchStorybar";
import {
  fetchPublishStory,
  fetchUnpublishStory,
} from "../utils/fetchPublishStory";

export const actions = {
  PUBLISH_STORY_DATA_PENDING: "PUBLISH_STORY_DATA_PENDING",
  PUBLISH_STORY_DATA_FULFILLED: "PUBLISH_STORY_DATA_FULFILLED",
  PUBLISH_STORY_DATA_REJECTED: "PUBLISH_STORY_DATA_REJECTED",
  PUBLISH_STORY_PENDING: "PUBLISH_STORY_PENDING",
  PUBLISH_STORY_FULFILLED: "PUBLISH_STORY_FULFILLED",
  PUBLISH_STORY_REJECTED: "PUBLISH_STORY_REJECTED",
  UNPUBLISH_STORY_PENDING: "UNPUBLISH_STORY_PENDING",
  UNPUBLISH_STORY_FULFILLED: "UNPUBLISH_STORY_FULFILLED",
  UNPUBLISH_STORY_REJECTED: "UNPUBLISH_STORY_REJECTED",
  SHOW_PUBLISH_POPUP: "SHOW_PUBLISH_POPUP",
  HIDE_PUBLISH_POPUP: "HIDE_PUBLISH_POPUP",
};

export function getPublishStoryData({ authToken, storyId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.PUBLISH_STORY_DATA_PENDING,
    });

    try {
      let story = (await fetchStory({ authToken, storyId })).result;
      let storybars = [];
      if (story.storyBarId) {
        const storybar = (
          await fetchStorybarData({ authToken, barId: story.storyBarId })
        ).result;
        storybars.push(storybar);
      } else {
        storybars = (await fetchStorybarsList({ authToken })).result;
      }

      dispatch({
        type: actions.PUBLISH_STORY_DATA_FULFILLED,
        data: {
          story: story,
          storybars: storybars,
        },
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.PUBLISH_STORY_DATA_REJECTED,
        error,
      });
    }
  };
}

export function publishStory(
  { authToken, storyId, publishData },
  timeout = 60
) {
  if (timeout === 0) {
    return {
      type: actions.PUBLISH_STORY_REJECTED,
      error: new Error("Timeout on story publishing."),
    };
  }

  return async (dispatch) => {
    dispatch({
      type: actions.PUBLISH_STORY_PENDING,
    });

    try {
      let data = await fetchPublishStory({ authToken, storyId, publishData });

      dispatch({
        type: actions.PUBLISH_STORY_FULFILLED,
        data,
      });
    } catch (error) {
      if (error.code === "media_processing") {
        console.log("Media is processing, waiting 5s to try again");
        setTimeout(() => {
          dispatch(
            publishStory({ authToken, storyId, publishData }, timeout - 5)
          );
        }, 5000);
      } else {
        console.error(error);

        dispatch({
          type: actions.PUBLISH_STORY_REJECTED,
          error,
        });
      }
    }
  };
}

export function unpublishStory({ authToken, barId, storyId }) {
  return async (dispatch) => {
    dispatch({
      type: actions.UNPUBLISH_STORY_PENDING,
    });

    try {
      let data = await fetchUnpublishStory({ authToken, barId, storyId });

      dispatch({
        type: actions.UNPUBLISH_STORY_FULFILLED,
        data: data.result,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: actions.UNPUBLISH_STORY_REJECTED,
        error,
      });
    }
  };
}
