import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import normalizeUrl from "normalize-url";
import _ from "lodash";
import { Transition, StoriesCount } from "../../components/common";
import { CardsContainer } from "../../components/containers";
import { Button } from "../../components/atoms";
import PublishModal from "../../components/modal/PublishModal";
import { openStory } from "../../redux/utils/embedApi";
import { getStory, setStoryTitle } from "../../redux/actions/editStoryActions";
import { publishStory } from "../../redux/actions/publishStoryActions";
import { createStorybar } from "../../redux/actions/storybarsActions";
import { filterCards } from "../../helpers/storiesHelper";
import { PreviewIcon, UploadIcon } from "../../icons/icons";
import "../../scss/pages/Edit.scss";

const Edit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { storyData } = useSelector((state) => state.editStory.toJS());
  const { idToken, orgInfo } = useSelector((state) => state.auth.toJS());
  const { data } = useSelector((state) => state.storybars.toJS());
  const { publishPreloader } = useSelector((state) => state.stories.toJS());

  const [title, setTitle] = useState(null);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [preloader, setPreloader] = useState(false)
  //Get story on mount
  useEffect(() => {
    dispatch(
      getStory({
        storyId: id,
        authToken: idToken,
      })
    );

    // eslint-disable-next-line
  }, []);

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
  };

  const handleTitleUpdate = () => {
    dispatch(setStoryTitle(title, id, idToken));
  };

  const handlePuplishPopupOpen = () => {
    setPublishModalOpen(true);
  };

  const handlePuplishPopupClose = () => {
    setPublishModalOpen(false);
  };

  const handleStoryPublish = async (publish, options) => {
    setPreloader(true)
    const publ = [];

    if (publish.includes(`home`)) {
      if (orgInfo && orgInfo.homepage) {
        publ.push(orgInfo.homepage);
      }
    }
    if (publish.includes(`url`))
      publ.push(
        normalizeUrl(options.url, {
          forceHttps: true,
          stripHash: true,
          removeQueryParameters: true,
          removeTrailingSlash: true,
          removeSingleSlash: true,
        }) + "/"
      );

    const barsData = await Promise.all(
      _.uniq(_.flattenDeep(publ)).map(
        (e) =>
          new Promise(async (resolve, reject) => {
            const result = data.filter((el) => el.title === e);
            if (result.length !== 0) {
              resolve(result[0].id);
            } else {
              var barId = await dispatch(
                createStorybar({ name: e, authToken: idToken })
              );
              resolve(barId);
            }
          })
      )
    );

    await dispatch(
      publishStory({
        authToken: idToken,
        storyId: id,
        publishData: barsData.map((e) => ({ id: e, duration: 0 })),
      })
    );

      setPreloader(false)

    history.replace("/stories");
  };

  return !_.isEmpty(storyData) ? (
    <>
      <div className="topbar-wrapper topbar-wrappert--edit">
        <input
          className="story-title"
          placeholder="New Highlight*"
          value={title ?? storyData.title}
          onChange={handleTitleChange}
        />
        <Button
          type="secondary"
          text="Update"
          disabled={!title || title === storyData.title}
          onClick={handleTitleUpdate}
        />
        <Button
          type="outline"
          text="Preview"
          onClick={() => openStory({ storyData, owner: orgInfo })}
          icon={<PreviewIcon />}
        />
        <Button
          text="Publish"
          onClick={handlePuplishPopupOpen}
          disabled={!storyData.cards.every((e) => e.backgroundMedia) || publishPreloader}
          icon={<UploadIcon />}
        />
        <StoriesCount isEdit />
      </div>
      <Transition>
        <div className="page-wrapper edit-page">
          <CardsContainer
            storyData={storyData}
            idToken={idToken}
            cards={filterCards(storyData)}
          />
        </div>
      </Transition>
      {isPublishModalOpen && (
        <PublishModal
          title={storyData.title}
          preloader={preloader}
          onClose={handlePuplishPopupClose}
          onPublish={handleStoryPublish}
        />
      )}
    </>
  ) : null;
};

export default Edit;
