import Immutable from 'immutable';

import {actions as A} from '../actions/storybarActions';

const initialState = Immutable.fromJS({
    fetched: false,
    fetching: false,
    data: {},
    error: null,
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case A.RESET_STORYBAR_DATA:
            return initialState;
        case A.STORYBAR_DATA_PENDING:
            return state
                .set('fetched', false)
                .set('fetching', true);
        case A.STORYBAR_DATA_FULFILLED:
            return state
                .set('fetched', true)
                .set('fetching', false)
                .set('data', Immutable.fromJS(action.data));
        case A.STORYBAR_DATA_REJECTED:
            return state
                .set('fetched', false)
                .set('fetching', false)
                .set('error', action.error);
        default:
            return state;
    }
}