import React from 'react';
import TutorialItem from './TutorialItem';
import image from '../../../images/tutorial.png';
import '../../../scss/components/Tutorials.scss';

const Tutorials = () => {
	return (
		<div className="tutorials_container">
			<TutorialItem img={image} caption="Create & Edit" />
			<TutorialItem img={image} caption="Publish" />
			<TutorialItem img={image} caption="Discover" />
			<TutorialItem img={image} caption="Analytics" />
			<TutorialItem img={image} caption="Advanced Features" />
		</div>
	);
};

export default Tutorials;
