import React from "react";
import { Loader } from "./";

const StoryCardAnalytic = ({ analytic }) => {
  return (
    <div className="hint2">
      {analytic ? (
        <div>
          <div className="statistic__name">Views</div>
          <div className="statistic__data">{analytic.views || 0}</div>
          <div className="statistic__name">Clicks</div>
          <div className="statistic__data">{analytic.clicks || 0}</div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 124,
            height: 134,
          }}
        >
          <Loader />
        </div>
      )}
      <span className="triangle" />
      <span className="hide" />
    </div>
  );
};

export default StoryCardAnalytic;
