import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CardEdit from "../common/CardEdit";
import { addCard, setCardOrder } from "../../redux/actions/editStoryActions";
import { reorder } from "../../helpers/storiesHelper";
import { RightArrow, Plus } from "../../icons/icons";
import move from "../../images/move.svg";
import "../../scss/components/CardsContainer.scss";

const CardsContainer = ({ storyData, idToken, cards }) => {
  const dispatch = useDispatch();
  const [scrollPos, setScrollPos] = useState("left");
  const wrapper = useRef(null);

  useEffect(() => {
    const container = wrapper.current;
    setTimeout(() => {
      checkScrollbarPosition(container, container.scrollLeft);
    }, 100);
  }, [storyData]);

  const handleRightArrowClick = () => {
    const container = wrapper.current;
    container.scrollLeft += 330;
    const scrollLeft = container.scrollLeft + 330;
    checkScrollbarPosition(container, scrollLeft);
  };

  const handleLeftArrowClick = () => {
    const container = wrapper.current;
    container.scrollLeft -= 330;
    const scrollLeft = container.scrollLeft - 330;
    checkScrollbarPosition(container, scrollLeft);
  };

  const checkScrollbarPosition = (el, scrollLeft) => {
    if (scrollLeft <= 150) {
      const container = wrapper.current;
      if (container) container.scrollLeft = 0;
      setScrollPos("left");
      return;
    }
    if (el.scrollWidth <= scrollLeft + el.clientWidth) {
      setScrollPos("right");
      return;
    }
    setScrollPos("");
  };

  const handleAddNewCard = () => {
    dispatch(addCard(storyData.cards.length, storyData.id, idToken));
    const container = wrapper.current;
    setTimeout(() => {
      container.scrollLeft += 10000;
    }, 150);
  };

  const setStories = (cards) => {
    dispatch(
      setCardOrder(
        JSON.stringify({ order: cards.map((e) => e._id) }),
        storyData.id,
        idToken
      )
    );
  };

  const hasScroll = () => {
    if (
      wrapper.current &&
      wrapper.current.scrollWidth > wrapper.current.clientWidth
    ) {
      return true;
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const items = reorder(cards, result.source.index, result.destination.index);

    setStories(items);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      ...draggableStyle,
    };
  };

  return (
    <div className="cards-container__outer">
      {hasScroll() && (
        <>
          {scrollPos !== "left" && (
            <div
              className="cards-container__arrow cards-container__arrow--left"
              onClick={handleLeftArrowClick}
            >
              <RightArrow />
            </div>
          )}
          {scrollPos !== "right" && (
            <div
              className="cards-container__arrow"
              onClick={handleRightArrowClick}
            >
              <RightArrow />
            </div>
          )}
        </>
      )}

      <div className="cards-container__wrapper" ref={wrapper}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-card" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className="cards-container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {cards.map((card, i) => (
                  <Draggable key={card._id} draggableId={card._id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className="cards-container__item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="move__arrow">
                          <img
                            src={move}
                            alt="move"
                            {...provided.dragHandleProps}
                            className="drag__arrow"
                          />
                        </div>
                        <CardEdit
                          key={card._id}
                          cardData={card}
                          storyId={storyData.id}
                          storyData={storyData}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                <div
                  className="cards-container__add"
                  onClick={handleAddNewCard}
                >
                  <span className="plus">
                    <Plus />
                  </span>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default CardsContainer;
