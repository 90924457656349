import React from "react";
import startPage from "../images/startpage.svg";
import wholeBackground from "../images/WholeBackground.png";
import { NoLoginHeader } from "../components/containers";
import { Transition } from "../components/common";
import "../scss/pages/StartPage.scss";

const StartPage = ({ history }) => {
  return (
    <Transition>
      <div
        className="start__page"
        style={{ backgroundImage: `url('${wholeBackground}')` }}
      >
        <NoLoginHeader isBack={false} />
        <div className="startpage__title">
          Start sharing your beautiful stories
        </div>
        <img src={startPage} alt="" id="startpage__img" />
        <div className="page__button">
          <div
            className="accent__button"
            onClick={() => history.push(`/registration`)}
          >
            Start now
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default StartPage;
