import React from "react";
// import { CheckGroup, Hint } from "../../components/common";
// import { FacebookLogin } from "../../icons/icons";
import "../../scss/pages/Discover.scss";

const Discover = ({ getDiscoverList }) => {
  // const [logins, setLogins] = useState([]);

  // const authInst = () => {
  //   try {
  //     window.FB.login(
  //       function (response) {
  //         if (response.status === "connected") {
  //           localStorage.setItem("fb_token", response.authResponse.accessToken);
  //           getDiscoverList();
  //         }
  //       },
  //       {
  //         scope:
  //           "email,instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement",
  //       }
  //     );
  //   } catch (e) {}
  // };

  return (
    <div className="discover__page page withbutton">
      <div className="page__title">You have no stories to discover</div>
      {/* <div className="page__subtitle">
        Select pre-made stories and just add them here, if you don't have any go
        to the Discover tab
      </div>
      <div className="page__subtitle mobile__sub">
        Select pre-made stories and just add them here
      </div>
      <div className="align__center">
        <CheckGroup
          className="discover_checkgroup onegrid"
          onChange={setLogins}
          current={logins}
          items={[
            {
              name: "facebook",
              title: "Login Facebook",
              icon: (
                <Hint text="log in to publish stories on Facebook">
                  <FacebookLogin />
                </Hint>
              ),
              noAnimate: true,
            },
          ]}
        />
      </div>
      <div className="page__button">
        <div className="accent__button" onClick={authInst}>
          Discover Stories
        </div>
      </div> */}
    </div>
  );
};

export default Discover;
